import { useContext } from "react";

import get from "lodash/get";

import { Variables } from "~/constants/request";

import { VariablesContext } from "../context";

export const useVariable = <T>(name: Variables): T => {
  const variables = useContext(VariablesContext);

  return get(variables, name, null) as T;
};
