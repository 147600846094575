"use client";
import React, { useEffect, useState } from "react";

import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { getCountry } from "~/helpers/country/get-country";
import { getCountryLangFromLocale } from "~/helpers/locales";
import { useAzureConfigurator } from "~/services/azure-configurator/use-azure-configurator";

interface MapWrapperProps {
  children: React.ReactNode;
}

export const MapWrapper: React.FC<MapWrapperProps> = ({ children }) => {
  const { locale } = useParams<GlobalPathParams>();
  const language = getCountryLangFromLocale(locale);
  const country = getCountry(locale);
  const { appConfig } = useAzureConfigurator(locale);
  const apiKey = appConfig?.googleMaps?.apiKey;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!apiKey) {
      return;
    }

    import("@googlemaps/js-api-loader")
      .then(async ({ Loader }) => {
        const loader = new Loader({
          apiKey,
          language,
          region: country?.code2,
          libraries: ["places"],
        });

        return loader.load();
      })
      .then(() => setIsLoading(false));
  }, [apiKey, country?.code2, language]);

  if (isLoading) {
    return null;
  }

  return <>{children}</>;
};
