"use client";
import { useCallback, useEffect, useState } from "react";

import type SwiperCore from "swiper";

import { useWindowState } from "~/hooks/use-window-state/use-window-state";
import { Optional } from "~/types/general.types";

import { ANIMATION_SPEED } from "./constants";

interface ReturnType {
  setSwiper: (swiper?: SwiperCore) => void;
  isNextEnabled: boolean;
  isPrevEnabled: boolean;
  windowWidth: number;
  handleSliceChange: (swiper: SwiperCore) => void;
  handleSwiperProgress: (swiper: SwiperCore) => void;
  handleNextClick: () => void;
  handlePrevClick: () => void;
  handleElementFocus: (index: number) => void;
  swiper?: SwiperCore;
}

export const useSwiper = (
  slideStep: number,
  initialIsNextEnabled = false,
  initialIsPrevEnabled = false,
): ReturnType => {
  const [swiper, setSwiper] = useState<Optional<SwiperCore>>();
  const [isNextEnabled, setIsNextEnabled] = useState<boolean>(
    () => !!initialIsNextEnabled,
  );
  const [isPrevEnabled, setIsPrevEnabled] = useState<boolean>(
    () => !!initialIsPrevEnabled,
  );
  const { width: windowWidth } = useWindowState();

  useEffect(() => {
    if (swiper) {
      setIsNextEnabled(!swiper.isEnd);
      setIsPrevEnabled(!swiper.isBeginning);
    }
  }, [swiper?.isEnd, swiper?.isBeginning, swiper]);

  const handleSwiperProgress = useCallback((swiper: SwiperCore) => {
    setIsNextEnabled(!swiper.isEnd);
    setIsPrevEnabled(!swiper.isBeginning);
  }, []);

  const handleSliceChange = useCallback((swiper: SwiperCore) => {
    setIsNextEnabled(!swiper.isEnd);
    setIsPrevEnabled(!swiper.isBeginning);
  }, []);

  const handleNextClick = useCallback(() => {
    for (let index = 0; index < slideStep; index++) {
      swiper?.slideNext(ANIMATION_SPEED);
    }
  }, [swiper, slideStep]);

  const handlePrevClick = useCallback(() => {
    for (let index = 0; index < slideStep; index++) {
      swiper?.slidePrev(ANIMATION_SPEED);
    }
  }, [swiper, slideStep]);

  const handleElementFocus = useCallback(
    (index: number) => {
      swiper?.slideTo(index);
    },
    [swiper],
  );

  return {
    setSwiper,
    isNextEnabled,
    isPrevEnabled,
    windowWidth: windowWidth || 0,
    handleSliceChange,
    handleNextClick,
    handlePrevClick,
    handleElementFocus,
    swiper,
    handleSwiperProgress,
  };
};
