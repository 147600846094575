"use client";

import React from "react";

import { CombinedButton } from "~/components/combined-button/component";
import { Routes } from "~/constants/request";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { useProtectedRoutes } from "~/hooks/use-protected-routes/hook";
import { Account24 } from "~/theme/icons/components/account";

interface AccountButtonDesktopProps {
  className?: string;
}

export const AccountButtonDesktop: React.FC<AccountButtonDesktopProps> = ({
  className,
}) => {
  const { goToProfile } = useProtectedRoutes();

  return (
    <CombinedButton
      href={Routes.PROFILE}
      onClick={goToProfile}
      className={className}
      aria-label="my account"
      {...getTestAutomationProps("profile-link")}
      id="profile-link"
    >
      <Account24 />
    </CombinedButton>
  );
};
