export const GEO_LOCATION_DESKTOP_PREFIX = "GeoLocationDesktop";

export const GEO_LOCATION_DESKTOP_CLASSES = {
  labelContainer: `${GEO_LOCATION_DESKTOP_PREFIX}-labelContainer`,
  label: `${GEO_LOCATION_DESKTOP_PREFIX}-label`,
  tooltip: `${GEO_LOCATION_DESKTOP_PREFIX}-tooltip`,
  helperText: `${GEO_LOCATION_DESKTOP_PREFIX}-helperText`,
  toolTipContainer: `${GEO_LOCATION_DESKTOP_PREFIX}-toolTipContainer`,
  tooltipText: `${GEO_LOCATION_DESKTOP_PREFIX}-tooltipText`,
  closeButton: `${GEO_LOCATION_DESKTOP_PREFIX}-closeButton`,
  arrow: `${GEO_LOCATION_DESKTOP_PREFIX}-arrow`,
};
