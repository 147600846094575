/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  JSON: { input: { [key: string]: any }; output: { [key: string]: any } };
};

export interface AbandonedItem {
  quantity: Scalars["Int"]["input"];
  sku: Scalars["String"]["input"];
}

export interface BillingAddressInput {
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  line1?: InputMaybe<Scalars["String"]["input"]>;
  line2?: InputMaybe<Scalars["String"]["input"]>;
  postCode?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export enum CacheControlScope {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export interface CompetitionLandingForm {
  campaignId: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  locale: Scalars["String"]["input"];
  marketingCommunicationOptInPc: Scalars["Boolean"]["input"];
  preferredStore?: InputMaybe<Scalars["String"]["input"]>;
}

export interface DateOfBirthInput {
  day: Scalars["String"]["input"];
  month: Scalars["String"]["input"];
  year: Scalars["String"]["input"];
}

export interface EntityFilterQuery {
  salesChannel?: InputMaybe<EntityFilterQueryParams>;
}

export interface EntityFilterQueryParams {
  not?: InputMaybe<Scalars["Boolean"]["input"]>;
  operator?: InputMaybe<Operators>;
  values?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
}

export interface FilterQuery {
  category?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  colorCode?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  colors?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  genderAttr?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  pattern?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  primarkCares?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  productType?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sizes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sku_price?: InputMaybe<Array<InputMaybe<SkuPriceInput>>>;
  stores?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  style?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  theme?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
}

export enum Operators {
  And = "AND",
  Or = "OR",
}

export enum PageType {
  ArticleNavItem = "ArticleNavItem",
  ArticlePage = "ArticlePage",
  BillingAddressesPage = "BillingAddressesPage",
  CategoryPage = "CategoryPage",
  Gcp = "Gcp",
  HomePage = "HomePage",
  OrderDetailsPage = "OrderDetailsPage",
  OrderHistoryPage = "OrderHistoryPage",
  PaymentCardsPage = "PaymentCardsPage",
  Pdp = "Pdp",
  ProfilePage = "ProfilePage",
  Rbp = "Rbp",
  SearchResults = "SearchResults",
  ShoppingBagPage = "ShoppingBagPage",
  ShoppingListPage = "ShoppingListPage",
  Sitemap = "Sitemap",
  StoreDetails = "StoreDetails",
  StoreLocator = "StoreLocator",
  StoresIn = "StoresIn",
  ThanksForRegisteringPage = "ThanksForRegisteringPage",
  ThanksForYourOrderPage = "ThanksForYourOrderPage",
}

export interface PaymentMethodInput {
  billingAddress?: InputMaybe<BillingAddressInput>;
  cardSchema?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  expirationDate?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  maskedPan?: InputMaybe<Scalars["String"]["input"]>;
  psp?: InputMaybe<Scalars["String"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
}

export interface ProductFilterQuery {
  key?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
}

export interface SkuPriceInput {
  end?: InputMaybe<Scalars["Int"]["input"]>;
  start?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface UpdateAccountInput {
  accountSource?: InputMaybe<Scalars["String"]["input"]>;
  billingAddresses?: InputMaybe<Array<InputMaybe<BillingAddressInput>>>;
  campaignId?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  dateOfBirth?: InputMaybe<DateOfBirthInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  marketingCommunicationOptInPc?: InputMaybe<Scalars["Boolean"]["input"]>;
  paymentMethods?: InputMaybe<Array<InputMaybe<PaymentMethodInput>>>;
  personContactId?: InputMaybe<Scalars["String"]["input"]>;
  personHasOpedOutOfEmail?: InputMaybe<Scalars["Boolean"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  preferredLanguage: Scalars["String"]["input"];
  preferredStore?: InputMaybe<Scalars["String"]["input"]>;
  selectedStore?: InputMaybe<Scalars["String"]["input"]>;
  utmCampaign?: InputMaybe<Scalars["String"]["input"]>;
  utmContent?: InputMaybe<Scalars["String"]["input"]>;
  utmMedium?: InputMaybe<Scalars["String"]["input"]>;
  utmSource?: InputMaybe<Scalars["String"]["input"]>;
  utmTerm?: InputMaybe<Scalars["String"]["input"]>;
}
