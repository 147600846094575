import * as React from "react";
import { useContext } from "react";

import { Box, Button, IconButton, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import dynamic from "next/dynamic";

import { GEO_LOCATION_DESKTOP_CLASSES } from "~/components/geo-location-desktop/constants";
import { GeoSearchErrorPopUp } from "~/components/pop-up/components/geo-search-error-pop-up/component";
import { StoreSelectorContext } from "~/context/store-selector/context";
import { importComponentByName } from "~/helpers/import-component-by-name";
import { useGeoLocation } from "~/hooks/use-geo-location/use-geo-location";
import { COLOR_BLUE_BRAND_50 } from "~/theme/colors";
import { Close12 } from "~/theme/icons/components/close-12";
import { SmallPointer16 } from "~/theme/icons/components/small-pointer";
import { getFormatMedia } from "~/theme/utils";

import { StoreSelectorUseType } from "../store-selector/types";

import { LabelContainer, TooltipContainer, TooltipStyled } from "./styled";

const StoreSelector = dynamic(async () =>
  importComponentByName(
    import("~/components/store-selector/component"),
    "StoreSelector",
  ),
);

export const GeoLocationDesktop = (): React.ReactElement => {
  const theme = useTheme();
  const { BREAKPOINT_DESKTOP } = getFormatMedia(theme);
  const isDesktopView = useMediaQuery(BREAKPOINT_DESKTOP);
  const storeSelector = useContext(StoreSelectorContext);
  const {
    isTooltipVisible,
    handleShareLocationClick,
    handleCloseButtonClick,
    geolocationTextFields,
    currentStore,
    handleChangeCurrentStore,
    storesInStoreSelector,
    offset,
    handleStoresChange,
    handleOffsetChange,
    searchShopAllowed,
    isPopUpActive,
    handlePopupClose,
  } = useGeoLocation(isDesktopView);

  return (
    <StoreSelector
      onCurrentStoreChange={handleChangeCurrentStore}
      onStoresChange={handleStoresChange}
      onOffsetChange={handleOffsetChange}
      storesInStoreSelector={storesInStoreSelector}
      currentStore={currentStore?.geomodifier ? currentStore : null}
      offset={offset}
      type={StoreSelectorUseType.geolocation}
      mainProps={storeSelector?.props}
    >
      {({ openStoreSelector }) => {
        return (
          <TooltipStyled
            title={
              <TooltipContainer onClick={handleShareLocationClick}>
                <Typography
                  component="span"
                  className={GEO_LOCATION_DESKTOP_CLASSES.tooltipText}
                >
                  {geolocationTextFields.tooltipText}
                </Typography>
                <IconButton
                  onClick={handleCloseButtonClick}
                  className={GEO_LOCATION_DESKTOP_CLASSES.closeButton}
                  size="large"
                >
                  <Close12 />
                </IconButton>
              </TooltipContainer>
            }
            placement="bottom-start"
            arrow
            open={isDesktopView && isTooltipVisible}
            TransitionProps={{ timeout: 500 }}
            slotProps={{ arrow: {} }}
          >
            <LabelContainer id="geolocation">
              <SmallPointer16 htmlColor={COLOR_BLUE_BRAND_50} />
              {geolocationTextFields.labelLinkText ? (
                <Box
                  component="span"
                  className={GEO_LOCATION_DESKTOP_CLASSES.helperText}
                >
                  {geolocationTextFields.labelLinkText}
                </Box>
              ) : (
                <Box />
              )}
              <Button
                className={GEO_LOCATION_DESKTOP_CLASSES.label}
                onClick={
                  searchShopAllowed ? openStoreSelector : handleShareLocationClick
                }
              >
                {geolocationTextFields.linkText}
              </Button>
              <GeoSearchErrorPopUp
                open={isPopUpActive}
                handleClose={handlePopupClose}
                openStoreSelector={openStoreSelector}
                title={geolocationTextFields.geoNoStoreFoundTitle}
                description={geolocationTextFields.geoNoStoreFoundBody}
              />
            </LabelContainer>
          </TooltipStyled>
        );
      }}
    </StoreSelector>
  );
};
