import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { COLOR_BLACK } from "~/theme/colors";

import { MENU_ITEM_CLASSES } from "./constants";

export const MenuItemContainer = styled(Typography)(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
    [`&.${MENU_ITEM_CLASSES.buttonRoot}`]: {
      width: "100%",
      border: "none",
      backgroundColor: "white",
      "&:hover, &:focus": {
        backgroundColor: "rgba(0, 0, 0, 0.05) !important",
      },
      color: COLOR_BLACK,
    },
    [`& .${MENU_ITEM_CLASSES.imageBox}`]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    [`& .${MENU_ITEM_CLASSES.label}`]: {
      font: "inherit",
      letterSpacing: "inherit",
    },
    [`& .${MENU_ITEM_CLASSES.arrow}`]: {
      marginLeft: "auto",
    },
  };
});
