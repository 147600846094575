"use client";

import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import type { Components } from "react-markdown";

import { CombinedLink } from "~/components/combined-link/component";
import { Markdown } from "~/components/markdown/component";
import { config } from "~/components/markdown/constants";
import { processNbspInMarkdown } from "~/components/markdown/helpers";
import { FUNCTIONAL_COOKIES } from "~/constants/cookie-categories";
import { OPTANON_CONSENT_COOKIES } from "~/constants/user-cookies";
import { getCookieCategories } from "~/helpers/one-trust/cookie-parser";
import { getTestAutomationProps } from "~/helpers/test-automation-props";

import { INFORMATION_BANNER_CLASSES } from "./constants";
import { useInfoBanner } from "./hooks";
import { InformationBannerContainer } from "./styled";

export interface InformationBannerProps {
  props?: {
    link?: {
      label?: string | null;
      url?: string | null;
    } | null;
    text?: string | null;
    newText?: string | null;
    textColor?: string | null;
    backgroundColor?: string | null;
  };
  onInfoBannerClose?: () => void;
  isInfoBannerClose?: boolean;
}

export const infoBannerBaseId = "info-banner";
export const days = 2;

export const InformationBanner = React.forwardRef<
  HTMLElement,
  InformationBannerProps
>(({ props, onInfoBannerClose, isInfoBannerClose }, ref) => {
  const { text, newText, textColor, link, backgroundColor } = props ?? {};
  const [isInfoBannerOpen, setIsInfoBannerOpen] = useState<boolean>(true);

  const { infoBannerCookieValue, setInfoBannerCookieValue } = useInfoBanner();

  useEffect(() => {
    if (!infoBannerCookieValue && !isInfoBannerClose) {
      setIsInfoBannerOpen(true);
      return;
    }
    setIsInfoBannerOpen(false);
  }, [infoBannerCookieValue, isInfoBannerClose, setIsInfoBannerOpen]);

  const linkAriaLabel = newText?.replace(/\[([^\]]+)\]\([^)]+\)/g, "$1"); // remove links from text

  const markdownConfig = {
    ...config,
    a: ({ children, href }) => (
      <CombinedLink
        href={href}
        target="_self"
        aria-label={processNbspInMarkdown(linkAriaLabel ?? "")}
      >
        {children}
      </CombinedLink>
    ),
  } as Components;

  useEffect(() => {
    const OptanonConsent = Cookies.get(OPTANON_CONSENT_COOKIES);

    if (OptanonConsent) {
      const cookieCategories = getCookieCategories(OptanonConsent);
      const isFunctionalCookieAccepted = cookieCategories[FUNCTIONAL_COOKIES];

      if (isFunctionalCookieAccepted && isInfoBannerClose) {
        setInfoBannerCookieValue(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInfoBannerClose]);
  return (
    <InformationBannerContainer
      {...getTestAutomationProps(infoBannerBaseId)}
      id={infoBannerBaseId}
      textColor={textColor ?? ""}
      backgroundColor={backgroundColor ?? ""}
      isInfoBannerOpen={isInfoBannerOpen}
    >
      <Box className={INFORMATION_BANNER_CLASSES.txtContainer} ref={ref}>
        {newText ? (
          <Markdown text={processNbspInMarkdown(newText)} config={markdownConfig} />
        ) : (
          <>
            <Typography
              {...getTestAutomationProps(`${infoBannerBaseId}-text`)}
              className={INFORMATION_BANNER_CLASSES.text}
              variant="body1"
              component="span"
            >
              {text}
            </Typography>
            <Typography
              {...getTestAutomationProps(`${infoBannerBaseId}-link`)}
              className={INFORMATION_BANNER_CLASSES.link}
              variant="body1"
              component="span"
            >
              <CombinedLink href={link?.url ?? ""}>{link?.label}</CombinedLink>
            </Typography>
          </>
        )}
      </Box>
      <IconButton
        {...getTestAutomationProps(`${infoBannerBaseId}-close-button`)}
        aria-label="Close"
        className={INFORMATION_BANNER_CLASSES.closeButton}
        onClick={onInfoBannerClose}
        size="large"
      >
        <CloseIcon />
      </IconButton>
    </InformationBannerContainer>
  );
});

InformationBanner.displayName = "InformationBanner";
