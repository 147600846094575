"use client";

import React from "react";

import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  COLOR_GREYSCALE_BLACK_8,
  COLOR_PRIMARY_BLACK,
  COLOR_PRIMARY_WHITE,
  COLOR_SUCCESS_BACKGROUND,
} from "~/theme/colors";
import { FontWeights } from "~/theme/typography";
import { getFormatMedia } from "~/theme/utils";

import { ADDED_TO_COLLECT_BAG_POPUP_CLASSES } from "./constants";

interface StyleProps {
  isOrderAmountMet: boolean;
}

export const AddedToCollectBagPopUpContainer = styled(
  ({ isOrderAmountMet, ...restProps }: StyleProps & BoxProps) => (
    <Box {...restProps} />
  ),
)(({ theme, isOrderAmountMet }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    padding: theme.spacing(2, 3, 0),
    background: COLOR_PRIMARY_WHITE,
    filter: "drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.15))",
    "&:before": {
      content: "''",
      position: "absolute",
      right: "24px",
      top: "-12px",
      width: 24,
      height: 24,
      background: COLOR_PRIMARY_WHITE,
      transform: "rotate(45deg)",
    },
    [formatMedia.BREAKPOINT_TABLET]: {
      width: 375,
    },
    [`& .${ADDED_TO_COLLECT_BAG_POPUP_CLASSES.infoContainer}`]: {
      display: "flex",
      padding: theme.spacing(2, 0, 2.25),
      borderBottom: `1px solid ${COLOR_GREYSCALE_BLACK_8}`,
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(2),
      },
    },
    [`& .${ADDED_TO_COLLECT_BAG_POPUP_CLASSES.infoTextContainer}`]: {
      maxWidth: 215,
      wordWrap: "break-word",
    },
    [`& .${ADDED_TO_COLLECT_BAG_POPUP_CLASSES.productImage}`]: {
      width: 96,
    },
    [`& .${ADDED_TO_COLLECT_BAG_POPUP_CLASSES.price}`]: {
      fontWeight: FontWeights.fontWeightBold,
      marginBottom: theme.spacing(2),
    },
    [`& .${ADDED_TO_COLLECT_BAG_POPUP_CLASSES.totalPrice}`]: {
      fontWeight: FontWeights.fontWeightMedium,
      marginBottom: theme.spacing(2),
    },
    [`& .${ADDED_TO_COLLECT_BAG_POPUP_CLASSES.totalOrderContainer}`]: {
      display: "flex",
      marginTop: theme.spacing(2),
      justifyContent: "space-between",
    },
    [`& .${ADDED_TO_COLLECT_BAG_POPUP_CLASSES.orderAmountMetContainer}`]: {
      padding: theme.spacing(0.75, 3),
      margin: theme.spacing(0, -3),
      background: isOrderAmountMet ? COLOR_SUCCESS_BACKGROUND : COLOR_PRIMARY_BLACK,
      color: isOrderAmountMet ? COLOR_PRIMARY_BLACK : COLOR_PRIMARY_WHITE,
    },
    [`& .${ADDED_TO_COLLECT_BAG_POPUP_CLASSES.buttonGroup}`]: {
      gridGap: 16,
      display: "flex",
      marginBottom: theme.spacing(2),
      justifyContent: "space-between",
      "*": {
        flex: 1,
      },
    },
  };
});
