import Cookies from "js-cookie";

import { PREVIOUS_PATH_FOR_BR_EMPTY_VALUE } from "~/constants/bloomreach";
import { EMPTY_ROUTE_HISTORY_MASK } from "~/context/router-history/constants";
import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";

import { PREVIOUS_PATH } from "../../../constants/local-storage";

export const getRefUrlClient = (pageLocale: string) => {
  const config = AzureConfigurator.getConfig(pageLocale);
  const isBloomreachRefUrl = config?.featureFlagList?.bloomreachRefUrl?.enabled;

  if (!isBloomreachRefUrl) {
    return PREVIOUS_PATH_FOR_BR_EMPTY_VALUE;
  }

  const previousPathsCookie = Cookies.get(PREVIOUS_PATH);
  if (!previousPathsCookie) {
    return PREVIOUS_PATH_FOR_BR_EMPTY_VALUE;
  }
  const previousPaths = previousPathsCookie.split(",");
  if (previousPaths[0] === EMPTY_ROUTE_HISTORY_MASK) {
    return PREVIOUS_PATH_FOR_BR_EMPTY_VALUE;
  }

  return previousPaths[0] ?? PREVIOUS_PATH_FOR_BR_EMPTY_VALUE;
};
