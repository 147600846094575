"use client";

import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import clsx from "clsx";

import { NavItemProps } from "~/bff/components/NavItem";
import { CombinedLink } from "~/components/combined-link/component";
import { convertComponentProps } from "~/components/global-navigation/helpers/convert-component-props";
import { getNavItemUrl } from "~/components/global-navigation/helpers/get-navItem-url";
import { globalNavigationKey } from "~/components/global-navigation/mock";
import { NavItemWithMeta } from "~/components/global-navigation/types";
import { getTestAutomationProps } from "~/helpers/test-automation-props";

import { SUB_CATEGORY_GROUP_CLASSES } from "./constants";
import { SubCategoryGroupContainer } from "./styled";

export interface SubCategoryGroupProps {
  itemClassName?: string;
  navItem: NavItemProps;
}

const subCategoryContentItemId = "global-navigation-sub-category-content-item";

export const SubCategoryGroup: React.FC<SubCategoryGroupProps> = ({
  itemClassName,
  navItem: { title: rootTitle, children, urlSlug, url, meta },
}) => {
  return (
    <SubCategoryGroupContainer
      {...getTestAutomationProps(`${globalNavigationKey}-subcategory-group`)}
    >
      {urlSlug || url ? (
        <Typography
          variant="body2"
          id={`${subCategoryContentItemId}-${meta?.deliveryId}`}
        >
          <CombinedLink
            href={url ? getNavItemUrl(url) : `/${urlSlug}`}
            className={itemClassName}
            underline="hover"
            display="block"
          >
            <Box component="strong">{rootTitle}</Box>
          </CombinedLink>
        </Typography>
      ) : (
        <Typography
          id={`${subCategoryContentItemId}-${meta?.deliveryId}`}
          className={itemClassName}
          variant="body2"
          {...getTestAutomationProps("title")}
        >
          <Box component="strong">{rootTitle}</Box>
        </Typography>
      )}
      {children?.map((item, index) => {
        const { title, urlSlug, url, meta } = convertComponentProps(
          item as NavItemWithMeta,
        );
        return (
          <Typography
            key={index}
            variant="body2"
            id={`${subCategoryContentItemId}-${meta?.deliveryId}`}
          >
            <CombinedLink
              href={url ? getNavItemUrl(url) : `/${urlSlug}`}
              className={clsx(SUB_CATEGORY_GROUP_CLASSES.item, itemClassName)}
              underline="hover"
              display="block"
            >
              {title}
            </CombinedLink>
          </Typography>
        );
      })}
    </SubCategoryGroupContainer>
  );
};
