export interface DYChooseResponseData {
  choices: {
    id: number;
    name: string;
    type: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    variations: { id: number; payload: { type: string; data: any } }[];
    decisionId: string;
  }[];
  cookies: { name: string; value: string; maxAge: string }[];
}

export interface ChooseCampaignVariationsArgs {
  pageType: DYContextType;
  pageData?: string[];
  pageLocation: string;
  pageLocale: string;
  campaignNames: DyCampaignName[];
  previewIds: string | string[];
  options?: {
    isImplicitPageview?: boolean;
    returnAnalyticsMetadata?: boolean;
    isImplicitImpressionMode?: boolean;
    isImplicitClientData?: boolean;
  };
}

export enum EngagementType {
  CLICK = "CLICK",
  SLOT_CLICK = "SLOT_CLICK",
  IMPRESSION = "IMP",
  SLOT_IMPRESSION = "SLOT_IMP",
  PUSH_NOTIFICATION_CLICK = "PN_CLICK",
}

export type CustomAsyncOperation = (
  choicesData: DYChooseResponseData | null,
) => void;

export enum DyCampaignName {
  POC = "PoC for DY Server Side Testing",
  RVP = "Recently Viewed Products",
  LoqateTesting = "Loqate API Testing",
  UgcTestingPdp = "UGC Testing PDP",
  Geolocation = "Geolocation",
  SplitterHomeNavigation = "Splitter Logic - Logo to link to the Homepage",
  CrossSellMVP = "CrossSellMVP",
  CrossSellFeatureBasket = "DY AB Cross-Sell Basket",
  MiniAddToBagCheckoutAndViewbagCTA = "Mini Add To Bag Checkout And Viewbag CTA",
  ApplePayExpressCheckoutAB = "Apple Pay Express Checkout AB",
}

export enum DYContextType {
  HomePage = "HOMEPAGE",
  Other = "OTHER",
  Category = "CATEGORY",
  Product = "PRODUCT",
  Cart = "CART",
}
