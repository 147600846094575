import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useParams, useSearchParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { Params } from "~/constants/request";
import { DySplitterHomeNavigationCampaignContextValue } from "~/context/dy-splitter-home-navigation-campaign/types";
import { chooseCampaignVariations } from "~/utils/dynamic-yield/choose-campaign-variations";
import { getTestCampaignChoicesFromResponse } from "~/utils/dynamic-yield/get-campaign-choices-from-response";
import { isDyCampaignEnabled } from "~/utils/dynamic-yield/is-dy-campaign-enabled";
import { reportUserEngagement } from "~/utils/dynamic-yield/report-user-engagement";
import {
  ChooseCampaignVariationsArgs,
  DyCampaignName,
  DYChooseResponseData,
  EngagementType,
} from "~/utils/dynamic-yield/types";

import { DynamicYieldContext } from "../dynamic-yield/context";

import { DySplitterHomeNavigationCampaignContext } from "./context";

export const DySplitterHomeNavigationCampaignProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const query = useSearchParams();
  const { locale: pageLocale } = useParams<GlobalPathParams>();
  const chooseCampaignVariationsArgs =
    useContext(DynamicYieldContext)?.chooseCampaignVariationsArgs;

  const [isDYEngagementEventTriggered, setIsDYEngagementEventTriggered] =
    useState(false);

  const [dyChooseResponseData, setDyChooseResponseData] =
    useState<DYChooseResponseData | null>(null);

  const isCampaignEnabled = isDyCampaignEnabled(
    DyCampaignName.SplitterHomeNavigation,
    pageLocale,
  );

  useEffect(() => {
    const fetchData = async () => {
      if (typeof chooseCampaignVariationsArgs === "undefined") {
        return;
      }
      const { pageType, pageData, pageLocation } = chooseCampaignVariationsArgs;

      const chooseCampaignData: ChooseCampaignVariationsArgs = {
        pageType,
        pageData,
        pageLocation,
        pageLocale,
        campaignNames: [DyCampaignName.SplitterHomeNavigation],
        previewIds: query.get(Params.DY_API_PREVIEW_IDS) ?? [],
        options: {
          isImplicitImpressionMode: false,
        },
      };

      const dyResponse = await chooseCampaignVariations({
        ...chooseCampaignData,
      });

      setDyChooseResponseData(dyResponse);
    };

    if (isCampaignEnabled) {
      fetchData();
    }
  }, [
    pageLocale,
    isCampaignEnabled,
    setDyChooseResponseData,
    query,
    chooseCampaignVariationsArgs,
  ]);

  const dyChoiceData = useMemo(
    () =>
      getTestCampaignChoicesFromResponse(
        dyChooseResponseData,
        DyCampaignName.SplitterHomeNavigation,
        pageLocale,
      ),
    [dyChooseResponseData, pageLocale],
  );

  const reportLogoClickEngagement = useCallback(async () => {
    const decisionId = dyChoiceData?.decisionId;

    if (decisionId && !isDYEngagementEventTriggered) {
      await reportUserEngagement(pageLocale, EngagementType.IMPRESSION, decisionId);

      setIsDYEngagementEventTriggered(true);
    }
  }, [dyChoiceData?.decisionId, isDYEngagementEventTriggered, pageLocale]);

  const contextValue = useMemo(() => {
    const value: DySplitterHomeNavigationCampaignContextValue = {
      isSplitterHomeNavigationCampaignEnabled: isCampaignEnabled,
      isHomeNavigationEnabled:
        dyChoiceData?.variations[0]?.payload?.data?.isHomepageNavigationEnabled ??
        false,
      reportLogoClickEngagement,
    };

    return dyChoiceData === null ? null : value;
  }, [dyChoiceData, isCampaignEnabled, reportLogoClickEngagement]);

  return (
    <DySplitterHomeNavigationCampaignContext.Provider value={contextValue}>
      {children}
    </DySplitterHomeNavigationCampaignContext.Provider>
  );
};
