import { DyCampaignName } from "./types";

export const DyCampaignsInfo: {
  [key in DyCampaignName]: {
    featureFlagName: string;
  };
} = {
  [DyCampaignName.POC]: {
    featureFlagName: "dyCampaignPoc",
  },
  [DyCampaignName.RVP]: {
    featureFlagName: "dyCampaignRVP",
  },
  [DyCampaignName.LoqateTesting]: {
    featureFlagName: "dyCampaignLoq",
  },
  [DyCampaignName.UgcTestingPdp]: {
    featureFlagName: "dyCampaignUgcPdp",
  },
  [DyCampaignName.Geolocation]: {
    featureFlagName: "dyCampaignGeo",
  },
  [DyCampaignName.SplitterHomeNavigation]: {
    featureFlagName: "dyCampaignSplitterHome",
  },
  [DyCampaignName.CrossSellMVP]: {
    featureFlagName: "crossSellFeatureBasket",
  },
  [DyCampaignName.CrossSellFeatureBasket]: {
    featureFlagName: "dyCampaignABCrossSellFeatureBasket",
  },
  [DyCampaignName.MiniAddToBagCheckoutAndViewbagCTA]: {
    featureFlagName: "dyCampaignABMiniAddToBagCheckoutAndViewbagCTA",
  },
  [DyCampaignName.ApplePayExpressCheckoutAB]: {
    featureFlagName: "dyCampaignABApplePayExpressCheckout",
  },
};

export const LOGGER_LABEL_PREFIX = "DY";

// See https://dy.dev/docs/page-context
export const DY_PAGE_DATA_EMPTY = [""];

export const DEFAULT_CHOOSE_CAMPAIGN_REQUEST_OPTIONS = {
  isImplicitPageview: false,
  returnAnalyticsMetadata: true,
  isImplicitImpressionMode: true,
  isImplicitClientData: true,
};
