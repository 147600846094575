"use client";

import * as React from "react";

import IconButton from "@mui/material/IconButton";

import { Right24 } from "~/theme/icons/components/right";

import { IconContainer } from "./styled";
import type { ArrowIconProps } from "./types";

export const ArrowIcon = (props: ArrowIconProps): React.ReactElement => {
  const { className, asButton, label } = props;

  return (
    <>
      {asButton ? (
        <IconButton className={className} aria-labelledby={label} size="large">
          <Right24 />
        </IconButton>
      ) : (
        <IconContainer className={className}>
          <Right24 />
        </IconContainer>
      )}
    </>
  );
};
