import React, { useMemo } from "react";

import { LocationStyled } from "./styled";

export interface LocationProps {
  description: string;
  matchedSubstrings: { length: number; offset: number }[];
  isFocused: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  placeId: string;
}

export const Location: React.FC<LocationProps> = ({
  description,
  matchedSubstrings,
  isFocused,
  onClick,
  placeId,
}) => {
  const formattedDescription = useMemo(() => {
    if (!matchedSubstrings) {
      return description;
    }
    const elements: (string | JSX.Element)[] = [];
    const sortedSubstrings = matchedSubstrings.sort((a, b) => a.offset - b.offset);
    sortedSubstrings.forEach((match, i) => {
      const { offset, length } = match;

      if (i === 0) {
        elements.push(description.slice(0, offset));
      } else {
        const prevMatch = sortedSubstrings[i - 1];
        elements.push(
          description.slice(prevMatch.offset + prevMatch.length, offset),
        );
      }

      const matchText = description.slice(offset, offset + length);
      elements.push(<strong key={i}>{matchText}</strong>);

      if (i === sortedSubstrings.length - 1) {
        elements.push(description.slice(offset + length));
      }
    });

    return elements;
  }, [description, matchedSubstrings]);

  return (
    <LocationStyled
      variant="text"
      onClick={(e) => onClick(e)}
      data-value={description}
      data-placeid={placeId}
      isFocused={isFocused}
    >
      {formattedDescription}
    </LocationStyled>
  );
};
