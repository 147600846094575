import { useState, useEffect } from "react";

import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { useSessionStorage } from "~/hooks/use-session-storage/hook";
import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";

import { AGEC_BANNER_STORAGE_KEY } from "../constants";

export const useAgecBanner = (): { shouldShowAgecBanner: boolean } => {
  const { locale } = useParams<GlobalPathParams>();
  const { getItem, setItem } = useSessionStorage();

  const isFeatureEnabled =
    AzureConfigurator.getConfig(locale)?.featureFlagList?.agecBanner?.enabled ||
    false;

  const [shouldShowAgecBanner, setShouldShowAgecBanner] = useState<boolean>(false);

  useEffect(
    function manageBannerVisibility() {
      const wasBannerAlreadyShown = getItem(AGEC_BANNER_STORAGE_KEY) === "1";

      if (!isFeatureEnabled || wasBannerAlreadyShown) {
        return;
      }

      setShouldShowAgecBanner(true);
      setItem(AGEC_BANNER_STORAGE_KEY, "1");
    },
    [isFeatureEnabled, getItem, setItem],
  );

  return {
    shouldShowAgecBanner: isFeatureEnabled && shouldShowAgecBanner,
  };
};
