import { useEffect, useState } from "react";

import { FUNCTIONAL_COOKIES } from "~/constants/cookie-categories";
import { awaitGlobalsInitialization } from "~/helpers/await-globals-initialization";
import { isCookiesGroupAccepted } from "~/helpers/one-trust/cookie-parser";

export const useIsFunctionalCookiesAccepted = () => {
  const [isFunctionalCookieAccepted, setIsFunctionalCookieAccepted] =
    useState(false);

  const initFunctionalCookieCheckCallback = () => {
    const isFunctionalCookies = isCookiesGroupAccepted(FUNCTIONAL_COOKIES);
    setIsFunctionalCookieAccepted(isFunctionalCookies);
  };

  useEffect(() => {
    if (!IS_SERVER) {
      awaitGlobalsInitialization({ windowVar: "OneTrust" }).then(() => {
        if (OneTrust?.IsAlertBoxClosed?.()) {
          initFunctionalCookieCheckCallback();
        } else {
          // eslint-disable-next-line no-restricted-globals
          window?.addEventListener(
            "consent.onetrust",
            initFunctionalCookieCheckCallback,
          );
        }
      });

      return () => {
        // eslint-disable-next-line no-restricted-globals
        window?.removeEventListener(
          "consent.onetrust",
          initFunctionalCookieCheckCallback,
        );
      };
    }
  }, []);

  return isFunctionalCookieAccepted;
};
