import { useCallback, useContext, useEffect, useState } from "react";

import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { StoreDetailsPageResponse } from "~/bff/transport/StoreDetailsPage";
import { Store } from "~/bff/types/Store";
import { StoreSelectorUseType } from "~/components/store-selector/types";
import { AuthContext } from "~/context/auth/context";
import { getStoreCollectionDates } from "~/helpers/get-store-collection-dates";
import { getStoreDetailsById } from "~/helpers/get-store-details-by-id";
import { getCountryCodeFromLocale } from "~/helpers/locales";
import { getSelectedOrPreferredStoreId } from "~/helpers/selected-store/get-selected-or-preferred-store-id";
import { isCurrentStoreInCurrentLocation } from "~/helpers/selected-store/is-current-store-in-current-location";
import { Nullable } from "~/types/general.types";

export interface UseStoreSelectorReturnTypes {
  currentStore?: Nullable<Store>;
  handleChangeCurrentStore?: (store?: Nullable<Store>) => void;
  handleClickOpenStoreSelector?: (callback: () => void) => () => void;
  storesInStoreSelector?: Nullable<Store>[];
  onStoresChange?: (stores?: Nullable<Store>[]) => void;
  offset?: number;
  onOffsetChange?: (offset: number) => void;
  isLoading?: boolean;
}

// TODO: Refactor this
let inFlightRequestForStoreDetails: Promise<
  StoreDetailsPageResponse | undefined
> | null = null;

export const useStoreSelectorData = (
  preferredStore?: Store | null,
  useType: string = StoreSelectorUseType.default,
): UseStoreSelectorReturnTypes => {
  const { locale } = useParams<GlobalPathParams>();
  const countryCode = getCountryCodeFromLocale(locale);
  const [currentStore, setCurrentStore] = useState<Store | null>(
    isCurrentStoreInCurrentLocation(countryCode, preferredStore ?? null)
      ? preferredStore ?? null
      : null,
  );
  const [storesInStoreSelector, setStoresInStoreSelector] = useState<
    Nullable<Store>[]
  >([]);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const onOffsetChange = useCallback((offset: number) => setOffset(offset), []);
  const authContext = useContext(AuthContext);

  const setStore = useCallback(
    (store?: Nullable<Store>) => {
      if (store?.trial && !authContext?.isMemberOfTrialStoreGroup) {
        setCurrentStore({ ...store, clickAndCollectStore: false });
        return;
      }
      setCurrentStore(store ?? null);
    },
    [setCurrentStore, authContext?.isMemberOfTrialStoreGroup],
  );

  const handleChangeCurrentStore = useCallback(
    (store?: Nullable<Store>) => {
      store && setStore(store);
    },
    [setStore],
  );

  const handleClickOpenStoreSelector = useCallback(
    (callback: () => void) => () => {
      callback();
    },
    [],
  );

  const handleStoreDetailsResponse = (
    promise: Promise<StoreDetailsPageResponse | undefined>,
  ) => {
    promise
      .then((response) => {
        const storeDetailsById = response?.storeDetailsPage?.props?.storeDetailsById;
        if (isCurrentStoreInCurrentLocation(countryCode, storeDetailsById)) {
          setStore(storeDetailsById ?? undefined);
        }
      })
      .finally(() => {
        inFlightRequestForStoreDetails = null;
        setIsLoading(false);
      });
  };

  const onStoresChange = useCallback((stores?: Nullable<Store>[]) => {
    setStoresInStoreSelector(stores ?? []);
  }, []);

  // Effect for handling store details
  useEffect(() => {
    const storeId = getSelectedOrPreferredStoreId(authContext?.account);

    if (!!storeId && storeId !== currentStore?.id) {
      if (preferredStore === null) {
        // We need to handle concurrent requests only on pages where we don't have  selected store.
        if (inFlightRequestForStoreDetails) {
          handleStoreDetailsResponse(inFlightRequestForStoreDetails);
        } else {
          inFlightRequestForStoreDetails = getStoreDetailsById(locale, storeId);
          handleStoreDetailsResponse(inFlightRequestForStoreDetails);
        }
      }
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore, locale, authContext?.account, countryCode, setStore]);

  // Effect for fetching collection dates
  useEffect(() => {
    const isShoppingBagPage = useType === StoreSelectorUseType.shoppingBag;
    const shouldFetchCollectionDates =
      isShoppingBagPage && currentStore && !currentStore.collectionDates;

    if (shouldFetchCollectionDates) {
      getStoreCollectionDates(locale, countryCode, currentStore.id).then(
        ({ storeDetailsPage }) => {
          const collectionDates =
            storeDetailsPage?.props?.storeDetailsById?.collectionDates;
          setStore({ ...currentStore, collectionDates });
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore, useType, locale, countryCode, setStore]);

  return {
    currentStore: currentStore ?? undefined,
    handleChangeCurrentStore,
    onStoresChange,
    handleClickOpenStoreSelector,
    storesInStoreSelector,
    offset,
    onOffsetChange,
    isLoading,
  };
};
