"use client";

import React from "react";

import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import {
  COLOR_BLACK,
  COLOR_WHITE,
  COLOR_GREYSCALE_BLACK_15,
  COLOR_PRIMARY_WHITE,
} from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

import { GEO_LOCATION_MOBILE_CLASS_NAMES } from "./constants";

export const LabelContainer = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 2),
    [formatMedia.BREAKPOINT_TABLET]: {
      padding: theme.spacing(1, 4),
    },
    [formatMedia.BREAKPOINT_DESKTOP]: {
      display: "none",
    },
    [`& .${GEO_LOCATION_MOBILE_CLASS_NAMES.text}`]: {
      ...theme.typography.body1,
      display: "flex",
      textDecoration: "underline",
      marginRight: theme.spacing(0.5),
      fontWeight: "bold",
      "&:hover, &:active, &:focus": {
        textDecoration: "underline",
      },
    },
  };
});

export const TooltipContainer = styled(Box)(({ theme }) => {
  return {
    padding: theme.spacing(2),
    [`& .${GEO_LOCATION_MOBILE_CLASS_NAMES.tooltipText}`]: {
      ...theme.typography.body2,
    },
    [`& .${GEO_LOCATION_MOBILE_CLASS_NAMES.closeButton}`]: {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      display: "block",
      padding: 0,
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
      color: COLOR_WHITE,
      background: "transparent",
      cursor: "pointer",
      fontSize: "8px",
      "&:hover, &:active, &:focus": {
        backgroundColor: "transparent",
      },
    },
  };
});

export const TooltipStyled = styled(
  ({ className, children, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}>
      {children}
    </Tooltip>
  ),
)(() => ({
  zIndex: 1000,
  [`& .${tooltipClasses.tooltipArrow}`]: {
    pointerEvents: "visible",
    top: 8,
    maxWidth: "90%",
    margin: "auto",
    padding: 0,
    background: COLOR_BLACK,
    borderRadius: 0,
    color: COLOR_WHITE,
    boxShadow: `0px 4px 16px 0px ${COLOR_GREYSCALE_BLACK_15}`,
    cursor: "pointer",
    marginTop: "0 !important",
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&::before": {
      background: COLOR_BLACK,
    },
  },
}));

export const GeolocationMobileContainer = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    [`& .${GEO_LOCATION_MOBILE_CLASS_NAMES.divider}`]: {
      [formatMedia.BREAKPOINT_DESKTOP]: {
        display: "none",
      },
    },
    [`& .${GEO_LOCATION_MOBILE_CLASS_NAMES.helperText}`]: {
      padding: theme.spacing(1.5, 2, 0, 2),
      fontSize: "12px",
      fontFamily: "Arial",
      fontWeight: 700,
      letterSpacing: "0.6px",
      [formatMedia.BREAKPOINT_TABLET]: {
        padding: theme.spacing(1.5, 4, 0, 4),
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        display: "none",
      },
    },
    [`& .${GEO_LOCATION_MOBILE_CLASS_NAMES.backdrop}`]: {
      color: COLOR_PRIMARY_WHITE,
      zIndex: 2,
    },
    [`& .${GEO_LOCATION_MOBILE_CLASS_NAMES.searchContainer}`]: {
      width: "100%",
      margin: theme.spacing(0.5, 0, 0.5, 1.5),
    },
    [`& .${GEO_LOCATION_MOBILE_CLASS_NAMES.control}`]: {
      margin: 0,
    },
  };
});
