"use client";

import React, { FunctionComponent, useMemo } from "react";

import kebabCase from "lodash/kebabCase";
import { ErrorBoundary } from "react-error-boundary";

import {
  BffComponent,
  BffComponentType,
  ComponentsConfig,
  Definition,
  IndependentDefinition,
} from "~/bff/ComponentsConfig";
import { ErrorFallback } from "~/components/error-boundary/component";

import { ComponentNotFound } from "./components/component-not-found/component";
import { UniversalComponentProps } from "./types";

export const UniversalComponent: FunctionComponent<UniversalComponentProps> = ({
  meta,
  pages,
  props,
  elements,
  component,
  componentsConfigs,
  parents,
}) => {
  const componentConfig = useMemo<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Definition<any> | IndependentDefinition | undefined
  >(() => {
    if (!component) {
      return undefined;
    }

    const componentConfig = componentsConfigs?.find((config) => config?.[component]);

    return componentConfig?.[component];
  }, [componentsConfigs, component]);

  const stopChildrenPreparation = useMemo<boolean>(
    () =>
      Boolean(
        componentConfig &&
          (componentConfig as IndependentDefinition).stopChildrenPreparation,
      ),
    [componentConfig],
  );

  const childConfigs = useMemo(
    () => [(componentConfig as Definition)?.overrides, ...(componentsConfigs ?? [])],
    [componentConfig, componentsConfigs],
  );

  const preparedProps = useMemo(() => {
    if (stopChildrenPreparation) {
      return Object.assign({}, props ?? {}, {
        meta: meta ?? null,
        pages: pages ?? null,
      });
    }

    return Object.assign({}, prepareProps(props, childConfigs) ?? {}, {
      meta: meta ?? null,
      pages: pages ?? null,
      testAutomationId: component ? kebabCase(component) : "",
    });
  }, [stopChildrenPreparation, childConfigs, component, meta, pages, props]);

  const Component = useMemo<React.FC<typeof preparedProps> | React.ComponentType>(
    () =>
      componentConfig?.component ??
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((props: any) => <ComponentNotFound component={component} {...props} />),
    [componentConfig, component],
  );

  const newParents = useMemo(
    () => (component ? [...(parents ?? []), component] : [...(parents ?? [])]),
    [component, parents],
  );

  return (
    <ErrorBoundary
      FallbackComponent={({ error, resetErrorBoundary }) => (
        <ErrorFallback
          resetErrorBoundary={resetErrorBoundary}
          error={error}
          component={component}
        />
      )}
    >
      <Component {...preparedProps}>
        {stopChildrenPreparation
          ? elements
          : Array.isArray(elements)
            ? elements.map((child, index) => {
                if (!child) {
                  return undefined;
                }
                const { component, props, pages, children } = child;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const key = `${index}-${component}-${(props as any)?.title || ""}`;
                return (
                  <UniversalComponent
                    key={key}
                    meta={child?._meta || child?.meta}
                    pages={pages}
                    props={props}
                    elements={children}
                    component={component as BffComponentType}
                    componentsConfigs={childConfigs}
                    parents={newParents}
                  />
                );
              })
            : undefined}
      </Component>
    </ErrorBoundary>
  );
};

export const prepareProps = (
  propObject: unknown,
  componentsConfigs?: (ComponentsConfig | undefined)[],
  key?: string | number,
): unknown => {
  const type = getObjectType(propObject);

  if (type === "Array") {
    return prepareArrayProps(propObject as unknown[], componentsConfigs);
  }

  if (type === "Object") {
    return prepareObjectProps(propObject, componentsConfigs, key);
  }

  return propObject;
};

export const getObjectType = (propObject: unknown): string => {
  return Object.prototype.toString.call(propObject).slice(8, -1);
};

const prepareArrayProps = (
  propArray: unknown[],
  componentsConfigs?: (ComponentsConfig | undefined)[],
): unknown[] => {
  return propArray.map((item, index) =>
    prepareProps(item, componentsConfigs, index),
  );
};

const prepareObjectProps = (
  propObject: unknown,
  componentsConfigs?: (ComponentsConfig | undefined)[],
  key?: string | number,
): unknown => {
  const bffComponent = propObject as BffComponent;

  if (bffComponent.component) {
    return createUniversalComponent(bffComponent, componentsConfigs, key);
  }

  return prepareStandardObjectProps(propObject, componentsConfigs);
};

const createUniversalComponent = (
  bffComponent: BffComponent,
  componentsConfigs?: (ComponentsConfig | undefined)[],
  key?: string | number,
): JSX.Element => {
  const { component, _meta, children, props } = bffComponent;
  return (
    <UniversalComponent
      key={key}
      meta={_meta}
      props={props}
      elements={children}
      component={component as BffComponentType}
      componentsConfigs={componentsConfigs}
    />
  );
};

const prepareStandardObjectProps = (
  propObject: unknown,
  componentsConfigs?: (ComponentsConfig | undefined)[],
): Record<string, unknown> => {
  const result: Record<string, unknown> = {};

  if (typeof propObject !== "object" || propObject === null) {
    return result;
  }

  for (const key in propObject) {
    if (Object.prototype.hasOwnProperty.call(propObject, key)) {
      result[key] = prepareProps(
        (propObject as Record<string, unknown>)[key],
        componentsConfigs,
      );
    }
  }

  return result;
};
