"use client";

import React from "react";

import { Box, Button, Backdrop, CircularProgress } from "@mui/material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import dynamic from "next/dynamic";

import { CustomLocationSearch } from "~/components/custom-location-search/component";
import { MapWrapper } from "~/components/map-wrapper/component";
import { GeoSearchErrorPopUp } from "~/components/pop-up/components/geo-search-error-pop-up/component";
import { StoreSelectorContext } from "~/context/store-selector/context";
import { importComponentByName } from "~/helpers/import-component-by-name";
import { useBreakpoints } from "~/hooks/use-break-points";
import { useGeoLocation } from "~/hooks/use-geo-location/use-geo-location";
import { COLOR_BLUE_BRAND_50 } from "~/theme/colors";
import { Close12 } from "~/theme/icons/components/close-12";
import { Pointer24 } from "~/theme/icons/components/pointer";

import { StoreSelectorUseType } from "../store-selector/types";

import { GEO_LOCATION_MOBILE_CLASS_NAMES } from "./constants";
import {
  GeolocationMobileContainer,
  LabelContainer,
  TooltipContainer,
  TooltipStyled,
} from "./styled";

const StoreSelector = dynamic(
  async () =>
    importComponentByName(
      import("~/components/store-selector/component"),
      "StoreSelector",
    ),
);

export const GeoLocationMobile = (): React.ReactElement => {
  const storeSelector = React.useContext(StoreSelectorContext);

  const { isSm, isXs, isMd } = useBreakpoints();

  const isMobileView = isSm || isXs || isMd;

  const {
    isTooltipVisible,
    handleShareLocationClick,
    handleCloseButtonClick,
    handleSearch,
    geolocationTextFields,
    currentStore,
    handleChangeCurrentStore,
    storesInStoreSelector,
    offset,
    handleStoresChange,
    handleOffsetChange,
    isGeoAllowed,
    searchShopAllowed,
    inProgress,
    displayLoader,
    isPopUpActive,
    handlePopupClose,
  } = useGeoLocation(isMobileView);

  const store = currentStore?.geomodifier ? currentStore : null;

  return (
    <GeolocationMobileContainer>
      <Backdrop
        open={inProgress}
        className={GEO_LOCATION_MOBILE_CLASS_NAMES.backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Divider className={GEO_LOCATION_MOBILE_CLASS_NAMES.divider} />
      {isGeoAllowed || currentStore?.name ? (
        <>
          {geolocationTextFields.labelLinkText ? (
            <Box
              component="div"
              className={GEO_LOCATION_MOBILE_CLASS_NAMES.helperText}
            >
              {geolocationTextFields.labelLinkText}
            </Box>
          ) : (
            <Box />
          )}
          <StoreSelector
            onCurrentStoreChange={handleChangeCurrentStore}
            onStoresChange={handleStoresChange}
            onOffsetChange={handleOffsetChange}
            storesInStoreSelector={storesInStoreSelector}
            currentStore={store}
            offset={offset}
            type={StoreSelectorUseType.geolocation}
            mainProps={storeSelector?.props}
          >
            {({ openStoreSelector }) => {
              return (
                <>
                  <GeoSearchErrorPopUp
                    open={isPopUpActive}
                    handleClose={handlePopupClose}
                    openStoreSelector={openStoreSelector}
                    title={geolocationTextFields.geoNoStoreFoundTitle}
                    description={geolocationTextFields.geoNoStoreFoundBody}
                  />
                  <TooltipStyled
                    title={
                      <TooltipContainer
                        className={GEO_LOCATION_MOBILE_CLASS_NAMES.toolTipBorders}
                        onClick={handleShareLocationClick}
                      >
                        <Box
                          component="span"
                          className={GEO_LOCATION_MOBILE_CLASS_NAMES.tooltipText}
                        >
                          {geolocationTextFields.tooltipText}
                        </Box>
                        <IconButton
                          className={GEO_LOCATION_MOBILE_CLASS_NAMES.closeButton}
                          onClick={handleCloseButtonClick}
                          size="large"
                        >
                          <Close12 />
                        </IconButton>
                      </TooltipContainer>
                    }
                    placement="bottom"
                    arrow
                    open={isMobileView && isTooltipVisible}
                    TransitionProps={{ timeout: 500 }}
                  >
                    <LabelContainer id="geolocation-mobile">
                      <Pointer24 htmlColor={COLOR_BLUE_BRAND_50} />
                      <Button
                        className={GEO_LOCATION_MOBILE_CLASS_NAMES.text}
                        onClick={
                          searchShopAllowed
                            ? openStoreSelector
                            : handleShareLocationClick
                        }
                      >
                        {geolocationTextFields.linkText}
                      </Button>
                    </LabelContainer>
                  </TooltipStyled>
                </>
              );
            }}
          </StoreSelector>
        </>
      ) : (
        <StoreSelector
          onCurrentStoreChange={handleChangeCurrentStore}
          onStoresChange={handleStoresChange}
          onOffsetChange={handleOffsetChange}
          storesInStoreSelector={storesInStoreSelector}
          currentStore={store}
          offset={offset}
          type={StoreSelectorUseType.geolocation}
          mainProps={storeSelector?.props}
        >
          {({ openStoreSelector }) => {
            return (
              <>
                <GeoSearchErrorPopUp
                  open={isPopUpActive}
                  handleClose={handlePopupClose}
                  openStoreSelector={openStoreSelector}
                  title={geolocationTextFields.geoNoStoreFoundTitle}
                  description={geolocationTextFields.geoNoStoreFoundBody}
                />
                <LabelContainer>
                  <Pointer24 htmlColor={COLOR_BLUE_BRAND_50} />
                  <MapWrapper>
                    <CustomLocationSearch
                      isHomePage={true}
                      searchPlaceholder={geolocationTextFields.searchPlaceholder}
                      handleSearch={handleSearch}
                      displayLoader={displayLoader}
                      classes={{
                        searchContainer:
                          GEO_LOCATION_MOBILE_CLASS_NAMES.searchContainer,
                        control: GEO_LOCATION_MOBILE_CLASS_NAMES.control,
                      }}
                    />
                  </MapWrapper>
                </LabelContainer>
              </>
            );
          }}
        </StoreSelector>
      )}
      <Divider className={GEO_LOCATION_MOBILE_CLASS_NAMES.divider} />
    </GeolocationMobileContainer>
  );
};
