"use client";
import React, { createContext, ReactNode, useContext } from "react";

import { Product } from "~/bff/types/Product";
import { Seo } from "~/bff/types/Seo";
import { Config } from "~/utils/jsonLd";

interface SeoSocialContext {
  pageDomain?: string;
  currentProduct: Product;
}
interface SeoMetaTitleContext {
  extraWord?: string;
  pageNumber?: string;
}
export interface CurrentProduct {
  name?: string;
  gender?: string;
  colour?: string;
  description?: string;
}

export interface ExtraSeoMetaTagsProps {
  seoSocial?: SeoSocialContext;
  seoMetaTitle?: SeoMetaTitleContext;
  seoUrlCanonical?: seoMetaLinkCanonical;
  showH1?: boolean;
  jsonLDConfig?: Config;
  colourExclusionList?: (string | null)[] | null;
  product?: CurrentProduct;
}

export interface SeoExtended extends Seo {
  extraProps?: ExtraSeoMetaTagsProps;
}

interface seoMetaLinkCanonical {
  pageDomain?: string;
}

interface SeoMetaTagsProviderProps {
  children: ReactNode | undefined;
  value: ExtraSeoMetaTagsProps;
}

export const SeoMetaTagsContext = createContext<ExtraSeoMetaTagsProps>({});

export const SeoMetaTagsProvider: React.FC<SeoMetaTagsProviderProps> = ({
  children,
  value,
}) => {
  return (
    <SeoMetaTagsContext.Provider value={value}>
      {children}
    </SeoMetaTagsContext.Provider>
  );
};

export const useSeoMetaTags = (): ExtraSeoMetaTagsProps => {
  return useContext(SeoMetaTagsContext) ?? {};
};

const marketingParamPatterns = [
  "fbclid",
  "gclid",
  "gbraid",
  "wbraid",
  "utm",
  "y_source",
  "sfmc",
];

export const getLinkWithoutMarketingParams = (link: string): string => {
  if (!link) {
    return "";
  }
  try {
    const url = new URL(link);
    const searchParams = url.searchParams;
    const searchParamsArray = Array.from(searchParams.entries());
    if (!searchParamsArray?.length) {
      return link;
    }
    searchParamsArray.forEach(([key]) => {
      if (marketingParamPatterns.some((pattern) => key?.startsWith(pattern))) {
        searchParams.delete(key);
      }
    });
    return url.toString();
  } catch (_) {
    return link;
  }
};
