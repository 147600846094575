import { head, last, times } from "lodash";

import { NavItemProps } from "~/bff/components/NavItem";
import { NavItem } from "~/bff/types/NavItem";
import { convertComponentProps } from "~/components/global-navigation/helpers/convert-component-props";

const COLUMN_MAX_LINE_COUNT = 12;
export const MAX_CATEGORY_COLUMN_NUMBER = 4;
const MIN_SEPARABLE_GROUP_SIZE = 4;

function chunkArrayToColumnsNumber<T = unknown>(
  array: T[],
  columnsCount = 2,
): T[][] {
  const result = [];
  const minLength = Math.floor(array.length / columnsCount);
  let tailCount = array.length % columnsCount;
  const arrayCopy = [...array];
  while (arrayCopy.length > 0) {
    result.push(arrayCopy.splice(0, tailCount ? minLength + 1 : minLength));
    tailCount--;
  }
  return result;
}

const chunkGroup = (
  group: NavItemProps | undefined | null,
  chunkFunction: (groupData: NavItem[]) => NavItem[][],
): NavItemProps[] =>
  chunkFunction(group?.children as NavItem[]).map(
    (subItemArray, index): NavItemProps => {
      const groupPart: NavItemProps = { ...group, children: subItemArray };
      if (index) {
        groupPart.title = null;
        groupPart.urlSlug = null;
      }
      return groupPart;
    },
  );

const chunkGroupToColumnsNumber = (
  group: NavItemProps | undefined | null,
  columnsCount = 2,
): NavItemProps[] =>
  chunkGroup(group, (groupData) =>
    chunkArrayToColumnsNumber(groupData, columnsCount),
  );

const chunkGroupToMaxLength = (
  group: NavItemProps,
  maxLength = COLUMN_MAX_LINE_COUNT - 1,
): NavItemProps[] => {
  const columnCount = group.children
    ? Math.ceil(group.children.length / maxLength)
    : undefined;
  return chunkGroup(group, (groupData) =>
    chunkArrayToColumnsNumber(groupData, columnCount),
  );
};

export const isItemGrouped = (item?: NavItemProps | undefined | null): boolean =>
  Boolean(item?.children?.length);
const isPartOfGroup = (item?: NavItemProps | null): boolean =>
  Boolean(item && !item?.title);
const isColumnOpen = (column?: Column) =>
  Boolean(
    column &&
      !isPartOfGroup(column.last) &&
      column.linesCounter < COLUMN_MAX_LINE_COUNT,
  );

const getRequiredSpace = (item: NavItemProps | undefined | null): number =>
  item?.children && isItemGrouped(item) ? 1 + item.children.length : 1;

const getSpareSpaceInColumn = (column: Column) =>
  isColumnOpen(column) ? COLUMN_MAX_LINE_COUNT - column.linesCounter : 0;

export class Column {
  list: (NavItemProps | null)[] = [];
  private _filledLinesCounter = 0;
  private _emptyLinesCounter = 0;
  private _groupsCounter = 0;
  private _singleItemsCounter = 0;

  constructor(list: Array<NavItemProps | null> = []) {
    list.forEach((item) => item && this.push(item));
  }

  get linesCounter(): number {
    return this._filledLinesCounter + this._emptyLinesCounter;
  }
  get filledLinesCounter(): number {
    return this._filledLinesCounter;
  }
  get groupsCounter(): number {
    return this._groupsCounter;
  }
  get singleItemsCounter(): number {
    return this._singleItemsCounter;
  }
  get itemsCounter(): number {
    return this.groupsCounter + this.singleItemsCounter;
  }

  get first(): NavItemProps | null | undefined {
    return head(this.list);
  }

  get last(): NavItemProps | null | undefined {
    return last(this.list);
  }

  get isColumnPartOfGroup(): boolean {
    return isPartOfGroup(this.first);
  }

  get isSingleGroup(): boolean {
    return Boolean(this.groupsCounter === 1 && !this.singleItemsCounter);
  }

  get isSeparable(): boolean {
    return Boolean(
      (this.groupsCounter &&
        (this.singleItemsCounter ||
          (this.first?.children &&
            this.first.children.length >= MIN_SEPARABLE_GROUP_SIZE))) ||
        this.singleItemsCounter > 1 ||
        this.groupsCounter > 1,
    );
  }

  isEmptyLineRequired = (newItem?: NavItemProps): boolean =>
    Boolean(this.last && (isItemGrouped(this.last) || isItemGrouped(newItem)));

  isEmptyLineRequiredToUnshift = (newItem?: NavItemProps): boolean =>
    Boolean(this.first && (isItemGrouped(this.first) || isItemGrouped(newItem)));

  push = (...items: NavItemProps[]): void => {
    items.forEach((item) => {
      if (!item) {
        return;
      }

      if (this.isEmptyLineRequired(item)) {
        this.list.push(null);
        this._emptyLinesCounter++;
      }

      const requiredSpace = getRequiredSpace(item);
      this._filledLinesCounter = this.filledLinesCounter + requiredSpace;
      if (isItemGrouped(item)) {
        this._groupsCounter++;
      } else {
        this._singleItemsCounter++;
      }

      this.list.push(item);
    });
  };

  pop = (
    popCount = 1,
  ): (NavItemProps | undefined | null)[] | NavItemProps | undefined | null => {
    const removedItems = times(
      popCount <= this.itemsCounter ? popCount : this.itemsCounter,
      () => {
        const item = this.list.pop();

        if (this.last === null) {
          this.list.pop();
          this._emptyLinesCounter--;
        }
        const requiredSpace = item ? getRequiredSpace(item) : 0;
        this._filledLinesCounter = this.filledLinesCounter - requiredSpace;
        if (item && isItemGrouped(item)) {
          this._groupsCounter--;
        } else {
          this._singleItemsCounter--;
        }
        return item;
      },
    );
    return removedItems.length > 1 ? removedItems : removedItems[0];
  };

  unshift = (...items: NavItemProps[]): void => {
    items.forEach((item) => {
      if (!item) {
        return;
      }

      if (this.isEmptyLineRequiredToUnshift(item)) {
        this.list.unshift(null);
        this._emptyLinesCounter++;
      }

      const requiredSpace = getRequiredSpace(item);
      this._filledLinesCounter = this.filledLinesCounter + requiredSpace;
      if (isItemGrouped(item)) {
        this._groupsCounter++;
      } else {
        this._singleItemsCounter++;
      }

      this.list.unshift(item);
    });
  };

  shift = (
    shiftCount = 1,
  ): (NavItemProps | undefined | null)[] | NavItemProps | undefined | null => {
    const removedItems = times(
      shiftCount <= this.itemsCounter ? shiftCount : this.itemsCounter,
      () => {
        const item = this.list.shift();

        if (this.first === null) {
          this.list.shift();
          this._emptyLinesCounter--;
        }
        const requiredSpace = getRequiredSpace(item);
        this._filledLinesCounter = this.filledLinesCounter - requiredSpace;
        if (isItemGrouped(item)) {
          this._groupsCounter--;
        } else {
          this._singleItemsCounter--;
        }
        return item;
      },
    );
    return shiftCount > 1 ? removedItems : removedItems[0];
  };
}

const divideToColumnsOfMaxHeight = (
  dataList: NavItem[],
  maxHeight = COLUMN_MAX_LINE_COUNT,
): Column[] => {
  return dataList.reduce((result: Column[], item: NavItem) => {
    const convertedItem = convertComponentProps(item);
    const requiredSpace = getRequiredSpace(convertedItem);

    if (requiredSpace > maxHeight && convertedItem) {
      chunkGroupToMaxLength(convertedItem).forEach((item) => {
        result.push(new Column([item]));
      });
    } else {
      let lastColumn = last(result);
      const spareSpaceInColumn = lastColumn ? getSpareSpaceInColumn(lastColumn) : 0;
      const emptyLineCount = Number(
        !!lastColumn?.isEmptyLineRequired(convertedItem),
      );

      if (!lastColumn || requiredSpace + emptyLineCount > spareSpaceInColumn) {
        result.push(new Column([]));
        lastColumn = last(result);
      }

      lastColumn && lastColumn.push(convertedItem);
    }
    return result;
  }, []);
};

const getColumnIndexToSplit = (columnList: Column[]) => {
  return columnList.reduce((columnIndexToSplit: number, item, index) => {
    if (!item.isSeparable || item.isColumnPartOfGroup) {
      return columnIndexToSplit;
    }

    const prevSelected = columnList[columnIndexToSplit];
    if (
      columnIndexToSplit === -1 ||
      item.linesCounter > prevSelected.linesCounter ||
      (item.linesCounter === prevSelected.linesCounter &&
        item.filledLinesCounter > prevSelected.filledLinesCounter)
    ) {
      return index;
    }

    return columnIndexToSplit;
  }, -1);
};

export const divideToNumberOfColumns = (
  dataList: NavItem[],
  minWidth = MAX_CATEGORY_COLUMN_NUMBER,
): Column[] => {
  const columnList = divideToColumnsOfMaxHeight(dataList);
  let columnsToFill = minWidth - columnList.length;

  const updateColumnIndex = (): number =>
    columnsToFill > 0 ? getColumnIndexToSplit(columnList) : -1;

  const processSingleGroup = (column: Column, index: number): Column[] => {
    let columnsNumberToDivideTo = 2;
    const preparedGroup = column.first;
    let nextColumnIndex = index + 1;

    while (columnList[nextColumnIndex]?.isColumnPartOfGroup) {
      const groupPart = columnList[nextColumnIndex].first;
      if (preparedGroup && groupPart?.children) {
        preparedGroup.children = preparedGroup?.children?.concat(groupPart.children);
      }
      columnsNumberToDivideTo++;
      nextColumnIndex++;
    }
    return chunkGroupToColumnsNumber(preparedGroup, columnsNumberToDivideTo).map(
      (item) => new Column([item]),
    );
  };

  const processNonGroup = (column: Column): Column[] => {
    const averageLength = Math.ceil(column.linesCounter / 2);
    const leftColumn = new Column();
    const rightColumn = new Column();

    while (column.linesCounter) {
      if (leftColumn.linesCounter < averageLength) {
        const itemFromStart = column.shift() as NavItemProps;
        leftColumn.push(itemFromStart);
      }
      if (rightColumn.linesCounter < averageLength) {
        const itemFromEnd = column.pop() as NavItemProps;
        if (itemFromEnd) {
          rightColumn.unshift(itemFromEnd);
        }
      }
    }
    return [leftColumn, rightColumn];
  };

  let columnIndexToSeparate = updateColumnIndex();
  while (columnIndexToSeparate >= 0) {
    const columnToSeparate = columnList[columnIndexToSeparate];
    let columnsToReplaceCounter = 1;
    let resultColumns: Column[] = [];

    if (columnToSeparate.isSingleGroup) {
      resultColumns = processSingleGroup(columnToSeparate, columnIndexToSeparate);
      columnsToReplaceCounter = resultColumns.length;
    } else {
      resultColumns = processNonGroup(columnToSeparate);
    }

    columnList.splice(
      columnIndexToSeparate,
      columnsToReplaceCounter,
      ...resultColumns,
    );
    columnsToFill = minWidth - columnList.length;
    columnIndexToSeparate = updateColumnIndex();
  }

  if (columnsToFill > 0) {
    times(columnsToFill, () => {
      columnList.push(new Column([]));
    });
  }

  return columnList;
};
