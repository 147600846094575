import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  PropsWithChildren,
  FunctionComponent,
  useContext,
} from "react";

import { useParams, usePathname } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { Product } from "~/bff/types/Product";
import { SkuColorAvailabilityDetails } from "~/bff/types/SkuColorAvailabilityDetails";
import { Store } from "~/bff/types/Store";
import { StyleInventory } from "~/bff/types/StyleInventory";
import { getNonStoreSpecificColourAvailability } from "~/components/sales-panel/helpers";
import { getStyleInventoryByStore } from "~/components/sales-panel/helpers/get-style-inventory-by-store";
import { Routes, Variables } from "~/constants/request";
import { useVariable } from "~/context/variables/hooks/use-variable";
import { getStoreDetailsById } from "~/helpers/get-store-details-by-id";
import { getSelectedOrPreferredStoreId } from "~/helpers/selected-store/get-selected-or-preferred-store-id";
import { isCurrentStoreInCurrentLocation } from "~/helpers/selected-store/is-current-store-in-current-location";

import { AuthContext } from "../auth/context";

import { ProductDetailsContext } from "./context";
import { InventoryExtendedType } from "./types";

export type ProductDetailsProviderProps = PropsWithChildren;

export const ProductDetailsProvider: FunctionComponent<
  ProductDetailsProviderProps
> = ({ children }) => {
  const { locale } = useParams<GlobalPathParams>();

  const pathname = usePathname();

  const countryCode = locale.split("-")[1];

  const authContext = useContext(AuthContext);
  const productPdpRequested = useVariable<Product>(Variables.PRODUCT);

  const [currentProduct, setCurrentProduct] = useState<Product>(productPdpRequested);
  const [selectedSize, setSelectedSize] = useState<string>("");
  const [currentStore, setCurrentStore] = useState<Store | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isStoreLoadedOrNoStore, setIsStoreLoadedOrNoStore] =
    useState<boolean>(false);
  const [inventoryByStore, setInventoryByStore] = useState<
    InventoryExtendedType[] | undefined
  >(undefined);
  const [isInventoryLoading, setIsInventoryLoading] = useState<boolean>(false);
  const [nonStoreSpecificInventory, setNonStoreSpecificInventory] = useState<
    (SkuColorAvailabilityDetails | null)[] | undefined | null
  >(undefined);
  const [currentSku, setCurrentSku] = useState<string>(""); // aka `selectedSize`? Who knows..
  const [currentPrice, setCurrentPrice] = useState<string>("");

  const prevCurrentStore = useRef<null | Store>(null);
  const prevCurrentSku = useRef<string | undefined>(undefined);

  const currentSkuInventoryInCurrentStore = useMemo<
    undefined | InventoryExtendedType
  >(
    () =>
      (inventoryByStore &&
        inventoryByStore.find((el) => el.skuId === (selectedSize || currentSku))) ||
      undefined,
    [currentSku, inventoryByStore, selectedSize],
  );

  const getInventoryByStore = useCallback(
    async (store: Store): Promise<StyleInventory | undefined> => {
      if (isInventoryLoading) {
        return;
      }
      const isStoreTrial = store?.trial;
      setIsInventoryLoading(true);
      try {
        const inventory = await getStyleInventoryByStore(
          locale,
          currentProduct?.styleCode ?? "",
          store?.id,
          isStoreTrial ?? undefined,
          authContext?.isMemberOfTrialStoreGroup,
        );

        setInventoryByStore((inventory?.data as InventoryExtendedType[]) || []);
        setIsInventoryLoading(false);

        return inventory;
      } catch (error) {
        setIsInventoryLoading(false);
        throw error;
      }
    },
    [
      currentProduct,
      isInventoryLoading,
      locale,
      authContext?.isMemberOfTrialStoreGroup,
    ],
  );

  const getNonStoreSpecificColourInventory = useCallback(() => {
    getNonStoreSpecificColourAvailability(
      locale,
      currentProduct?.styleCode ?? "",
    ).then((inventory) => {
      setNonStoreSpecificInventory(inventory?.skuColorAvailability);
    });
  }, [currentProduct?.styleCode, locale]);

  useEffect(() => {
    if (
      currentStore &&
      (currentStore.id !== prevCurrentStore.current?.id ||
        currentProduct.sku !== prevCurrentSku.current) &&
      currentProduct?.styleCode
    ) {
      prevCurrentSku.current = currentProduct.sku ?? undefined;
      getInventoryByStore(currentStore);
      return;
    }
    const storeId: string | null | undefined = getSelectedOrPreferredStoreId(
      authContext?.account,
    );

    if (!storeId && currentProduct?.styleCode) {
      getNonStoreSpecificColourInventory();
    }
  }, [currentStore, currentProduct?.styleCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateCurrentProduct = useCallback(
    (product: Product) => {
      setCurrentProduct(product);
    },
    [setCurrentProduct],
  );

  const setStore = useCallback(
    (store: Store | null) => {
      if (store?.trial && !authContext?.isMemberOfTrialStoreGroup) {
        setCurrentStore({ ...store, clickAndCollectStore: false });
        return;
      }
      setCurrentStore(store);
    },
    [setCurrentStore, authContext?.isMemberOfTrialStoreGroup],
  );

  const updateCurrentStore = useCallback(
    (store: Store) => {
      const nextStore = store;
      const prevStore = prevCurrentStore.current;

      if (nextStore !== prevStore) {
        setStore(store);
        prevCurrentStore.current = store;
      }
    },
    [setStore],
  );

  const updateSelectedSize = useCallback((sku: string) => {
    setSelectedSize(sku);
  }, []);

  useEffect(
    () => {
      const storeId = getSelectedOrPreferredStoreId(authContext?.account);
      const isShoppingListPage = pathname === Routes.SHOPPING_LIST;

      if (!storeId) {
        setIsStoreLoadedOrNoStore(true);
      }

      if (!isShoppingListPage && currentStore?.id !== storeId && !!storeId) {
        setIsLoading(true);
        getStoreDetailsById(locale, storeId)
          .then((storeDetailsPageResponse) => {
            const storeDetailsById =
              storeDetailsPageResponse?.storeDetailsPage?.props?.storeDetailsById;
            if (
              isCurrentStoreInCurrentLocation(countryCode, storeDetailsById ?? null)
            ) {
              setStore(storeDetailsById ?? null);
            }
          })
          .finally(() => {
            setIsLoading(false);
            setIsStoreLoadedOrNoStore(true);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext?.account],
  );

  return (
    <ProductDetailsContext.Provider
      value={{
        currentProduct,
        updateCurrentProduct,
        selectedSize,
        updateSelectedSize,
        currentStore,
        updateCurrentStore,
        isLoading,
        isStoreLoadedOrNoStore,
        inventoryByStore,
        getInventoryByStore,
        setCurrentSku,
        currentSku,
        nonStoreSpecificInventory,
        currentPrice,
        setCurrentPrice,
        currentSkuInventoryInCurrentStore,
      }}
    >
      {children}
    </ProductDetailsContext.Provider>
  );
};
