import { styled } from "@mui/material/styles";

import { COLOR_BLACK, COLOR_WHITE } from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

import { BANNER_CLASSES } from "./constants";

interface UseStylesProps {
  textColor?: string;
}

export const BannerContainer = styled("div")<UseStylesProps>(({
  theme,
  textColor,
}) => {
  const formatMedia = getFormatMedia(theme);
  return {
    display: "contents",
    [`& .${BANNER_CLASSES.root}`]: {
      position: "relative",
      "&::after": {
        display: "block",
        content: "''",
        width: "100%",
        paddingBottom: "33%",

        [formatMedia.BREAKPOINT_DESKTOP]: {
          paddingBottom: "0",
        },
      },
    },
    [`& .${BANNER_CLASSES.image}`]: {
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    [`& .${BANNER_CLASSES.label}`]: {
      fontSize: "26px",
      marginLeft: "16px",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      color: textColor === "light" ? COLOR_WHITE : COLOR_BLACK,
    },
  };
});
