import {
  StyleInventoryInStoreResponse,
  StyleInventoryInStoreVariables,
} from "~/bff/transport/StyleInventoryInStore";
import { StyleInventory } from "~/bff/types/StyleInventory";
import { getApolloClient } from "~/graphql/client";
import { GET_STYLE_INVENTORY_IN_STORE_QUERY } from "~/graphql/queries/getStyleInventoryInStore";
import { Logger } from "~/utils/logger";

export const getStyleInventoryByStore = async (
  locale: string,
  styleCode: string | undefined,
  storeId: string | undefined,
  isStoreTrial?: boolean,
  isMemberOfTrialStoreGroup?: boolean,
): Promise<StyleInventory | undefined> => {
  try {
    if (!locale || !styleCode || !storeId) {
      return;
    }

    const { data } = await getApolloClient().query<
      StyleInventoryInStoreResponse,
      StyleInventoryInStoreVariables
    >({
      query: GET_STYLE_INVENTORY_IN_STORE_QUERY,
      variables: {
        locale,
        styleCode,
        storeId,
      },
    });

    if (isStoreTrial && !isMemberOfTrialStoreGroup) {
      const storesWithTrialFlags = data?.styleInventoryInStore?.data?.map(
        (item) => ({
          ...item,
          clickAndCollectStore: false,
          colourId: item?.colourId ?? "",
          locationId: item?.locationId ?? "",
          skuId: item?.skuId ?? "",
        }),
      );
      return { ...data?.styleInventoryInStore, data: storesWithTrialFlags };
    }

    return data.styleInventoryInStore ?? undefined;
  } catch (error) {
    Logger.getLogger().error(String(error));
  }
};
