export const OPTION_CONTENT_PREFIX = "OptionContent";

export const OPTION_CONTENT_CLASSES = {
  hidden: `${OPTION_CONTENT_PREFIX}-hidden`,
  column: `${OPTION_CONTENT_PREFIX}-column`,
  commonColumn: `${OPTION_CONTENT_PREFIX}-commonColumn`,
  specialItem: `${OPTION_CONTENT_PREFIX}-specialItem`,
  specialLinksDivider: `${OPTION_CONTENT_PREFIX}-specialLinksDivider`,
  rootLink: `${OPTION_CONTENT_PREFIX}-rootLink`,
  subcategoryItem: `${OPTION_CONTENT_PREFIX}-subcategoryItem`,
  banner: `${OPTION_CONTENT_PREFIX}-banner`,
};
