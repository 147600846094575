"use client";

import React from "react";

import { ProductDetails } from "./types";

export type ProductDetailsContextValue = ProductDetails | null;

export const ProductDetailsContext =
  React.createContext<ProductDetailsContextValue>(null);
