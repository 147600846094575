import React from "react";

import { IconProps } from "./../types";

import { BaseSVGIcon } from "./base-svg-icon";

export const Pointer24: React.FC<IconProps> = (props) => {
  const { htmlColor } = props;
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 16.86 13 22 12 22C11 22 4 16.86 4 10C4 7.87827 4.84285 5.84344 6.34315 4.34315C7.84344 2.84285 9.87827 2 12 2C14.1217 2 16.1566 2.84285 17.6569 4.34315C19.1571 5.84344 20 7.87827 20 10V10Z"
        stroke={htmlColor ?? "currentColor"}
        strokeWidth="2"
        strokeMiterlimit="10"
        fill="none"
      />
      <path
        d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
        stroke={htmlColor ?? "currentColor"}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </BaseSVGIcon>
  );
};
