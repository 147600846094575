"use client";

import * as React from "react";

import { prepareTextAttribute } from "~/helpers/prepare-text-attribute";
import { getImageSrc } from "~/utils/image/get-image-src";

import { CrmImageStyled } from "./styled";
import type { CrmImageProps } from "./types";

export const CrmImage = (props: CrmImageProps): React.ReactElement => {
  const { className, source, alt, title } = props;

  return (
    <CrmImageStyled
      className={className}
      src={getImageSrc(source)}
      alt={prepareTextAttribute(alt, true)}
      title={prepareTextAttribute(title)}
    />
  );
};
