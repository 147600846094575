import { ReactNode } from "react";

import { StoreSelectorProps } from "~/bff/components/StoreSelector";
import { BreadcrumbsItem } from "~/bff/types/BreadcrumbsItem";
import { Inventory } from "~/bff/types/Inventory";
import { Store } from "~/bff/types/Store";
import { StoreStockDisclaimer } from "~/bff/types/StoreStockDisclaimer";
import { StyleInventory } from "~/bff/types/StyleInventory";
import { NewDataLayerDataTypes } from "~/constants/data-layer";
import {
  AvailabilityStatusKey,
  SalesChannelKey,
} from "~/constants/product-availability-statuses";
import { Nullable } from "~/types/general.types";

import { ProductVariant } from "../sales-panel/types";

export enum StoreSelectorUseType {
  default = "default",
  geolocation = "geolocation",
  filter = "filter",
  shoppingList = "shoppingList",
  shoppingBag = "shoppingBag",
  coordsProduct = "coords",
}

export interface StoreSelectorTextLibrary {
  title: string;
  checkboxLabel: string;
  description: string;
  warning: string;
  warningDescription: string;
  buttonLabel: string;
}

export interface StoreSelectorMainProps extends StoreSelectorProps {
  subtitle?: string;
}

export interface StoreSelectorData {
  disclaimer?: StoreStockDisclaimer;
  currentStore?: Nullable<Store>;
  productInfo?: {
    sku: string;
    styleCode?: string;
    color?: string;
    colorsList?: string[];
    variants?: ProductVariant[];
    isOneSize?: boolean;
    productId?: string;
    productName?: string;
    isCoords?: boolean;
  };
  storesInStoreSelector?: Nullable<Store>[];
  offset?: number;
  currentStatus?: AvailabilityStatusKey | SalesChannelKey;
  dataLayerDataState?: NewDataLayerDataTypes;
  mainProps?: StoreSelectorMainProps;
  currentStyleInventoryInfo?: Inventory;
}

export type OpenStoreDetailsFunc = (options?: {
  shouldOpenStoreSelector?: boolean;
  disableBackButton?: boolean;
}) => void;

export interface StoreSelectorPropsExtended extends StoreSelectorData {
  children: (props: {
    className?: string;
    openStoreSelector: () => void;
    openStoreDetails: OpenStoreDetailsFunc;
    setCheckedStoreCard: (store: Store | null) => void;
  }) => ReactNode;

  onCurrentStoreChange?: (store: Nullable<Store>) => void;
  onAfterCurrentStoreChange?: (store?: Nullable<Store>) => void;
  onStoresChange?: (stores: Nullable<Store>[]) => void;
  onOffsetChange?: (count: number) => void;
  updateVariants?: (
    colorsAvailable: Inventory[],
    currentVariants: ProductVariant[],
    storeId: string,
  ) => void;
  updateIsSoldOut?: (value: boolean) => void;
  type?: StoreSelectorUseType;
  onDataLayerDataChange?: (data: NewDataLayerDataTypes) => void;
  subTitle?: string;
  breadcrumbs?: Nullable<BreadcrumbsItem>[] | undefined;
  fetchInventory?: (
    store: Nullable<Store>,
    styleCode?: string,
  ) => Promise<StyleInventory | undefined | null>;
  styleCode?: string;
}
