import { styled } from "@mui/material/styles";

import { COLOR_GREYSCALE_BLACK_54 } from "~/theme/colors";

import { LAST_SEARCHES_CLASSES } from "./constants";

export const LastSearchesContainer = styled("div")(({ theme }) => {
  return {
    [`& .${LAST_SEARCHES_CLASSES.header}`]: {
      padding: theme.spacing(0.75, 2),
      marginTop: theme.spacing(2.75),
    },
    [`& .${LAST_SEARCHES_CLASSES.headerText}`]: {
      ...theme.typography.body2,
      color: COLOR_GREYSCALE_BLACK_54,
      lineHeight: "20px",
    },
    [`& .${LAST_SEARCHES_CLASSES.element}`]: {
      padding: theme.spacing(1.5, 2),
    },
  };
});
