import React from "react";

import { IconProps } from "./../types";

import { BaseSVGIcon } from "./base-svg-icon";

export const Right32: React.FC<IconProps> = (props) => {
  return (
    <BaseSVGIcon width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
      <path
        d="M10 2L24 16L10 30"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </BaseSVGIcon>
  );
};

export const Right24: React.FC<IconProps> = (props) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M9 19L16 12L9 5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </BaseSVGIcon>
  );
};

export const RightTiny24: React.FC<IconProps> = (props) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M10 16L14 12L10 8"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </BaseSVGIcon>
  );
};
