export const ErrorReasons = {
  MIN_ORDER_PRICE: "minOrderPrice",
  MAX_ORDER_PRICE: "maxOrderPrice",
  STOCK_ERROR: "stockQuantityExceeded",
  QUANTITY_LIMIT_ERROR: "maxLineItemsQuantityError",
  MAX_LINE_ITEMS: "cartSizeExceeded",
  NOT_FOUND_IN_LINE_ITEM: "inventoryNotFoundInLineItem",
};

export const SHOPPING_BAG_ORDER_SUMMARY_PREFIX = "shoppingBagOrderSummary";

export const SHOPPING_BAG_ORDER_SUMMARY_CLASSES = {
  title: `${SHOPPING_BAG_ORDER_SUMMARY_PREFIX}-title`,
  collectInfo: `${SHOPPING_BAG_ORDER_SUMMARY_PREFIX}-collectInfo`,
  totalPrice: `${SHOPPING_BAG_ORDER_SUMMARY_PREFIX}-totalPrice`,
  deliveryPriceText: `${SHOPPING_BAG_ORDER_SUMMARY_PREFIX}-deliveryPriceText`,
  notification: `${SHOPPING_BAG_ORDER_SUMMARY_PREFIX}-notification`,
  notificationIcon: `${SHOPPING_BAG_ORDER_SUMMARY_PREFIX}-notificationIcon`,
  checkoutButton: `${SHOPPING_BAG_ORDER_SUMMARY_PREFIX}-checkoutButton`,
  applePay: `${SHOPPING_BAG_ORDER_SUMMARY_PREFIX}-applePay`,
};
