import { useEffect, useState } from "react";

import { useDebouncedCallback } from "use-debounce";

import { DEBOUNCE_DELAY_300 } from "~/constants/delay-constants";

export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);

  const debouncedSetMatches = useDebouncedCallback((mediaMatches: boolean) => {
    setMatches(mediaMatches);
  }, DEBOUNCE_DELAY_300);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => debouncedSetMatches(media.matches);
    media.addEventListener("change", listener);
    // eslint-disable-next-line no-restricted-globals
    return () => window.removeEventListener("change", listener);
  }, [debouncedSetMatches, matches, query]);
  return matches;
};
