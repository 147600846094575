"use client";

import React, { useMemo } from "react";

import Button, { ButtonProps } from "@mui/material/Button";
import { LinkProps } from "@mui/material/Link/Link";
import { noop } from "lodash";
import NextLink from "next/link";
import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { getLinkUrl } from "~/helpers/locales";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { useLink } from "~/hooks/use-link/hook";
import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";
import { prepareLinkProps } from "~/utils/link/prepareLinkProps";

interface ExtendedButtonProps extends ButtonProps {
  target?: string;
}

export const CombinedButton = React.forwardRef<
  HTMLButtonElement,
  ExtendedButtonProps
>(({ children, ...props }, ref) => {
  const { locale } = useParams<GlobalPathParams>();
  const baseUrl = AzureConfigurator?.getConfig(locale)?.frontEndBaseUrl;
  const { href, className } = props;
  const { shouldDisableLink } = useLink(href ?? "", locale);

  const { props: buttonProps, children: buttonChildren } = useMemo(
    () =>
      prepareLinkProps(props as unknown as LinkProps, children, baseUrl as string),
    [props, children, baseUrl],
  );

  if (shouldDisableLink) {
    return (
      <Button
        ref={ref}
        className={className}
        {...getTestAutomationProps("button")}
        {...(buttonProps as unknown as ExtendedButtonProps)}
        href={undefined}
        aria-disabled="true"
        tabIndex={-1}
        onClick={noop}
        sx={{
          cursor: "default",
          pointerEvents: "none",
        }}
        suppressHydrationWarning
      >
        {buttonChildren}
      </Button>
    );
  }

  return (
    <NextLink
      prefetch={false}
      href={getLinkUrl(locale, href)}
      passHref
      locale={false}
      legacyBehavior
    >
      <Button
        ref={ref}
        className={className}
        {...getTestAutomationProps("button")}
        {...(buttonProps as unknown as ExtendedButtonProps)}
        suppressHydrationWarning
      >
        {buttonChildren}
      </Button>
    </NextLink>
  );
});

CombinedButton.displayName = "CombinedButton";
