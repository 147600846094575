"use client";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { HERO_BANNER_CAROUSEL_ITEM_CLASSES } from "./constants";

export const CarouselItemStyled = styled(Box)(({ theme }) => ({
  [`.${HERO_BANNER_CAROUSEL_ITEM_CLASSES.imageFrame}`]: {
    width: "100%",
    position: "relative",
    "&::before": {
      content: "''",
      paddingTop: "100%",
      display: "block",
    },
  },
  [`.${HERO_BANNER_CAROUSEL_ITEM_CLASSES.image}`]: {
    width: "100%",
    display: "block",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: 0,
  },
  [`.${HERO_BANNER_CAROUSEL_ITEM_CLASSES.title}`]: {
    marginTop: theme.spacing(1.5),
    textAlign: "center",
    display: "block",
  },
}));
