import { get, isEmpty, set, unset } from "lodash";

import { Nullable } from "~/types/general.types";

export type ObjectType = Record<string, unknown>;

export interface Config {
  placeholders: Record<
    string,
    string | string[] | (Nullable<string> | undefined)[] | null | undefined
  >;
  overrides?: Record<string, unknown>;
}

const removeEmptyFields = (object: ObjectType): ObjectType => {
  for (const prop in object) {
    if (isEmpty(object[prop]) && typeof object[prop] !== "number") {
      unset(object, prop);
    }
    if (typeof object[prop] === "object") {
      object[prop] = removeEmptyFields(object[prop] as ObjectType);
    }
  }
  return object;
};

export const replacePlaceholders = (
  object: ObjectType,
  config?: Config,
): ObjectType => {
  const validObject = removeEmptyFields(object);

  if (!config) {
    return validObject;
  }

  for (const key in config.placeholders) {
    if (get(validObject, key) === "{{placeholder}}") {
      config.placeholders[key]?.toString().trim()
        ? set(validObject, key, config.placeholders[key])
        : unset(validObject, key);
    }
  }

  for (const key in config.overrides) {
    set(validObject, key, config.overrides[key]);
  }

  return validObject;
};
