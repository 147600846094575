import { styled } from "@mui/material/styles";
import Link from "next/link";

import { COLOR_PRIMARY_BLACK, COLOR_PRIMARY_WHITE } from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

import { AGEC_BANNER_CLASSES } from "./constants";

export const AgecBannerContainer = styled(Link)(({ theme }) => {
  const { BREAKPOINT_DESKTOP, BREAKPOINT_TABLET } = getFormatMedia(theme);

  return {
    display: "block",
    color: COLOR_PRIMARY_WHITE,
    background: COLOR_PRIMARY_BLACK,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: "justify",
    textDecoration: "none",
    fontFamily: "Arial, sans-serif",
    position: "relative",
    overflow: "hidden",
    height: "20vh",
    transition: "max-height 0.3s ease-in-out",

    [BREAKPOINT_TABLET]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },

    [BREAKPOINT_DESKTOP]: {
      minHeight: "20vh",
      height: "auto",
    },

    [`&.${AGEC_BANNER_CLASSES.expanded}`]: {
      height: "auto",
    },

    [`& .${AGEC_BANNER_CLASSES.title}`]: {
      textAlign: "center",
      fontWeight: "bold",

      [BREAKPOINT_TABLET]: {
        marginBottom: "1.5em",
      },
    },

    [`& .${AGEC_BANNER_CLASSES.list}`]: {
      margin: 0,
      listStyleType: "'- '",
      paddingLeft: "2ch",
    },

    [`& .${AGEC_BANNER_CLASSES.textLink}`]: {
      color: "#4B63FF",
    },

    [`& .${AGEC_BANNER_CLASSES.showMoreButtonContainer}`]: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: 85,
      display: "flex",
      alignItems: "flex-end",
      background:
        "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 1) 80%)",
    },

    [`& .${AGEC_BANNER_CLASSES.showMoreButton}`]: {
      textDecoration: "underline",
      color: COLOR_PRIMARY_WHITE,
      padding: 0,

      [`&:hover`]: {
        backgroundColor: "transparent",
      },
    },
  };
});
