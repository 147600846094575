"use client";

import * as React from "react";

import Button from "@mui/material/Button";
import clsx from "clsx";
import { noop } from "lodash";

import { CombinedLink } from "~/components/combined-link/component";
import { convertComponentProps } from "~/components/global-navigation/helpers/convert-component-props";
import { getNavItemUrl } from "~/components/global-navigation/helpers/get-navItem-url";
import { NavItemWithMeta } from "~/components/global-navigation/types";
import { Variables } from "~/constants/request";
import { useVariable } from "~/context/variables/hooks/use-variable";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { useTouchDevice } from "~/hooks/use-touch-device/index";

import { PageType } from "__generated__/globalTypes";

import { DESKTOP_SECOND_LEVEL_NAVIGATION_CLASSES } from "./constants";
import { DesktopSecondLevelNavigationContainer } from "./styled";
import { DesktopSecondLevelNavigationProps } from "./types";

export const DesktopSecondLevelNavigation = (
  props: DesktopSecondLevelNavigationProps,
): React.ReactElement => {
  const {
    isSectionHidden,
    setActiveOptionIndex,
    optionRowId,
    navigation,
    onCloseOption,
    activeCategoryIndex,
    activeOptionIndex,
    getOnHoverOption,
    optionItemId,
    clearNavigationDrawerTimeout,
  } = props;

  const pageType = useVariable(Variables.PAGE_TYPE);
  const isSearchPage = pageType === PageType.SearchResults;
  const isTouchDevice = useTouchDevice();

  return (
    <DesktopSecondLevelNavigationContainer
      className={clsx(
        isSectionHidden && DESKTOP_SECOND_LEVEL_NAVIGATION_CLASSES.hidden,
      )}
      onClick={() => setActiveOptionIndex(null)}
      id={optionRowId}
      onMouseLeave={isTouchDevice ? noop : onCloseOption}
      isSearchPage={isSearchPage}
    >
      {navigation?.map((navigationElement, navIndex) =>
        navigationElement?.children?.map((item, index: number) => {
          if (item !== null) {
            const { title, children, urlSlug, url, meta } = convertComponentProps(
              item as NavItemWithMeta,
            );

            /* define whether an item is in an active mode or not */
            const activeNavItem = navIndex === activeCategoryIndex;

            return children?.length ? (
              activeNavItem ? (
                <Button
                  key={`${meta?.deliveryId}-${index}`}
                  className={clsx(
                    DESKTOP_SECOND_LEVEL_NAVIGATION_CLASSES.item,
                    DESKTOP_SECOND_LEVEL_NAVIGATION_CLASSES.optionItem,
                    {
                      selected: activeOptionIndex === index,
                    },
                  )}
                  onClick={(event) => {
                    event.stopPropagation();
                    setActiveOptionIndex(index);
                  }}
                  onMouseEnter={getOnHoverOption(index)}
                  id={`${optionItemId}-${meta?.deliveryId}`}
                  {...getTestAutomationProps("option")}
                >
                  {title}
                </Button>
              ) : null
            ) : (
              <CombinedLink
                key={`${index}-${meta?.deliveryId}`}
                href={url ? getNavItemUrl(url) : `/${urlSlug}`}
                className={clsx(
                  !activeNavItem && DESKTOP_SECOND_LEVEL_NAVIGATION_CLASSES.hidden,
                  DESKTOP_SECOND_LEVEL_NAVIGATION_CLASSES.item,
                  DESKTOP_SECOND_LEVEL_NAVIGATION_CLASSES.optionItem,
                  {
                    selected: activeOptionIndex === index,
                  },
                )}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                id={`${optionItemId}-${meta?.deliveryId}`}
                onMouseEnter={() => {
                  setActiveOptionIndex(null);
                  clearNavigationDrawerTimeout();
                }}
                underline="none"
                display="block"
                {...getTestAutomationProps("option")}
              >
                {title}
              </CombinedLink>
            );
          }
        }),
      )}
    </DesktopSecondLevelNavigationContainer>
  );
};
