import React from "react";

import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { FontWeightProperty } from "csstype";

import { COLOR_BLACK, COLOR_GREYSCALE_BLACK_15, COLOR_WHITE } from "~/theme/colors";

import { GEO_LOCATION_DESKTOP_CLASSES } from "./constants";

export const TooltipStyled = styled(
  ({ className, children, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}>
      {children}
    </Tooltip>
  ),
)(() => ({
  zIndex: 1300,
  [`& .${tooltipClasses.tooltipArrow}`]: {
    pointerEvents: "visible",
    maxWidth: "363px",
    margin: "0 16px",
    padding: 0,
    background: COLOR_BLACK,
    borderRadius: 0,
    color: COLOR_WHITE,
    boxShadow: `0px 4px 16px 0px ${COLOR_GREYSCALE_BLACK_15}`,
    cursor: "pointer",
    marginTop: "0 !important",
  },
  [`& .${tooltipClasses.arrow}`]: {
    left: "5% !important",
    marginLeft: "-2.9em !important",
    fontSize: 18,
    "&::before": {
      background: COLOR_BLACK,
    },
  },
}));

export const TooltipContainer = styled(Box)(({ theme }) => {
  return {
    padding: theme.spacing(2),
    [`& .${GEO_LOCATION_DESKTOP_CLASSES.tooltipText}`]: {
      ...theme.typography.body2,
    },
    [`& .${GEO_LOCATION_DESKTOP_CLASSES.closeButton}`]: {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      display: "block",
      padding: 0,
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
      color: COLOR_WHITE,
      background: "transparent",
      cursor: "pointer",
      fontSize: "8px",
      "&:hover, &:active, &:focus": {
        backgroundColor: "transparent",
      },
    },
  };
});

export const LabelContainer = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [`& .${GEO_LOCATION_DESKTOP_CLASSES.helperText}`]: {
      padding: theme.spacing(0, 0, 0, 1),
      lineHeight: "24px",
      fontSize: "12px",
      fontFamily: "Arial",
      fontWeight: 700,
      letterSpacing: "0.6px",
    },
    [`& .${GEO_LOCATION_DESKTOP_CLASSES.label}`]: {
      ...theme.typography.body2,
      fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
      paddingLeft: theme.spacing(1),
      textDecoration: "underline",
      "&:hover, &:active, &:focus": {
        textDecoration: "underline",
        background: "none",
      },
    },
  };
});
