import React from "react";

import { IconProps } from "./../types";

import { BaseSVGIcon } from "./base-svg-icon";

export const Close24: React.FC<IconProps> = (props) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M5.96002 6.04001L17.99 18.01"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M5.97998 17.98L18.01 6.01001"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </BaseSVGIcon>
  );
};
