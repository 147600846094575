import { styled } from "@mui/material/styles";

import { getFormatMedia } from "~/theme/utils";

export const DesktopHeaderWrapper = styled("header")(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    display: "none",
    [formatMedia.BREAKPOINT_DESKTOP]: {
      display: "block",
    },
  };
});
