import { useEffect, useState } from "react";

export const useTouchDevice = (): boolean => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  useEffect(() => {
    const hasTouchCapabilities =
      typeof ontouchstart !== "undefined" || navigator.maxTouchPoints > 0;

    setIsTouchDevice(hasTouchCapabilities);
  }, []);
  return isTouchDevice;
};
