"use client";

import React from "react";

import Box from "@mui/material/Box";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import { clamp } from "lodash";

import { COLOR_BLACK, COLOR_GREYSCALE_BLACK_75 } from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

import { DESKTOP_GLOBAL_NAVIGATION_CLASSES } from "./constants";

export interface UseStylesProps {
  offset: number;
  infoBannerOffset: number;
  isInfoBannerClosed?: boolean;
  categoriesRowOffset: number;
  scrollTop: number;
  isInfoBanner?: boolean;
}
const optionsRowHeight = 48;
const maxHeightOfHeaderPanel = 660;
const globalNavigationPaddingTop = 14;

const fadeInEffect = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slideInEffect = keyframes`
  0% {
    transform: scale(1, 0);
  }
  100% {
    transform: scale(1, 1);
  }
`;

export const DesktopGlobalNavigationContainer = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    position: "relative",
    paddingTop: theme.spacing(1.75),
    [`& .${DESKTOP_GLOBAL_NAVIGATION_CLASSES.row}`]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      "& > *:not(:last-of-type)": {
        marginRight: theme.spacing(4),
      },
    },
    [`& .${DESKTOP_GLOBAL_NAVIGATION_CLASSES.floor}`]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),

      [formatMedia.BREAKPOINT_WIDE_DESKTOP]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
      },
    },
    [`& .${DESKTOP_GLOBAL_NAVIGATION_CLASSES.item}`]: {
      color: COLOR_GREYSCALE_BLACK_75,
      padding: `${theme.spacing(1.5)} 0`,
      position: "relative",

      "&.selected, &:active": {
        color: COLOR_BLACK,
      },
      "&::before": {
        content: "''",
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        borderBottomWidth: 2,
        borderBottomStyle: "solid",
        borderColor: "transparent",
      },
      "&:hover::before": {
        borderColor: COLOR_GREYSCALE_BLACK_75,
      },
      "&:active::before": {
        borderColor: COLOR_BLACK,
      },
    },
    [`& .${DESKTOP_GLOBAL_NAVIGATION_CLASSES.categoryItem}`]: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
      fontWeight: theme.typography.fontWeightRegular as number,
      lineHeight: "24px",
      letterSpacing: "0.03em",
      textTransform: "uppercase",

      "&.rootLink": {
        ...theme.typography.button,
      },
    },
    [`& .${DESKTOP_GLOBAL_NAVIGATION_CLASSES.hidden}`]: {
      display: "none",
    },
  };
});

export const DesktopGlobalNavigationDrawer = styled(
  ({
    offset,
    infoBannerOffset,
    isInfoBannerClosed,
    categoriesRowOffset,
    scrollTop,
    isInfoBanner,
    ...restProps
  }: UseStylesProps & DrawerProps) => <Drawer {...restProps} />,
)(({
  theme,
  offset,
  infoBannerOffset,
  isInfoBannerClosed,
  categoriesRowOffset,
  scrollTop,
  isInfoBanner,
}) => {
  const localOffsetHeight = (categoriesRowOffset: number, scrollTop: number) =>
    optionsRowHeight + categoriesRowOffset - scrollTop;

  const formatMedia = getFormatMedia(theme);

  return {
    marginTop:
      !isInfoBanner || isInfoBannerClosed
        ? `${
            localOffsetHeight(categoriesRowOffset, scrollTop) +
            offset +
            globalNavigationPaddingTop
          }px`
        : `${
            localOffsetHeight(categoriesRowOffset, scrollTop) +
            offset +
            infoBannerOffset +
            globalNavigationPaddingTop
          }px`,
    "& .MuiBackdrop-root": {
      position: "absolute",
      animation: `300ms 0ms backwards ${fadeInEffect}`,
    },
    [`& .${DESKTOP_GLOBAL_NAVIGATION_CLASSES.drawerContent}`]: {
      position: "absolute",
      boxShadow: `0px 12px 12px rgb(0 0 0 / 15%)`,
      animation: `300ms 0ms both ${slideInEffect}`,
      transformOrigin: "top",
    },
    [`& .${DESKTOP_GLOBAL_NAVIGATION_CLASSES.optionContent}`]: {
      maxHeight: isFinite(maxHeightOfHeaderPanel)
        ? `${clamp(
            maxHeightOfHeaderPanel -
              localOffsetHeight(categoriesRowOffset, scrollTop) -
              (offset || 0),
            0,
            Infinity,
          )}px`
        : `calc(100vh - ${
            localOffsetHeight(categoriesRowOffset, scrollTop) + (offset || 0)
          }px)`,
    },
    [`& .${DESKTOP_GLOBAL_NAVIGATION_CLASSES.floor}`]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),

      [formatMedia.BREAKPOINT_WIDE_DESKTOP]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
      },
    },
    [`& .${DESKTOP_GLOBAL_NAVIGATION_CLASSES.hidden}`]: {
      display: "none",
    },
  };
});
