import { useEffect, useState } from "react";

import { useDebounce } from "use-debounce";
import { v4 as uuidv4 } from "uuid";

import { DEBOUNCE_DELAY_300 } from "~/constants/delay-constants";

import { WindowState } from "./types";

export const useWindowState = (): WindowState => {
  const [windowState, setWindowState] = useState<WindowState>(() => ({
    width: undefined,
    height: undefined,
    token: uuidv4(),
  }));
  const [windowStateCached] = useDebounce(windowState, DEBOUNCE_DELAY_300);

  useEffect(() => {
    const handleResize = () =>
      setWindowState({
        width: innerWidth,
        height: innerHeight,
        token: uuidv4(),
      });
    handleResize();
    addEventListener("resize", handleResize);
    return () => removeEventListener("resize", handleResize);
  }, []);

  return windowStateCached;
};
