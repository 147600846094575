"use client";

import React, { useEffect, useMemo, useState } from "react";

import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { DySplitterHomeNavigationCampaignProvider } from "~/context/dy-splitter-home-navigation-campaign/provider";
import { HeaderProvider } from "~/context/header/provider";

import { HeaderDesktop } from "./components/header-desktop/component";
import { HeaderMobile } from "./components/header-mobile/component";
import { convertHeaderToProps } from "./helpers/convert-header-to-props";
import { DesktopHeaderWrapper } from "./styled";
import { ExtendedHeaderProps, ConvertedHeaderProps } from "./types";

export const Header: React.FC<ExtendedHeaderProps> = (data) => {
  const { locale } = useParams<GlobalPathParams>();

  const { searchPanel } = data;
  const props: ConvertedHeaderProps = useMemo(
    () => convertHeaderToProps(data),
    [data],
  );
  const [isInfoBannerClose, setIsInfoBannerClose] = useState(false);
  const handleBannerClose = () => {
    setIsInfoBannerClose(true);
  };

  useEffect(() => {
    setIsInfoBannerClose(false);
  }, [locale]);

  return (
    <HeaderProvider>
      <DySplitterHomeNavigationCampaignProvider>
        <DesktopHeaderWrapper id="desktopHeader" role="banner">
          <HeaderDesktop
            {...props}
            onInfoBannerClose={handleBannerClose}
            isInfoBannerClose={isInfoBannerClose}
            searchPanel={searchPanel}
          />
        </DesktopHeaderWrapper>
        <HeaderMobile
          {...props}
          onInfoBannerClose={handleBannerClose}
          isInfoBannerClose={isInfoBannerClose}
          searchPanel={searchPanel}
        />
      </DySplitterHomeNavigationCampaignProvider>
    </HeaderProvider>
  );
};
