import React from "react";

import { NotificationOptions } from "./types";

export type NotificationContextValue = {
  notification?: JSX.Element;
  handleShowNotification?: (
    notificationMessage?: string,
    options?: NotificationOptions,
  ) => void;
} | null;

export const NotificationContext =
  React.createContext<NotificationContextValue>(null);
