import React, { useMemo, FC, useEffect, useState, useRef, useContext } from "react";

import { usePathname, useRouter } from "next/navigation";

import { PdpProps_Translations } from "~/bff/types/PdpProps_Translations";
import { ShoppingListProduct } from "~/bff/types/ShoppingListProduct";
import { AddedToCollectBagPopUp } from "~/components/added-to-collect-bag-pop-up/component";
import { CombinedButton } from "~/components/combined-button/component";
import { Routes } from "~/constants/request";
import { ShoppingBagContext } from "~/context/shopping-bag/context";
import { VariablesContext } from "~/context/variables/context";
import { useIntersectionObserver } from "~/hooks/use-inter-section-observer/hook";
import { Cc24 } from "~/theme/icons/components/cc";
import { Nullable } from "~/types/general.types";

import { SHOPPING_BAG_BUTTON_CLASSES } from "./constants";
import { ShoppingBagButtonContainer } from "./styled";

export const ShoppingBagButton: FC = () => {
  const router = useRouter();
  const pathName = usePathname();

  const shoppingBagContext = useContext(ShoppingBagContext);
  const productsCount = useMemo(() => {
    const products = shoppingBagContext?.initialUserShoppingBag?.products;
    return products?.reduce((productCounter, product) => {
      return product?.quantity ? productCounter + product?.quantity : productCounter;
    }, 0);
  }, [shoppingBagContext?.initialUserShoppingBag?.products]);
  const shoppingBagButtonRef = useRef<Nullable<HTMLButtonElement>>(null);
  const observerEntry = useIntersectionObserver(shoppingBagButtonRef, {
    threshold: 0.1,
  });

  const [latestAddedProduct, setLatestAddedProduct] =
    useState<Nullable<ShoppingListProduct>>(null);

  const { translations } = useContext(VariablesContext) ?? {};

  const { salesPanel, addedToCollectBagPopUp } =
    (translations as PdpProps_Translations) ?? {};

  const handleShoppingBagButtonClick = () => {
    if (pathName?.includes(Routes.SHOPPING_BAG)) {
      router.refresh();
    }
  };

  useEffect(() => {
    if (
      shoppingBagContext?.shouldShowAddToCartPopUp &&
      shoppingBagContext?.latestAddedSku
    ) {
      const latestProduct =
        shoppingBagContext?.shoppingBagSkuMap?.get(
          shoppingBagContext?.latestAddedSku,
        ) || null;
      setLatestAddedProduct(latestProduct);
      return;
    }

    setLatestAddedProduct(null);
  }, [
    shoppingBagContext?.latestAddedSku,
    shoppingBagContext?.shouldShowAddToCartPopUp,
    shoppingBagContext?.shoppingBagSkuMap,
  ]);

  return (
    <ShoppingBagButtonContainer
      isCounterNumberSingleDigit={productsCount ? productsCount < 10 : false}
      isButtonInView={!!observerEntry?.isIntersecting}
    >
      {latestAddedProduct && (
        <AddedToCollectBagPopUp
          product={latestAddedProduct}
          totalOrderPrice={
            shoppingBagContext?.initialUserShoppingBag?.totalPrice ?? undefined
          }
          translations={{
            colorLabel: salesPanel?.colourLabelText ?? undefined,
            sizeLabel: salesPanel?.sizeSelector?.sizeGuideButtonText ?? undefined,
            orderAmountMet:
              addedToCollectBagPopUp?.minOrderAmountMetText ?? undefined,
            orderAmountNotMet:
              addedToCollectBagPopUp?.minOrderAmountNotMetText ?? undefined,
            title: addedToCollectBagPopUp?.popUpTitle ?? undefined,
            totalOrder: addedToCollectBagPopUp?.totalOrderText ?? undefined,
            checkoutCTA: addedToCollectBagPopUp?.checkoutCTAText ?? undefined,
            viewBagCTA: addedToCollectBagPopUp?.viewBagCTAText ?? undefined,
          }}
          className={SHOPPING_BAG_BUTTON_CLASSES.popup}
        />
      )}
      <CombinedButton
        className={SHOPPING_BAG_BUTTON_CLASSES.root}
        href={Routes.SHOPPING_BAG}
        aria-label="open shopping bag page"
        ref={shoppingBagButtonRef}
        onClick={handleShoppingBagButtonClick}
        id="shopping-bag-link"
      >
        <Cc24 />
        {Boolean(productsCount) && (
          <div className={SHOPPING_BAG_BUTTON_CLASSES.counter}>{productsCount}</div>
        )}
      </CombinedButton>
    </ShoppingBagButtonContainer>
  );
};
