"use client";

import React from "react";

import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  COLOR_ADDITIONAL_WARM_GRAY_3U,
  COLOR_BLACK,
  COLOR_GREYSCALE_BLACK_75,
} from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

import { DESKTOP_SECOND_LEVEL_NAVIGATION_CLASSES } from "./constants";

export interface UseStylesProps extends BoxProps {
  isSearchPage?: boolean;
}

export const DesktopSecondLevelNavigationContainer = styled(
  ({ isSearchPage, ...restProps }: UseStylesProps) => <Box {...restProps} />,
)(({ theme, isSearchPage }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),

    [formatMedia.BREAKPOINT_WIDE_DESKTOP]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(4),
    },
    backgroundColor: COLOR_ADDITIONAL_WARM_GRAY_3U,
    position: isSearchPage ? "absolute" : "static",
    left: 0,
    right: 0,
    width: "100%",
    bottom: theme.spacing(-6),
    zIndex: 1,
    [`&.${DESKTOP_SECOND_LEVEL_NAVIGATION_CLASSES.hidden}`]: {
      display: "none",
    },
    [`& .${DESKTOP_SECOND_LEVEL_NAVIGATION_CLASSES.item}`]: {
      ...theme.typography.body1,
      color: COLOR_GREYSCALE_BLACK_75,
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      position: "relative",

      "&.selected, &:active": {
        color: COLOR_BLACK,
      },

      "&::before": {
        content: "''",
        position: "absolute",
        bottom: -4,
        left: 0,
        width: "100%",
        borderBottomWidth: 2,
        borderBottomStyle: "solid",
        borderColor: "transparent",
      },
      "&:hover::before, &:focus::before": {
        borderColor: COLOR_GREYSCALE_BLACK_75,
      },
      "&:active::before": {
        borderColor: COLOR_BLACK,
      },
      "&:hover": {
        cursor: "pointer",
      },
    },
    [`& .${DESKTOP_SECOND_LEVEL_NAVIGATION_CLASSES.optionItem}`]: {
      "&.selected::before": {
        borderColor: COLOR_BLACK,
      },
    },
    [`& .${DESKTOP_SECOND_LEVEL_NAVIGATION_CLASSES.hidden}`]: {
      display: "none",
    },
  };
});
