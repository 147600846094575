"use client";

import React, { useCallback, useState } from "react";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import clsx from "clsx";

import { CombinedButton } from "~/components/combined-button/component";
import { GeoLocationDesktop } from "~/components/geo-location-desktop/component";
import { AdditionalNavigation } from "~/components/global-navigation/components/desktop-global-navigation/additional-navigation/component";
import { DesktopGlobalNavigation } from "~/components/global-navigation/components/desktop-global-navigation/component";
import { InformationBanner } from "~/components/information-banner/component";
import { SearchPanelDesktop } from "~/components/search-panel/components/search-panel-desktop/component";
import { Routes } from "~/constants/request";
import { HeaderContext } from "~/context/header/context";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { useIsGeoLocationEnabled } from "~/hooks/use-geo-location/use-is-geo-location-enabled";
import { SmallPointer16 } from "~/theme/icons/components/small-pointer";

import { useInfoBanner } from "../../../information-banner/hooks";
import { ConvertedHeaderProps } from "../../types";

import { HEADER_DESKTOP_CLASSES } from "./constants";
import { HeaderDesktopWrapper, InformationBannerContainer } from "./styled";

const OFFSET = 96;

export const HeaderDesktop: React.FC<ConvertedHeaderProps> = ({
  logo,
  additionalItems,
  navigation,
  breadcrumbs,
  informationBanner,
  onInfoBannerClose,
  isInfoBannerClose,
  searchPanel,
  storeLocatorLinkLabel,
  translations,
}) => {
  const isSearchOpen = React.useContext(HeaderContext)?.isSearchOpen;
  const [infoBannerHeight, setInfoBannerHeight] = useState(0);

  const isGeolocationEnabled = useIsGeoLocationEnabled();

  const { infoBannerCookieValue: isInfoBannerClosed } = useInfoBanner();
  const searchPanelOffset = isInfoBannerClosed ? 0 : infoBannerHeight;

  const infoBannerRefCallback = useCallback((node: HTMLElement) => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setInfoBannerHeight(entry.target.getBoundingClientRect().height);
      }
    });

    resizeObserver.observe(node);
    setInfoBannerHeight(node.getBoundingClientRect().height);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleBannerClose = useCallback(() => {
    setInfoBannerHeight(0);
    onInfoBannerClose?.();
  }, [onInfoBannerClose]);

  const storeLocatorLinkItem = {
    link: {
      url: Routes.STORES_SEARCH,
      label: storeLocatorLinkLabel,
    },
  };

  return (
    <>
      <InformationBannerContainer>
        {informationBanner ? (
          <InformationBanner
            ref={infoBannerRefCallback}
            props={informationBanner?.props}
            onInfoBannerClose={handleBannerClose}
            isInfoBannerClose={isInfoBannerClose}
          />
        ) : null}
      </InformationBannerContainer>
      <HeaderDesktopWrapper {...getTestAutomationProps("header")} id="header">
        <Box className={HEADER_DESKTOP_CLASSES.searchAnimationBox}>
          <Collapse in={!isSearchOpen} timeout={500}>
            <Box
              className={clsx(
                HEADER_DESKTOP_CLASSES.floor,
                HEADER_DESKTOP_CLASSES.secondaryLevel,
              )}
              {...getTestAutomationProps("secondary-navigation")}
              id="secondary-navigation"
            >
              {isGeolocationEnabled ? (
                <>
                  <GeoLocationDesktop />
                  <AdditionalNavigation
                    items={[...(additionalItems || []), storeLocatorLinkItem]}
                  />
                </>
              ) : (
                <>
                  {storeLocatorLinkLabel ? (
                    <CombinedButton
                      href={Routes.STORES_SEARCH}
                      variant="text"
                      className="body2"
                      startIcon={<SmallPointer16 />}
                      {...getTestAutomationProps("store-locator-link")}
                      id="store-locator-link"
                    >
                      {storeLocatorLinkLabel}
                    </CombinedButton>
                  ) : (
                    <Box />
                  )}
                  <AdditionalNavigation items={additionalItems} />
                </>
              )}
            </Box>
          </Collapse>
          <SearchPanelDesktop
            logo={logo}
            wrapperClassName={HEADER_DESKTOP_CLASSES.floor}
            searchPanel={searchPanel}
            offset={searchPanelOffset}
          />
        </Box>
        <DesktopGlobalNavigation
          navigation={navigation}
          viewAllLabel={translations?.viewAll}
          breadcrumbs={breadcrumbs}
          offset={OFFSET}
          infoBannerHeight={infoBannerHeight}
          isInfoBanner={!!informationBanner}
        />
      </HeaderDesktopWrapper>
    </>
  );
};
