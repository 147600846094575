import React from "react";

import { ShoppingBagResponse } from "~/bff/transport/ShoppingBag";
import { ShoppingList } from "~/bff/types/ShoppingList";
import { ShoppingListProduct } from "~/bff/types/ShoppingListProduct";

import { GetShoppingBagResponse } from "./types";

export interface ShoppingBagContextData {
  userShoppingBag?: ShoppingList;
  initialUserShoppingBag?: Partial<ShoppingList>;
  shoppingBagSkuMap: Map<string, ShoppingListProduct | null>;
  handleGetShoppingBag?: (
    shouldDisplayLoading?: boolean,
  ) => Promise<GetShoppingBagResponse>;
  handleSetUserShoppingBag?: (userShoppingBag: ShoppingList) => void;
  handleAddProductToShoppingBag?: (
    sku: string | undefined,
    storeId?: string,
    blockPopUp?: boolean | undefined,
  ) => void;
  handleReplaceProductInShoppingBag?: (
    sku: string | undefined,
    productId: string | undefined,
    addedAt: string | undefined,
    quantity: number | undefined,
  ) => void;
  handleUpdateShoppingBagLocation?: (locationId: string) => void;
  handleMoveProductToShoppingList?: (
    sku: string | undefined,
    productId: string | undefined,
    addedAt: string | undefined,
    shoppingListId: string | undefined,
  ) => Promise<ShoppingBagResponse>;
  handleRemoveProductFromShoppingBag?: (
    productId: string | undefined,
  ) => Promise<ShoppingBagResponse>;
  isLoading?: boolean;
  shouldShowAddToCartPopUp?: boolean;
  latestAddedSku?: string;
}

export const ShoppingBagContext = React.createContext<
  ShoppingBagContextData | undefined
>(undefined);
