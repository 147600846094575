import { useCallback, useContext, useMemo } from "react";

import { useMutation } from "@apollo/client";

import { Account } from "~/bff/types/Account";
import { LOCALES } from "~/constants/i18n";
import { AuthContext } from "~/context/auth/context";
import { getApolloClient } from "~/graphql/client";
import {
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_LAST_INTERACTION_DATE,
} from "~/graphql/queries/accountQueries";
import { Logger } from "~/utils/logger";

import { UpdateAccountInput } from "__generated__/globalTypes";

import { useLoadAccountLazyQuery } from "../use-load-account-lazy-query";
import { useToken } from "../use-token";

export interface UseUserAccountReturnType {
  loadAccount: (token: string) => Promise<void>;
  updateUserAccount: (
    token: string,
    account: Account | UpdateAccountInput,
  ) => Promise<void>;
  isAccountDataLoading: boolean;
  updateUserForLastInteractionDate?: () => void;
}

export const useUserAccount = (locale: LOCALES): UseUserAccountReturnType => {
  const client = useMemo(() => getApolloClient({ locale }), [locale]);

  const authContext = useContext(AuthContext);

  const { extractToken } = useToken(locale);

  const [getAccountData, { loading: isAccountDataLoading }] =
    useLoadAccountLazyQuery(locale, authContext?.updateAccount);

  const [updateAccountData] = useMutation(UPDATE_ACCOUNT, {
    notifyOnNetworkStatusChange: true,
    client,
    onCompleted: (result) => {
      if (!result?.updateAccount?.errors || !result?.updateAccount?.errors?.length) {
        authContext?.updateAccount?.(result?.updateAccount);
      }
    },
    onError: (error) => {
      Logger.getLogger().error(error);
    },
  });

  const [updateAccountDataForLastInteractionDate] = useMutation(
    UPDATE_ACCOUNT_LAST_INTERACTION_DATE,
    {
      notifyOnNetworkStatusChange: true,
      client,
      onError: (error) => {
        Logger.getLogger().error(error);
      },
    },
  );

  const loadAccount = useCallback(
    async (token: string) => {
      await getAccountData({
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        variables: {
          locale,
        },
      });
    },
    [getAccountData, locale],
  );

  const updateUserAccount = useCallback(
    async (token: string, account: Account) => {
      const options = {
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        variables: {
          account: {
            ...account,
            preferredLanguage: locale,
          },
          locale,
        },
      };

      await updateAccountData(options);
    },
    [locale, updateAccountData],
  );

  const updateUserForLastInteractionDate = useCallback(async () => {
    const token = await extractToken();
    return updateAccountDataForLastInteractionDate({
      context: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      variables: {
        locale,
      },
    });
  }, [locale, updateAccountDataForLastInteractionDate, extractToken]);

  return {
    loadAccount,
    updateUserAccount,
    isAccountDataLoading,
    updateUserForLastInteractionDate,
  };
};
