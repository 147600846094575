import React from "react";

import { IconProps } from "./../types";

import { BaseSVGIcon } from "./base-svg-icon";

export const PriorityHigh24: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <circle cx="12" cy="19" r="2" fill="currentColor" />
    <path d="M10 3h4v12h-4z" fill="currentColor" />
  </BaseSVGIcon>
);
