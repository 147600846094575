import { styled } from "@mui/material/styles";
import { FontWeightProperty } from "csstype";

import { COLOR_PRIMARY_BLACK, COLOR_WHITE } from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

import { SHOPPING_BAG_BUTTON_CLASSES } from "./constants";

interface ShoppingBagButtonContainerProps {
  isCounterNumberSingleDigit: boolean;
  isButtonInView: boolean;
}

export const ShoppingBagButtonContainer = styled(
  "div",
)<ShoppingBagButtonContainerProps>(({
  theme,
  isCounterNumberSingleDigit,
  isButtonInView,
}) => {
  const formatMedia = getFormatMedia(theme);
  return {
    display: "inline-block",
    [`& .${SHOPPING_BAG_BUTTON_CLASSES.root}`]: {
      position: "relative",
      paddingInline: theme.spacing(1),
    },
    [`& .${SHOPPING_BAG_BUTTON_CLASSES.counter}`]: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      top: 6,
      right: -2,
      height: 16,
      width: isCounterNumberSingleDigit && 16,
      paddingInline: 5,
      background: COLOR_PRIMARY_BLACK,
      color: COLOR_WHITE,
      fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
      fontSize: 12,
      borderRadius: 8,
    },
    [`& .${SHOPPING_BAG_BUTTON_CLASSES.popup}`]: {
      zIndex: 99999,
      position: "absolute",
      visibility: "visible",
      right: -8,
      top: !isButtonInView ? 76 : 62,
      width: 375,
      [formatMedia.BREAKPOINT_TABLET]: {
        right: 8,
        top: !isButtonInView ? 82 : 64,
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        position: !isButtonInView ? "fixed" : "absolute",
        right: !isButtonInView ? 16 : -16,
        top: !isButtonInView ? 24 : 52,
      },
      [formatMedia.BREAKPOINT_LARGE_DESKTOP]: {
        right: !isButtonInView ? 48 : -16,
      },
    },
  };
});
