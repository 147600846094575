import { ImageTemplates } from "~/components/image/types";
import { WindowFormat } from "~/context/breakpoints/types";

export const imageTemplates: ImageTemplates = {
  [WindowFormat.mobile]: "productimages-mobile",
  [WindowFormat.tablet]: "productimages-tablet",
  [WindowFormat.desktop]: "productimages-regulardesktop",
  [WindowFormat.wideDesktop]: "productimages-largedesktop",
};

export const ADDED_TO_COLLECT_BAG_POPUP_PREFIX = "AddedToCollectBagPopUp";

export const ADDED_TO_COLLECT_BAG_POPUP_CLASSES = {
  infoContainer: `${ADDED_TO_COLLECT_BAG_POPUP_PREFIX}-infoContainer`,
  infoTextContainer: `${ADDED_TO_COLLECT_BAG_POPUP_PREFIX}-infoTextContainer`,
  productImage: `${ADDED_TO_COLLECT_BAG_POPUP_PREFIX}-productImage`,
  price: `${ADDED_TO_COLLECT_BAG_POPUP_PREFIX}-price`,
  totalPrice: `${ADDED_TO_COLLECT_BAG_POPUP_PREFIX}-totalPrice`,
  totalOrderContainer: `${ADDED_TO_COLLECT_BAG_POPUP_PREFIX}-totalOrderContainer`,
  orderAmountMetContainer: `${ADDED_TO_COLLECT_BAG_POPUP_PREFIX}-orderAmountMetContainer`,
  buttonGroup: `${ADDED_TO_COLLECT_BAG_POPUP_PREFIX}-buttonGroup`,
};
