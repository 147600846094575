import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";

import { COLOR_BLACK } from "~/theme/colors";

import { GEO_SEARCH_ERROR_POPUP_CLASSES } from "./constants";

export const GeoSearchErrorPopUpDialog = styled(Dialog)(({ theme }) => {
  return {
    [`& .${GEO_SEARCH_ERROR_POPUP_CLASSES.root}`]: {
      display: "flex",
      padding: theme.spacing(2),
      margin: 0,
      borderRadius: 0,
      width: "240px",
      boxSizing: "content-box",
    },
    [`& .${GEO_SEARCH_ERROR_POPUP_CLASSES.titleBlock}`]: {
      padding: 0,
    },
    [`& .${GEO_SEARCH_ERROR_POPUP_CLASSES.titleText}`]: {
      fontSize: "16px",
      textAlign: "center",
    },
    [`& .${GEO_SEARCH_ERROR_POPUP_CLASSES.descriptionBlock}`]: {
      padding: 0,
    },
    [`& .${GEO_SEARCH_ERROR_POPUP_CLASSES.descriptionText}`]: {
      color: COLOR_BLACK,
      textAlign: "center",
      margin: 0,
    },
    [`& .${GEO_SEARCH_ERROR_POPUP_CLASSES.closeButton}`]: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      display: "block",
      padding: 0,
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
      background: "transparent",
      cursor: "pointer",
      "&:hover, &:active, &:focus": {
        backgroundColor: "transparent",
      },
    },
  };
});
