export const IS_GEOLOCATION_AVAILABLE =
  typeof navigator !== "undefined" && "geolocation" in navigator;
export const GEOLOCATION_ERROR_IS_NOT_AVAILABLE =
  "Geolocation is not available in your browser";
export const GEOLOCATION_ERROR_GETTING_YOUR_LOCATION =
  "We're sorry. There was a problem getting your location, please search using a City, Region, or Postal Code query.";

export const SEARCH_FIELD_PREFIX = "SearchField";

export const SEARCH_FIELD_CLASSES = {
  label: `${SEARCH_FIELD_PREFIX}-label`,
};
