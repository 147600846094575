import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";
import { Nullable } from "~/types/general.types";

import { isDyCampaignEnabled } from "./is-dy-campaign-enabled";
import { DYChooseResponseData, DyCampaignName } from "./types";

export const getTestCampaignChoicesFromResponse = (
  responseChoicesData: Nullable<DYChooseResponseData>,
  campaignName: DyCampaignName,
  pageLocale: string,
): null | DYChooseResponseData["choices"][number] => {
  const isDyEnabled = Boolean(
    AzureConfigurator?.getConfig(pageLocale)?.featureFlagList?.dynamicYield?.enabled,
  );

  if (!isDyEnabled) {
    return null;
  }

  const testCampaignChoices = isDyCampaignEnabled(campaignName, pageLocale)
    ? responseChoicesData?.choices?.find((el) => el.name === campaignName)
    : null;

  if (typeof testCampaignChoices === "undefined") {
    return null;
  }

  return testCampaignChoices;
};
