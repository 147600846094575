import { useCallback, useEffect, useState, MouseEvent, useContext } from "react";

import Cookies from "js-cookie";
import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { Store } from "~/bff/types/Store";
import { PlaceApiProvider } from "~/constants/data-layer";
import { SELECTED_STORE, GEO_ALLOWED } from "~/constants/user-cookies";
import { AuthContext } from "~/context/auth/context";
import { DictionaryLabelContext } from "~/context/dictionary-label/context";
import { DictionaryKeys } from "~/context/dictionary-label/types";
import { getCountry } from "~/helpers/country/get-country";
import { getStoreDetailsById } from "~/helpers/get-store-details-by-id";
import {
  getStoresByCoords,
  getStoresByPlaceId,
} from "~/helpers/get-stores-by-place-id";
import { setGeoStatusToCookies } from "~/helpers/selected-store/set-geo-status-to-cookies";
import { setStoreToCookies } from "~/helpers/selected-store/set-store-to-cookies";
import { updateSelectedStoreToAccount } from "~/helpers/selected-store/update-selected-store-to-account";
import { useIsEnabled as useIsLoqateSearchEnabled } from "~/hooks/loqate/use-is-enabled";
import { useToken } from "~/hooks/use-token";
import { useUserAccount } from "~/hooks/use-user-account/index";
import { useAzureConfigurator } from "~/services/azure-configurator/use-azure-configurator";
import { Nullable } from "~/types/general.types";
import { Logger } from "~/utils/logger/browser";

export interface GeolocationTextFields {
  tooltipText: string;
  labelLinkText: string;
  linkText: string;
  searchPlaceholder: string;
  geoNoStoreFoundTitle: string;
  geoNoStoreFoundBody: string;
}

const STORE_SEARCH_RADIUS = 2500;

export const useGeoLocation = (deviceView: boolean) => {
  const { locale } = useParams<GlobalPathParams>();
  const account = useContext(AuthContext)?.account;
  const { updateUserAccount } = useUserAccount(locale);
  const storeFromAccount = useContext(AuthContext);
  const { extractToken } = useToken(locale);
  const config = useAzureConfigurator(locale);
  const isLoqateSearchEnabled = useIsLoqateSearchEnabled();

  const country = getCountry(locale);
  const storeId =
    Cookies.get(SELECTED_STORE) ?? storeFromAccount?.account?.selectedStore;
  const geoStatus = Cookies.get(GEO_ALLOWED);

  const dictionary = useContext(DictionaryLabelContext);
  const clickAndCollectEnabled = config?.featureFlags?.clickAndCollect?.enabled;

  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const [searchShopAllowed, setSearchShopAllowed] = useState<boolean>(false);
  const [isGeoAllowed, setGeoAllowed] = useState<boolean>(true);
  const [currentStore, setCurrentStore] = useState<Nullable<Store>>(null);
  const [storesInStoreSelector, setStoresInStoreSelector] = useState<
    Nullable<Store>[]
  >([]);
  const [offset, setOffset] = useState(0);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [isPopUpActive, setIsPopUpActive] = useState<boolean>(false);
  const [geolocationTextFields, setGeolocationTextFields] =
    useState<GeolocationTextFields>({
      tooltipText: dictionary?.[DictionaryKeys.GeoTooltipText] ?? "",
      labelLinkText: "",
      linkText: dictionary?.[DictionaryKeys.GeoFindYourStore] ?? "",
      searchPlaceholder: dictionary?.[DictionaryKeys.GeoSearchPlaceholder] ?? "",
      geoNoStoreFoundTitle: dictionary?.[DictionaryKeys.GeoNoStoreFoundTitle] ?? "",
      geoNoStoreFoundBody: dictionary?.[DictionaryKeys.GeoNoStoreFoundBody] ?? "",
    });

  useEffect(() => {
    if (storeId) {
      getStoreDetailsById(locale, storeId).then((response) => {
        const { storeDetailsPage } = response ?? {};
        const store = storeDetailsPage?.props?.storeDetailsById ?? null;
        setCurrentStore(store);
        updateStoreTextFields(store);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId, clickAndCollectEnabled, locale]);

  useEffect(() => {
    if (geoStatus === "0") {
      setGeoAllowed(false);
    }
  }, [isGeoAllowed, geoStatus]);

  useEffect(() => {
    if (deviceView && typeof storeId !== "string") {
      const timer = setTimeout(() => {
        setIsTooltipVisible(true);
      }, 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [deviceView, storeId]);

  useEffect(() => {
    if (searchShopAllowed) {
      const geo = navigator.geolocation;
      geo.getCurrentPosition(handleSuccess, handleError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchShopAllowed, isGeoAllowed]);

  const updateStoreTextFields = useCallback(
    async (store: Nullable<Store>) => {
      setGeolocationTextFields({
        ...geolocationTextFields,
        linkText: store?.geomodifier ?? "",
        labelLinkText:
          clickAndCollectEnabled && store?.clickAndCollectStore
            ? dictionary?.[DictionaryKeys.GeoYourCcStore] ?? ""
            : dictionary?.[DictionaryKeys.GeoYourStore] ?? "",
      });
    },
    [clickAndCollectEnabled, dictionary, geolocationTextFields],
  );

  const saveCurrentStore = useCallback(
    async (store: Nullable<Store>) => {
      setCurrentStore(store);
      if (store) {
        setStoreToCookies(store.id);
        updateStoreTextFields(store);
      }
      if (account && store) {
        const token = await extractToken();
        await updateSelectedStoreToAccount(
          account,
          store.id,
          token,
          updateUserAccount,
        );
      }
    },
    [account, extractToken, updateStoreTextFields, updateUserAccount],
  );

  const handleStoresChange = useCallback(
    (stores: Nullable<Store>[]) => setStoresInStoreSelector(stores),
    [],
  );

  const handleOffsetChange = useCallback((offset: number) => setOffset(offset), []);

  const handleSuccess = useCallback(
    async (position: GeolocationPosition) => {
      setGeoStatusToCookies(1);
      setGeoAllowed(true);
      const storesArr = await getStoresByCoords(
        locale,
        position.coords.latitude,
        position.coords.longitude,
        1,
        0,
        STORE_SEARCH_RADIUS,
      );

      if (storesArr.length) {
        await saveCurrentStore(storesArr[0]);
      } else {
        setIsPopUpActive(true);
      }
    },
    [locale, saveCurrentStore],
  );

  const handleError = () => {
    setGeoStatusToCookies(0);
    setGeoAllowed(false);
    setStoreToCookies("");
  };

  const handleChangeCurrentStore = useCallback(
    async (store: Nullable<Store>) => {
      await saveCurrentStore(store);
      updateStoreTextFields(store);
    },
    [saveCurrentStore, updateStoreTextFields],
  );

  const handleShareLocationClick = useCallback(
    async (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      setIsTooltipVisible(false);
      setSearchShopAllowed(true);
    },
    [],
  );

  const handleCloseButtonClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setIsTooltipVisible(false);
    setStoreToCookies("");
    event.stopPropagation();
  }, []);

  const handlePopupClose = () => {
    setIsPopUpActive(false);
  };

  const handleSearch = useCallback(
    async (_query: string, placeId: string | undefined) => {
      try {
        setInProgress(true);

        const placeApiProvider = isLoqateSearchEnabled
          ? PlaceApiProvider.loqate
          : PlaceApiProvider.google;

        const result = await getStoresByPlaceId(
          locale,
          country?.code2 ?? "",
          placeId || "",
          placeApiProvider,
        );

        if (result?.stores?.length) {
          const store = result.stores[0];
          saveCurrentStore(store);
        } else {
          setIsPopUpActive(true);
        }
      } catch (error) {
        Logger.getLogger().error(error as string);
        throw error;
      } finally {
        setInProgress(false);
      }
    },
    [country?.code2, locale, saveCurrentStore, isLoqateSearchEnabled],
  );

  const displayLoader = () => {
    setInProgress(true);
  };

  return {
    handleShareLocationClick,
    handleCloseButtonClick,
    handleStoresChange,
    handleOffsetChange,
    handleChangeCurrentStore,
    handleSearch,
    isTooltipVisible,
    isGeoAllowed,
    searchShopAllowed,
    geolocationTextFields,
    currentStore,
    storesInStoreSelector,
    offset,
    inProgress,
    displayLoader,
    isPopUpActive,
    handlePopupClose,
  };
};
