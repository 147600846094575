import { useState } from "react";

import Cookies, { CookieAttributes } from "js-cookie";

import { Routes } from "~/constants/request";
import { isCookiesGroupAccepted } from "~/helpers/one-trust/cookie-parser";
import { Logger } from "~/utils/logger";

type ReturnType<T> = [T, (value: T) => void];

export const useCookies = <T>(
  key: string,
  initialValue: T,
  options?: CookieAttributes & { oneTrustGroup: string },
): ReturnType<T> => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const { oneTrustGroup } = options ?? {};
      if (IS_SERVER || !oneTrustGroup || !isCookiesGroupAccepted(oneTrustGroup)) {
        return initialValue;
      }
      const item = Cookies.get(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      Logger.getLogger().error(error as string);
      return initialValue;
    }
  });
  const setValue = (value: T) => {
    try {
      const { oneTrustGroup, ...cookieOptions } = options ?? {};
      if (!IS_SERVER && oneTrustGroup && isCookiesGroupAccepted(oneTrustGroup)) {
        setStoredValue(value);
        Cookies.set(key, JSON.stringify(value), {
          path: Routes.HOME,
          ...cookieOptions,
        });
      }
    } catch (error) {
      Logger.getLogger().error(error as string);
    }
  };
  return [storedValue, setValue];
};
