import { Logger } from "~/utils/logger";

import { LOGGER_LABEL_PREFIX } from "../../constants";

import { DY_DATALAYER_EVENT_NAME } from "./constants";
import { DyDataLayerEvent } from "./types";
import { dyAnalyticsMetadataSchema, dyResponseDataSchema } from "./yup-schema";

const LOGGER_LABEL = `${LOGGER_LABEL_PREFIX}:processDataLayerEvents`;

export const processDataLayerEvents = async (responseDataJson: unknown) => {
  try {
    const dyResponseDataValid =
      await dyResponseDataSchema.validate(responseDataJson);

    for (const choice of dyResponseDataValid.choices) {
      for (const variation of choice.variations) {
        const isMissingAnalyticsMetadata = !(
          typeof variation.analyticsMetadata === "object" &&
          variation.analyticsMetadata !== null
        );
        if (isMissingAnalyticsMetadata) {
          continue;
        }
        try {
          const analyticsMetadataValid = await dyAnalyticsMetadataSchema.validate(
            variation.analyticsMetadata,
          );

          const dyDataLayerEvent: DyDataLayerEvent = {
            event: DY_DATALAYER_EVENT_NAME,
            campaignID: analyticsMetadataValid.campaignId,
            campaignName: analyticsMetadataValid.campaignName,
            experienceID: analyticsMetadataValid.experienceId,
            experienceName: analyticsMetadataValid.experienceName,
            variationID: analyticsMetadataValid.variationId.toString(),
            variationName: analyticsMetadataValid.variationName,
          };

          /* eslint-disable no-restricted-globals */
          if (!window?.dataLayer) {
            throw new Error(
              `window?.dataLayer is unavailable and equals: ${window?.dataLayer}`,
            );
          }

          window.dataLayer.push(dyDataLayerEvent);

          Logger.getLogger(LOGGER_LABEL).debug(
            `DY data layer event was pushed to the window.dataLayer:\n${JSON.stringify(
              dyDataLayerEvent,
              null,
              4,
            )}`,
          );
          /* eslint-enable no-restricted-globals */
        } catch (e: unknown) {
          Logger.getLogger(LOGGER_LABEL).warn(
            `Particular DY data layer event was not pushed to the window.dataLayer for the reason below:\n${e}`,
          );
        }
      }
    }
  } catch (e: unknown) {
    Logger.getLogger(LOGGER_LABEL).warn(
      `All of the DY data layer events were not pushed to the window.dataLayer for the reason below:\n${e}`,
    );
  }
};
