"use client";
import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import clsx from "clsx";

import { NavItemProps } from "~/bff/components/NavItem";
import { CombinedLink } from "~/components/combined-link/component";
import { Image } from "~/components/image/component";
import { prepareTextAttribute } from "~/helpers/prepare-text-attribute";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { getImageSrc } from "~/utils/image/get-image-src";

import { HERO_BANNER_CAROUSEL_ITEM_CLASSES, imageTemplates } from "./constants";
import { CarouselItemStyled } from "./styled";

export const HeroBannerCarouselItem: React.FC<NavItemProps> = ({
  carouselImage,
  title,
  urlSlug,
  testAutomationId = "nav-item",
}) => {
  return (
    <CombinedLink
      href={`${urlSlug}`}
      underline="none"
      display="block"
      {...getTestAutomationProps(testAutomationId)}
    >
      <CarouselItemStyled>
        <Box className={HERO_BANNER_CAROUSEL_ITEM_CLASSES.imageFrame}>
          <Image
            className={HERO_BANNER_CAROUSEL_ITEM_CLASSES.image}
            imageTemplates={imageTemplates}
            src={carouselImage ? getImageSrc(carouselImage.source ?? undefined) : ""}
            alt={prepareTextAttribute(carouselImage?.alt, true)}
            title={prepareTextAttribute(carouselImage?.title)}
          />
        </Box>
        <Typography
          variant="caption"
          className={clsx("category", HERO_BANNER_CAROUSEL_ITEM_CLASSES.title)}
          {...getTestAutomationProps(`${testAutomationId}-title`)}
        >
          {title}
        </Typography>
      </CarouselItemStyled>
    </CombinedLink>
  );
};
