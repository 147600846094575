import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { COLOR_PRIMARY_BLACK, COLOR_PRIMARY_WHITE } from "~/theme/colors";

const NOTIFICATION_CLASSES_PREFIX = "notification";

export const NOTIFICATION_CLASSES = {
  message: `${NOTIFICATION_CLASSES_PREFIX}-message`,
};

export const NotificationContainer = styled(Box)(({ theme }) => {
  return {
    padding: theme.spacing(1.5, 2),
    backgroundColor: COLOR_PRIMARY_BLACK,
    color: COLOR_PRIMARY_WHITE,
    display: "flex",
    [`& .${NOTIFICATION_CLASSES.message}`]: {
      marginLeft: theme.spacing(1.25),
    },
  };
});
