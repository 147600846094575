import { useEffect } from "react";

export const useWindowEvent = (
  event: string,
  handler: (event: unknown) => void,
  passive = false,
): void => {
  useEffect(() => {
    addEventListener(event, handler, passive);

    return () => removeEventListener(event, handler);
  });
};
