import { gql } from "@apollo/client";

export const GET_AUTO_SUGGESTIONS = gql`
  query Autosuggestions(
    $q: String!
    $locale: String
    $brUid2: String
    $brRefUrl: String
  ) {
    autosuggestions(q: $q, locale: $locale, _br_uid_2: $brUid2, ref_url: $brRefUrl) {
      queryContext {
        originalQuery
      }
      suggestionGroups {
        catalogName
        view
        querySuggestions {
          query
          displayText
        }
        attributeSuggestions {
          value
          name
          attributeType
        }
      }
    }
  }
`;
