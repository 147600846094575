import * as yup from "yup";

export const dyAnalyticsMetadataSchema = yup.object().shape({
  campaignId: yup.number().required().positive().integer(),
  campaignName: yup.string().required(),
  experienceId: yup.number().required().positive().integer(),
  experienceName: yup.string().required(),
  variationId: yup.number().required().positive().integer(),
  variationName: yup.string().required(),
});

export const dyResponseDataSchema = yup.object().shape({
  choices: yup
    .array()
    .of(
      yup.object().shape({
        variations: yup
          .array()
          .of(
            yup.object().shape({
              analyticsMetadata: dyAnalyticsMetadataSchema,
            }),
          )
          .required(),
      }),
    )
    .required(),
});
