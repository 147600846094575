import moment from "moment";

import { CartValidationError } from "~/bff/types/CartValidationError";
import { Store } from "~/bff/types/Store";
import { ShoppingBagContextData } from "~/context/shopping-bag/context";
import { getCollectionDateFromStore } from "~/helpers/store";

import { ErrorReasons } from "./constants";

type ValueOf<T> = T[keyof T];

type ShoppingBag = ValueOf<Pick<ShoppingBagContextData, "userShoppingBag">>;

type GetPreparedDataReturnType = Partial<{
  storeName: string;
  totalPrice: string;
  daysToCollect: number;
  minOrderPrice: string;
  quantityLimit: string;
  hasRequiredMinPrice: boolean;
  hasCollectionPoint: boolean;
  hasExceededMaxPrice: boolean;
  hasMaxLineItems: boolean;
  maxLineItems?: string;
}>;

type GetPreparedData = ({
  store,
  bag,
}: {
  store: Store | undefined;
  bag: ShoppingBag | undefined;
}) => GetPreparedDataReturnType;

const getDaysToCollect = (store?: Store): number | null => {
  if (!store) {
    return null;
  }

  try {
    const currentDate = new Date();
    const collectDate = getCollectionDateFromStore(store);
    if (!collectDate) {
      return null;
    }

    const diffBetweenDates = moment(collectDate).diff(moment(currentDate));
    const diffInDays = Math.ceil(moment.duration(diffBetweenDates).asDays());
    return diffInDays;
  } catch (err) {
    return null;
  }
};

const hasRequiredMinPrice = (bag: ShoppingBag): boolean => {
  const hasError = bag?.validateCart?.errors?.some(
    (error) => error?.reason === ErrorReasons.MIN_ORDER_PRICE,
  );
  return Boolean(!hasError);
};

const hasExceededMaxPrice = (bag: ShoppingBag): boolean => {
  const hasError = bag?.validateCart?.errors?.some(
    (error) => error?.reason === ErrorReasons.MAX_ORDER_PRICE,
  );
  return Boolean(hasError);
};

const getMaxLineItemsError = (
  bag: ShoppingBag,
): CartValidationError | null | undefined => {
  return bag?.validateCart?.errors?.find(
    (error) => error?.reason === ErrorReasons.MAX_LINE_ITEMS,
  );
};

export const getPreparedData: GetPreparedData = ({ store, bag }) => {
  try {
    const maxLineItemsError = getMaxLineItemsError(bag);

    return {
      storeName: store?.geomodifier ?? undefined,
      totalPrice: bag?.totalPrice ?? undefined,
      daysToCollect: getDaysToCollect(store) ?? undefined,
      minOrderPrice: store?.storeLimits?.minOrderPrice ?? undefined,
      quantityLimit: store?.storeLimits?.maxLineItemQuantity ?? undefined,
      hasCollectionPoint: store?.clickAndCollectStore ?? undefined,
      hasRequiredMinPrice: hasRequiredMinPrice(bag) ?? undefined,
      hasExceededMaxPrice: hasExceededMaxPrice(bag) ?? undefined,
      hasMaxLineItems: Boolean(maxLineItemsError),
      maxLineItems: maxLineItemsError?.expected ?? undefined,
    };
  } catch (err) {
    return {};
  }
};

export const checkApplePayEnabled = () => {
  const applePaySession = (global?.window as any)?.ApplePaySession;

  return applePaySession?.canMakePayments();
};
