import { NonStoreColorSelectorInventoryResponse } from "~/bff/transport/NonStoreColorSelectorInventory";
import {
  StyleInventoryInStoreResponse,
  StyleInventoryInStoreVariables,
} from "~/bff/transport/StyleInventoryInStore";
import { Inventory } from "~/bff/types/Inventory";
import { NonStoreColorSelectorInventory } from "~/bff/types/NonStoreColorSelectorInventory";
import { Product } from "~/bff/types/Product";
import { Store } from "~/bff/types/Store";
import { StyleInventory } from "~/bff/types/StyleInventory";
import { Variant } from "~/bff/types/Variant";
import {
  ConvertTypesInput,
  ConvertTypesOutput,
  ProductVariant,
  SalesPanelProductInfo,
} from "~/components/sales-panel/types";
import {
  AvailabilityStatusKey,
  ClickAndCollectStatusKey,
  SalesChannelKey,
} from "~/constants/product-availability-statuses";
import { InventoryExtendedType } from "~/context/product-details/types";
import { getApolloClient } from "~/graphql/client";
import { GET_NON_STORE_SPECIFIC_COLOUR_AVAILABILITY } from "~/graphql/queries/getNonStoreSpecificColourAvailability";
import { GET_STYLE_INVENTORY_IN_STORE_QUERY } from "~/graphql/queries/getStyleInventoryInStore";
import { Nullable } from "~/types/general.types";
import { Logger } from "~/utils/logger";

export const getStyleInventoryByStore = async (
  locale: string,
  styleCode: string,
  storeId: string,
  isStoreTrial?: boolean,
  isMemberOfTrialStoreGroup?: boolean,
): Promise<StyleInventory | undefined | null> => {
  if (!locale || !styleCode || !storeId) {
    return;
  }
  try {
    const { data } = await getApolloClient().query<
      StyleInventoryInStoreResponse,
      StyleInventoryInStoreVariables
    >({
      query: GET_STYLE_INVENTORY_IN_STORE_QUERY,
      variables: {
        locale,
        styleCode,
        storeId,
      },
    });
    if (isStoreTrial && !isMemberOfTrialStoreGroup) {
      const storesWithTrialFlags = data?.styleInventoryInStore?.data?.map(
        (item) => ({
          ...item,
          clickAndCollectStore: false,
          colourId: item?.colourId ?? "",
          locationId: item?.locationId ?? "",
          skuId: item?.skuId ?? "",
        }),
      );
      return { ...data?.styleInventoryInStore, data: storesWithTrialFlags };
    }
    return data.styleInventoryInStore;
  } catch (error) {
    Logger.getLogger().error(String(error));
  }
};

export const getNonStoreSpecificColourAvailability = async (
  locale: string,
  styleCode: string,
): Promise<NonStoreColorSelectorInventory | undefined | null> => {
  try {
    const { data } =
      await getApolloClient().query<NonStoreColorSelectorInventoryResponse>({
        query: GET_NON_STORE_SPECIFIC_COLOUR_AVAILABILITY,
        variables: {
          locale,
          styleCode,
        },
      });
    return data.nonStoreColorSelectorInventory;
  } catch (error) {
    Logger.getLogger().error(String(error));
  }
};

export const getCurrentColorsAvailable = (
  colorsId: string[],
  styleVariantsAvailability: Inventory[],
): Inventory[] => {
  return colorsId.reduce((prev: Inventory[], current: string) => {
    const currentColorInventories = styleVariantsAvailability?.filter(
      (color) => color.colourId === current,
    );
    return [...prev, ...currentColorInventories];
  }, []);
};

export const getVariantsWithAvailabilityStatuses = (
  storeId: Nullable<string>,
  variants: ProductVariant[],
  currentColorsAvailable: Inventory[],
): ProductVariant[] => {
  return variants.map((variant) => {
    const currentVariant = currentColorsAvailable?.find(
      (color) =>
        color?.skuId === variant?.sku && (!storeId || color?.locationId === storeId),
    );
    return {
      ...variant,
      availability: currentVariant?.available,
      salesChannel: currentVariant?.salesChannel,
      clickAndCollectAvailableQuantity:
        currentVariant?.clickAndCollectAvailableQuantity,
      clickAndCollectStore: currentVariant?.clickAndCollectStore,
      clickAndCollectStock: currentVariant?.clickAndCollectStock,
    };
  });
};

export const isNotSoldInStore = (product?: Inventory): boolean => {
  if (!product) {
    return false;
  }
  return product?.salesChannel === SalesChannelKey.notSoldInStore;
};

export const isStoreOnly = (product?: Inventory): boolean => {
  if (!product) {
    return false;
  }
  return product?.salesChannel === SalesChannelKey.storeOnly;
};

export const isVariantSoldOutInStore = (product: Inventory): boolean => {
  if (!product) {
    return false;
  }
  return product?.available === AvailabilityStatusKey.soldOutInStore;
};

export const isAllSkuOutOfStockForClickAndCollect = (
  styleInventoryInStore?: Inventory[],
): boolean => {
  if (!styleInventoryInStore?.length) {
    return false;
  }
  return styleInventoryInStore?.every((variant: Inventory) => {
    return (
      (!variant?.clickAndCollectStock ||
        !variant?.clickAndCollectAvailableQuantity) &&
      variant?.clickAndCollectStore
    );
  });
};

const filterInventoryByVariantsAndStore = (
  storeId: string | null,
  inventory?: Inventory[],
  variants?: Map<string, Variant>,
) => {
  return inventory?.filter(
    (el) => variants?.get(el?.skuId) && el?.locationId === storeId,
  );
};

const isEveryElementMeetRequirements = (
  array: Inventory[] | undefined,
  callback: (product: Inventory) => boolean,
) => {
  return array?.every((el) => callback(el));
};

const isSameSalesChannel = (arr: Inventory[] | undefined) => {
  if (!arr?.length) {
    return false;
  }

  const propValues = arr.map((obj) => obj?.salesChannel);
  const firstPropValue = propValues[0];

  return propValues.every((value) => value === firstPropValue);
};

export const calculateInStoreStatus = ({
  storeId,
  isOneSize,
  currentColor,
  styleInventory,
  currentSku,
  variants,
}: {
  storeId: Nullable<string>;
  isOneSize: boolean;
  currentColor?: string;
  styleInventory?: InventoryExtendedType[];
  currentSku?: string;
  variants?: Map<string, Variant>;
}): AvailabilityStatusKey | SalesChannelKey => {
  const productInStore = styleInventory?.filter(
    (variant) =>
      variant.colourId === currentColor &&
      (!storeId || variant.locationId === storeId),
  );

  const currentProductBySku = productInStore?.find(
    (variant) => variant?.skuId === currentSku,
  );

  const oneSizeProductByColourId = styleInventory?.find(
    (color) =>
      color?.colourId === currentColor && (!storeId || color.locationId === storeId),
  );

  const inventoryForVariants = filterInventoryByVariantsAndStore(
    storeId,
    styleInventory,
    variants,
  );

  const isAllVariantsNotSoldInStore = isEveryElementMeetRequirements(
    inventoryForVariants,
    isNotSoldInStore,
  );
  const isAllVariantsSoldOut = isEveryElementMeetRequirements(
    inventoryForVariants,
    isVariantSoldOutInStore,
  );

  if (isNotSoldInStore(currentProductBySku) || isAllVariantsNotSoldInStore) {
    return SalesChannelKey.notSoldInStore;
  }

  if (isOneSize) {
    if (isNotSoldInStore(oneSizeProductByColourId)) {
      return SalesChannelKey.notSoldInStore;
    }
    return (
      oneSizeProductByColourId?.available ??
      AvailabilityStatusKey.notAvailableInStore
    );
  }
  if (!isOneSize && currentSku === "") {
    if (
      isAllVariantsSoldOut &&
      isAllSkuOutOfStockForClickAndCollect(inventoryForVariants) &&
      isSameSalesChannel(inventoryForVariants)
    ) {
      return AvailabilityStatusKey.soldOutInStore;
    }
    return AvailabilityStatusKey.sizeNotSelected;
  }

  const isProductNotRanged =
    productInStore?.reduce((sum, variant) => {
      const isSkuNotRanged =
        variant.available === AvailabilityStatusKey.notAvailableInStore ||
        !variant.available;
      return sum && isSkuNotRanged;
    }, true) ?? true;

  if (isProductNotRanged) {
    return AvailabilityStatusKey.notAvailableInStore;
  } else {
    return currentProductBySku?.available as AvailabilityStatusKey | SalesChannelKey;
  }
};

export const calculateClickAndCollectInStoreStatus = ({
  store,
  currentSku,
  isOneSize,
  styleVariantsAvailability,
  styleInventoryInStore,
  variants,
  currentColor,
  isMemberOfTrialStoreGroup,
}: {
  store: Nullable<Store>;
  currentSku: string;
  isOneSize: boolean;
  styleVariantsAvailability: Inventory[];
  styleInventoryInStore: Inventory[];
  variants: Map<string, Variant>;
  currentColor?: string;
  isMemberOfTrialStoreGroup?: boolean;
}): ClickAndCollectStatusKey => {
  if (store?.trial && !isMemberOfTrialStoreGroup) {
    return ClickAndCollectStatusKey.notAvailableInStore;
  }
  const styleVariantsWithUpdatedCurrentStore = styleVariantsAvailability
    ? [
        ...styleVariantsAvailability.filter(
          (variant) =>
            !styleInventoryInStore?.find(
              (styleInventory) =>
                styleInventory.locationId === variant.locationId &&
                styleInventory.skuId === variant.skuId &&
                styleInventory.colourId === variant.colourId,
            ),
        ),
        ...(styleInventoryInStore ?? []),
      ]
    : [];
  const isStoreWithClickAndCollectOption = store?.clickAndCollectStore;
  const currentVariant = variants?.get(currentSku);
  const isCurrentVariantStoreOnly =
    !currentVariant?.clickAndCollect && !currentVariant?.clickAndCollectExclusive;

  if (!currentSku && !isOneSize) {
    const anySkuWithClickAndCollectOption =
      styleVariantsWithUpdatedCurrentStore?.find(
        (item) => item?.clickAndCollectStock && item?.locationId === store?.id,
      );

    const inventoryForVariants = filterInventoryByVariantsAndStore(
      store?.id ? store.id : null,
      styleVariantsAvailability,
      variants,
    );

    const isAllVariantsAreStoreOnly = isEveryElementMeetRequirements(
      inventoryForVariants,
      isStoreOnly,
    );

    const isAllVariantsSoldOutInStore = isEveryElementMeetRequirements(
      inventoryForVariants,
      isVariantSoldOutInStore,
    );

    const isAllVariantsClickAndCollectExclusive = isEveryElementMeetRequirements(
      inventoryForVariants,
      isNotSoldInStore,
    );

    if (isAllVariantsAreStoreOnly) {
      return ClickAndCollectStatusKey.empty;
    }

    if (
      isStoreOnly(anySkuWithClickAndCollectOption) &&
      !isStoreWithClickAndCollectOption
    ) {
      return ClickAndCollectStatusKey.empty;
    }

    if (anySkuWithClickAndCollectOption && !isStoreWithClickAndCollectOption) {
      return ClickAndCollectStatusKey.notAvailableInStore;
    }

    if (
      isAllVariantsClickAndCollectExclusive &&
      isAllSkuOutOfStockForClickAndCollect(inventoryForVariants) &&
      isSameSalesChannel(inventoryForVariants)
    ) {
      return ClickAndCollectStatusKey.soldOut;
    }

    if (
      isAllSkuOutOfStockForClickAndCollect(inventoryForVariants) &&
      isAllVariantsSoldOutInStore &&
      isSameSalesChannel(inventoryForVariants)
    ) {
      return ClickAndCollectStatusKey.soldOut;
    }

    return ClickAndCollectStatusKey.sizeNotSelected;
  }
  const currentStyleInventoryInfo = styleVariantsWithUpdatedCurrentStore?.find(
    (info) => {
      if (isOneSize) {
        return info?.colourId === currentColor && info.locationId === store?.id;
      }
      return info?.skuId === currentSku && info.locationId === store?.id;
    },
  );

  if (!currentStyleInventoryInfo && isCurrentVariantStoreOnly) {
    return ClickAndCollectStatusKey.empty;
  }

  const isSkuPartOfClickAndCollectCampaign =
    currentStyleInventoryInfo?.salesChannel === SalesChannelKey.notSoldInStore ||
    currentStyleInventoryInfo?.salesChannel === SalesChannelKey.availableEverywhere;

  const isCurrentVariantAvailableForClickAndCollect =
    currentVariant?.clickAndCollect || currentVariant?.clickAndCollectExclusive;

  if (
    !currentStyleInventoryInfo &&
    isCurrentVariantAvailableForClickAndCollect &&
    isStoreWithClickAndCollectOption
  ) {
    return ClickAndCollectStatusKey.soldOut;
  }

  if (isStoreOnly(currentStyleInventoryInfo)) {
    return ClickAndCollectStatusKey.empty;
  }
  if (
    currentStyleInventoryInfo?.clickAndCollectStore &&
    isSkuPartOfClickAndCollectCampaign &&
    !currentStyleInventoryInfo?.clickAndCollectAvailableQuantity
  ) {
    return ClickAndCollectStatusKey.soldOut;
  }
  if (!currentStyleInventoryInfo?.clickAndCollectStore) {
    return ClickAndCollectStatusKey.notAvailableInStore;
  }
  if (
    currentStyleInventoryInfo?.clickAndCollectStore &&
    !currentStyleInventoryInfo?.clickAndCollectAvailableQuantity
  ) {
    return ClickAndCollectStatusKey.soldOut;
  }
  return ClickAndCollectStatusKey.availableInStore;
};

export const getVariantsFromProductInfo = (
  productInfo: SalesPanelProductInfo | Product,
): Variant[] => {
  const masterVariant = {
    displaySize: productInfo?.isProductActive ? productInfo?.displaySize : null,
    sizeId: productInfo?.sizeId,
    sku: productInfo?.sku,
    price: productInfo?.masterPrice,
    clickAndCollect: productInfo?.clickAndCollect,
    clickAndCollectExclusive: productInfo?.clickAndCollectExclusive,
    availability: null,
  };
  const otherProductVariants =
    productInfo?.variants?.map((variant) => ({
      ...variant,
      availability: null,
    })) ?? [];
  return [masterVariant, ...otherProductVariants];
};

export const convertTypes = ({
  productInfo,
  breadcrumbs,
  currentSize,
  sizeSelectorTranslations,
  disclaimer,
  categoryExists,
  currentStore,
  addToBagButtonText,
  currentSku,
  currentPrice,
  displayPrice,
  addButtonText,
  addedButtonText,
  pricePerUnitText,
}: ConvertTypesInput): ConvertTypesOutput => {
  return {
    currentStoreId: currentStore?.id ? currentStore.id : null,
    colourCode: productInfo?.colourCode ? productInfo.colourCode : undefined,
    currentSizeName: currentSize?.currentSizeName ?? undefined,
    addToBagButtonText: addToBagButtonText ?? undefined,
    sizeLabelText: sizeSelectorTranslations?.sizeGuideButtonText ?? undefined,
    breadcrumbs: breadcrumbs?.filter((item) => item !== undefined && item !== null),
    productCode: productInfo?.productCode ?? "",
    productName: productInfo?.name ?? "",
    productDescription: productInfo?.description ?? "",
    productStyleCode: productInfo?.styleCode ?? "",
    price: (currentPrice || displayPrice) ?? "",
    productSku: (currentSku || productInfo?.sku) ?? "",
    productCurrentSku: currentSku ?? "",
    pricePerUnitText: pricePerUnitText ?? "",
    productDisplaySize: productInfo?.displaySize ?? "",
    productInfoSku: productInfo?.sku ?? "",
    categoryExists: categoryExists ?? undefined,
    disclaimer: disclaimer ?? undefined,
    addButtonText: addButtonText ?? undefined,
    addedButtonText: addedButtonText ?? undefined,
    productUrl: productInfo?.url ?? "",
  };
};
