/* eslint-disable no-restricted-globals */

import React, { useEffect, useState, useRef } from "react";

import { useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { debounce } from "lodash";

import { getFormatMedia } from "~/theme/utils";

import { AGEC_BANNER_CLASSES, AGEC_BANNER_LINK } from "./constants";
import { AgecBannerContainer } from "./styled";

export const AgecBanner = (): React.ReactElement => {
  const theme = useTheme();
  const { BREAKPOINT_DESKTOP } = getFormatMedia(theme);
  const isDesktop = useMediaQuery(BREAKPOINT_DESKTOP);

  const [contentDisplayState, setContentDisplayState] = useState<
    "collapsed" | "expanded"
  >(isDesktop ? "expanded" : "collapsed");

  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleContentVisibility = () => {
      setContentDisplayState((currentState) => {
        if (!contentRef.current) {
          return currentState;
        }

        // if it's been expanded once, we don't touch it anymore
        if (currentState === "expanded") {
          return currentState;
        }

        // if it's a desktop, we always show the full content
        if (isDesktop) {
          return "expanded";
        }

        const isContentTooBig =
          contentRef.current.scrollHeight > window.innerHeight * 0.2;

        if (isContentTooBig) {
          return "collapsed";
        }

        return currentState;
      });
    };

    handleContentVisibility();
    window.addEventListener("resize", debounce(handleContentVisibility, 300));
    return () => window.removeEventListener("resize", handleContentVisibility);
  }, [isDesktop]);

  const handleShowMore = (e: React.MouseEvent<HTMLButtonElement>) => {
    // this is needed because we’re inside an AgecBannerContainer which is a Link.
    e.preventDefault();
    e.stopPropagation();
    setContentDisplayState("expanded");
  };

  return (
    <AgecBannerContainer
      className={
        contentDisplayState === "expanded" ? AGEC_BANNER_CLASSES.expanded : ""
      }
      aria-label="Injonction à l'encontre de la SAS Primark France"
      href={AGEC_BANNER_LINK}
      target="_blank"
    >
      <Container ref={contentRef}>
        <div className={AGEC_BANNER_CLASSES.title}>
          Injonction à l&apos;encontre de la SAS Primark France
        </div>

        {!isDesktop && contentDisplayState === "collapsed" && (
          <Container className={AGEC_BANNER_CLASSES.showMoreButtonContainer}>
            <Button
              className={AGEC_BANNER_CLASSES.showMoreButton}
              onClick={handleShowMore}
            >
              Afficher plus d&apos;info
            </Button>
          </Container>
        )}

        <div
          className={
            contentDisplayState === "collapsed"
              ? AGEC_BANNER_CLASSES.truncatedContent
              : ""
          }
        >
          <span>
            Une enquête de la Direction Départementale de la Protection des
            Populations du Val-de-Marne a mis en évidence que la SAS Primark France
            mettait en vente des vêtements et des chaussures :
            <br />
            <br />
            <ul className={AGEC_BANNER_CLASSES.list}>
              <li>
                sans mettre à disposition des consommateurs les informations
                obligatoires relatives aux qualités et caractéristiques
                environnementales des produits ;
              </li>

              <li>
                en présentant certaines mentions en langue anglaise, sans traduction
                en langue française.
              </li>
            </ul>
            <br />
            Ces manquements privent les consommateurs d&apos;une information précise
            et complète sur les caractéristiques environnementales des articles et
            sur la traçabilité des étapes de leur fabrication.
            <br />
            <br />
            La DDPP du Val-de-Marne a donc enjoint à la SAS Primark France de se
            mettre en conformité avec la réglementation.
            <br />
            <br />
            Pour en savoir plus, consultez{" "}
            <a
              href={AGEC_BANNER_LINK}
              target="_blank"
              className={AGEC_BANNER_CLASSES.textLink}
              tabIndex={contentDisplayState === "expanded" ? 0 : -1}
              aria-hidden={contentDisplayState === "collapsed"}
              rel="noreferrer"
            >
              https://www.economie.gouv.fr/dgccrf/
            </a>
          </span>
        </div>
      </Container>
    </AgecBannerContainer>
  );
};

/* eslint-enable no-restricted-globals */
