export const MobileBrowserRegExp =
  /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone|playbook|silk/i;

export const TRANSITION_DURATION_200 = "200ms";

export enum Keys {
  Enter = "Enter",
  Tab = "Tab",
  ArrowUp = "ArrowUp",
  ArrowDown = "ArrowDown",
}
