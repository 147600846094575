import { gql } from "@apollo/client";

export const GET_STORES_FOR_FILTERS = gql`
  query StoresList($locale: String!, $limit: Int, $offset: Int) {
    storesList(locale: $locale, limit: $limit, offset: $offset) {
      count
      stores {
        id
        trial
        name
        geomodifier
        address {
          line1
          line2
          city
          postalCode
          countryCode
          region
        }
        distance {
          distanceMiles
          distanceKilometers
        }
        clickAndCollectStore
        storeLimits {
          minOrderPrice
          maxOrderPrice
          maxLineItemQuantity
        }
        timeZoneUtcOffset
        timezone
        additionalHoursText
        facilities
        hours {
          monday {
            openIntervals {
              start
              end
            }
            isClosed
            date
          }
          tuesday {
            openIntervals {
              end
              start
            }
            isClosed
            date
          }
          wednesday {
            openIntervals {
              end
              start
            }
            isClosed
            date
          }
          thursday {
            openIntervals {
              end
              start
            }
            isClosed
            date
          }
          friday {
            openIntervals {
              end
              start
            }
            isClosed
            date
          }
          saturday {
            openIntervals {
              end
              start
            }
            isClosed
            date
          }
          sunday {
            openIntervals {
              end
              start
            }
            isClosed
            date
          }
        }
        holidayHours {
          openIntervals {
            end
            start
          }
          isClosed
          date
        }
      }
    }
  }
`;

export const GET_STORES_FOR_SEARCH_FILTERS = gql`
  query GetStoresForSearchFilters(
    $locale: String!
    $latitude: Float!
    $longitude: Float!
    $radius: Int
  ) {
    geosearch(
      locale: $locale
      latitude: $latitude
      longitude: $longitude
      radius: $radius
    ) {
      stores {
        id
        trial
        name
        geomodifier
        address {
          line1
          line2
          city
          countryCode
          region
        }
        distance {
          distanceMiles
          distanceKilometers
        }
        clickAndCollectStore
      }
    }
  }
`;
