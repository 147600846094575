import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";
import { FeatureFlagList } from "~/services/azure-configurator/types";

import { DyCampaignsInfo } from "./constants";
import { DyCampaignName } from "./types";

export const isDyCampaignEnabled = (
  campaignName: DyCampaignName,
  pageLocale: string,
): boolean => {
  const isDyEnabled = Boolean(
    AzureConfigurator.getConfig(pageLocale)?.featureFlagList?.dynamicYield?.enabled,
  );

  const isCampaignEnabled =
    AzureConfigurator.getConfig(pageLocale)?.featureFlagList?.[
      DyCampaignsInfo[campaignName].featureFlagName as keyof FeatureFlagList
    ]?.enabled;

  return isDyEnabled && !!isCampaignEnabled;
};
