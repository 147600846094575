"use client";

import React, { useCallback, MouseEvent, useMemo } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import NextLink from "next/link";
import { useParams } from "next/navigation";

import { Params, Routes } from "~/constants/request";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { History24 } from "~/theme/icons/components/history";
import { Nullable } from "~/types/general.types";

import { LAST_SEARCHES_CLASSES } from "./constants";
import { LastSearchesContainer } from "./styled";

export interface LastSearchesProps {
  header: Nullable<string> | undefined;
  searches?: string[];
  onSelect?: (query: string) => void;
}

export const LastSearches: React.FC<LastSearchesProps> = ({
  header,
  searches,
  onSelect,
}) => {
  const { locale } = useParams();
  const query = new URLSearchParams();

  const lastSearches = useMemo(
    () =>
      searches
        ?.map((query) => `${query ?? ""}`.trim())
        .filter(Boolean)
        .slice(0, 4),
    [searches],
  );
  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      const query = event.currentTarget.dataset.query as string;
      if (onSelect) {
        onSelect(query);
      }
    },
    [onSelect],
  );

  if (!searches?.length) {
    return null;
  }

  return (
    <LastSearchesContainer>
      <Box
        className={LAST_SEARCHES_CLASSES.header}
        {...getTestAutomationProps("last-search-title")}
      >
        <Typography className={LAST_SEARCHES_CLASSES.headerText}>
          {header}
        </Typography>
      </Box>
      {lastSearches?.map((text) => {
        query.set(Params.QUERY, text);
        return (
          <Box
            className={LAST_SEARCHES_CLASSES.element}
            key={text}
            {...getTestAutomationProps("last-search-link")}
          >
            <NextLink
              prefetch={false}
              href={`/${locale}${Routes.SEARCH}?${query.toString()}`}
              passHref
              locale={false}
              legacyBehavior
            >
              <Button
                variant="text"
                className="body1"
                onClick={handleClick}
                startIcon={<History24 />}
                data-query={text}
              >
                {text}
              </Button>
            </NextLink>
          </Box>
        );
      })}
    </LastSearchesContainer>
  );
};
