import {
  DEFAULT_RETRY_CALL_TIMEOUT,
  MAX_LOG_EVENT_TRIES,
} from "~/constants/bloomreach";
import { Logger } from "~/utils/logger";

import { BloomreachEventType, BloomreachGroupType, BrEventData } from "./types";

let tries = 1;
let timeout: NodeJS.Timeout;

export function logBloomreachEvent(data: BrEventData): void {
  try {
    if (typeof BrTrk === "undefined") {
      tries++;
      if (tries < MAX_LOG_EVENT_TRIES) {
        timeout = setTimeout(
          () => logBloomreachEvent(data),
          DEFAULT_RETRY_CALL_TIMEOUT,
        );
      }
      return;
    }

    const { group, etype } = data;
    const { searchData, extraParams } = getEventData(data);
    BrTrk?.getTracker().logEvent(group, etype, searchData, ...extraParams);

    clearTimeout(timeout);
    tries = 1;
  } catch (error) {
    Logger.getLogger().error(String(error));
  }
}

function getEventData(data: BrEventData) {
  const { group, etype } = data;
  let searchData:
    | SearchData
    | WidgetViewData
    | WidgetClickData
    | WidgetAddData
    | BloomreachAddToCartData
    | null = null;
  const extraParams = [];

  if (group === BloomreachGroupType.suggest) {
    if (etype === BloomreachEventType.click) {
      searchData = {
        aq: data.aq ?? "",
        q: data.q,
        catalogs: data.catalogs,
        view_id: data.viewId,
      };
      extraParams.push({}, true);
    } else if (etype === BloomreachEventType.submit) {
      searchData = {
        q: data.q,
        catalogs: data.catalogs,
        view_id: data.viewId,
      };
      extraParams.push({}, true);
    }
  } else if (group === BloomreachGroupType.widget) {
    if (etype === BloomreachEventType.widgetView) {
      searchData = {
        wrid: data.wrid ?? undefined,
        wq: data.wq ?? undefined,
        wid: data.wid ?? undefined,
        wty: data.wty ?? undefined,
      };
      extraParams.push(true);
    } else if (etype === BloomreachEventType.widgetClick) {
      searchData = {
        wrid: data.wrid ?? undefined,
        wid: data.wid ?? undefined,
        wty: data.wty ?? undefined,
        item_id: data.item_id ?? undefined,
        wq: data.wq ?? undefined,
      };
      extraParams.push(true);
    }
  } else if (group === BloomreachGroupType.cart) {
    if (etype === BloomreachEventType.widgetAdd) {
      searchData = {
        wrid: data.wrid ?? undefined,
        wq: data.wq ?? undefined,
        wid: data.wid ?? undefined,
        wty: data.wty ?? undefined,
        item_id: data.item_id ?? undefined,
        sku: data.sku,
      };
    } else if (etype === BloomreachEventType.clickAdd) {
      searchData = {
        prod_id: data.item_id ?? undefined,
        sku: data.sku,
      };
    }
  }

  return { searchData, extraParams };
}
