export const DEFAULT_ZOOM = 10;
export const MY_LOCATION_ZOOM = 14;
export const DEFAULT_STORES_RADIUS = 50;

export const STORE_LOCATOR_PAGE_PREFIX = "StoreLocatorPage";

export const STORE_LOCATOR_PAGE_CLASSES = {
  header: `${STORE_LOCATOR_PAGE_PREFIX}-header`,
  map: `${STORE_LOCATOR_PAGE_PREFIX}-map`,
  breadcrumbs: `${STORE_LOCATOR_PAGE_PREFIX}-breadcrumbs`,
};
