import Cookie from "js-cookie";
import { isEqual } from "lodash";

import { EnvNames } from "~/constants/env-names";
import { DY_ID, DY_ID_SERVER, DY_SESSION } from "~/constants/user-cookies";
import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";

import { Logger } from "../../logger";
import {
  DEFAULT_CHOOSE_CAMPAIGN_REQUEST_OPTIONS,
  DY_PAGE_DATA_EMPTY,
  LOGGER_LABEL_PREFIX,
} from "../constants";
import {
  DYChooseResponseData,
  DYContextType,
  ChooseCampaignVariationsArgs,
} from "../types";

import { processContentSquareEvents } from "./process-content-square-events";
import { processDataLayerEvents } from "./process-data-layer-events";

export const LOGGER_LABEL = `${LOGGER_LABEL_PREFIX}:chooseCampaignVariations`;

// Choose endpoint client
// More info: https://dy.dev/reference/choosing-variations
export const chooseCampaignVariations = async ({
  pageType,
  pageData,
  pageLocation,
  pageLocale,
  campaignNames,
  previewIds,
  options,
}: ChooseCampaignVariationsArgs): Promise<DYChooseResponseData | null> => {
  // We do not want our app to break due to errors in the reporting tool,
  // so we wrap the whole function into try-catch to be safe

  Logger.getLogger(LOGGER_LABEL).debug(
    `chooseCampaignVariations function was called: ${JSON.stringify(
      {
        pageType,
        pageData,
        pageLocation,
        pageLocale,
        campaignNames,
        previewIds,
      },
      null,
      2,
    )}`,
  );

  if (campaignNames.length === 0) {
    Logger.getLogger(LOGGER_LABEL).warn(
      "Empty campaign names array was passed, skipping /choose API call",
    );
    return null;
  }

  try {
    if (IS_SERVER) {
      throw new Error(
        "Making this action on the server is prohibited since it messes up with cache invalidation",
      );
    }

    if (
      (pageType === DYContextType.Category || pageType === DYContextType.Product) &&
      !pageData
    ) {
      throw new Error(
        `pageData is required for Category and Product page types, current page type: ${pageType}`,
      );
    }

    if (
      pageType === DYContextType.HomePage &&
      !isEqual(pageData, DY_PAGE_DATA_EMPTY)
    ) {
      throw new Error(
        `any other pageData except DY_PAGE_DATA_EMPTY value is prohibited to be used` +
          `for Homepage page type, current page type: ${pageType}`,
      );
    }

    let previewIdsArray = [];

    if (typeof previewIds === "string") {
      previewIdsArray.push(previewIds);
    } else {
      previewIdsArray = previewIds;
    }

    const dyConfig = AzureConfigurator.getConfig(pageLocale)?.dynamicYield;
    const isProd =
      AzureConfigurator.getConfig(pageLocale)?.envName === EnvNames.PROD;

    const clientApiKey = dyConfig?.clientApiKey;
    const euClientBaseUrl = dyConfig?.euClientBaseUrl;

    if (
      typeof dyConfig !== "object" ||
      typeof clientApiKey !== "string" ||
      typeof euClientBaseUrl !== "string"
    ) {
      throw new Error(`Wrong DY configuration! Configuration: ${dyConfig}`);
    }

    const responseData = await fetch(`${euClientBaseUrl}/v2/serve/user/choose`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "DY-API-Key": clientApiKey,
      },
      body: JSON.stringify({
        user: {
          dyid: Cookie.get(DY_ID),
          dyid_server: Cookie.get(DY_ID_SERVER),
          // eslint-disable-next-line no-restricted-globals
          active_consent_accepted: !window?.DYO?.ActiveConsent?.isUserOptOut(),
        },
        session: {
          dy: Cookie.get(DY_SESSION),
        },
        context: {
          page: {
            type: pageType,
            data: pageData || undefined,
            location: pageLocation,
            locale: pageLocale,
          },
          device: {
            userAgent: navigator.userAgent,
          },
        },
        selector: {
          names: campaignNames,
          preview: {
            ids: previewIdsArray,
          },
        },
        options: { ...DEFAULT_CHOOSE_CAMPAIGN_REQUEST_OPTIONS, ...options },
      }),
    });

    Logger.getLogger("DY:chooseCampaignVariations").info(
      `DY \`choose\` API call has been successfully made`,
    );

    const responseDataJson = await responseData.json();

    await processDataLayerEvents(responseDataJson);
    isProd && (await processContentSquareEvents(responseDataJson));

    return responseDataJson;
  } catch (e: unknown) {
    Logger.getLogger(LOGGER_LABEL).error(String(e));
    return null;
  }
};
