import React from "react";

import { IconProps } from "./../types";

import { BaseSVGIcon } from "./base-svg-icon";

export const NotAvailable24: React.FC<IconProps> = (props) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M18.96 6.78001C18.69 5.78001 16.8 5.67001 15.76 6.53001C14.99 7.17001 13.98 7.97002 12.52 9.36001L10.07 5.63001C9.16999 4.25001 6.28999 5.31001 7.25999 7.06001C7.67999 7.81001 8.87999 9.68002 10.2 11.71C6.14 15.87 3.83999 17.46 4.00999 18.41C4.17999 19.36 7.00999 18.88 8.00999 18.25C8.46999 17.97 10.21 16.38 12.11 14.59L14.59 18.36C15.65 19.99 17.85 17.36 16.66 15.59C16.24 14.97 15.42 13.72 14.49 12.31C16.13 10.73 17.49 9.31002 17.9 8.96002C18.72 8.07001 19.14 7.41001 18.96 6.78001Z"
        fill="currentColor"
      />
    </BaseSVGIcon>
  );
};
