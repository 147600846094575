import dynamic from "next/dynamic";

import {
  BffComponentType as Component,
  ComponentsConfig,
} from "~/bff/ComponentsConfig";
import { SearchPanel } from "~/components/search-panel/component";
import { importComponentByName } from "~/helpers/import-component-by-name";

const HeroBannerCarouselItem = dynamic(async () =>
  importComponentByName(
    import(
      "~/components/hero-banner/components/hero-banner-carousel-item/component"
    ),
    "HeroBannerCarouselItem",
  ),
);

export const searchPanelConfig: ComponentsConfig = {
  [Component.NAV_ITEM]: {
    component: HeroBannerCarouselItem,
  },

  [Component.SEARCH_PANEL]: {
    component: SearchPanel,
  },
};
