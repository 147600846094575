"use client";

import React, { FunctionComponent, PropsWithChildren, useMemo } from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { getFormatMedia } from "~/theme/utils";

import { BreakpointsContext } from "./context";
import { WindowFormat } from "./types";

export type BreakpointsProviderProps = PropsWithChildren;

export const BreakpointsProvider: FunctionComponent<BreakpointsProviderProps> = ({
  children,
}) => {
  const theme = useTheme();
  const formatMedia = getFormatMedia(theme);

  const isTablet = useMediaQuery(formatMedia.BREAKPOINT_TABLET);
  const isDesktop = useMediaQuery(formatMedia.BREAKPOINT_DESKTOP);
  const isWideDesktop = useMediaQuery(formatMedia.BREAKPOINT_WIDE_DESKTOP);

  const format = useMemo(() => {
    if (isWideDesktop) {
      return WindowFormat.wideDesktop;
    }
    if (isDesktop) {
      return WindowFormat.desktop;
    }
    if (isTablet) {
      return WindowFormat.tablet;
    }
    return WindowFormat.mobile;
  }, [isWideDesktop, isDesktop, isTablet]);

  return (
    <BreakpointsContext.Provider value={format}>
      {children}
    </BreakpointsContext.Provider>
  );
};
