import { useState, useLayoutEffect } from "react";

import { useMediaQuery } from "../use-media-query/use-media-query";

export const useBreakpoints = () => {
  const [isClient, setIsClient] = useState(false);

  const breakpoints = {
    isXs: useMediaQuery("(max-width: 320px)"),
    isSm: useMediaQuery("(min-width: 321px) and (max-width: 559px)"),
    isMd: useMediaQuery("(min-width: 560px) and (max-width: 1024px)"),
    isLg: useMediaQuery("(min-width: 1025px) and (max-width: 1152px)"),
    isXl: useMediaQuery("(min-width: 1153px)"),
    active: "SSR",
  };

  useLayoutEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (typeof window !== "undefined") {
      setIsClient(true);
    }
  }, []);

  if (isClient && breakpoints.isXs) {
    breakpoints.active = "xs";
  }
  if (isClient && breakpoints.isSm) {
    breakpoints.active = "sm";
  }
  if (isClient && breakpoints.isMd) {
    breakpoints.active = "md";
  }
  if (isClient && breakpoints.isLg) {
    breakpoints.active = "lg";
  }

  return breakpoints;
};
