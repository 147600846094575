"use client";

import React, { MouseEventHandler, useMemo } from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { clamp } from "lodash";

import { NavItemProps } from "~/bff/components/NavItem";
import { BannerItem } from "~/bff/types/BannerItem";
import { NavItem } from "~/bff/types/NavItem";
import { NavItemBanner } from "~/bff/types/NavItemBanner";
import { NavItemSpecialLinks } from "~/bff/types/NavItemSpecialLinks";
import { NavItemSpecialLinksItem } from "~/bff/types/NavItemSpecialLinksItem";
import { CombinedLink } from "~/components/combined-link/component";
import { OPTION_CONTENT_CLASSES } from "~/components/global-navigation/components/desktop-global-navigation/option-content/constants";
import {
  Column,
  divideToNumberOfColumns,
  isItemGrouped,
  MAX_CATEGORY_COLUMN_NUMBER,
} from "~/components/global-navigation/components/desktop-global-navigation/option-content/helper";
import { getNavItemUrl } from "~/components/global-navigation/helpers/get-navItem-url";
import { getReplacedLabel } from "~/components/global-navigation/helpers/get-replaced-label";
import { globalNavigationKey } from "~/components/global-navigation/mock";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { Nullable } from "~/types/general.types";

import { Banner } from "../../banner/component";
import { MenuItem } from "../../menu-item/component";
import { SubCategoryGroup } from "../sub-category-group/component";

import { OptionContentContainer } from "./styled";

export interface OptionContentProps {
  className?: string;
  viewAllLabel?: string;
  navItem: NavItemProps;
  id?: string;
  activeOption?: boolean;
  onMouseLeave?: MouseEventHandler;
  setActiveOptionIndex: (value: React.SetStateAction<Nullable<number>>) => void;
}
const optionContentItemId = "global-navigation-option-content-item";
export const OptionContent: React.FC<OptionContentProps> = ({
  className,
  navItem: {
    meta,
    title: rootTitle,
    children = [],
    specialLinks: specialLinksData,
    bannerArray: bannerArrayData,
    urlSlug,
    url,
  },
  viewAllLabel,
  id,
  onMouseLeave,
  setActiveOptionIndex,
  activeOption,
}) => {
  const category = rootTitle || "";
  const rootLabel = getReplacedLabel(viewAllLabel, category);
  const idRootLabel = (rootLabel || "").trim().replaceAll(" ", "-").toLowerCase();

  const rootDeliveryKey = meta?.deliveryKey;
  const specialLinks = ((specialLinksData as NavItemSpecialLinks)?.children ??
    []) as NavItemSpecialLinksItem[];

  const isCommonColumnOn = specialLinks?.length || rootDeliveryKey;

  const bannersList = ((bannerArrayData as NavItemBanner)?.children ??
    []) as BannerItem[];

  const minCategoryColumnNumber = clamp(
    MAX_CATEGORY_COLUMN_NUMBER - bannersList.length,
    1,
    MAX_CATEGORY_COLUMN_NUMBER,
  );

  const columnsList = useMemo<Column[]>(() => {
    return divideToNumberOfColumns(children as NavItem[], minCategoryColumnNumber);
  }, [children, minCategoryColumnNumber]);

  const bannerCount = MAX_CATEGORY_COLUMN_NUMBER - columnsList.length;

  if (!activeOption) {
    return;
  }

  return (
    <OptionContentContainer
      className={className}
      id={id}
      onMouseLeave={onMouseLeave}
      onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Escape") {
          setActiveOptionIndex(null);
        }
      }}
      {...getTestAutomationProps(`${globalNavigationKey}-option-content`)}
    >
      {isCommonColumnOn && (
        <Box
          className={clsx(
            OPTION_CONTENT_CLASSES.column,
            OPTION_CONTENT_CLASSES.commonColumn,
          )}
        >
          {specialLinks?.map(({ props }) => {
            const { image, link, name } = props ?? {};
            const idLinkLabel = (link?.label || "")
              .trim()
              .replaceAll(" ", "-")
              .toLowerCase();
            const uniqeId = `${optionContentItemId}-${idLinkLabel}-${name}-${meta?.deliveryId}`;
            return (
              <CombinedLink
                id={uniqeId}
                key={uniqeId}
                href={link?.url ?? ""}
                display="block"
                underline="hover"
              >
                <MenuItem
                  className={OPTION_CONTENT_CLASSES.specialItem}
                  image={image}
                  label={link?.label ?? ""}
                  variant="body2"
                  {...getTestAutomationProps("special-item")}
                />
              </CombinedLink>
            );
          })}
          {specialLinks?.length > 0 && (
            <Divider className={OPTION_CONTENT_CLASSES.specialLinksDivider} />
          )}
          {(urlSlug || url) && rootLabel && (
            <CombinedLink
              id={`${optionContentItemId}-${idRootLabel}-${meta?.deliveryId}`}
              href={url ? getNavItemUrl(url) : `/${urlSlug}`}
              variant="body2"
              className={OPTION_CONTENT_CLASSES.rootLink}
              underline="hover"
              display="block"
              key={meta?.deliveryId}
            >
              {rootLabel}
            </CombinedLink>
          )}
        </Box>
      )}

      {!!children?.length &&
        columnsList?.map((column, index) =>
          index < MAX_CATEGORY_COLUMN_NUMBER ? (
            <Box key={`${index}-column`} className={OPTION_CONTENT_CLASSES.column}>
              {column.list.map((item, index) => {
                if (!item) {
                  return (
                    <Typography
                      key={`${index}_empty_line`}
                      variant="body2"
                      className={OPTION_CONTENT_CLASSES.subcategoryItem}
                    />
                  );
                }

                if (isItemGrouped(item)) {
                  return (
                    <SubCategoryGroup
                      key={item?.meta?.deliveryId}
                      itemClassName={OPTION_CONTENT_CLASSES.subcategoryItem}
                      navItem={item}
                    />
                  );
                }

                const { title, urlSlug, url, meta } = item;

                return (
                  <Typography
                    key={`${optionContentItemId}-${meta?.deliveryId}`}
                    variant="body2"
                    id={`${optionContentItemId}-${meta?.deliveryId}`}
                  >
                    <CombinedLink
                      href={url ? getNavItemUrl(url) : `/${urlSlug}`}
                      className={OPTION_CONTENT_CLASSES.subcategoryItem}
                      underline="hover"
                      display="block"
                    >
                      {title}
                    </CombinedLink>
                  </Typography>
                );
              })}
            </Box>
          ) : null,
        )}
      {bannersList?.map(({ props }, index) => {
        const { image, link } = props ?? {};
        const isBanner = index < bannerCount;

        return isBanner ? (
          <Banner
            key={image?.source?.id || image?.source?.name || link?.url}
            className={clsx(
              OPTION_CONTENT_CLASSES.column,
              OPTION_CONTENT_CLASSES.banner,
            )}
            image={image}
            link={link}
          />
        ) : null;
      })}
    </OptionContentContainer>
  );
};
