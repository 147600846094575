import { useParams, usePathname } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { Routes } from "~/constants/request";
import { useAzureConfigurator } from "~/services/azure-configurator/use-azure-configurator";

export const useIsShoppingBagEnabled = (): boolean | undefined => {
  const { locale } = useParams<GlobalPathParams>();
  const config = useAzureConfigurator(locale);
  const pathName = usePathname();

  const isCheckoutPage = pathName === Routes.CHECKOUT;
  const isEnabledInAzure = config?.featureFlags?.clickAndCollect?.enabled;

  return isEnabledInAzure && !isCheckoutPage;
};
