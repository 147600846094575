import React, { PropsWithChildren } from "react";

import { StoreSelector } from "~/bff/types/StoreSelector";
import { Nullable } from "~/types/general.types";

import { StoreSelectorContext } from "./context";

interface StoreSelectorProviderProps extends PropsWithChildren {
  storeSelector?: Nullable<StoreSelector>;
}

export const StoreSelectorProvider: React.FC<StoreSelectorProviderProps> = ({
  children,
  storeSelector,
}) => {
  return (
    <StoreSelectorContext.Provider value={storeSelector?.props as StoreSelector}>
      {children}
    </StoreSelectorContext.Provider>
  );
};
