"use client";

import React, { FC } from "react";

import Box from "@mui/material/Box";
import { clamp } from "lodash";

import { NavItemProps } from "~/bff/components/NavItem";
import { BannerItem } from "~/bff/types/BannerItem";
import { NavItem } from "~/bff/types/NavItem";
import { NavItemBanner } from "~/bff/types/NavItemBanner";
import { NavItemSpecialLinks } from "~/bff/types/NavItemSpecialLinks";
import { NavItemSpecialLinksItem } from "~/bff/types/NavItemSpecialLinksItem";
import { CombinedLink } from "~/components/combined-link/component";
import { convertComponentProps } from "~/components/global-navigation/helpers/convert-component-props";
import { getNavItemUrl } from "~/components/global-navigation/helpers/get-navItem-url";
import { getReplacedLabel } from "~/components/global-navigation/helpers/get-replaced-label";
import { NavItemWithMeta } from "~/components/global-navigation/types";

import {
  MAX_CATEGORY_COLUMN_NUMBER,
  divideToNumberOfColumns,
  isItemGrouped,
} from "../option-content/helper";

import { HiddenGlobalNavOptionContentProps } from "./types";

export const HiddenGlobalNavOptionContent: FC<HiddenGlobalNavOptionContentProps> = ({
  navItem: {
    meta,
    title: rootTitle,
    children = [],
    specialLinks: specialLinksData,
    bannerArray: bannerArrayData,
    urlSlug,
    url,
  },
  viewAllLabel,
}) => {
  const rootLabel = getReplacedLabel(viewAllLabel || "", rootTitle || "");

  const specialLinks = ((specialLinksData as NavItemSpecialLinks)?.children ??
    []) as NavItemSpecialLinksItem[];

  const bannersList = ((bannerArrayData as NavItemBanner)?.children ??
    []) as BannerItem[];

  const minCategoryColumnNumber = clamp(
    MAX_CATEGORY_COLUMN_NUMBER - bannersList.length,
    1,
    MAX_CATEGORY_COLUMN_NUMBER,
  );

  const columnsList = divideToNumberOfColumns(
    children as NavItem[],
    minCategoryColumnNumber,
  );

  const renderCategoryGroup = (item: NavItemProps, index: number) => {
    const { title: rootTitle, children, urlSlug, url, meta } = item;
    const key = meta?.deliveryId
      ? `${index}-${meta.deliveryId}`
      : `${index}-${rootTitle}-${url}-${urlSlug}`;
    return (
      <Box key={key}>
        {urlSlug || url ? (
          <CombinedLink href={url ? getNavItemUrl(url) : `/${urlSlug}`}>
            {rootTitle}
          </CombinedLink>
        ) : null}
        {children?.map((item, index) => {
          const { title, urlSlug, url } = convertComponentProps(
            item as NavItemWithMeta,
          );
          return (
            <CombinedLink
              key={`${index}-${urlSlug}`}
              href={url ? getNavItemUrl(url) : `/${urlSlug}`}
            >
              {title}
            </CombinedLink>
          );
        })}
      </Box>
    );
  };

  return (
    <Box>
      {specialLinks?.map(({ props }, index) => {
        const { image, link } = props ?? {};
        const key = image?.source?.id
          ? `${index}-${image.source.id}`
          : `${index}-${link?.label}`;
        return (
          <CombinedLink key={key} href={link?.url || ""}>
            {link?.label}
          </CombinedLink>
        );
      })}
      {(urlSlug || url) && rootLabel && (
        <CombinedLink
          href={url ? getNavItemUrl(url) : `/${urlSlug}`}
          key={meta?.deliveryId || urlSlug || url}
        >
          {rootLabel}
        </CombinedLink>
      )}

      {!!children?.length &&
        columnsList?.map((column) =>
          column.list.map((item, index) => {
            if (!item) {
              return null;
            }

            if (isItemGrouped(item)) {
              return renderCategoryGroup(item, index);
            }

            const { title, urlSlug, url, meta } = item;
            const key = meta?.deliveryId
              ? `${index}-${meta.deliveryId}`
              : `${index}-${title}-${url}-${urlSlug}`;

            return (
              <CombinedLink
                key={key}
                href={url ? getNavItemUrl(url) : `/${urlSlug}`}
              >
                {title}
              </CombinedLink>
            );
          }),
        )}
      {bannersList?.map(({ props }, index) => {
        const { image, link } = props ?? {};
        let key;
        if (image?.source?.id) {
          key = `${index}-${image.source.id}`;
        } else if (image?.source?.name) {
          key = `${index}-${image.source.name}`;
        } else if (link?.url) {
          key = `${index}-${link.url}`;
        } else {
          key = `${index}`;
        }
        return (
          <CombinedLink key={key} href={link?.url ?? ""}>
            {image?.source?.name ?? link?.label}
          </CombinedLink>
        );
      })}
    </Box>
  );
};
