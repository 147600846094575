"use client";

import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import Box from "@mui/material/Box";
import clsx from "clsx";
import { last, noop } from "lodash";
import { usePathname } from "next/navigation";

import { CombinedLink } from "~/components/combined-link/component";
import { globalNavigationKey } from "~/components/global-navigation/mock";
import {
  NavItemWithMeta,
  CommonGlobalNavigationalProps,
} from "~/components/global-navigation/types";
import { Variables } from "~/constants/request";
import { useVariable } from "~/context/variables/hooks/use-variable";
import { prepareTextAttribute } from "~/helpers/prepare-text-attribute";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { useTouchDevice } from "~/hooks/use-touch-device/index";
import { useWindowScroll } from "~/hooks/use-window-scroll/index";
import { Nullable } from "~/types/general.types";

import { PageType } from "__generated__/globalTypes";

import { useInfoBanner } from "../../../information-banner/hooks";
import { convertComponentProps } from "../../helpers/convert-component-props";
import { getNavItemUrl } from "../../helpers/get-navItem-url";

import { DESKTOP_GLOBAL_NAVIGATION_CLASSES } from "./constants";
import { DesktopSecondLevelNavigation } from "./desktop-second-level-navigation/component";
import { HiddenGlobalNavOptionContent } from "./hidden-global-nav-option-content/component";
import { OptionContent } from "./option-content/component";
import {
  DesktopGlobalNavigationContainer,
  DesktopGlobalNavigationDrawer,
} from "./styled";

export interface DesktopGlobalNavigationProps extends CommonGlobalNavigationalProps {
  className?: string;
  offset: number;
  viewAllLabel?: string | null;
  isInfoBanner?: boolean;
  infoBannerHeight: number;
}

const globalNavigationContentId = "global-navigation-content";
const optionRowId = "global-navigation-options-row";
const optionItemId = "global-navigation-option";
const optionContentId = "global-navigation-option-content";
const DELAY_LOAD_TIME = 300;

export const DesktopGlobalNavigation: React.FC<DesktopGlobalNavigationProps> = ({
  className,
  navigation = [],
  offset = 0,
  viewAllLabel,
  breadcrumbs,
  isInfoBanner,
  infoBannerHeight,
}) => {
  const pathName = usePathname();
  const { scrollTop } = useWindowScroll();
  const isTouchDevice = useTouchDevice();

  const pageType = useVariable(Variables.PAGE_TYPE);

  const isClpPlpPage = pageType === PageType.CategoryPage;
  const isRpbPage = pageType === PageType.Rbp;
  const isPdpPage = pageType === PageType.Pdp;
  const isGcpPage = pageType === PageType.Gcp;
  const [isInfoBannerClosed, setIsInfoBannerClosed] = useState(false);
  const [categoriesRowOffset, setCategoriesRowOffset] = useState(0);

  const openNavigationDrawerTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { infoBannerCookieValue } = useInfoBanner();
  const categoriesRowHeight =
    typeof document !== "undefined"
      ? document.getElementById("categoriesRow")?.offsetHeight
      : 0;

  useEffect(() => {
    if (infoBannerCookieValue) {
      setIsInfoBannerClosed(true);
    }
  }, [infoBannerCookieValue]);

  useEffect(() => {
    if (categoriesRowHeight) {
      setCategoriesRowOffset(categoriesRowHeight);
    }
  }, [categoriesRowHeight]);

  const rootDeliveryKey = useMemo<Nullable<string> | undefined>(() => {
    return isClpPlpPage || isPdpPage || isGcpPage || isRpbPage
      ? last(breadcrumbs)?.id
      : null;
  }, [breadcrumbs, isClpPlpPage, isPdpPage, isGcpPage, isRpbPage]);

  const [activeRootDeliveryKey, setActiveRootDeliveryKey] =
    useState(rootDeliveryKey);

  useEffect(() => {
    setActiveRootDeliveryKey(rootDeliveryKey);
  }, [rootDeliveryKey]);

  const getIndexOfCategory = useCallback(
    (navTree: NavItemWithMeta[] | null, key: Nullable<string> | undefined) => {
      const index = navTree?.findIndex(
        ({ _meta: { deliveryId } }) => deliveryId === key,
      );
      return index !== undefined && index >= 0 ? index : null;
    },
    [],
  );

  const activeCategoryIndex = useMemo(
    () => getIndexOfCategory(navigation, activeRootDeliveryKey),
    [getIndexOfCategory, navigation, activeRootDeliveryKey],
  );

  const rootCategoryIndex = useMemo(
    () => getIndexOfCategory(navigation, rootDeliveryKey),
    [getIndexOfCategory, navigation, rootDeliveryKey],
  );

  const [activeOptionIndex, setActiveOptionIndex] = useState<Nullable<number>>(null);

  const activeOption = useMemo(() => {
    if (activeCategoryIndex !== null && activeOptionIndex !== null && navigation) {
      const category = navigation[activeCategoryIndex];
      if (category.children && category.children[activeOptionIndex]) {
        const option = category.children[activeOptionIndex] as NavItemWithMeta;
        return convertComponentProps(option);
      }
    }
    return null;
  }, [navigation, activeCategoryIndex, activeOptionIndex]);

  const clearNavigationDrawerTimeout = () => {
    if (openNavigationDrawerTimeoutRef.current) {
      clearTimeout(openNavigationDrawerTimeoutRef.current);
      openNavigationDrawerTimeoutRef.current = null;
    }
  };

  const onCloseOption: MouseEventHandler = useCallback(
    (e: React.MouseEvent) => {
      clearNavigationDrawerTimeout();
      if (
        activeOption &&
        (e.relatedTarget as HTMLElement).id !== optionRowId &&
        !(e.relatedTarget as HTMLElement).id?.includes(optionItemId) &&
        (e.relatedTarget as HTMLElement).id !== optionContentId
      ) {
        setActiveOptionIndex(null);
        setActiveRootDeliveryKey(rootDeliveryKey);
      }
    },
    [activeOption, rootDeliveryKey],
  );

  const onCloseCategory: MouseEventHandler = useCallback(
    (e) => {
      if (
        (e.relatedTarget as HTMLElement).id !== optionRowId &&
        (e.relatedTarget as HTMLElement).id !== "categoriesRow" &&
        !(e.relatedTarget as HTMLElement).id?.includes(optionItemId)
      ) {
        setActiveOptionIndex(null);
        setActiveRootDeliveryKey(rootDeliveryKey);
      }
    },
    [rootDeliveryKey],
  );

  const getOnHoverOption = useCallback(
    (index: number): MouseEventHandler =>
      () => {
        if (openNavigationDrawerTimeoutRef.current) {
          clearNavigationDrawerTimeout();
        }

        if (typeof document !== "undefined") {
          (document.activeElement as HTMLElement)?.blur();
        }

        openNavigationDrawerTimeoutRef.current = setTimeout(() => {
          setActiveOptionIndex(index);
        }, DELAY_LOAD_TIME);

        return () => {
          clearNavigationDrawerTimeout();
        };
      },
    [],
  );

  useEffect(() => {
    setActiveOptionIndex(null);
  }, [pathName]);

  const isSecondLevelNavBarHidden = (): boolean => {
    if (navigation && activeCategoryIndex !== null && activeCategoryIndex >= 0) {
      const children = navigation[activeCategoryIndex]?.children;
      return !(children && children.length > 0);
    }
    return true;
  };

  const desktopSecondLevelNavProps = {
    isSectionHidden: isSecondLevelNavBarHidden(),
    setActiveOptionIndex,
    optionRowId,
    navigation,
    onCloseOption,
    activeCategoryIndex,
    activeOptionIndex,
    getOnHoverOption,
    optionItemId,
    clearNavigationDrawerTimeout,
  };

  return (
    <DesktopGlobalNavigationContainer
      component="nav"
      role="navigation"
      className={className}
      {...getTestAutomationProps(globalNavigationKey)}
    >
      <Box
        id="categoriesRow"
        className={clsx(
          DESKTOP_GLOBAL_NAVIGATION_CLASSES.floor,
          DESKTOP_GLOBAL_NAVIGATION_CLASSES.row,
        )}
        onMouseLeave={onCloseCategory}
      >
        {navigation?.map((item, index) => {
          const { title, urlSlug, url, meta } = convertComponentProps(item);
          return (
            <CombinedLink
              id={`${globalNavigationContentId}-${meta?.deliveryId}`}
              key={`${globalNavigationContentId}-${meta?.deliveryId}`}
              href={url ? getNavItemUrl(url) : `/${urlSlug}`}
              className={clsx(
                DESKTOP_GLOBAL_NAVIGATION_CLASSES.item,
                DESKTOP_GLOBAL_NAVIGATION_CLASSES.categoryItem,
                {
                  rootLink: rootCategoryIndex === index,
                },
              )}
              title={prepareTextAttribute(title)}
              underline="none"
              display="block"
              {...getTestAutomationProps("category")}
            >
              {title}
            </CombinedLink>
          );
        })}
      </Box>
      <DesktopSecondLevelNavigation {...desktopSecondLevelNavProps} />
      <DesktopGlobalNavigationDrawer
        classes={{
          paper: DESKTOP_GLOBAL_NAVIGATION_CLASSES.drawerContent,
        }}
        anchor="top"
        open={!!activeOption}
        elevation={0}
        offset={offset}
        infoBannerOffset={infoBannerHeight}
        isInfoBannerClosed={isInfoBannerClosed}
        categoriesRowOffset={categoriesRowOffset}
        scrollTop={scrollTop}
        isInfoBanner={isInfoBanner}
      >
        {navigation?.map((navigationElement, navIndex) =>
          navigationElement?.children?.map((navItemElement, index) => {
            const activeNavItem = navIndex === activeCategoryIndex;

            if (!activeNavItem) {
              return;
            }

            return (
              <OptionContent
                key={`${index}-${
                  (navItemElement as NavItemWithMeta)?._meta?.deliveryId
                }-${optionContentId}`}
                className={clsx(
                  DESKTOP_GLOBAL_NAVIGATION_CLASSES.floor,
                  DESKTOP_GLOBAL_NAVIGATION_CLASSES.optionContent,
                )}
                navItem={convertComponentProps(navItemElement as NavItemWithMeta)}
                activeOption={activeOptionIndex === index}
                id={`${optionContentId}-${
                  (navItemElement as NavItemWithMeta)?._meta?.deliveryId
                }`}
                onMouseLeave={isTouchDevice ? noop : onCloseOption}
                viewAllLabel={viewAllLabel || ""}
                setActiveOptionIndex={setActiveOptionIndex}
              />
            );
          }),
        )}
      </DesktopGlobalNavigationDrawer>
      <Box className={DESKTOP_GLOBAL_NAVIGATION_CLASSES.hidden}>
        {navigation?.map((navigationElement) =>
          navigationElement?.children?.map((navItemElement, index) => {
            const { specialLinks, bannerArray, url, children } =
              convertComponentProps(navItemElement as NavItemWithMeta) ?? {};
            if (!specialLinks && !bannerArray && url && !children) {
              return null;
            }

            return (
              <HiddenGlobalNavOptionContent
                key={`${index}-${
                  (navItemElement as NavItemWithMeta)?._meta?.deliveryId
                }-${optionContentId}`}
                navItem={convertComponentProps(navItemElement as NavItemWithMeta)}
                viewAllLabel={viewAllLabel}
              />
            );
          }),
        )}
      </Box>
    </DesktopGlobalNavigationContainer>
  );
};
