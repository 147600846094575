export enum BloomreachPageType {
  HomePage = "homepage",
  PdpPage = "product",
  ClpPage = "category",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  PlpPage = "category",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  RbpPage = "category",
  SearchResultsPage = "search",
  ArticlePage = "content",
  thematic = "thematic",
  ThanksForYourOrderPage = "other",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  other = "other",
}
export enum Component {
  HomePage = "HomePage",
  GcpPage = "GcpPage",
  ShoppingListPage = "ShoppingListPage",
  ShoppingBagPage = "ShoppingBagPage",
  ThanksForYourOrderPage = "ThanksForYourOrderPage",
  ArticleThemePage = "ArticleThemePage",
  RbpPage = "RbpPage",
  ArticlePage = "ArticlePage",
  PdpPage = "PdpPage",
}

export enum BloomreachCatalogsAzureKeys {
  products = "domainKey",
  articles = "catalogName",
}

export const DEFAULT_RETRY_CALL_TIMEOUT = 3000;
export const MAX_LOG_EVENT_TRIES = 5;

export const BR_USERID_KEY = "_br_uid_2";
export const BR_DEFAULT_UID = "dummy";

export const PREVIOUS_PATH_FOR_BR_EMPTY_VALUE = "";
