import { ImageTemplates } from "~/components/image/types";
import { WindowFormat } from "~/context/breakpoints/types";

export const imageTemplates: ImageTemplates = {
  [WindowFormat.mobile]: "navbanner-mobile",
  [WindowFormat.tablet]: "navbanner-tablet",
  [WindowFormat.desktop]: "navbanner-regulardesktop",
  [WindowFormat.wideDesktop]: "navbanner-largedesktop",
};

export const BANNER_PREFIX = "Banner";

export const BANNER_CLASSES = {
  root: `${BANNER_PREFIX}-root`,
  image: `${BANNER_PREFIX}-image`,
  label: `${BANNER_PREFIX}-label`,
};
