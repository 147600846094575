import { gql } from "@apollo/client";

export const GET_NON_STORE_SPECIFIC_COLOUR_AVAILABILITY = gql`
  query NonStoreSpecificColourAvailability($locale: String!, $styleCode: String!) {
    nonStoreColorSelectorInventory(locale: $locale, styleCode: $styleCode) {
      skuColorAvailability {
        skuId
        colorId
        isAvailable
      }
    }
  }
`;
