"use client";

import React from "react";

import { Box } from "@mui/material";

import { CombinedButton } from "~/components/combined-button/component";
import { AdditionalItem } from "~/components/global-navigation/types";

import { ADDITIONAL_NAVIGATION_CLASSES } from "./constants";
import { AdditionalNavigationContainer } from "./styled";

export interface AdditionalNavigationProps {
  className?: string;
  items: AdditionalItem[] | undefined;
}

export const AdditionalNavigation: React.FC<AdditionalNavigationProps> = ({
  className,
  items = [],
}) => {
  return (
    <AdditionalNavigationContainer
      className={className}
      id="header-additional-navigation"
    >
      {items?.map(({ link }) => {
        const idValue = link?.label?.replace(" ", "-").toLowerCase();
        return (
          <Box key={link?.url} component="li">
            <CombinedButton
              href={link?.url ?? ""}
              variant="text"
              className={ADDITIONAL_NAVIGATION_CLASSES.link}
              id={`header-additional-navigation-${idValue}`}
            >
              {link?.label}
            </CombinedButton>
          </Box>
        );
      })}
    </AdditionalNavigationContainer>
  );
};
