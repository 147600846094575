export const addMetaLinkIfNotExists = (
  url: string,
  className: string,
  type: string,
) => {
  const preconnectLink = document.createElement("link");
  preconnectLink.rel = type;
  preconnectLink.href = url;
  preconnectLink.className = className;

  const allDomElements = document.querySelectorAll(`.${className}`);
  const hrefExists = Array.from(allDomElements).some(
    (link) => link.getAttribute("href") === url,
  );

  if (!hrefExists) {
    document.head.appendChild(preconnectLink);
  }
};
