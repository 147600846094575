"use client";

import React, { useMemo, VFC } from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { ImageCommon } from "~/bff/types/ImageCommon";
import { LinkedLogo } from "~/components/logo/components/linked-logo/component";
import { ShoppingBagButton } from "~/components/shopping-bag-button/component";
import { ShoppingListButton } from "~/components/shopping-list-button/component";
import { Routes } from "~/constants/request";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { useIsShoppingBagEnabled } from "~/hooks/use-is-shopping-bag-enabled/hook";
import { useAzureConfigurator } from "~/services/azure-configurator/use-azure-configurator";
import { Close24 } from "~/theme/icons/components/close";
import { Menu24 } from "~/theme/icons/components/menu";
import { Search24 } from "~/theme/icons/components/search";
import { Nullable } from "~/types/general.types";

import { HEADER_TOOLBAR_CLASSES } from "./constants";
import { HeaderToolbarContainer } from "./styled";

export interface HeaderToolbarProps {
  logo?: Nullable<ImageCommon>;
  isMenuOpen?: boolean;
  isSearchOpen?: boolean;
  onMenuOpen?: () => void;
  onSearchOpen?: () => void;
}

export const HeaderToolbar: VFC<HeaderToolbarProps> = ({
  logo,
  isMenuOpen,
  isSearchOpen,
  onMenuOpen,
  onSearchOpen,
}) => {
  const { locale } = useParams<GlobalPathParams>();
  const config = useAzureConfigurator(locale);

  const isShoppingBagEnabled = useIsShoppingBagEnabled();
  const isShoppingListEnabled = useMemo(
    () => config?.featureFlags?.shoppingList?.enabled,
    [config],
  );

  return (
    <HeaderToolbarContainer
      {...getTestAutomationProps("header-toolbar")}
      id="mobile-header-toolbar"
    >
      <IconButton
        className={HEADER_TOOLBAR_CLASSES.menuButton}
        color="inherit"
        aria-label={!isMenuOpen ? "open menu" : "close menu"}
        {...getTestAutomationProps("toggle-button")}
        id="mobile-toggle-button"
        onClick={onMenuOpen}
        size="large"
      >
        {isMenuOpen ? <Close24 /> : <Menu24 />}
      </IconButton>
      <LinkedLogo
        className={HEADER_TOOLBAR_CLASSES.logo}
        image={logo}
        href={Routes.HOME}
      />
      <Box
        className={HEADER_TOOLBAR_CLASSES.buttonGroup}
        id="mobile-account-toolbar"
      >
        <IconButton
          disabled={isSearchOpen}
          color="inherit"
          aria-label="open search panel"
          onClick={onSearchOpen}
          {...getTestAutomationProps("search-button")}
          id="mobile-search-button"
          size="large"
        >
          <Search24 />
        </IconButton>
        {isShoppingListEnabled && (
          <ShoppingListButton
            className={HEADER_TOOLBAR_CLASSES.shoppingListButton}
          />
        )}
        {isShoppingBagEnabled && <ShoppingBagButton />}
      </Box>
    </HeaderToolbarContainer>
  );
};
