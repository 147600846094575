import { Logger } from "~/utils/logger";

import { dyResponseDataSchema } from "../process-data-layer-events/yup-schema";

import { AFTER_PAGE_VIEW, LOGGER_LABEL } from "./constants";
import { createIntegrationCallback, initializeUxa, processChoice } from "./helpers";

export const processContentSquareEvents = async (responseDataJson: unknown) => {
  initializeUxa();
  try {
    const dyResponseDataValid =
      await dyResponseDataSchema.validate(responseDataJson);
    dyResponseDataValid.choices.forEach(processChoice);

    // eslint-disable-next-line no-restricted-globals
    window?._uxa?.push([AFTER_PAGE_VIEW, createIntegrationCallback()]);
  } catch (e: unknown) {
    Logger.getLogger(LOGGER_LABEL).warn(
      `DY content square data were not pushed to the window.CS_CONF for the reason below:\n${e}`,
    );
  }
};
