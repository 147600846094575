import React, {
  useCallback,
  useState,
  useEffect,
  ChangeEvent,
  MouseEvent,
  useRef,
} from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { ClassNameMap } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import clsx from "clsx";
import { debounce } from "lodash";
import { useSearchParams } from "next/navigation";
import { useDebouncedCallback } from "use-debounce";

import { Image } from "~/components/image/component";
import { getStoreFilters } from "~/components/store-selector/components/search-field/helpers";
import { Keys } from "~/constants/common";
import { DEBOUNCE_DELAY_300 } from "~/constants/delay-constants";
import { Params } from "~/constants/request";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { useIsEnabled as useIsLoqateSearchEnabled } from "~/hooks/loqate/use-is-enabled";
import { useLocationSearch } from "~/hooks/use-location-search";
import { Search24 } from "~/theme/icons/components/search";
import { Nullable } from "~/types/general.types";

import { Location } from "./components/location/component";
import { CUSTOM_LOCATION_SEARCH_CLASSES, SS_LISTBOX_ID } from "./constants";
import { useKeyboardNavigation } from "./hooks/use-keyboard-navigation";
import { CustomLocationSearchContainer } from "./styled";

export interface CustomLocationSearchProps {
  searchPlaceholder: Nullable<string> | undefined;
  handleSearch: (
    query: string,
    placeId: string | undefined,
    isCustomSearch: boolean,
  ) => void;
  isStoreLocatorPage?: boolean;
  isHomePage?: boolean;
  clearButton?: string;
  onSearchValueChange?: (query: string) => void;
  isInStockOnly?: boolean;
  isClickAndCollectOnly?: boolean;
  onStoreFilterChange?: (value: string) => void;
  pushGaDataToDataLayer?: (search: string) => void;
  displayLoader?: () => void;
  onSearchFieldFocus?: () => void;
  onSelectSuggestion?: (selectedSuggestion: string) => void;
  classes?: Partial<ClassNameMap>;
}

export const CustomLocationSearch: React.FC<CustomLocationSearchProps> = ({
  searchPlaceholder,
  handleSearch,
  isStoreLocatorPage,
  isHomePage,
  clearButton,
  onSearchValueChange,
  isInStockOnly,
  isClickAndCollectOnly,
  onStoreFilterChange,
  pushGaDataToDataLayer,
  displayLoader,
  classes: externalClasses,
  onSearchFieldFocus,
  onSelectSuggestion,
}) => {
  const searchParams = useSearchParams();
  const { locations, getLocations, resetLocations } =
    useLocationSearch(isStoreLocatorPage);
  const isLoqateSearchEnabled = useIsLoqateSearchEnabled() && !isStoreLocatorPage;

  const [searchBoxVisible, setSearchBoxVisible] = useState<boolean>(false);
  const [searchInputFocused, setSearchInputFocused] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [showClearBtn, setShowClearBtn] = useState(false);
  const [searchIsSubmitted, setSearchIsSubmitted] = useState<boolean>(false);

  const isInStockOnlyRef = useRef<boolean | undefined>(isInStockOnly);
  const isClickAndCollectOnlyRef = useRef<boolean | undefined>(
    isClickAndCollectOnly,
  );
  const inputRef = useRef<HTMLInputElement>();

  const { selectedLocationIndex, setSelectedLocationIndex, setUserInput } =
    useKeyboardNavigation({
      locations,
      searchBoxVisible,
      setSearchText,
      getLocations,
    });

  useEffect(() => {
    if (isStoreLocatorPage) {
      const query = searchParams.get(Params.STORE_QUERY) ?? "";
      if (searchText !== query) {
        setSearchText(query);
        setUserInput(query);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, isStoreLocatorPage, setUserInput]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value;

      setSelectedLocationIndex(null);
      setSearchText(searchValue);
      resetLocations();
    },
    [resetLocations, setSelectedLocationIndex],
  );

  useEffect(() => {
    if (searchIsSubmitted && locations !== null) {
      setSearchIsSubmitted(false);
      handleSearch(searchText, locations?.[0]?.placeId, true);
      setUserInput("");
    }
  }, [
    handleSearch,
    locations,
    searchIsSubmitted,
    searchText,
    displayLoader,
    setUserInput,
  ]);

  useEffect(() => {
    if (!isHomePage && locations?.length && searchInputFocused && searchText) {
      setSearchBoxVisible(true);
    } else {
      setSearchBoxVisible(false);
    }
  }, [locations, searchInputFocused, searchText, isHomePage]);

  useEffect(() => {
    if (selectedLocationIndex !== null && locations?.length) {
      setSearchText(locations[selectedLocationIndex]?.description);
    }
  }, [locations, selectedLocationIndex]);

  const handleLocationClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const value = event?.currentTarget?.dataset?.value;
      const placeId = event?.currentTarget?.dataset?.placeid;

      if (!value || !placeId) {
        return;
      }

      setSearchText(value);
      handleSearch(value, placeId, true);
      onSelectSuggestion?.(value);

      getLocations(value);

      setSearchInputFocused(false);
      setSelectedLocationIndex(null);
      setUserInput("");
    },
    [
      handleSearch,
      onSelectSuggestion,
      getLocations,
      setSelectedLocationIndex,
      setUserInput,
    ],
  );

  useEffect(() => {
    const handleClick = (e: Event) => {
      const clickedItem = e.target;
      if (clickedItem === inputRef.current) {
        return;
      }
      setSearchInputFocused(false);
      setSelectedLocationIndex(null);
    };
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener("click", debounce(handleClick, DEBOUNCE_DELAY_300));
    return () => {
      // eslint-disable-next-line no-restricted-globals
      window.removeEventListener("click", handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputFocus = () => {
    setSearchInputFocused(true);
    onSearchFieldFocus?.();
  };

  const searchSubmit = useCallback(() => {
    if (locations === null && searchText) {
      // If user submits search before the location data is fetched
      setSearchIsSubmitted(true);
      displayLoader?.();
      return;
    }

    if (locations && selectedLocationIndex !== null) {
      handleSearch(
        locations[selectedLocationIndex]?.description,
        locations[selectedLocationIndex]?.placeId,
        true,
      );
      onSelectSuggestion?.(locations[selectedLocationIndex]?.description);
      getLocations(locations[selectedLocationIndex]?.description);
    } else {
      handleSearch(searchText, locations?.[0]?.placeId, true);
      getLocations(searchText);
    }

    setUserInput("");
  }, [
    displayLoader,
    getLocations,
    handleSearch,
    onSelectSuggestion,
    locations,
    searchText,
    selectedLocationIndex,
    setUserInput,
  ]);

  const debouncedKeyPressHandler = useDebouncedCallback((event: KeyboardEvent) => {
    if (event.key === Keys.Enter) {
      searchSubmit();

      setSearchInputFocused(false);
      setSelectedLocationIndex(null);
    }
  }, DEBOUNCE_DELAY_300);

  useEffect(() => {
    const element = inputRef.current;
    element?.addEventListener("keydown", debouncedKeyPressHandler);
    return () => {
      debouncedKeyPressHandler.cancel();
      element?.removeEventListener("keydown", debouncedKeyPressHandler);
    };
  }, [
    debouncedKeyPressHandler,
    getLocations,
    locations,
    searchBoxVisible,
    searchSubmit,
    searchText,
    selectedLocationIndex,
    setSelectedLocationIndex,
  ]);

  const handleClearButtonClick = useCallback(() => {
    setSelectedLocationIndex(null);
    setSearchText("");
    resetLocations();
    setShowClearBtn(false);
  }, [resetLocations, setSelectedLocationIndex]);

  const handleUserInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setUserInput(value);
    setSearchInputFocused(true);

    if (!isStoreLocatorPage && !isHomePage) {
      onSearchValueChange?.(value);
      setShowClearBtn(true);
    }
  };

  useEffect(() => {
    if (isStoreLocatorPage || isHomePage) {
      return;
    }
    const storeFilters = getStoreFilters(isInStockOnly, isClickAndCollectOnly);
    onStoreFilterChange?.(storeFilters);
    if (
      isInStockOnlyRef?.current !== isInStockOnly ||
      isClickAndCollectOnlyRef?.current !== isClickAndCollectOnly
    ) {
      pushGaDataToDataLayer?.(searchText);
      isInStockOnlyRef.current = isInStockOnly;
      isClickAndCollectOnlyRef.current = isClickAndCollectOnly;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isInStockOnly,
    isClickAndCollectOnly,
    isStoreLocatorPage,
    onStoreFilterChange,
    pushGaDataToDataLayer,
  ]);

  return (
    <CustomLocationSearchContainer className={externalClasses?.searchContainer}>
      <TextField
        fullWidth
        value={searchText}
        inputRef={inputRef}
        onChange={handleInputChange}
        onInput={handleUserInput}
        onFocus={handleInputFocus}
        className={clsx(
          CUSTOM_LOCATION_SEARCH_CLASSES.control,
          externalClasses?.control,
        )}
        placeholder={searchPlaceholder ?? undefined}
        inputProps={{
          "aria-labelledby": "store-search-description",
          "data-cs-override-id": "",
          role: "combobox",
          "aria-expanded": searchBoxVisible,
          "aria-controls": SS_LISTBOX_ID,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              className={CUSTOM_LOCATION_SEARCH_CLASSES.searchAdornment}
              position="end"
            >
              {showClearBtn && (
                <Button
                  variant="text"
                  className="body2 underlined"
                  onClick={handleClearButtonClick}
                  {...getTestAutomationProps("clear")}
                >
                  {clearButton}
                </Button>
              )}
              <IconButton
                className={CUSTOM_LOCATION_SEARCH_CLASSES.searchButton}
                size="small"
                color="primary"
                onClick={searchSubmit}
                aria-label={searchPlaceholder ?? undefined}
              >
                <Search24 />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {searchBoxVisible && (
        <Box //NOSONAR ARIA is acceptable to be used here
          className={CUSTOM_LOCATION_SEARCH_CLASSES.searchBox}
          id={SS_LISTBOX_ID}
          role="listbox"
        >
          <>
            {locations?.map(({ description, placeId, matchedSubstrings }, index) => (
              <Location
                key={`${description}-${placeId}`}
                description={description}
                placeId={placeId}
                matchedSubstrings={matchedSubstrings}
                isFocused={index === selectedLocationIndex}
                onClick={handleLocationClick}
              />
            ))}
          </>
          {isLoqateSearchEnabled ? (
            <Image
              src="/assets/images/loqate-logo.svg"
              alt="loqate"
              className={CUSTOM_LOCATION_SEARCH_CLASSES.loqateLogo}
            />
          ) : (
            <Image
              src="/assets/images/powered-by-google-on-white3_hdpi.png"
              alt="google"
              className={CUSTOM_LOCATION_SEARCH_CLASSES.googleLogo}
            />
          )}
        </Box>
      )}
    </CustomLocationSearchContainer>
  );
};
