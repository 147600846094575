import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

import {
  COLOR_GREYSCALE_NTA_BLACK_8,
  COLOR_GREYSCALE_NTA_BLACK_15,
  COLOR_GREYSCALE_NTA_BLACK_25,
} from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

import { BACK_TO_TOP_BUTTON_CLASSES } from "./constants";

export const BackToTopButtonContainer = styled(Button)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    display: "none",
    position: "fixed",
    bottom: 32,
    right: 0,
    width: 48,
    height: 48,
    zIndex: 99,
    backgroundColor: COLOR_GREYSCALE_NTA_BLACK_8,
    "&:hover": {
      backgroundColor: COLOR_GREYSCALE_NTA_BLACK_15,
    },
    "&:active": {
      backgroundColor: COLOR_GREYSCALE_NTA_BLACK_25,
    },
    "&.MuiButtonBase-root:before": {
      content: "none",
    },
    cursor: "pointer",
    [formatMedia.BREAKPOINT_DESKTOP]: {
      display: "block",
      transition: "300ms",
      transitionTimingFunction: theme.transitions.easing.easeOut,
    },
    [`&.${BACK_TO_TOP_BUTTON_CLASSES.isVisible}`]: {
      visibility: "visible",
      right: 0,
    },
    [`&.${BACK_TO_TOP_BUTTON_CLASSES.isHidden}`]: {
      visibility: "hidden",
      right: -48,
    },
    [`& .${BACK_TO_TOP_BUTTON_CLASSES.up}`]: {
      position: "absolute",
      top: 12,
      left: 16,
    },
    [`& .${BACK_TO_TOP_BUTTON_CLASSES.caption}`]: {
      top: 28,
      left: 0,
      right: 0,
      position: "absolute",
      textAlign: "center",
      userSelect: "none",
    },
  };
});
