"use client";

import React, {
  useCallback,
  useMemo,
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import clsx from "clsx";
import { useParams } from "next/navigation";
import { useThrottledCallback } from "use-debounce";

import { GlobalPathParams } from "~/app/[locale]/types";
import { BffComponentType } from "~/bff/ComponentsConfig";
import { ImageCommon } from "~/bff/types/ImageCommon";
import { SearchPanel } from "~/bff/types/SearchPanel";
import { AccountButtonDesktop } from "~/components/account-page/components/account-button-desktop/component";
import { LinkedLogo } from "~/components/logo/components/linked-logo/component";
import { SearchField, SearchFieldProps } from "~/components/search-field/component";
import { SEARCH_PANEL_DESKTOP_CLASSES } from "~/components/search-panel/components/search-panel-desktop/constants";
import { ShoppingBagButton } from "~/components/shopping-bag-button/component";
import { ShoppingListButton } from "~/components/shopping-list-button/component";
import { UniversalComponent } from "~/components/universal-component/component";
import { BffComponentExtended } from "~/components/universal-component/types";
import { searchPanelConfig } from "~/constants/components-configs/search-panel-config";
import { THROTTLE_DELAY_100 } from "~/constants/delay-constants";
import { Routes } from "~/constants/request";
import { HeaderContext } from "~/context/header/context";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { useIsShoppingBagEnabled } from "~/hooks/use-is-shopping-bag-enabled/hook";
import { useAzureConfigurator } from "~/services/azure-configurator/use-azure-configurator";
import { Close24 } from "~/theme/icons/components/close";
import { Nullable } from "~/types/general.types";

import { SearchPanelDesktopContainer } from "./styled";

const DEFAULT_ICONS_COUNT = 0;

export interface SearchPanelProps {
  searchPanel?: Nullable<SearchPanel>;
  wrapperClassName?: string;
  logo?: Nullable<ImageCommon>;
  offset?: number;
}

export const SearchPanelDesktop: React.FC<SearchPanelProps> = ({
  searchPanel,
  wrapperClassName,
  logo,
  offset = 0,
}) => {
  const { locale } = useParams<GlobalPathParams>();
  const config = useAzureConfigurator(locale);

  const isMyAccountEnabled = useMemo(
    () => config?.featureFlags?.myAccount?.enabled,
    [config],
  );

  const isShoppingBagEnabled = useIsShoppingBagEnabled();
  const isShoppingListEnabled = useMemo(
    () => config?.featureFlags?.shoppingList?.enabled,
    [config],
  );

  const iconsCount = useMemo(() => {
    let result = DEFAULT_ICONS_COUNT;
    if (isMyAccountEnabled) {
      result += 1;
    }
    if (isShoppingListEnabled) {
      result += 1;
    }
    if (isShoppingBagEnabled) {
      result += 1;
    }
    return result;
  }, [isMyAccountEnabled, isShoppingListEnabled, isShoppingBagEnabled]);

  const {
    query,
    isSearchOpen,
    handleQueryChange,
    handleQueryClear,
    handleSearchClick,
    handleSearchClose,
    handleSearchOpen,
  } = React.useContext(HeaderContext) ?? {};

  const searchPanelRef = useRef<HTMLDivElement>(null);

  const [panelPosition, setPanelPosition] = useState<number>(0);

  const throttledSetSearchPanelPosition = useThrottledCallback(() => {
    const headerPaddingTop = 8;
    const drawerMargin = 16;
    const searchPanelHeight =
      searchPanelRef?.current?.getBoundingClientRect().height || 0;
    const documentScrollPosition = document.documentElement.scrollTop;
    const panelPosition =
      searchPanelHeight +
      offset +
      headerPaddingTop +
      drawerMargin -
      documentScrollPosition;
    setPanelPosition(panelPosition);
  }, THROTTLE_DELAY_100);

  useEffect(() => {
    throttledSetSearchPanelPosition();

    // eslint-disable-next-line no-restricted-globals
    window.addEventListener("scroll", throttledSetSearchPanelPosition);

    return () => {
      throttledSetSearchPanelPosition.cancel(); // Cleanup throttled callback
      // eslint-disable-next-line no-restricted-globals
      window.removeEventListener("scroll", throttledSetSearchPanelPosition);
    };
  });

  useEffect(() => {
    throttledSetSearchPanelPosition();
  }, [offset, throttledSetSearchPanelPosition]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (handleQueryChange) {
        handleQueryChange(event.currentTarget.value);
      }
    },
    [handleQueryChange],
  );

  const searchFieldProps = useMemo<SearchFieldProps>(
    () =>
      isSearchOpen
        ? {
            className: SEARCH_PANEL_DESKTOP_CLASSES.searchFieldCentered,
            fullWidth: true,
          }
        : {
            mode: "button",
            className: SEARCH_PANEL_DESKTOP_CLASSES.searchFieldRight,
            onClick: handleSearchOpen,
          },
    [isSearchOpen, handleSearchOpen],
  );

  const onCloseButtonClick = useCallback(() => {
    handleSearchClose?.();
    handleQueryClear?.();
  }, [handleSearchClose, handleQueryClear]);

  useEffect(() => {
    if (isSearchOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isSearchOpen]);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.code === "Escape") {
        handleSearchClose?.();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);
    return () => document.removeEventListener("keydown", handleEscapeKey);
  }, [handleSearchClose]);

  const topLine = useMemo(
    () => (
      <Box className={SEARCH_PANEL_DESKTOP_CLASSES.toolbar}>
        <LinkedLogo
          className={
            isSearchOpen
              ? SEARCH_PANEL_DESKTOP_CLASSES.logoLeft
              : SEARCH_PANEL_DESKTOP_CLASSES.logoCentered
          }
          size="large"
          image={logo}
          href={Routes.HOME}
          onClick={handleSearchClose}
        />
        <SearchField
          {...searchFieldProps}
          value={query}
          onChange={handleChange}
          isSearchOpen={isSearchOpen}
          onClear={handleQueryClear}
          onSearch={handleSearchClick}
          onEscape={handleSearchClose}
          translations={searchPanel?.props?.translations}
        />
        {!isSearchOpen && (
          <Box
            className={SEARCH_PANEL_DESKTOP_CLASSES.iconsWrapper}
            id="account-toolbar"
          >
            {isShoppingListEnabled && (
              <ShoppingListButton
                className={SEARCH_PANEL_DESKTOP_CLASSES.shoppingListButton}
              />
            )}
            {isMyAccountEnabled && (
              <AccountButtonDesktop
                className={SEARCH_PANEL_DESKTOP_CLASSES.accountButton}
              />
            )}
            {isShoppingBagEnabled && <ShoppingBagButton />}
          </Box>
        )}
        {isSearchOpen && (
          <IconButton
            className={SEARCH_PANEL_DESKTOP_CLASSES.closeButton}
            color="inherit"
            aria-label="close search panel"
            onClick={onCloseButtonClick}
            {...getTestAutomationProps("close-button")}
            size="large"
          >
            <Close24 />
          </IconButton>
        )}
      </Box>
    ),
    [
      logo,
      query,
      isSearchOpen,
      isMyAccountEnabled,
      isShoppingListEnabled,
      isShoppingBagEnabled,
      handleSearchClose,
      handleChange,
      handleQueryClear,
      searchFieldProps,
      handleSearchClick,
      onCloseButtonClick,
      searchPanel?.props?.translations,
    ],
  );

  return (
    <SearchPanelDesktopContainer
      isSearchOpen={isSearchOpen}
      iconsCount={iconsCount}
      panelPosition={panelPosition}
    >
      <Box
        className={clsx(
          SEARCH_PANEL_DESKTOP_CLASSES.toolbarWrapper,
          wrapperClassName,
        )}
        {...getTestAutomationProps("search-panel")}
        {...{ ref: searchPanelRef }}
        id="search-panel"
      >
        {topLine}
      </Box>
      {isSearchOpen && (
        <div role="dialog" className={SEARCH_PANEL_DESKTOP_CLASSES.drawer}>
          <div
            className={SEARCH_PANEL_DESKTOP_CLASSES.modalBackdrop}
            onClick={handleSearchClose}
            aria-hidden={true}
          />
          <div className={SEARCH_PANEL_DESKTOP_CLASSES.drawerContent}>
            {searchPanel && (
              <UniversalComponent
                meta={searchPanel._meta}
                props={searchPanel.props}
                component={
                  searchPanel.component ? BffComponentType.SEARCH_PANEL : null
                }
                elements={searchPanel.children as BffComponentExtended[]}
                componentsConfigs={[searchPanelConfig]}
              />
            )}
          </div>
        </div>
      )}
    </SearchPanelDesktopContainer>
  );
};
