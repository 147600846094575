import { styled } from "@mui/material/styles";

import { COLOR_GREYSCALE_BLACK_54 } from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

import { SEARCH_PANEL_CLASSES } from "./constants";

export const SearchPanelContainer = styled("div")(({ theme }) => {
  const { BREAKPOINT_DESKTOP, BREAKPOINT_TABLET } = getFormatMedia(theme);
  return {
    height: "100%",
    position: "relative",
    margin: 0,
    [BREAKPOINT_TABLET]: {
      margin: theme.spacing(0, 6.5),
    },
    [BREAKPOINT_DESKTOP]: {
      top: 0,
      margin: 0,
      bottom: 44,
      height: "unset",
      position: "absolute",
      left: "calc(25% + 8px)",
      right: "calc(25% + 24px)",
    },
    [`& .${SEARCH_PANEL_CLASSES.element}`]: {
      backgroundColor: "white",
    },
    [`& .${SEARCH_PANEL_CLASSES.elements}`]: {
      paddingLeft: theme.spacing(2),
      marginRight: 0,
      [BREAKPOINT_TABLET]: {
        marginRight: theme.spacing(-6.5),
      },
      [BREAKPOINT_DESKTOP]: {
        marginRight: 0,
      },
    },
    [`& .${SEARCH_PANEL_CLASSES.popular}`]: {
      ...theme.typography.body2,
      color: COLOR_GREYSCALE_BLACK_54,
      lineHeight: "20px",
      margin: theme.spacing(1, 0, 0),
      padding: theme.spacing(1.75, 2),
    },
    [`& .${SEARCH_PANEL_CLASSES.divider}`]: {
      margin: theme.spacing(3, 0, 1),
      [BREAKPOINT_DESKTOP]: {
        margin: theme.spacing(5, 0, 1),
      },
    },
    [`& .${SEARCH_PANEL_CLASSES.needHelp}`]: {
      padding: theme.spacing(1.5, 2),
    },
  };
});
