export enum BloomreachGroupType {
  suggest = "suggest",
  widget = "widget",
  cart = "cart",
}

export enum BloomreachEventType {
  widgetView = "widget-view",
  widgetClick = "widget-click",
  widgetAdd = "widget-add",
  click = "click",
  clickAdd = "click-add",
  submit = "submit",
}

export interface BrEventData {
  group: BloomreachGroupType;
  etype: BloomreachEventType;
  aq?: string;
  q?: string;
  catalogs?: { name: string }[];
  wrid?: string | null;
  wq?: string | null;
  wid?: string | null;
  wty?: string | null;
  item_id?: string | null;
  prod_id?: string;
  sku?: string;
  viewId?: string;
}
