import dynamic from "next/dynamic";

import {
  BffComponentType as Component,
  ComponentsConfig,
} from "~/bff/ComponentsConfig";
import { CrmImage } from "~/components/crm-image/component";
import { GlobalLayout } from "~/components/global-layout/component";
import { Header } from "~/components/header/component";
import { HeroBannerCarouselItem } from "~/components/hero-banner/components/hero-banner-carousel-item/component";
import { JsonLd } from "~/components/json-ld/component";
import { SearchPanel } from "~/components/search-panel/component";
import { SeoJsonLd } from "~/components/seo-meta-tags/components/seo-jsonLd/component";
import { importComponentByName } from "~/helpers/import-component-by-name";

// eslint-disable-next-line no-restricted-globals
const Footer = dynamic(
  async () =>
    importComponentByName(import("~/components/footer/component"), "Footer"),
);
const Page404 = dynamic(
  async () =>
    importComponentByName(import("~/components/page-404/component"), "Page404"),
);

const SessionExpiredPopUp = dynamic(
  async () =>
    importComponentByName(
      import("~/components/session-expired-pop-up/component"),
      "SessionExpiredPopUp",
    ),
);
const ClickAndCollectInterrupterPanel = dynamic(
  async () =>
    importComponentByName(
      import("~/components/click-and-collect-interrupter-panel/component"),
      "ClickAndCollectInterrupterPanel",
    ),
);

export const commonComponentsConfig: ComponentsConfig = {
  [Component.GLOBAL_LAYOUT]: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: GlobalLayout as unknown as any,
  },
  [Component.NOT_FOUND]: {
    component: Page404,
    // TODO: It's technical debt, we have availability to
    //  implement component without this parameter.
    stopChildrenPreparation: true,
  },
  [Component.SESSION_EXPIRED_POP_UP]: {
    component: SessionExpiredPopUp,
  },

  [Component.NAV_ITEM]: {
    component: HeroBannerCarouselItem,
  },

  [Component.SEARCH_PANEL]: {
    component: SearchPanel,
  },

  [Component.JSON_LD]: {
    component: JsonLd,
  },

  [Component.JSON_LD_CONTAINER]: {
    component: SeoJsonLd,
  },

  [Component.FOOTER]: {
    component: Footer,
    stopChildrenPreparation: true,
  },

  [Component.HEADER]: {
    component: Header,
    // TODO: It's technical debt, we have availability to
    //  implement component without this parameter.
    stopChildrenPreparation: true,
  },
  [Component.IMAGE]: {
    component: CrmImage,
  },
  [Component.CLICK_COLLECT_INTERRUPTER_PANEL]: {
    component: ClickAndCollectInterrupterPanel,
  },
};
