import { useEffect, useState } from "react";

import Cookies from "js-cookie";
import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { Routes } from "~/constants/request";
import { INFO_BANNER_CLOSED } from "~/constants/user-cookies";

type UseInfoBanner = () => {
  infoBannerCookieValue: boolean;
  setInfoBannerCookieValue: (value: boolean) => void;
};

const getValueFromCookie = (cookieKey: string): boolean => {
  const rawValue = Cookies.get(cookieKey);
  if (rawValue === undefined) {
    return false;
  }

  const parsedValue = JSON.parse(Cookies.get(cookieKey) ?? '""');
  return typeof parsedValue === "boolean" ? parsedValue : false;
};

export const useInfoBanner: UseInfoBanner = () => {
  const { locale } = useParams<GlobalPathParams>();

  const infoBannerCookieKey = `${INFO_BANNER_CLOSED}_${locale}`;

  const [infoBannerCookieValue, setInfoBannerCookieValue] = useState<boolean>(
    getValueFromCookie(infoBannerCookieKey),
  );

  useEffect(() => {
    setInfoBannerCookieValue(getValueFromCookie(infoBannerCookieKey));
  }, [infoBannerCookieKey]);

  return {
    infoBannerCookieValue: infoBannerCookieValue,
    setInfoBannerCookieValue: () => {
      Cookies.set(infoBannerCookieKey, JSON.stringify(true), {
        expires: 2,
        path: Routes.HOME,
      });
    },
  };
};
