export const SEARCH_PANED_DESKTOP_PREFIX = "SearchPanelDesktop";

export const SEARCH_PANEL_DESKTOP_CLASSES = {
  toolbarWrapper: `${SEARCH_PANED_DESKTOP_PREFIX}-toolbarWrapper`,
  toolbar: `${SEARCH_PANED_DESKTOP_PREFIX}-toolbar`,
  logoCentered: `${SEARCH_PANED_DESKTOP_PREFIX}-logoCentered`,
  logoLeft: `${SEARCH_PANED_DESKTOP_PREFIX}-logoLeft`,
  iconsWrapper: `${SEARCH_PANED_DESKTOP_PREFIX}-iconsWrapper`,
  searchFieldRight: `${SEARCH_PANED_DESKTOP_PREFIX}-searchFieldRight`,
  searchFieldCentered: `${SEARCH_PANED_DESKTOP_PREFIX}-searchFieldCentered`,
  closeButton: `${SEARCH_PANED_DESKTOP_PREFIX}-closeButton`,
  drawerContent: `${SEARCH_PANED_DESKTOP_PREFIX}-drawerContent`,
  drawer: `${SEARCH_PANED_DESKTOP_PREFIX}-drawer`,
  modalBackdrop: `${SEARCH_PANED_DESKTOP_PREFIX}-modalBackdrop`,
  accountButton: `${SEARCH_PANED_DESKTOP_PREFIX}-accountButton`,
  shoppingListButton: `${SEARCH_PANED_DESKTOP_PREFIX}-shoppingListButton`,
};
