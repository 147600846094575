"use client";
import React from "react";

export type ClickAndCollectInterrupterPanelContextValue = {
  isOpen: boolean;
  targetLink?: string;
  toggleClickAndCollectInterrupter: (open: boolean, url?: string) => void;
} | null;

export const ClickAndCollectInterrupterPanelContext =
  React.createContext<ClickAndCollectInterrupterPanelContextValue>(null);
