import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const IconContainer = styled(Box)(() => ({
  height: "40px",
  width: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
