import { Location, LoqateLocation } from ".";

const getNormalizedLoqateLocationName = ({
  primaryText,
  secondaryText,
}: {
  primaryText: string;
  secondaryText: string;
}): string => {
  if (!secondaryText?.length) {
    return primaryText;
  }

  return `${primaryText}, ${secondaryText}`;
};

/**
 * Transforms Loqate "Highlight" property to normalized form.

 * @param {string} substrings - substrings returned from Loqate in "highlight" parameter.
 * @param {number} primaryText - "text" parameter from Loqate response.
 * @returns {Location["matchedSubstrings"]} - normalized matched substrings.

 * @example
 * Input: { substrings: "0-6,7-9;0-1", primaryText: "London Apprentice" }
 * Output: [{ offset: 0, length: 6 }, { offset: 7, length: 2 }, { offset: 19, length: 1 }]
 */
const getNormalizedLoqateSubstrings = ({
  substrings,
  primaryText,
}: {
  substrings: string;
  primaryText: string;
}): Location["matchedSubstrings"] => {
  const resultSubstrings: Location["matchedSubstrings"] = [];

  const [primaryTextSubstrings, secondaryTextSubstrings] = substrings.split(";");

  primaryTextSubstrings?.split(",").forEach((substring) => {
    const [startIndex, endIndex] = substring.split("-");

    resultSubstrings.push({
      offset: Number(startIndex),
      length: Number(endIndex) - Number(startIndex),
    });
  });

  // +2 is needed for "," and " " after the primary text.
  const secondaryTextSubstringsOffset = primaryText.length + 2;

  secondaryTextSubstrings?.split(",").forEach((substring) => {
    const [startIndex, endIndex] = substring.split("-");

    resultSubstrings.push({
      offset: Number(startIndex) + Number(secondaryTextSubstringsOffset),
      length: Number(endIndex) - Number(startIndex),
    });
  });

  return resultSubstrings;
};

export const getNormalizedLoqateResults = (
  results: LoqateLocation[],
): Location[] => {
  return results
    ?.map((result: LoqateLocation) => {
      return {
        placeId: result.Id,
        description: getNormalizedLoqateLocationName({
          primaryText: result.Text,
          secondaryText: result.Description,
        }),
        matchedSubstrings: getNormalizedLoqateSubstrings({
          substrings: result.Highlight,
          primaryText: result.Text,
        }),
      };
    })
    ?.slice(0, 5);
};

export const getNormalizedGoogleResults = (
  results: google.maps.places.AutocompletePrediction[],
): Location[] => {
  return results?.map(({ description, place_id, matched_substrings }) => ({
    description,
    placeId: place_id,
    matchedSubstrings: matched_substrings,
  }));
};

export const waitForLocations = async (promise: Promise<unknown>, time = 5000) => {
  const promiseTimeout = new Promise((resolve) =>
    setTimeout(resolve, time, "timeout"),
  );
  const result = await Promise.any([promiseTimeout, promise]);

  return result === "timeout";
};
