import { times } from "lodash";

import { NavItemWithMeta } from "~/components/global-navigation/types";

export const globalNavigationKey = "global-navigation";

export const defaultRootLabel = "View all";

export const urlMocked = "https://example.com/url";

export const linkMocked = {
  label: "Example link",
  url: urlMocked,
};

export const imageDataMocked = {
  alt: "Image alt",
  source: {
    id: "3aa3b019-f423-4f40-b3b3-782f889f89c9",
    name: "d6-arrivals",
    endpoint: "primark",
    defaultHost: "primedia.primark.com",
  },
};

export const navigationMock = [
  {
    _meta: {
      deliveryKey: "mens",
      name: "Mens",
      deliveryId: "a4f96b79-6a55-4947-810e-41a0f4c03260",
      hierarchy: {
        root: true,
        parentId: null,
      },
    },
    component: "NavItem",
    props: {
      id: "d8a2f1ad-aaed-45cb-9bfb-5d9bd9f0c6e9",
      title: "Mens",
      order: 2,
      description: null,
      urlSlug: "c/mens",
      navMenuImage: {
        alt: null,
        source: {
          id: "3aa3b019-f423-4f40-b3b3-782f889f89c9",
          name: "d6-arrivals",
          endpoint: "primark",
          defaultHost: "primedia.primark.com",
        },
      },
      carouselImage: null,
    },
    children: [
      {
        _meta: {
          deliveryKey: "mens/clothing",
          name: "Clothing",
          deliveryId: "1a729738-d709-4bf7-9884-2006625cdd49",
          hierarchy: {
            root: false,
            parentId: "a4f96b79-6a55-4947-810e-41a0f4c03260",
          },
        },
        component: "NavItem",
        props: {
          urlSlug: "c/mens/clothing",
          id: "d5c31bf7-5439-42bf-9c38-6453eb76eabb",
          title: "Clothing",
          order: null,
          description: null,
          navMenuImage: {
            alt: null,
            source: {
              id: "b3f9db6c-837e-41b4-8895-d4c6185988ab",
              name: "Primark-Cares-Corporate-26",
              endpoint: "primark",
              defaultHost: "primedia.primark.com",
            },
          },
          carouselImage: null,
          specialLinks: {
            children: [
              {
                props: {
                  name: "Special One",
                  link: {
                    label: "Special One link",
                    url: "https://example.com/url",
                  },
                  image: {
                    alt: null,
                    source: {
                      id: "984c8048-0103-4d6a-a639-36001369d161",
                      name: "sustainable-shirts-homepage-option2",
                      endpoint: "primark",
                      defaultHost: "primedia.primark.com",
                    },
                  },
                },
              },
            ],
          },
          bannerArray: {
            children: [
              {
                props: {
                  image: {
                    alt: null,
                    source: {
                      id: "4d9e83bd-45ba-4899-a701-a8954d7fdf45",
                      name: "d8-performance-hero",
                      endpoint: "primark",
                      defaultHost: "primedia.primark.com",
                    },
                  },
                  link: {
                    label: "Banner label Dev",
                    url: "https://example.com/url",
                  },
                },
              },
              {
                props: {
                  image: {
                    alt: null,
                    source: {
                      id: "d020b6ff-67c7-4a3a-bf97-87975b745e56",
                      name: "baby_1",
                      endpoint: "primark",
                      defaultHost: "primedia.primark.com",
                    },
                  },
                  link: {
                    label: "Banner label Dev",
                    url: "https://example.com/url",
                  },
                },
              },
            ],
          },
        },
        children: [
          {
            _meta: {
              deliveryKey: "mens/clothing/trousers",
              name: "Trousers",
              deliveryId: "5d76ce7d-40ba-4761-837c-52a1c12a418f",
              hierarchy: {
                root: false,
                parentId: "1a729738-d709-4bf7-9884-2006625cdd49",
              },
            },
            props: {
              urlSlug: "mens/clothing/trousers",
              id: "1045005d-597c-42f8-bb7d-82bb97791951",
              title: "Trousers",
              order: null,
              description: null,
              navMenuImage: null,
              carouselImage: null,
            },
            component: "NavItem",
            children: [
              {
                _meta: {
                  deliveryKey: "mens/clothing/trousers/smart-trousers",
                  name: "Smart Trousers",
                  deliveryId: "29377113-412e-4580-ba60-65f3cbff394d",
                  hierarchy: {
                    root: false,
                    parentId: "5d76ce7d-40ba-4761-837c-52a1c12a418f",
                  },
                },
                props: {
                  urlSlug: "mens/clothing/trousers/smart-trousers",
                  id: "000d551e-b0c5-43a9-b35c-476047de576a",
                  title: "Smart Trousers",
                  order: null,
                  description: null,
                  carouselImage: null,
                },
              },
              {
                _meta: {
                  deliveryKey: "mens/clothing/trousers/joggers",
                  name: "Joggers",
                  deliveryId: "133829d9-3d31-41b7-84b9-198f5f89a767",
                  hierarchy: {
                    root: false,
                    parentId: "5d76ce7d-40ba-4761-837c-52a1c12a418f",
                  },
                },
                props: {
                  urlSlug: "mens/clothing/trousers/joggers",
                  id: "f4b1cfec-09b8-43fc-a626-0cec2f3ef5d8",
                  title: "Joggers",
                  order: null,
                  description: null,
                  carouselImage: null,
                },
              },
              {
                _meta: {
                  deliveryKey: "mens/clothing/trousers/cargo-trousers",
                  name: "Cargo Trousers",
                  deliveryId: "cd848971-0d55-4c2e-bb02-5df8db844ba2",
                  hierarchy: {
                    root: false,
                    parentId: "5d76ce7d-40ba-4761-837c-52a1c12a418f",
                  },
                },
                props: {
                  urlSlug: "mens/clothing/trousers/cargo-trousers",
                  id: "53511ba6-15be-4d2d-90de-7c6dabc3b09c",
                  title: "Cargo Trousers",
                  order: null,
                  description: null,
                  carouselImage: null,
                },
              },
              {
                _meta: {
                  deliveryKey: "mens/clothing/trousers/chinos",
                  name: "Chinos",
                  deliveryId: "ed57f20b-1f7f-4036-a0d3-697e44bf7563",
                  hierarchy: {
                    root: false,
                    parentId: "5d76ce7d-40ba-4761-837c-52a1c12a418f",
                  },
                },
                props: {
                  urlSlug: "mens/clothing/trousers/chinos",
                  id: "0ba9bfc7-bad3-4f19-a346-1f60cf166893",
                  title: "Chinos",
                  order: null,
                  description: null,
                  carouselImage: null,
                },
              },
            ],
          },
          {
            _meta: {
              deliveryKey: "mens/clothing/sportswear",
              name: "Sportswear",
              deliveryId: "91382d60-20ac-4b0d-91f9-c8028b16cab1",
              hierarchy: {
                root: false,
                parentId: "1a729738-d709-4bf7-9884-2006625cdd49",
              },
            },
            props: {
              urlSlug: "mens/clothing/sportswear",
              id: "c611cb32-220f-4043-bbda-6fc14b4f3a1d",
              title: "Sportswear",
              order: null,
              description: null,
              navMenuImage: null,
              carouselImage: null,
            },
            component: "NavItem",
            children: [
              {
                _meta: {
                  deliveryKey: "mens/clothing/sportswear/gym-tops-and-t-shirts",
                  name: "Gym Tops & T-Shirts",
                  deliveryId: "918e896c-7b47-417a-8177-c0020012c486",
                  hierarchy: {
                    root: false,
                    parentId: "91382d60-20ac-4b0d-91f9-c8028b16cab1",
                  },
                },
                props: {
                  urlSlug: "mens/clothing/sportswear/gym-tops-and-t-shirts",
                  id: "ae2c5247-9d5a-4224-9930-51f8d02af54f",
                  title: "Gym Tops & T-Shirts",
                  order: null,
                  description: null,
                  carouselImage: null,
                },
              },
              {
                _meta: {
                  deliveryKey: "mens/clothing/sportswear/gym-and-running-shorts",
                  name: "Gym & Running Shorts",
                  deliveryId: "13eb042a-419b-4aad-abab-b4d97a257459",
                  hierarchy: {
                    root: false,
                    parentId: "91382d60-20ac-4b0d-91f9-c8028b16cab1",
                  },
                },
                props: {
                  urlSlug: "mens/clothing/sportswear/gym-and-running-shorts",
                  id: "71731e3c-2d35-4517-9789-363edcb779fd",
                  title: "Gym & Running Shorts",
                  order: null,
                  description: null,
                  carouselImage: null,
                },
              },
            ],
          },
          {
            _meta: {
              deliveryKey: "mens/clothing/shorts",
              name: "Shorts",
              deliveryId: "5cdd8514-c188-4e31-8e92-7c3f76a2769c",
              hierarchy: {
                root: false,
                parentId: "1a729738-d709-4bf7-9884-2006625cdd49",
              },
            },
            props: {
              urlSlug: "mens/clothing/shorts",
              id: "95a21da3-5c9f-4a59-b9c4-ff712bdd7d6b",
              title: "Shorts",
              order: null,
              description: null,
              navMenuImage: null,
              carouselImage: null,
            },
            component: "NavItem",
            children: [
              {
                _meta: {
                  deliveryKey: "mens/clothing/shorts/chino-shorts",
                  name: "Chino Shorts",
                  deliveryId: "d6a5a8e5-c620-4c6b-b271-6ca71f35bd2d",
                  hierarchy: {
                    root: false,
                    parentId: "5cdd8514-c188-4e31-8e92-7c3f76a2769c",
                  },
                },
                props: {
                  urlSlug: "mens/clothing/shorts/chino-shorts",
                  id: "46fc80e1-d2c4-4613-8910-e66ab64a1cec",
                  title: "Chino Shorts",
                  order: null,
                  description: null,
                  carouselImage: null,
                },
              },
              {
                _meta: {
                  deliveryKey: "mens/clothing/shorts/cargo-shorts",
                  name: "Cargo Shorts",
                  deliveryId: "1f424a23-771b-4d29-b86c-1fdd170492ac",
                  hierarchy: {
                    root: false,
                    parentId: "5cdd8514-c188-4e31-8e92-7c3f76a2769c",
                  },
                },
                props: {
                  urlSlug: "mens/clothing/shorts/cargo-shorts",
                  id: "8f560c18-cee1-4ef0-93a1-996fa173c1ca",
                  title: "Cargo Shorts",
                  order: null,
                  description: null,
                  carouselImage: null,
                },
              },
              {
                _meta: {
                  deliveryKey: "mens/clothing/shorts/denim-shorts",
                  name: "Denim Shorts",
                  deliveryId: "88e23705-946b-49df-8820-a4b132e2f6e2",
                  hierarchy: {
                    root: false,
                    parentId: "5cdd8514-c188-4e31-8e92-7c3f76a2769c",
                  },
                },
                props: {
                  urlSlug: "mens/clothing/shorts/denim-shorts",
                  id: "9ef66a92-fb6d-4837-8df9-8cb17a54fb92",
                  title: "Denim Shorts",
                  order: null,
                  description: null,
                  carouselImage: null,
                },
              },
            ],
          },
          {
            _meta: {
              deliveryKey: "mens/clothing/shoes",
              name: "Shoes",
              deliveryId: "8e668d82-4791-4537-a730-c4c38fd1226f",
              hierarchy: {
                root: false,
                parentId: "1a729738-d709-4bf7-9884-2006625cdd49",
              },
            },
            props: {
              urlSlug: "mens/clothing/shoes",
              id: "c29f3a39-3e13-4a69-9ab8-ebd05582894e",
              title: "Shoes",
              order: null,
              description: null,
              navMenuImage: null,
              carouselImage: null,
            },
            component: "NavItem",
            children: [
              {
                _meta: {
                  deliveryKey: "mens/clothing/shoes/trainers",
                  name: "Trainers",
                  deliveryId: "f1ac743c-9700-47d5-947d-881152a4b3a9",
                  hierarchy: {
                    root: false,
                    parentId: "8e668d82-4791-4537-a730-c4c38fd1226f",
                  },
                },
                props: {
                  urlSlug: "mens/clothing/shoes/trainers",
                  id: "b4e65323-098e-4f54-8ff6-cff9c1826acc",
                  title: "Trainers",
                  order: null,
                  description: null,
                  carouselImage: null,
                },
              },
              {
                _meta: {
                  deliveryKey: "mens/clothing/shoes/boots",
                  name: "Boots",
                  deliveryId: "3d373d02-7040-4222-aa38-45d83f4a610f",
                  hierarchy: {
                    root: false,
                    parentId: "8e668d82-4791-4537-a730-c4c38fd1226f",
                  },
                },
                props: {
                  urlSlug: "mens/clothing/shoes/boots",
                  id: "58cbce5d-8bfb-4466-8a79-f7d4bda0870d",
                  title: "Boots",
                  order: null,
                  description: null,
                  carouselImage: null,
                },
              },
              {
                _meta: {
                  deliveryKey: "mens/clothing/shoes/sandals-and-slides",
                  name: "Sandals & Slides",
                  deliveryId: "1abaaae6-9f44-427c-81a3-37d6c7b433e8",
                  hierarchy: {
                    root: false,
                    parentId: "8e668d82-4791-4537-a730-c4c38fd1226f",
                  },
                },
                props: {
                  urlSlug: "mens/clothing/shoes/sandals-and-slides",
                  id: "fd4b76e0-32d6-4082-b0e9-f73361561721",
                  title: "Sandals & Slides",
                  order: null,
                  description: null,
                  carouselImage: null,
                },
              },
              {
                _meta: {
                  deliveryKey: "mens/clothing/shoes/slippers-and-mules",
                  name: "Slippers & Mules",
                  deliveryId: "d0012a83-cdf8-4555-b95c-a99632240b7e",
                  hierarchy: {
                    root: false,
                    parentId: "8e668d82-4791-4537-a730-c4c38fd1226f",
                  },
                },
                props: {
                  urlSlug: "mens/clothing/shoes/slippers-and-mules",
                  id: "a31b922a-d8c4-4f13-96e5-e80d2c23bcde",
                  title: "Slippers & Mules",
                  order: null,
                  description: null,
                  carouselImage: null,
                },
              },
            ],
          },
        ],
      },
      {
        _meta: {
          deliveryKey: "mens/accessories",
          name: "Accessories",
          deliveryId: "005fd918-cfa2-4d23-bd51-38b154147f77",
          hierarchy: {
            root: false,
            parentId: "a4f96b79-6a55-4947-810e-41a0f4c03260",
          },
        },
        component: "NavItem",
        props: {
          urlSlug: "mens/accessories",
          id: "e08df8ce-c29c-43b2-8f49-7d0e4aefa2ae",
          title: "Accessories",
          order: null,
          description: null,
          navMenuImage: null,
          carouselImage: null,
          specialLinks: null,
          bannerArray: null,
        },
        children: [
          {
            _meta: {
              deliveryKey: "mens/accessories/sunglasses",
              name: "Sunglasses",
              deliveryId: "317c853f-d293-448f-a1f0-ab01f63ac09a",
              hierarchy: {
                root: false,
                parentId: "005fd918-cfa2-4d23-bd51-38b154147f77",
              },
            },
            props: {
              urlSlug: "mens/accessories/sunglasses",
              id: "08382744-efc2-48ea-afe9-b9deea28bac2",
              title: "Sunglasses",
              order: null,
              description: null,
              navMenuImage: null,
              carouselImage: null,
            },
            component: "NavItem",
            children: [],
          },
          {
            _meta: {
              deliveryKey: "mens/accessories/hats-gloves-and-scarves",
              name: "Hats, Gloves & Scarves",
              deliveryId: "33977329-a0e2-49e4-ab98-601a9324e4ad",
              hierarchy: {
                root: false,
                parentId: "005fd918-cfa2-4d23-bd51-38b154147f77",
              },
            },
            props: {
              urlSlug: "mens/accessories/hats-gloves-and-scarves",
              id: "8a30250f-c5c2-4ffc-a7e5-ea4ede2ac771",
              title: "Hats, Gloves & Scarves",
              order: null,
              description: null,
              navMenuImage: null,
              carouselImage: null,
            },
            component: "NavItem",
            children: [],
          },
          {
            _meta: {
              deliveryKey: "mens/accessories/belts",
              name: "Belts",
              deliveryId: "b400899c-93a2-4b91-bc43-1f382b290dff",
              hierarchy: {
                root: false,
                parentId: "005fd918-cfa2-4d23-bd51-38b154147f77",
              },
            },
            props: {
              urlSlug: "mens/accessories/belts",
              id: "012b132c-9b58-4017-b3ab-1a00392734d8",
              title: "Belts",
              order: null,
              description: null,
              navMenuImage: null,
              carouselImage: null,
            },
            component: "NavItem",
            children: [],
          },
          {
            _meta: {
              deliveryKey: "mens/accessories/bags-and-wallets",
              name: "Bags & Wallets",
              deliveryId: "12d7cfec-9422-4e63-9551-eb6f8816b72d",
              hierarchy: {
                root: false,
                parentId: "005fd918-cfa2-4d23-bd51-38b154147f77",
              },
            },
            props: {
              urlSlug: "mens/accessories/bags-and-wallets",
              id: "17228d4c-56e7-4e39-ad4f-fb6cc0950353",
              title: "Bags & Wallets",
              order: null,
              description: null,
              navMenuImage: null,
              carouselImage: null,
            },
            component: "NavItem",
            children: [],
          },
        ],
      },
      {
        _meta: {
          deliveryKey: "mens/shoes",
          name: "Shoes",
          deliveryId: "6af65735-7c92-4aa2-86f4-549474a43333",
          hierarchy: {
            root: false,
            parentId: "a4f96b79-6a55-4947-810e-41a0f4c03260",
          },
        },
        component: "NavItem",
        props: {
          urlSlug: "mens/hats",
          id: "11061fc8-5d74-49d4-9959-5afc7cadb454",
          title: "Hats",
          order: null,
          description: null,
          navMenuImage: null,
          carouselImage: null,
          specialLinks: null,
          bannerArray: null,
        },
        children: [],
      },
      {
        _meta: {
          deliveryKey: "mens/shoes",
          name: "Shoes",
          deliveryId: "6af65735-7c92-4aa2-86f4-549474a43236",
          hierarchy: {
            root: false,
            parentId: "a4f96b79-6a55-4947-810e-41a0f4c03260",
          },
        },
        component: "NavItem",
        props: {
          urlSlug: "mens/shoes",
          id: "11061fc8-5d74-49d4-9959-5afc7cadb851",
          title: "Shoes",
          order: null,
          description: null,
          navMenuImage: null,
          carouselImage: null,
          specialLinks: null,
          bannerArray: null,
        },
        children: [
          {
            _meta: {
              deliveryKey: "mens/shoes/trainers",
              name: "Trainers",
              deliveryId: "a6f7bbf1-d43d-4d3a-af6d-d772a18db140",
              hierarchy: {
                root: false,
                parentId: "6af65735-7c92-4aa2-86f4-549474a43236",
              },
            },
            props: {
              urlSlug: "mens/shoes/trainers",
              id: "25ba1e45-1f5b-455d-ba07-90672310f904",
              title: "Trainers",
              order: null,
              description: null,
              navMenuImage: null,
              carouselImage: null,
            },
            component: "NavItem",
            children: [],
          },
        ],
      },
    ],
  },
  {
    _meta: {
      deliveryKey: "womens",
      name: "Womens",
      deliveryId: "4a97bffd-2adc-4304-bbf9-7c4212be20cd",
      hierarchy: {
        root: true,
        parentId: null,
      },
    },
    component: "NavItem",
    props: {
      urlSlug: "womens",
      id: "195912e7-b981-48a0-9b97-b9dab4dd91be",
      title: "Womens",
      order: null,
      description: null,
      navMenuImage: null,
      carouselImage: null,
    },
    children: [
      {
        _meta: {
          deliveryKey: "womens/clothing",
          name: "Clothing",
          deliveryId: "a4fa24f8-8eaf-404f-ad0c-2d74a27376b4",
          hierarchy: {
            root: false,
            parentId: "4a97bffd-2adc-4304-bbf9-7c4212be20cd",
          },
        },
        component: "NavItem",
        props: {
          urlSlug: "womens/clothing",
          id: "87ffe719-f5b0-40fb-8fad-693e9f00ca8f",
          title: "Clothing",
          order: null,
          description: null,
          navMenuImage: null,
          carouselImage: null,
          specialLinks: null,
          bannerArray: null,
        },
        children: [],
      },
    ],
  },
];

export const categoryNavItem: NavItemWithMeta = navigationMock[0];
export const optionNavItem: NavItemWithMeta = navigationMock[0].children[0];
export const subcategoryGroupNavItem: NavItemWithMeta =
  navigationMock[0].children[0].children[0];
export const subcategoryNavItem: NavItemWithMeta =
  navigationMock[0].children[0].children[0].children[0];

export const additionalNavigationListMocked = times(3, (index) => ({
  link: {
    url: urlMocked,
    label: `Example ${index + 1}`,
  },
  image: imageDataMocked,
}));
