import moment from "moment";
import { object, string } from "yup";

import { Store } from "~/bff/types/Store";

type PrepareStoreCollectionInfo = (
  value?: Store | null,
  options?: { dateFormat: string },
) => {
  time?: string;
  date?: string;
  address: {
    city?: string;
    street?: string;
  };
} | null;

const schema = object({
  time: string().optional(),
  date: string().optional(),
  address: object({
    city: string().required(),
    street: string().required(),
  }).optional(),
});

export const prepareStoreCollectionInfo: PrepareStoreCollectionInfo = (
  initialValue,
  options,
) => {
  try {
    const collectionDate = initialValue?.collectionDates?.dates?.[0]?.collectionDate;
    const transformedValue = {
      time: initialValue?.collectionDates?.minCollectionTime,
      date:
        collectionDate && options?.dateFormat
          ? moment(new Date(collectionDate)).format(options.dateFormat)
          : collectionDate,
      address: {
        city: initialValue?.address?.city,
        street:
          initialValue?.address?.line1 +
          (initialValue?.address?.line2 ? `, ${initialValue?.address?.line2}` : ""),
      },
    };

    const validValue = schema.validateSync(transformedValue);
    return schema.cast(validValue, { stripUnknown: true });
  } catch (err) {
    return null;
  }
};
