import Cookie from "js-cookie";

import { DY_ID, DY_ID_SERVER, DY_SESSION } from "~/constants/user-cookies";
import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";
import { Logger } from "~/utils/logger";

import { LOGGER_LABEL_PREFIX } from "./constants";
import { EngagementType } from "./types";

// More info: https://dy.dev/reference/reporting-engagement
export const reportUserEngagement = async (
  pageLocale: string,
  /*
    Use the value CLICK or IMP (impressions) for API Custom Code Campaigns,
    Use the value SLOT_CLICK for API Recommendations campaigns,
    Use the value PN_CLICK to track click on Reconnect Push Notifications,
    Use the value PN_IMP to track Reconnect Push Notification delivery,
    Use the value SLOT_IMP for QSR Recommendations campaigns only.
  */
  engagementType: EngagementType,
  decisionOrSlotId: string,
  isSlot = false,
): Promise<void> => {
  // We do not want our app to break due to errors in the reporting tool,
  // so we wrap the whole function into try-catch to be safe
  try {
    if (IS_SERVER) {
      throw new Error("Making this action on the server is prohibited");
    }

    const dyConfig = AzureConfigurator.getConfig(pageLocale)?.dynamicYield;

    const clientApiKey = dyConfig?.clientApiKey;
    const euClientReportCollectUrl = dyConfig?.euClientReportCollectUrl;

    if (
      typeof dyConfig !== "object" ||
      typeof clientApiKey !== "string" ||
      typeof euClientReportCollectUrl !== "string"
    ) {
      throw new Error(`Wrong DY configuration! Configuration: ${dyConfig}`);
    }

    await fetch(`${euClientReportCollectUrl}/v2/collect/user/engagement`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "DY-API-Key": clientApiKey,
      },
      body: JSON.stringify({
        user: {
          dyid: Cookie.get(DY_ID),
          dyid_server: Cookie.get(DY_ID_SERVER),
          // eslint-disable-next-line no-restricted-globals
          active_consent_accepted: !window?.DYO?.ActiveConsent?.isUserOptOut(),
        },
        session: {
          dy: Cookie.get(DY_SESSION),
        },
        engagements: [
          {
            type: engagementType,
            ...(isSlot
              ? {
                  slotId: decisionOrSlotId,
                }
              : {
                  decisionId: decisionOrSlotId,
                }),
          },
        ],
      }),
    });

    return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    Logger.getLogger(`${LOGGER_LABEL_PREFIX}:reportUserEngagement`).error(e);
    return;
  }
};
