import React from "react";

import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  COLOR_GREYSCALE_BLACK_4,
  COLOR_GREYSCALE_BLACK_8,
  COLOR_WHITE,
} from "~/theme/colors";

interface UseStylesProps extends ButtonProps {
  isFocused: boolean;
}

export const LocationStyled = styled(
  ({ isFocused, ...restProps }: UseStylesProps) => (
    <Button {...restProps} role="option" /> //NOSONAR ARIA is acceptable to be used here
  ),
)(({ theme, isFocused }) => {
  return {
    ...theme.typography.body1,
    width: "100%",
    display: "block",
    padding: theme.spacing(0, 2),
    backgroundColor: isFocused ? COLOR_GREYSCALE_BLACK_8 : COLOR_WHITE,
    "&:hover": {
      backgroundColor: COLOR_GREYSCALE_BLACK_4,
    },
    "& span": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  };
});
