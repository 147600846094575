import { gql } from "@apollo/client";

export const GET_STYLE_INVENTORY_IN_STORE_QUERY = gql`
  query StyleInventoryInStore(
    $locale: String!
    $styleCode: String!
    $storeId: String!
  ) {
    styleInventoryInStore(
      locale: $locale
      styleCode: $styleCode
      storeId: $storeId
    ) {
      data {
        skuId
        available
        colourId
        clickAndCollectAvailableQuantity
        clickAndCollectStock
        clickAndCollectStore
        locationId
        locationName
        salesChannel
      }
      colours {
        colourCode
        soldOut
      }
      soldOut
    }
  }
`;
