import { RefObject, useEffect, useState } from "react";

import { Nullable } from "~/types/general.types";

interface Args extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
  rootProvider?: () => Element | Document | null;
}

export const useIntersectionObserver = (
  elementRef: Nullable<RefObject<Element>> | undefined,
  { threshold = 0, rootProvider, rootMargin = "0%", freezeOnceVisible = false }: Args,
): IntersectionObserverEntry | undefined => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const frozen = entry?.isIntersecting && freezeOnceVisible;
  const updateEntry = ([newEntry]: IntersectionObserverEntry[]): void => {
    setEntry(newEntry);
  };

  const node = elementRef?.current;

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) {
      return;
    }

    const root = rootProvider ? rootProvider() : null;
    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => {
      observer.disconnect();
    };
  }, [node, rootMargin, frozen, threshold, rootProvider]);

  return entry;
};
