export enum DictionaryKeys {
  BackToTop = "back_to_top",
  FromLabel = "from_label",
  ShareButton = "share_button",
  SeoTwitterSite = "seo_twitter_site",
  BrandName = "brand_name",
  Colour = "color_singular",
  Colours = "color_plural",
  ItemAddedToShoppingListMsg = "item_added_shopping_list_msg",
  LimitationModalWindowTitle = "limitation_modal_window_title",
  LimitationModalWindowDescription = "limitation_modal_window_description",
  LimitationModalWindowOkButton = "limitation_modal_window_ok_button",
  ClickPlusCollectTrialStore = "click_collect_trial_store",
  ShoppingListTooltip = "shopping_list_tooltip",
  ByAuthor = "by_author",
  MinRead = "min_read",
  Miles = "miles",
  Unit = "unit_of_measure",
  Kilometers = "kilometers",
  ShowDescription = "show_description",
  HideDescription = "hide_description",
  GeoNoStoreFoundTitle = "geo_no_store_found_title",
  GeoNoStoreFoundBody = "geo_no_store_found_body",
  GeoFindYourStore = "geo_find_your_store",
  GeoTooltipText = "geo_tooltip_text",
  GeoYourStore = "geo_your_store",
  GeoYourCcStore = "geo_your_cc_store",
  GeoSearchPlaceholder = "geo_search_placeholder",
  CoordsQuickViewButton = "coords_quick_view_btn",
  CoordsShopSetTitle = "coords_shop_set_title",
  NotAvailableForClickCollect = "not_available_for_click_collect",
  AddedToBag = "added_to_bag",
  PaginatedTitle = "paginated_title",
}

export type Dictionary = {
  [key in DictionaryKeys]?: string;
};
