import React, { Children, useMemo } from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { template } from "lodash";

import { isDebug } from "~/utils/is-debug";

import { COMPONENT_HASNT_BEEN_CONFIGURED_YET } from "./constants";
import { ComponentNotFoundProps } from "./types";

export const ComponentNotFound: React.FC<ComponentNotFoundProps> = ({
  children,
  component,
}) => {
  const message = useMemo(
    () => template(COMPONENT_HASNT_BEEN_CONFIGURED_YET)({ component }),
    [component],
  );

  if (isDebug()) {
    return <>{children}</>;
  }

  return (
    <Alert
      sx={{ paddingTop: 3, paddingBottom: 0, "& .MuiAlert-message": { padding: 0 } }}
      severity="error"
    >
      <AlertTitle>Error</AlertTitle>
      <Typography sx={{ paddingBottom: 3 }}>{message}</Typography>
      {Children.count(children) !== 0 ? (
        <>
          <Divider />
          {children}
        </>
      ) : undefined}
    </Alert>
  );
};
