interface UseStorageReturnValue {
  getItem: (key: string) => string | null | undefined;
  setItem: (key: string, value: string) => boolean;
  removeItem: (key: string) => boolean;
}

const isStorageExist = () => typeof sessionStorage !== "undefined";

export const getItem = (key: string) => {
  if (!isStorageExist()) {
    return undefined;
  }

  return sessionStorage.getItem(key);
};

export const setItem = (key: string, value: string) => {
  if (!isStorageExist()) {
    return false;
  }

  sessionStorage.setItem(key, value);
  return true;
};

export const removeItem = (key: string) => {
  if (!isStorageExist()) {
    return false;
  }

  sessionStorage.removeItem(key);
  return true;
};

export const useSessionStorage = (): UseStorageReturnValue => {
  return {
    getItem,
    setItem,
    removeItem,
  };
};
