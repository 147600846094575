"use client";

import React, { ReactNode } from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { SearchPanelProps } from "~/bff/components/SearchPanel";
import { useSwiper } from "~/components/carousel/hooks/use-swiper/index";
import { CombinedButton } from "~/components/combined-button/component";
import { SEARCH_PANEL_CLASSES } from "~/components/search-panel/constants";
import { Routes } from "~/constants/request";
import { HeaderContext } from "~/context/header/context";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { Swiper, SwiperSlide } from "~/lib/swiper/component";
import { Pointer24 } from "~/theme/icons/components/pointer";
import { Question24 } from "~/theme/icons/components/question";

import { LastSearches } from "./components/last-searches/component";
import { Suggestions } from "./components/Suggestions";
import { SearchPanelContainer } from "./styled";

// Import Swiper styles
import "swiper/css/bundle";

export const SearchPanel: React.FC<SearchPanelProps> = ({
  translations,
  needHelp,
  children,
}) => {
  const {
    lastSearchesSectionTitle: LAST_SEARCHES_SECTION_TITLE,
    popularSectionTitle: POPULAR_SECTION_TITLE,
    storeLocatorLabel: STORE_LOCATOR_LABEL,
  } = translations ?? {};

  const { label: NEED_HELP_LABEL, url: needHelpUrl } = needHelp ?? {};
  const theme = useTheme();
  const { setSwiper, windowWidth, handleSliceChange } = useSwiper(1);

  const { query, suggestions, lastSearches, handleSuggestionClick } =
    React.useContext(HeaderContext) ?? {};

  return (
    <SearchPanelContainer>
      {suggestions?.length ? (
        <Suggestions
          query={query}
          suggestions={suggestions}
          onSelect={handleSuggestionClick}
        />
      ) : (
        <>
          <LastSearches
            header={LAST_SEARCHES_SECTION_TITLE}
            searches={lastSearches}
            onSelect={handleSuggestionClick}
          />
          <Typography
            className={SEARCH_PANEL_CLASSES.popular}
            {...getTestAutomationProps("popular-section-title")}
          >
            {POPULAR_SECTION_TITLE}
          </Typography>
          <Box
            className={SEARCH_PANEL_CLASSES.elements}
            {...getTestAutomationProps("elements")}
          >
            <Swiper
              key={windowWidth}
              initialSlide={0}
              spaceBetween={16}
              onSwiper={setSwiper}
              onSlideChange={handleSliceChange}
              slidesPerView={2.2}
              breakpoints={{
                [theme.breakpoints.values.sm]: {
                  slidesPerView: 4.2,
                },
                [theme.breakpoints.values.md]: {
                  slidesPerView: 5,
                },
              }}
            >
              {React.Children.map(children, (element) => (
                <SwiperSlide {...getTestAutomationProps("element")}>
                  <Box className={SEARCH_PANEL_CLASSES.element}>
                    {element as ReactNode}
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
          <Divider className={SEARCH_PANEL_CLASSES.divider} />
          <Box className={SEARCH_PANEL_CLASSES.needHelp}>
            <CombinedButton
              href={Routes.STORES_SEARCH}
              startIcon={<Pointer24 />}
              variant="text"
              className="body1"
            >
              {STORE_LOCATOR_LABEL}
            </CombinedButton>
          </Box>
          <Box className={SEARCH_PANEL_CLASSES.needHelp}>
            <CombinedButton
              href={needHelpUrl ?? ""}
              startIcon={<Question24 />}
              variant="text"
              className="body1"
            >
              {NEED_HELP_LABEL}
            </CombinedButton>
          </Box>
        </>
      )}
    </SearchPanelContainer>
  );
};
