import { Account } from "~/bff/types/Account";
import { normalizeAccountData } from "~/helpers/normalize-account-data";

import { setStoreToCookies } from "./set-store-to-cookies";

export const updateSelectedStoreToAccount = async (
  account: Account | null | undefined,
  storeId: string | undefined,
  token: string | null | undefined,
  updateUserAccount: (token: string, account: Account) => Promise<void>,
): Promise<void> => {
  typeof storeId === "string" && setStoreToCookies(storeId);

  if (account && token) {
    await updateUserAccount(token, {
      ...normalizeAccountData(account),
      selectedStore: storeId,
    });
  }
};
