import { Store } from "~/bff/types/Store";

export const getCollectionDateFromStore = (store: Store): string | null => {
  const date = store?.collectionDates?.dates?.[0]?.collectionDate;
  if (date === null || date === undefined) {
    return null;
  }

  const isDateValid = !isNaN(new Date(date).getTime());
  return isDateValid ? date : null;
};
