import { BreadcrumbsItem } from "~/bff/types/BreadcrumbsItem";
import { CountryCode } from "~/helpers/country/types";
import { Nullable } from "~/types/general.types";

export enum DataLayerData {
  event = "event",
  siteCountry = "site_country",
  pageCategory = "page_category",
  pageSubCategory = "page_sub_category",
  pageSubSubCategory = "page_sub_sub_category",
  pageSubSubSubCategory = "page_sub_sub_sub_category",

  searchTermUsed = "search_term_used",
  searchResults = "search_results",

  storeLocatorSearchTermTyped = "storeLocatorSearchTermTyped",
  storeLocatorSearchTermUsed = "storeLocatorSearchTermUsed",
  storeLocatorSearchResults = "storeLocatorSearchResults",
  storeLocatorSearchCountry = "storeLocatorSearchCountry",
  storeLocatorSearchTown = "storeLocatorSearchTown",
  storeLocatorStoreSelected = "store_locator_store_selected",
  storeSelected = "store_selected",
  storeSearch = "store_search",
  storeFilters = "store_filters",
  storeViewed = "store_viewed",
  searchInput = "search_input",
  searchInputCount = "search_input_count",
  storeSelectorType = "store_selector_type",
  ecommerce = "ecommerce",
  currencyCode = "currencyCode",
  currency = "currency",
  detail = "detail",
  click = "click",
  products = "products",
  impressions = "impressions",
  items = "items",
  itemListNamePartForCoord = "Shop Set for",
  itemListNamePartForCoordModal = "Shop Set for Modal",
  add = "add",
  remove = "remove",
  itemListName = "item_list_name",
  itemListId = "item_list_id",
  value = "value",
  shippingTier = "shipping_tier",
  paymentType = "payment_type",

  type = "type",
  loggedInStatus = "logged_in_status",
  sortOrder = "sort_order",
  productId = "product_id",
  productName = "product_name",
  filterType = "filter_type",
  filterValue = "filter_value",
  filterResults = "filter_results",
  source = "source",

  videoAction = "video_action",
  videoName = "video_name",
  videoTime = "video_time",

  slotIndex = "slot_index",

  checkoutFlow = "checkout_flow",
}

export enum DataLayerFilterType {
  filterByStore = "Filter by store",
}

export enum EventTypes {
  plp = "plp",
  search = "search",
  pdp = "pdp",
  rbp = "rbp",
  storeSelector = "store_selector",
  storeLocator = "store_locator",
  addToBag = "add_to_bag",
  shoppingList = "shopping_list",
  shoppingBag = "shopping_bag",
  pdpSeeSimilarProducts = "pdp_see_similar_products",
  pdpSizeGuide = "pdp_size_guide",
  applyFilter = "apply_filter",
  pdpShopDetails = "pdp_shop_details",
  accountAction = "account_action",
  signUp = "sign_up",
  homepage = "homepage",
  category = "category",
  inspiration = "inspiration",
  video = "video",
  ugcWidgetView = "ugc_widget_view",
  ugcWidgetScroll = "ugc_widget_scroll",
  ugcWidgetClick = "ugc_widget_click",
  loadMore = "load_more",
  shopSetModal = "pdp_shop_set_modal",
  pdpShopSet = "pdp_shop_set",
  youMayAlsoLike = "you_may_also_like",
}

export enum SortOrderTypes {
  newest = "Newest",
  relevance = "Relevance",
  priceLowToHigh = "Price Low - High",
  priceHighToLow = "Price High - Low",
}

export enum ClickCollectStatuses {
  notYetKnown = "not_yet_known",
  clickCollectExclusive = "click + collect exclusive",
  clickCollect = "click + collect",
  nonClickCollect = "non click + collect",
  storeNotYetSelected = "store_not_yet_selected",
  skuNotYetSelected = "sku_not_yet_selected",
}

export enum InStoreStatuses {
  inStock = "In stock in store",
  lowInStock = "Stock running low in-store",
  notAvailableInStore = "Not available in this store",
  notSoldInStore = "Not Sold in-store",
  soldOutInStore = "Out of stock in store",
}

export enum StoreFilters {
  none = "in stock in store:FALSE|in stock for click + collect:FALSE",
  inStore = "in stock in store:TRUE|in stock for click + collect:FALSE",
  inStockForCC = "in stock in store:FALSE|in stock for click + collect:TRUE",
  inStoreAndInStockForCC = "in stock in store:TRUE|in stock for click + collect:TRUE",
}

export enum PlaceApiProvider {
  google = "Google",
  loqate = "Loqate",
}

export enum ProductDataLayerData {
  name = "name",
  id = "id",
  brand = "brand",
  category = "category",
  position = "position",
  variant = "variant",
  dimension1 = "dimension1",
  dimension2 = "dimension2",
  price = "price",
}

export enum NewProductDataLayerData {
  itemId = "item_id",
  itemName = "item_name",
  index = "index",
  itemBrand = "item_brand",
  itemCategory = "item_category",
  itemCategory2 = "item_category2",
  itemCategory3 = "item_category3",
  itemCategory4 = "item_category4",
  itemCategory5 = "item_category5",
  dimension45 = "dimension45",
  dimension48 = "dimension48",
  dimension49 = "dimension49",
  dimension50 = "dimension50",
  dimension51 = "dimension51",
  dimension52 = "dimension52",
  dimension53 = "dimension53",
  price = "price",
  quantity = "quantity",
  variant = "variant",
  dimension2 = "dimension2",
  transactionId = "transaction_id",
  shipping = "shipping",
  tax = "tax",
  collection_lead_time_days = "collection_lead_time_days",
}

export enum StoreSelectorType {
  pdp = "PDP",
  shopSet = "Shop Set",
}

export enum EventsNames {
  checkAvailability = "check availability",
  ecommerceClicks = "ecommerce.clicks",
  ecommerceDetail = "ecommerce.detail",
  ecommerceImpressions = "ecommerce.impressions",
  ecommerceRemoveFromCart = "ecommerce.removefromcart",
  signup = "sign_up",
  content = "content",
  viewItemList = "view_item_list",
  viewItem = "view_item",
  storeSelectorOpen = "store_selector_open",
  storeSelectorSearch = "store_selector_search",
  storeSelected = "store_selected",
  storeLocatorOpen = "store_locator_open",
  storeLocatorSearch = "store_locator_search",
  storeLocatorStoreSelected = "store_locator_store_selected",
  addToCart = "add_to_cart",
  addToWishList = "add_to_wishlist",
  pdpSizeGuide = "pdp_size_guide",
  plpApplyFilter = "plp_apply_filter",
  pdpShopDetails = "pdp_shop_details",
  accountSignIn = "account_sign_in",
  accountRegister = "account_register",
  viewCart = "view_cart",
  removeFromCart = "remove_from_cart",
  storeLocatorDetails = "store_locator_details",
  storeLocatorFindDirections = "store_locator_find_directions",
  storeSelectionTextBoxClick = "store_selection_textbox_click",
  storeSelectorSuggestionSelected = "store_selector_suggestion_selected",
  homepage = "homepage",
  category = "category",
  inspiration = "inspiration",
  videoAction = "video_action",
  ugcWidgetView = "ugc_widget_view",
  ugcWidgetScroll = "ugc_widget_scroll",
  ugcWidgetClick = "ugc_widget_click",
  shopSetModal = "shop_set_modal",
  beginCheckout = "begin_checkout",
  addShippingInfo = "add_shipping_info",
  addPaymentInfo = "add_payment_info",
  purchase = "purchase",
  selectItem = "select_item",
}

interface ProductDataLayerTypes {
  [ProductDataLayerData.name]?: Nullable<string>;
  [ProductDataLayerData.id]?: Nullable<string>;
  [ProductDataLayerData.brand]?: string;
  [ProductDataLayerData.category]?: string;
  [ProductDataLayerData.position]?: number;
  [ProductDataLayerData.variant]?: Nullable<string>;
  [ProductDataLayerData.dimension1]?: string;
  [ProductDataLayerData.dimension2]?: string;
}

interface NewProductDataLayerTypes {
  [NewProductDataLayerData.itemId]?: string | null;
  [NewProductDataLayerData.itemName]?: string | null;
  [NewProductDataLayerData.index]?: number | null;
  [NewProductDataLayerData.itemBrand]?: string | null;
  [NewProductDataLayerData.itemCategory]?: string | null;
  [NewProductDataLayerData.itemCategory2]?: string | null;
  [NewProductDataLayerData.itemCategory3]?: string | null;
  [NewProductDataLayerData.itemCategory4]?: string | null;
  [NewProductDataLayerData.itemCategory5]?: string | boolean | null;
  [NewProductDataLayerData.dimension45]?: string | null;
  [NewProductDataLayerData.dimension49]?: string | boolean | null;
  [NewProductDataLayerData.dimension50]?: string | boolean | null;
  [NewProductDataLayerData.dimension51]?: string | boolean | null;
  [NewProductDataLayerData.dimension52]?: string | null;
  [NewProductDataLayerData.dimension53]?: string | null;
  [NewProductDataLayerData.price]?: string | number | null;
  [NewProductDataLayerData.quantity]?: number | null;
  [NewProductDataLayerData.variant]?: string | null;
  [NewProductDataLayerData.dimension2]?: string | null;
}

interface Ecommerce {
  [DataLayerData.currencyCode]?: string;
  [DataLayerData.impressions]?: ProductDataLayerTypes[];
  [DataLayerData.click]?: {
    [DataLayerData.products]?: ProductDataLayerTypes[];
  };
  [DataLayerData.detail]?: {
    [DataLayerData.products]?: ProductDataLayerTypes[];
  };
  [DataLayerData.add]?: {
    [DataLayerData.products]?: ProductDataLayerTypes[];
  };
  [DataLayerData.remove]?: {
    [DataLayerData.products]?: ProductDataLayerTypes[];
  };
}

interface NewEcommerce {
  [DataLayerData.currency]?: string;
  [DataLayerData.itemListName]?: string;
  [DataLayerData.itemListId]?: string;
  [DataLayerData.value]?: number | string;
  [DataLayerData.items]?: NewProductDataLayerTypes[];
}

export interface ShoppingBagDataLayerDataTypes {
  [DataLayerData.event]: EventsNames;
  [DataLayerData.siteCountry]?: string;
  [DataLayerData.pageCategory]?: Nullable<string>;
  [DataLayerData.pageSubCategory]?: Nullable<string>;
  [DataLayerData.pageSubSubCategory]?: Nullable<string>;
  [DataLayerData.ecommerce]?: {
    [DataLayerData.currency]?: string;
    [DataLayerData.itemListName]?: string;
    [DataLayerData.value]?: number | string;
  };
  [DataLayerData.searchTermUsed]?: string;
  [DataLayerData.searchResults]?: number;
  [DataLayerData.storeSelected]?: string;
}

export interface DataForGaTypes {
  filterType: string;
  filterValue: string;
  breadcrumbs: Nullable<Nullable<BreadcrumbsItem>[]> | undefined;
  sortOrder: string;
  numFound: number | null;
}

export interface DataLayerDataTypes {
  [DataLayerData.event]: EventsNames;
  [DataLayerData.siteCountry]?: CountryCode | null;
  [DataLayerData.pageCategory]?: Nullable<string>;
  [DataLayerData.pageSubCategory]?: Nullable<string>;
  [DataLayerData.pageSubSubCategory]?: Nullable<string>;
  [DataLayerData.ecommerce]?: Ecommerce;
  [DataLayerData.searchTermUsed]?: string;
  [DataLayerData.searchResults]?: number;
  [DataLayerData.storeSelected]?: Nullable<string>;
}
export interface ShopSetModalDataLayerDataTypes {
  [DataLayerData.event]: EventsNames;
  [DataLayerData.siteCountry]?: string;
  [DataLayerData.pageCategory]?: string;
  [DataLayerData.pageSubCategory]?: string;
  [DataLayerData.pageSubSubCategory]?: string;
  [DataLayerData.storeSelected]?: string;
  [DataLayerData.productId]?: string;
  [DataLayerData.productName]?: string;
}

export interface NewDataLayerDataTypes {
  [DataLayerData.event]: EventsNames;
  [DataLayerData.siteCountry]?: CountryCode | null;
  [DataLayerData.type]?: EventTypes;
  [DataLayerData.pageCategory]?: Nullable<string>;
  [DataLayerData.pageSubCategory]?: Nullable<string>;
  [DataLayerData.pageSubSubCategory]?: Nullable<string>;
  [DataLayerData.loggedInStatus]?: boolean;
  [DataLayerData.sortOrder]?: string;
  [DataLayerData.ecommerce]?: NewEcommerce;
  [DataLayerData.storeSelected]?: string | null;
  [DataLayerData.storeSearch]?: string;
  [DataLayerData.storeFilters]?: string;
  [DataLayerData.storeViewed]?: string;
  [DataLayerData.storeLocatorStoreSelected]?: string;
  [DataLayerData.productId]?: Nullable<string>;
  [DataLayerData.productName]?: Nullable<string>;
  [DataLayerData.filterType]?: string;
  [DataLayerData.filterValue]?: string;
  [DataLayerData.filterResults]?: number;
  [DataLayerData.source]?: PlaceApiProvider;
  [DataLayerData.videoAction]?: string;
  [DataLayerData.videoTime]?: number;
  [DataLayerData.videoName]?: string;
}

export const dataLayerDataWithUndefinedValues: Partial<DataLayerDataTypes> = {
  [DataLayerData.siteCountry]: undefined,
  [DataLayerData.pageCategory]: undefined,
  [DataLayerData.pageSubCategory]: undefined,
  [DataLayerData.pageSubSubCategory]: undefined,
  [DataLayerData.searchTermUsed]: undefined,
  [DataLayerData.searchResults]: undefined,
  [DataLayerData.storeSelected]: undefined,
};

export const newDataLayerDataWithUndefinedValues: Partial<NewDataLayerDataTypes> = {
  [DataLayerData.type]: undefined,
  [DataLayerData.siteCountry]: undefined,
  [DataLayerData.pageCategory]: undefined,
  [DataLayerData.pageSubCategory]: undefined,
  [DataLayerData.pageSubSubCategory]: undefined,
  [DataLayerData.loggedInStatus]: undefined,
  [DataLayerData.sortOrder]: undefined,
  [DataLayerData.storeSearch]: undefined,
  [DataLayerData.storeSelected]: undefined,
  [DataLayerData.storeFilters]: undefined,
  [DataLayerData.storeViewed]: undefined,
  [DataLayerData.productId]: undefined,
  [DataLayerData.productName]: undefined,
  [DataLayerData.filterType]: undefined,
  [DataLayerData.filterValue]: undefined,
  [DataLayerData.filterResults]: undefined,
};

export const shoppingBagDataLayerWithUndefinedValues: Partial<ShoppingBagDataLayerDataTypes> =
  {
    [DataLayerData.siteCountry]: undefined,
    [DataLayerData.pageCategory]: undefined,
    [DataLayerData.pageSubCategory]: undefined,
    [DataLayerData.pageSubSubCategory]: undefined,
    [DataLayerData.searchTermUsed]: undefined,
    [DataLayerData.searchResults]: undefined,
    [DataLayerData.storeSelected]: undefined,
  };
