import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { getFormatMedia } from "~/theme/utils";

interface HeaderMobileWrapperProps {
  isScrollOnTheTop: boolean;
}

export const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "isScrollOnTheTop",
})<HeaderMobileWrapperProps>(({ theme, isScrollOnTheTop }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    [formatMedia.BREAKPOINT_DESKTOP]: {
      display: "none",
    },
    boxShadow: isScrollOnTheTop
      ? "none"
      : "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
  };
});

export const InformationBannerContainer = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    display: "block",
    [formatMedia.BREAKPOINT_DESKTOP]: {
      display: "none",
    },
  };
});
