import { NavItemProps } from "~/bff/components/NavItem";

import { NavItemWithMeta } from "../types";

export const convertComponentProps = ({
  props,
  children,
  _meta,
}: NavItemWithMeta): NavItemProps => ({
  ...props,
  meta: _meta,
  children: children,
});
