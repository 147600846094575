/* eslint-disable */
/* @ts-ignore */
/**
 * DO NOT MODIFY IT BY HAND.
 * This file was automatically generated.
 */

import React from "react";
import { Nullable } from "~/types/general.types";
import { ALP } from "~/bff/components/ALP";
import { ATP } from "~/bff/components/ATP";
import { Accordion } from "~/bff/components/Accordion";
import { ArticleCards } from "~/bff/components/ArticleCards";
import { ArticlePage } from "~/bff/components/ArticlePage";
import { ArticlePageGenericButton } from "~/bff/components/ArticlePageGenericButton";
import { ArticlePageImageContainer } from "~/bff/components/ArticlePageImageContainer";
import { ArticlePageProductCard } from "~/bff/components/ArticlePageProductCard";
import { ArticlePageProductCarousel } from "~/bff/components/ArticlePageProductCarousel";
import { ArticlePageRichTextBlock } from "~/bff/components/ArticlePageRichTextBlock";
import { ArticleTile } from "~/bff/components/ArticleTile";
import { AuthorComponent } from "~/bff/components/AuthorComponent";
import { BannerItem } from "~/bff/components/BannerItem";
import { BillingAddressesPage } from "~/bff/components/BillingAddressesPage";
import { BrowseCollectionBanner } from "~/bff/components/BrowseCollectionBanner";
import { CLP } from "~/bff/components/CLP";
import { CheckInPage } from "~/bff/components/CheckInPage";
import { ClickAndCollectPage } from "~/bff/components/ClickAndCollectPage";
import { ClickAndCollectStoreSelector } from "~/bff/components/ClickAndCollectStoreSelector";
import { ClickCollectAccordion } from "~/bff/components/ClickCollectAccordion";
import { ClickCollectCategoryCards } from "~/bff/components/ClickCollectCategoryCards";
import { ClickCollectHeroBanner } from "~/bff/components/ClickCollectHeroBanner";
import { ClickCollectInterrupterPanel } from "~/bff/components/ClickCollectInterrupterPanel";
import { ClickCollectStoreSelector } from "~/bff/components/ClickCollectStoreSelector";
import { CollectionsCarousel } from "~/bff/components/CollectionsCarousel";
import { CollectionsCarouselItem } from "~/bff/components/CollectionsCarouselItem";
import { ColorPalette } from "~/bff/components/ColorPalette";
import { CompetitionLandingPage } from "~/bff/components/CompetitionLandingPage";
import { ContactUs } from "~/bff/components/ContactUs";
import { CookiePolicyPage } from "~/bff/components/CookiePolicyPage";
import { Copyright } from "~/bff/components/Copyright";
import { CountrySelector } from "~/bff/components/CountrySelector";
import { CountrySelectorChildren } from "~/bff/components/CountrySelectorChildren";
import { CrossSell } from "~/bff/components/CrossSell";
import { EditorialContent } from "~/bff/components/EditorialContent";
import { EditorialContentProductGroup } from "~/bff/components/EditorialContentProductGroup";
import { EditorialContentVideo } from "~/bff/components/EditorialContentVideo";
import { ExcludedColours } from "~/bff/components/ExcludedColours";
import { FacetList } from "~/bff/components/FacetList";
import { FiltersMenu } from "~/bff/components/FiltersMenu";
import { Footer } from "~/bff/components/Footer";
import { FooterNav } from "~/bff/components/FooterNav";
import { FooterNavChildren } from "~/bff/components/FooterNavChildren";
import { FooterNavChildrenLinks } from "~/bff/components/FooterNavChildrenLinks";
import { FooterSocialLinks } from "~/bff/components/FooterSocialLinks";
import { FooterSocialLinksItem } from "~/bff/components/FooterSocialLinksItem";
import { GCP } from "~/bff/components/GCP";
import { GenericBanner } from "~/bff/components/GenericBanner";
import { GiftCardBalanceCheckerComponent } from "~/bff/components/GiftCardBalanceCheckerComponent";
import { GlobalLayout } from "~/bff/components/GlobalLayout";
import { Header } from "~/bff/components/Header";
import { HeaderLogoImage } from "~/bff/components/HeaderLogoImage";
import { HeaderPrimaryNavLinks } from "~/bff/components/HeaderPrimaryNavLinks";
import { HeaderPrimaryNavLinksCategory } from "~/bff/components/HeaderPrimaryNavLinksCategory";
import { HeaderPrimaryNavLinksCategoryItem } from "~/bff/components/HeaderPrimaryNavLinksCategoryItem";
import { HeaderSecondaryNavLinks } from "~/bff/components/HeaderSecondaryNavLinks";
import { HeaderSecondaryNavLinksItem } from "~/bff/components/HeaderSecondaryNavLinksItem";
import { HeroBanner } from "~/bff/components/HeroBanner";
import { HeroBannerCarousel } from "~/bff/components/HeroBannerCarousel";
import { HeroBannerCarouselItem } from "~/bff/components/HeroBannerCarouselItem";
import { HeroBannerCategory } from "~/bff/components/HeroBannerCategory";
import { HeroImageProductCarousel } from "~/bff/components/HeroImageProductCarousel";
import { HomePage } from "~/bff/components/HomePage";
import { HomePageBanner } from "~/bff/components/HomePageBanner";
import { Image } from "~/bff/components/Image";
import { InformationBanner } from "~/bff/components/InformationBanner";
import { JsonLd } from "~/bff/components/JsonLd";
import { JsonLdArray } from "~/bff/components/JsonLdArray";
import { JsonLdContainer } from "~/bff/components/JsonLdContainer";
import { LatestArticlesCarousel } from "~/bff/components/LatestArticlesCarousel";
import { MetaColourExclusion } from "~/bff/components/MetaColourExclusion";
import { MyAccountNavigation } from "~/bff/components/MyAccountNavigation";
import { MyAccountPage } from "~/bff/components/MyAccountPage";
import { NavItem } from "~/bff/components/NavItem";
import { NavItemBanner } from "~/bff/components/NavItemBanner";
import { NavItemSpecialLinks } from "~/bff/components/NavItemSpecialLinks";
import { NavItemSpecialLinksItem } from "~/bff/components/NavItemSpecialLinksItem";
import { NewIn } from "~/bff/components/NewIn";
import { Newsletter } from "~/bff/components/Newsletter";
import { NewsletterPopup } from "~/bff/components/NewsletterPopup";
import { NotFound } from "~/bff/components/NotFound";
import { NotFoundChildren } from "~/bff/components/NotFoundChildren";
import { NotFoundItemChildren } from "~/bff/components/NotFoundItemChildren";
import { OrderDetailsPage } from "~/bff/components/OrderDetailsPage";
import { OrderHistoryPage } from "~/bff/components/OrderHistoryPage";
import { OrderSummaryComponent } from "~/bff/components/OrderSummaryComponent";
import { PDP } from "~/bff/components/PDP";
import { PLP } from "~/bff/components/PLP";
import { PasswordResetSuccessPopUp } from "~/bff/components/PasswordResetSuccessPopUp";
import { PaymentCardsPage } from "~/bff/components/PaymentCardsPage";
import { PdpAttributePrefix } from "~/bff/components/PdpAttributePrefix";
import { PdpProductDetailsAccordion } from "~/bff/components/PdpProductDetailsAccordion";
import { PrimaniaComponent } from "~/bff/components/PrimaniaComponent";
import { ProductItem } from "~/bff/components/ProductItem";
import { RbpPage } from "~/bff/components/RbpPage";
import { RecentlyViewed } from "~/bff/components/RecentlyViewed";
import { RelatedArticleCards } from "~/bff/components/RelatedArticleCards";
import { RelatedEditorialContent } from "~/bff/components/RelatedEditorialContent";
import { RelatedLatestArticlesCarousel } from "~/bff/components/RelatedLatestArticlesCarousel";
import { RobotsTxt } from "~/bff/components/RobotsTxt";
import { SearchPanel } from "~/bff/components/SearchPanel";
import { SearchResultsPage } from "~/bff/components/SearchResultsPage";
import { SeoLinkCanonicalMetaTag } from "~/bff/components/SeoLinkCanonicalMetaTag";
import { SeoMetaTag } from "~/bff/components/SeoMetaTag";
import { SeoSocialMetaTag } from "~/bff/components/SeoSocialMetaTag";
import { SeoTitleMetaTag } from "~/bff/components/SeoTitleMetaTag";
import { SessionExpiredPopUp } from "~/bff/components/SessionExpiredPopUp";
import { ShoppingBagPage } from "~/bff/components/ShoppingBagPage";
import { ShoppingBagPageSeoRobots } from "~/bff/components/ShoppingBagPageSeoRobots";
import { ShoppingListPage } from "~/bff/components/ShoppingListPage";
import { ShoppingTypeFilter } from "~/bff/components/ShoppingTypeFilter";
import { SitemapPage } from "~/bff/components/SitemapPage";
import { SizeGuide } from "~/bff/components/SizeGuide";
import { SortOptions } from "~/bff/components/SortOptions";
import { StoreDetailsPage } from "~/bff/components/StoreDetailsPage";
import { StoreLocatorPage } from "~/bff/components/StoreLocatorPage";
import { StoreSelector } from "~/bff/components/StoreSelector";
import { StoreStockDisclaimer } from "~/bff/components/StoreStockDisclaimer";
import { StoresInPage } from "~/bff/components/StoresInPage";
import { ThanksForRegisteringPage } from "~/bff/components/ThanksForRegisteringPage";
import { ThanksForYourOrderPage } from "~/bff/components/ThanksForYourOrderPage";
import { ThemeLinks } from "~/bff/components/ThemeLinks";
import { ThemeNavItem } from "~/bff/components/ThemeNavItem";
import { UgcWidget } from "~/bff/components/UgcWidget";
import { CookiePolicy } from "~/bff/components/CookiePolicy";
export interface Definition<T = React.FC> {
  component: T | React.ComponentType;
  overrides?: ComponentsConfig;
}
export interface IndependentDefinition {
  component: React.FC;
  /*
   * THIS RENDERING METHOD HAS MADE JUST IN CASE
   * AND WE SHOULDN'T USE IT IN SIMPLE CASES.
   *
   * You can ask advice from:
   * - nkornilov@primark.onmicrosoft.com
   * - iivashchenko@primark.onmicrosoft.com
   * - volodymyr.forfutdinov@primark.onmicrosoft.com
   * - orodzevich@primark.onmicrosoft.com
   *
   * @deprecated
   * @experimental
   */
  stopChildrenPreparation: true;
}
export interface BffComponent {
  _meta?: unknown;
  pages?: unknown;
  props?: unknown;
  children?: BffComponent[];
  component?: Nullable<string>;
}
export enum BffComponentType {
  ALP = "ArticleLandingPage",
  ATP = "ArticleThemePage",
  ACCORDION = "Accordion",
  ARTICLE_CARDS = "ArticleCards",
  ARTICLE_PAGE = "ArticlePage",
  ARTICLE_PAGE_GENERIC_BUTTON = "ArticlePageGenericButton",
  ARTICLE_PAGE_IMAGE_CONTAINER = "ArticlePageImageContainer",
  ARTICLE_PAGE_PRODUCT_CARD = "ArticlePageProductCard",
  ARTICLE_PAGE_PRODUCT_CAROUSEL = "ArticlePageProductCarousel",
  ARTICLE_PAGE_RICH_TEXT_BLOCK = "ArticlePageRichTextBlock",
  ARTICLE_TILE = "ArticleTile",
  AUTHOR_COMPONENT = "AuthorComponent",
  BANNER_ITEM = "BannerItem",
  BILLING_ADDRESSES_PAGE = "BillingAddressesPage",
  BROWSE_COLLECTION_BANNER = "BrowseCollectionBanner",
  CLP = "ClpPage",
  CHECK_IN_PAGE = "CheckInPage",
  CLICK_AND_COLLECT_PAGE = "ClickAndCollectPage",
  CLICK_AND_COLLECT_STORE_SELECTOR = "ClickAndCollectStoreSelector",
  CLICK_COLLECT_ACCORDION = "ClickCollectAccordion",
  CLICK_COLLECT_CATEGORY_CARDS = "ClickCollectCategoryCards",
  CLICK_COLLECT_HERO_BANNER = "ClickCollectHeroBanner",
  CLICK_COLLECT_INTERRUPTER_PANEL = "ClickCollectInterrupterPanel",
  CLICK_COLLECT_STORE_SELECTOR = "ClickCollectStoreSelector",
  COLLECTIONS_CAROUSEL = "CollectionsCarousel",
  COLLECTIONS_CAROUSEL_ITEM = "CollectionsCarouselItem",
  COLOR_PALETTE = "ColorPalette",
  COMPETITION_LANDING_PAGE = "CompetitionLandingPage",
  CONTACT_US = "GcpContactUsPage",
  COOKIE_POLICY = "CookiePolicy",
  COOKIE_POLICY_PAGE = "CookiePolicyPage",
  COPYRIGHT = "Copyright",
  COUNTRY_SELECTOR = "CountrySelector",
  COUNTRY_SELECTOR_CHILDREN = "CountrySelectorChildren",
  CROSS_SELL = "PdpCrossSell",
  EDITORIAL_CONTENT = "EditorialContent",
  EDITORIAL_CONTENT_PRODUCT_GROUP = "EditorialContentProductGroup",
  EDITORIAL_CONTENT_VIDEO = "EditorialContentVideo",
  EXCLUDED_COLOURS = "ExcludedColours",
  FACET_LIST = "FacetList",
  FILTERS_MENU = "FiltersMenu",
  FOOTER = "Footer",
  FOOTER_NAV = "FooterNav",
  FOOTER_NAV_CHILDREN = "FooterNavChildren",
  FOOTER_NAV_CHILDREN_LINKS = "FooterNavChildrenLinks",
  FOOTER_SOCIAL_LINKS = "FooterSocialLinks",
  FOOTER_SOCIAL_LINKS_ITEM = "FooterSocialLinksItem",
  GCP = "GcpPage",
  GENERIC_BANNER = "GenericTextAndImageBanner",
  GIFT_CARD_BALANCE_CHECKER_COMPONENT = "GiftCardBalanceCheckerComponent",
  GLOBAL_LAYOUT = "GlobalLayout",
  HEADER = "Header",
  HEADER_LOGO_IMAGE = "HeaderLogoImage",
  HEADER_PRIMARY_NAV_LINKS = "HeaderPrimaryNavLinks",
  HEADER_PRIMARY_NAV_LINKS_CATEGORY = "HeaderPrimaryNavLinksCategory",
  HEADER_PRIMARY_NAV_LINKS_CATEGORY_ITEM = "HeaderPrimaryNavLinksCategoryItem",
  HEADER_SECONDARY_NAV_LINKS = "HeaderSecondaryNavLinks",
  HEADER_SECONDARY_NAV_LINKS_ITEM = "HeaderSecondaryNavLinksItem",
  HERO_BANNER = "HeroBanner",
  HERO_BANNER_CAROUSEL = "HeroBannerCarousel",
  HERO_BANNER_CAROUSEL_ITEM = "HeroBannerCarouselItem",
  HERO_BANNER_CATEGORY = "HeroBannerCategory",
  HERO_IMAGE_PRODUCT_CAROUSEL = "HeroImageProductCarousel",
  HOME_PAGE = "HomePage",
  HOME_PAGE_BANNER = "HomePageBanner",
  IMAGE = "Image",
  INFORMATION_BANNER = "InformationBanner",
  JSON_LD = "JSON-LD",
  JSON_LD_ARRAY = "JsonLdArray",
  JSON_LD_CONTAINER = "JSON-LD/Container",
  LATEST_ARTICLES_CAROUSEL = "LatestArticlesCarousel",
  META_COLOUR_EXCLUSION = "MetaColourExclusion",
  MY_ACCOUNT_NAVIGATION = "MyAccountNavigation",
  MY_ACCOUNT_PAGE = "MyAccountPage",
  NAV_ITEM = "NavItem",
  NAV_ITEM_BANNER = "NavItemBanner",
  NAV_ITEM_SPECIAL_LINKS = "NavItemSpecialLinks",
  NAV_ITEM_SPECIAL_LINKS_ITEM = "NavItemSpecialLinksItem",
  NEW_IN = "productsCarousel",
  NEWSLETTER = "Newsletter",
  NEWSLETTER_POPUP = "NewsletterPopup",
  NOT_FOUND = "404Page",
  NOT_FOUND_CHILDREN = "NotFoundChildren",
  NOT_FOUND_ITEM_CHILDREN = "NotFoundItemChildren",
  ORDER_DETAILS_PAGE = "OrderDetailsPage",
  ORDER_HISTORY_PAGE = "OrderHistoryPage",
  ORDER_SUMMARY_COMPONENT = "OrderSummaryComponent",
  PDP = "PdpPage",
  PLP = "PlpPage",
  PASSWORD_RESET_SUCCESS_POP_UP = "PasswordResetSuccessPopUp",
  PAYMENT_CARDS_PAGE = "PaymentCardsPage",
  PDP_ATTRIBUTE_PREFIX = "PdpAttributePrefix",
  PDP_PRODUCT_DETAILS_ACCORDION = "PDP/ProductDetailsAccordion",
  PRIMANIA_COMPONENT = "PrimaniaComponent",
  PRODUCT_ITEM = "ProductItem",
  RBP_PAGE = "RbpPage",
  RECENTLY_VIEWED = "RecentlyViewedProducts",
  RELATED_ARTICLE_CARDS = "RelatedArticleCards",
  RELATED_EDITORIAL_CONTENT = "RelatedEditorialContent",
  RELATED_LATEST_ARTICLES_CAROUSEL = "RelatedLatestArticlesCarousel",
  ROBOTS_TXT = "RobotsTxt",
  SEARCH_PANEL = "SearchPanel",
  SEARCH_RESULTS_PAGE = "SearchResultsPage",
  SEO_LINK_CANONICAL_META_TAG = "SeoLinkCanonical",
  SEO_SOCIAL_META_TAG = "SeoSocial",
  SEO_TITLE_META_TAG = "SeoTitle",
  SESSION_EXPIRED_POP_UP = "SessionExpiredPopUp",
  SHOPPING_BAG_PAGE = "ShoppingBagPage",
  SHOPPING_BAG_PAGE_SEO_ROBOTS = "ShoppingBagPageSeoRobots",
  SHOPPING_LIST_PAGE = "ShoppingListPage",
  SHOPPING_TYPE_FILTER = "ShoppingTypeFilter",
  SITEMAP_PAGE = "SitemapPage",
  SIZE_GUIDE = "SizeGuide",
  SORT_OPTIONS = "SortOptions",
  STORE_DETAILS_PAGE = "StoreDetailsPage",
  STORE_LOCATOR_PAGE = "StoreLocatorPage",
  STORE_SELECTOR = "StoreSelector",
  STORE_STOCK_DISCLAIMER = "StoreStockDisclaimer",
  STORES_IN_PAGE = "StoresInPage",
  THANKS_FOR_REGISTERING_PAGE = "ThanksForRegisteringPage",
  THANKS_FOR_YOUR_ORDER_PAGE = "ThanksForYourOrderPage",
  THEME_LINKS = "ThemeLinks",
  THEME_NAV_ITEM = "ThemeNavItem",
  UGC_WIDGET = "UGC Widget",
  SEO_DESCRIPTION = "SeoDescription",
  SEO_ROBOTS = "SeoRobots",
}
export interface ComponentsConfig {
  [BffComponentType.ALP]?: Definition<ALP> | IndependentDefinition;
  [BffComponentType.ATP]?: Definition<ATP> | IndependentDefinition;
  [BffComponentType.ACCORDION]?: Definition<Accordion> | IndependentDefinition;
  [BffComponentType.ARTICLE_CARDS]?:
    | Definition<ArticleCards>
    | IndependentDefinition;
  [BffComponentType.ARTICLE_PAGE]?: Definition<ArticlePage> | IndependentDefinition;
  [BffComponentType.ARTICLE_PAGE_GENERIC_BUTTON]?:
    | Definition<ArticlePageGenericButton>
    | IndependentDefinition;
  [BffComponentType.ARTICLE_PAGE_IMAGE_CONTAINER]?:
    | Definition<ArticlePageImageContainer>
    | IndependentDefinition;
  [BffComponentType.ARTICLE_PAGE_PRODUCT_CARD]?:
    | Definition<ArticlePageProductCard>
    | IndependentDefinition;
  [BffComponentType.ARTICLE_PAGE_PRODUCT_CAROUSEL]?:
    | Definition<ArticlePageProductCarousel>
    | IndependentDefinition;
  [BffComponentType.ARTICLE_PAGE_RICH_TEXT_BLOCK]?:
    | Definition<ArticlePageRichTextBlock>
    | IndependentDefinition;
  [BffComponentType.ARTICLE_TILE]?: Definition<ArticleTile> | IndependentDefinition;
  [BffComponentType.AUTHOR_COMPONENT]?:
    | Definition<AuthorComponent>
    | IndependentDefinition;
  [BffComponentType.BANNER_ITEM]?: Definition<BannerItem> | IndependentDefinition;
  [BffComponentType.BILLING_ADDRESSES_PAGE]?:
    | Definition<BillingAddressesPage>
    | IndependentDefinition;
  [BffComponentType.BROWSE_COLLECTION_BANNER]?:
    | Definition<BrowseCollectionBanner>
    | IndependentDefinition;
  [BffComponentType.CLP]?: Definition<CLP> | IndependentDefinition;
  [BffComponentType.CHECK_IN_PAGE]?: Definition<CheckInPage> | IndependentDefinition;
  [BffComponentType.CLICK_AND_COLLECT_PAGE]?:
    | Definition<ClickAndCollectPage>
    | IndependentDefinition;
  [BffComponentType.CLICK_AND_COLLECT_STORE_SELECTOR]?:
    | Definition<ClickAndCollectStoreSelector>
    | IndependentDefinition;
  [BffComponentType.CLICK_COLLECT_ACCORDION]?:
    | Definition<ClickCollectAccordion>
    | IndependentDefinition;
  [BffComponentType.CLICK_COLLECT_CATEGORY_CARDS]?:
    | Definition<ClickCollectCategoryCards>
    | IndependentDefinition;
  [BffComponentType.CLICK_COLLECT_HERO_BANNER]?:
    | Definition<ClickCollectHeroBanner>
    | IndependentDefinition;
  [BffComponentType.CLICK_COLLECT_INTERRUPTER_PANEL]?:
    | Definition<ClickCollectInterrupterPanel>
    | IndependentDefinition;
  [BffComponentType.CLICK_COLLECT_STORE_SELECTOR]?:
    | Definition<ClickCollectStoreSelector>
    | IndependentDefinition;
  [BffComponentType.COLLECTIONS_CAROUSEL]?:
    | Definition<CollectionsCarousel>
    | IndependentDefinition;
  [BffComponentType.COLLECTIONS_CAROUSEL_ITEM]?:
    | Definition<CollectionsCarouselItem>
    | IndependentDefinition;
  [BffComponentType.COLOR_PALETTE]?:
    | Definition<ColorPalette>
    | IndependentDefinition;
  [BffComponentType.COMPETITION_LANDING_PAGE]?:
    | Definition<CompetitionLandingPage>
    | IndependentDefinition;
  [BffComponentType.CONTACT_US]?: Definition<ContactUs> | IndependentDefinition;
  [BffComponentType.COOKIE_POLICY]?:
    | Definition<CookiePolicy>
    | IndependentDefinition;
  [BffComponentType.COOKIE_POLICY_PAGE]?:
    | Definition<CookiePolicyPage>
    | IndependentDefinition;
  [BffComponentType.COPYRIGHT]?: Definition<Copyright> | IndependentDefinition;
  [BffComponentType.COUNTRY_SELECTOR]?:
    | Definition<CountrySelector>
    | IndependentDefinition;
  [BffComponentType.COUNTRY_SELECTOR_CHILDREN]?:
    | Definition<CountrySelectorChildren>
    | IndependentDefinition;
  [BffComponentType.CROSS_SELL]?: Definition<CrossSell> | IndependentDefinition;
  [BffComponentType.EDITORIAL_CONTENT]?:
    | Definition<EditorialContent>
    | IndependentDefinition;
  [BffComponentType.EDITORIAL_CONTENT_PRODUCT_GROUP]?:
    | Definition<EditorialContentProductGroup>
    | IndependentDefinition;
  [BffComponentType.EDITORIAL_CONTENT_VIDEO]?:
    | Definition<EditorialContentVideo>
    | IndependentDefinition;
  [BffComponentType.EXCLUDED_COLOURS]?:
    | Definition<ExcludedColours>
    | IndependentDefinition;
  [BffComponentType.FACET_LIST]?: Definition<FacetList> | IndependentDefinition;
  [BffComponentType.FILTERS_MENU]?: Definition<FiltersMenu> | IndependentDefinition;
  [BffComponentType.FOOTER]?: Definition<Footer> | IndependentDefinition;
  [BffComponentType.FOOTER_NAV]?: Definition<FooterNav> | IndependentDefinition;
  [BffComponentType.FOOTER_NAV_CHILDREN]?:
    | Definition<FooterNavChildren>
    | IndependentDefinition;
  [BffComponentType.FOOTER_NAV_CHILDREN_LINKS]?:
    | Definition<FooterNavChildrenLinks>
    | IndependentDefinition;
  [BffComponentType.FOOTER_SOCIAL_LINKS]?:
    | Definition<FooterSocialLinks>
    | IndependentDefinition;
  [BffComponentType.FOOTER_SOCIAL_LINKS_ITEM]?:
    | Definition<FooterSocialLinksItem>
    | IndependentDefinition;
  [BffComponentType.GCP]?: Definition<GCP> | IndependentDefinition;
  [BffComponentType.GENERIC_BANNER]?:
    | Definition<GenericBanner>
    | IndependentDefinition;
  [BffComponentType.GIFT_CARD_BALANCE_CHECKER_COMPONENT]?:
    | Definition<GiftCardBalanceCheckerComponent>
    | IndependentDefinition;
  [BffComponentType.GLOBAL_LAYOUT]?:
    | Definition<GlobalLayout>
    | IndependentDefinition;
  [BffComponentType.HEADER]?: Definition<Header> | IndependentDefinition;
  [BffComponentType.HEADER_LOGO_IMAGE]?:
    | Definition<HeaderLogoImage>
    | IndependentDefinition;
  [BffComponentType.HEADER_PRIMARY_NAV_LINKS]?:
    | Definition<HeaderPrimaryNavLinks>
    | IndependentDefinition;
  [BffComponentType.HEADER_PRIMARY_NAV_LINKS_CATEGORY]?:
    | Definition<HeaderPrimaryNavLinksCategory>
    | IndependentDefinition;
  [BffComponentType.HEADER_PRIMARY_NAV_LINKS_CATEGORY_ITEM]?:
    | Definition<HeaderPrimaryNavLinksCategoryItem>
    | IndependentDefinition;
  [BffComponentType.HEADER_SECONDARY_NAV_LINKS]?:
    | Definition<HeaderSecondaryNavLinks>
    | IndependentDefinition;
  [BffComponentType.HEADER_SECONDARY_NAV_LINKS_ITEM]?:
    | Definition<HeaderSecondaryNavLinksItem>
    | IndependentDefinition;
  [BffComponentType.HERO_BANNER]?: Definition<HeroBanner> | IndependentDefinition;
  [BffComponentType.HERO_BANNER_CAROUSEL]?:
    | Definition<HeroBannerCarousel>
    | IndependentDefinition;
  [BffComponentType.HERO_BANNER_CAROUSEL_ITEM]?:
    | Definition<HeroBannerCarouselItem>
    | IndependentDefinition;
  [BffComponentType.HERO_BANNER_CATEGORY]?:
    | Definition<HeroBannerCategory>
    | IndependentDefinition;
  [BffComponentType.HERO_IMAGE_PRODUCT_CAROUSEL]?:
    | Definition<HeroImageProductCarousel>
    | IndependentDefinition;
  [BffComponentType.HOME_PAGE]?: Definition<HomePage> | IndependentDefinition;
  [BffComponentType.HOME_PAGE_BANNER]?:
    | Definition<HomePageBanner>
    | IndependentDefinition;
  [BffComponentType.IMAGE]?: Definition<Image> | IndependentDefinition;
  [BffComponentType.INFORMATION_BANNER]?:
    | Definition<InformationBanner>
    | IndependentDefinition;
  [BffComponentType.JSON_LD]?: Definition<JsonLd> | IndependentDefinition;
  [BffComponentType.JSON_LD_ARRAY]?: Definition<JsonLdArray> | IndependentDefinition;
  [BffComponentType.JSON_LD_CONTAINER]?:
    | Definition<JsonLdContainer>
    | IndependentDefinition;
  [BffComponentType.LATEST_ARTICLES_CAROUSEL]?:
    | Definition<LatestArticlesCarousel>
    | IndependentDefinition;
  [BffComponentType.META_COLOUR_EXCLUSION]?:
    | Definition<MetaColourExclusion>
    | IndependentDefinition;
  [BffComponentType.MY_ACCOUNT_NAVIGATION]?:
    | Definition<MyAccountNavigation>
    | IndependentDefinition;
  [BffComponentType.MY_ACCOUNT_PAGE]?:
    | Definition<MyAccountPage>
    | IndependentDefinition;
  [BffComponentType.NAV_ITEM]?: Definition<NavItem> | IndependentDefinition;
  [BffComponentType.NAV_ITEM_BANNER]?:
    | Definition<NavItemBanner>
    | IndependentDefinition;
  [BffComponentType.NAV_ITEM_SPECIAL_LINKS]?:
    | Definition<NavItemSpecialLinks>
    | IndependentDefinition;
  [BffComponentType.NAV_ITEM_SPECIAL_LINKS_ITEM]?:
    | Definition<NavItemSpecialLinksItem>
    | IndependentDefinition;
  [BffComponentType.NEW_IN]?: Definition<NewIn> | IndependentDefinition;
  [BffComponentType.NEWSLETTER]?: Definition<Newsletter> | IndependentDefinition;
  [BffComponentType.NEWSLETTER_POPUP]?:
    | Definition<NewsletterPopup>
    | IndependentDefinition;
  [BffComponentType.NOT_FOUND]?: Definition<NotFound> | IndependentDefinition;
  [BffComponentType.NOT_FOUND_CHILDREN]?:
    | Definition<NotFoundChildren>
    | IndependentDefinition;
  [BffComponentType.NOT_FOUND_ITEM_CHILDREN]?:
    | Definition<NotFoundItemChildren>
    | IndependentDefinition;
  [BffComponentType.ORDER_DETAILS_PAGE]?:
    | Definition<OrderDetailsPage>
    | IndependentDefinition;
  [BffComponentType.ORDER_HISTORY_PAGE]?:
    | Definition<OrderHistoryPage>
    | IndependentDefinition;
  [BffComponentType.ORDER_SUMMARY_COMPONENT]?:
    | Definition<OrderSummaryComponent>
    | IndependentDefinition;
  [BffComponentType.PDP]?: Definition<PDP> | IndependentDefinition;
  [BffComponentType.PLP]?: Definition<PLP> | IndependentDefinition;
  [BffComponentType.PASSWORD_RESET_SUCCESS_POP_UP]?:
    | Definition<PasswordResetSuccessPopUp>
    | IndependentDefinition;
  [BffComponentType.PAYMENT_CARDS_PAGE]?:
    | Definition<PaymentCardsPage>
    | IndependentDefinition;
  [BffComponentType.PDP_ATTRIBUTE_PREFIX]?:
    | Definition<PdpAttributePrefix>
    | IndependentDefinition;
  [BffComponentType.PDP_PRODUCT_DETAILS_ACCORDION]?:
    | Definition<PdpProductDetailsAccordion>
    | IndependentDefinition;
  [BffComponentType.PRIMANIA_COMPONENT]?:
    | Definition<PrimaniaComponent>
    | IndependentDefinition;
  [BffComponentType.PRODUCT_ITEM]?: Definition<ProductItem> | IndependentDefinition;
  [BffComponentType.RBP_PAGE]?: Definition<RbpPage> | IndependentDefinition;
  [BffComponentType.RECENTLY_VIEWED]?:
    | Definition<RecentlyViewed>
    | IndependentDefinition;
  [BffComponentType.RELATED_ARTICLE_CARDS]?:
    | Definition<RelatedArticleCards>
    | IndependentDefinition;
  [BffComponentType.RELATED_EDITORIAL_CONTENT]?:
    | Definition<RelatedEditorialContent>
    | IndependentDefinition;
  [BffComponentType.RELATED_LATEST_ARTICLES_CAROUSEL]?:
    | Definition<RelatedLatestArticlesCarousel>
    | IndependentDefinition;
  [BffComponentType.ROBOTS_TXT]?: Definition<RobotsTxt> | IndependentDefinition;
  [BffComponentType.SEARCH_PANEL]?: Definition<SearchPanel> | IndependentDefinition;
  [BffComponentType.SEARCH_RESULTS_PAGE]?:
    | Definition<SearchResultsPage>
    | IndependentDefinition;
  [BffComponentType.SEO_LINK_CANONICAL_META_TAG]?:
    | Definition<SeoLinkCanonicalMetaTag>
    | IndependentDefinition;
  [BffComponentType.SEO_SOCIAL_META_TAG]?:
    | Definition<SeoSocialMetaTag>
    | IndependentDefinition;
  [BffComponentType.SEO_TITLE_META_TAG]?:
    | Definition<SeoTitleMetaTag>
    | IndependentDefinition;
  [BffComponentType.SESSION_EXPIRED_POP_UP]?:
    | Definition<SessionExpiredPopUp>
    | IndependentDefinition;
  [BffComponentType.SHOPPING_BAG_PAGE]?:
    | Definition<ShoppingBagPage>
    | IndependentDefinition;
  [BffComponentType.SHOPPING_BAG_PAGE_SEO_ROBOTS]?:
    | Definition<ShoppingBagPageSeoRobots>
    | IndependentDefinition;
  [BffComponentType.SHOPPING_LIST_PAGE]?:
    | Definition<ShoppingListPage>
    | IndependentDefinition;
  [BffComponentType.SHOPPING_TYPE_FILTER]?:
    | Definition<ShoppingTypeFilter>
    | IndependentDefinition;
  [BffComponentType.SITEMAP_PAGE]?: Definition<SitemapPage> | IndependentDefinition;
  [BffComponentType.SIZE_GUIDE]?: Definition<SizeGuide> | IndependentDefinition;
  [BffComponentType.SORT_OPTIONS]?: Definition<SortOptions> | IndependentDefinition;
  [BffComponentType.STORE_DETAILS_PAGE]?:
    | Definition<StoreDetailsPage>
    | IndependentDefinition;
  [BffComponentType.STORE_LOCATOR_PAGE]?:
    | Definition<StoreLocatorPage>
    | IndependentDefinition;
  [BffComponentType.STORE_SELECTOR]?:
    | Definition<StoreSelector>
    | IndependentDefinition;
  [BffComponentType.STORE_STOCK_DISCLAIMER]?:
    | Definition<StoreStockDisclaimer>
    | IndependentDefinition;
  [BffComponentType.STORES_IN_PAGE]?:
    | Definition<StoresInPage>
    | IndependentDefinition;
  [BffComponentType.THANKS_FOR_REGISTERING_PAGE]?:
    | Definition<ThanksForRegisteringPage>
    | IndependentDefinition;
  [BffComponentType.THANKS_FOR_YOUR_ORDER_PAGE]?:
    | Definition<ThanksForYourOrderPage>
    | IndependentDefinition;
  [BffComponentType.THEME_LINKS]?: Definition<ThemeLinks> | IndependentDefinition;
  [BffComponentType.THEME_NAV_ITEM]?:
    | Definition<ThemeNavItem>
    | IndependentDefinition;
  [BffComponentType.UGC_WIDGET]?: Definition<UgcWidget> | IndependentDefinition;
  [BffComponentType.SEO_ROBOTS]?: Definition<SeoMetaTag> | IndependentDefinition;
  [BffComponentType.SEO_DESCRIPTION]?:
    | Definition<SeoMetaTag>
    | IndependentDefinition;
}
