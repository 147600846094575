import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { COLOR_GREYSCALE_NTA_BLACK_15, COLOR_WHITE } from "~/theme/colors";

import { CUSTOM_LOCATION_SEARCH_CLASSES } from "./constants";

export const CustomLocationSearchContainer = styled(Box)(({ theme }) => {
  return {
    position: "relative",
    [`& .${CUSTOM_LOCATION_SEARCH_CLASSES.header}`]: {
      display: "flex",
      alignItems: "center",
    },
    [`& .${CUSTOM_LOCATION_SEARCH_CLASSES.label}`]: {
      marginRight: "auto",
    },
    [`& .${CUSTOM_LOCATION_SEARCH_CLASSES.control}`]: {
      marginTop: theme.spacing(1),
    },
    [`& .${CUSTOM_LOCATION_SEARCH_CLASSES.backdrop}`]: {
      zIndex: 1000,
    },
    [`& .${CUSTOM_LOCATION_SEARCH_CLASSES.searchAdornment}`]: {
      marginRight: -13,
    },
    [`& .${CUSTOM_LOCATION_SEARCH_CLASSES.searchButton}`]: {
      marginLeft: theme.spacing(2),
    },
    [`& .${CUSTOM_LOCATION_SEARCH_CLASSES.searchBox}`]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      position: "absolute",
      top: "calc(100% + 5px)",
      left: 0,
      width: "calc(100% - 16px)",
      boxShadow: "0 8px 24px rgba(0,0,0,.15)",
      backgroundColor: COLOR_WHITE,
      borderTop: `1px solid ${COLOR_GREYSCALE_NTA_BLACK_15}`,
      zIndex: 1,
    },
    [`& .${CUSTOM_LOCATION_SEARCH_CLASSES.googleLogo}`]: {
      width: 120,
      marginLeft: "auto",
      margin: theme.spacing(1, 2, 1, "auto"),
    },
    [`& .${CUSTOM_LOCATION_SEARCH_CLASSES.loqateLogo}`]: {
      margin: theme.spacing(1, 2, 1, "auto"),
    },
  };
});
