"use client";

import React from "react";

import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  COLOR_ADDITIONAL_WARM_GRAY_3U,
  COLOR_PRIMARY_BLACK,
  COLOR_PRIMARY_WHITE,
} from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

import { INFORMATION_BANNER_CLASSES } from "./constants";

interface InformationBannerContainerProps {
  textColor: string;
  backgroundColor: string;
  isInfoBannerOpen: boolean;
}

export const InformationBannerContainer = styled(
  ({
    textColor,
    backgroundColor,
    isInfoBannerOpen,
    ...restProps
  }: InformationBannerContainerProps & BoxProps) => <Box {...restProps} />,
)(({ theme, textColor, backgroundColor, isInfoBannerOpen }) => {
  const formatMedia = getFormatMedia(theme);
  const closeButtonFullWidth = 40;

  return {
    width: "100%",
    height: "auto",
    minHeight: theme.spacing(6),
    display: isInfoBannerOpen ? "flex" : "none",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    lineHeight: "24px",
    color: textColor === "light" ? COLOR_PRIMARY_WHITE : COLOR_PRIMARY_BLACK,
    backgroundColor: backgroundColor
      ? backgroundColor
      : textColor === "light"
        ? COLOR_PRIMARY_BLACK
        : COLOR_ADDITIONAL_WARM_GRAY_3U,
    [formatMedia.BREAKPOINT_DESKTOP]: {
      position: "relative",
    },
    [`& .${INFORMATION_BANNER_CLASSES.txtContainer}`]: {
      padding: theme.spacing(1.5, 2),
      paddingRight: `calc(${theme.spacing(0.5)} + ${closeButtonFullWidth}px)`,
      [formatMedia.BREAKPOINT_TABLET]: {
        paddingLeft: theme.spacing(4),
        paddingRight: `calc(${theme.spacing(2)} + ${closeButtonFullWidth}px)`,
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        paddingLeft: theme.spacing(8),
        paddingRight: `calc(${theme.spacing(8)} + ${closeButtonFullWidth}px)`,
      },
    },
    [`& .${INFORMATION_BANNER_CLASSES.text}`]: {
      paddingRight: theme.spacing(1),
    },
    [`& .${INFORMATION_BANNER_CLASSES.link}`]: {
      textDecoration: "underline",
      marginRight: theme.spacing(0.5),
    },
    [`& .${INFORMATION_BANNER_CLASSES.closeButton}`]: {
      position: "absolute",
      right: theme.spacing(0.5),
      top: theme.spacing(0.5),
      [formatMedia.BREAKPOINT_TABLET]: {
        right: theme.spacing(2),
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        right: theme.spacing(8),
      },
      color: textColor === "light" ? COLOR_PRIMARY_WHITE : COLOR_PRIMARY_BLACK,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  };
});
