import { Logger } from "~/utils/logger";

import {
  LOGGER_LABEL,
  TRACK_DYNAMIC_VARIABLE,
  TRACKING_VARIABLE_PREFIX,
  VERSION,
} from "./constants";
import { Choice, Variation } from "./types";

const sendTrackingDataToContentSquare = (
  trackingKey: string,
  trackingValue: string,
) => {
  trackingKey = TRACKING_VARIABLE_PREFIX + trackingKey;

  // eslint-disable-next-line no-restricted-globals
  window?._uxa?.push([
    TRACK_DYNAMIC_VARIABLE,
    {
      key: trackingKey,
      value: trackingValue,
    },
  ]);
};

export const processVariation = (variation: Variation) => {
  if (
    typeof variation.analyticsMetadata === "object" &&
    variation.analyticsMetadata !== null
  ) {
    try {
      const { campaignName, variationName } = variation.analyticsMetadata;

      if (campaignName && variationName) {
        sendTrackingDataToContentSquare(campaignName, variationName);

        Logger.getLogger(LOGGER_LABEL).debug(
          `Particular DY variation: ${JSON.stringify(
            variation,
          )} was pushed to the window.CS_CONF`,
        );
      }
    } catch (e: unknown) {
      Logger.getLogger(LOGGER_LABEL).warn(
        `Particular DY variation: ${JSON.stringify(
          variation,
        )} was not pushed to the window.CS_CONF for the reason below:\n${e}`,
      );
    }
  }
};

export const initializeUxa = () => {
  // eslint-disable-next-line no-restricted-globals
  window._uxa = window._uxa || [];
};

export const processChoice = (choice: Choice) => {
  for (const variation of choice.variations) {
    processVariation(variation);
  }
};

export const createIntegrationCallback = (): (() => void) => {
  let disableCallback = false;
  return () => {
    if (!disableCallback) {
      disableCallback = true;
      // eslint-disable-next-line no-restricted-globals
      if (window.CS_CONF) {
        // eslint-disable-next-line no-restricted-globals
        window.CS_CONF.integrations = window.CS_CONF.integrations || [];
        // eslint-disable-next-line no-restricted-globals
        window.CS_CONF.integrations.push(`Dynamic Yield Server Side - v${VERSION}`);
      }
    }
  };
};
