import { CategorySpecificAttributes } from "~/bff/types/CategorySpecificAttributes";
import { MaterialsComposition } from "~/bff/types/MaterialsComposition";
import { Product } from "~/bff/types/Product";
import { Nullable } from "~/types/general.types";

export const extractColourCode = (productCode: string): string =>
  productCode?.slice(9);

export const extractProductMaterials = (
  materialsCompositions: (MaterialsComposition | null)[],
): string =>
  materialsCompositions
    ?.filter((el) => !!el?.material && !!el?.materialPercentage)
    ?.map((el) => `${el?.material} ${el?.materialPercentage}`)
    .join(", ");

enum SPECIFIC_PRODUCT_ACCORDION_KEYS {
  MATERIAL = "materialsComposition",
  COUNTRY_AVAILABILITY = "countryAvailability",
  MODEL_HEIGHT = "modelHeightPerLocale",
  MODEL_SIZE = "modelSize",
  ADULT_SUPERVISION = "adultSupervision", // Deprecated on Commerce. We should hide the value of this attribute. Needs to be removed eventualy
  BATTERIES_INCLUDED = "batteriesIncluded", // Deprecated on Commerce. We should hide the value of this attribute. Needs to be removed eventualy
}
export type KeyType = keyof Product | SPECIFIC_PRODUCT_ACCORDION_KEYS;
export type ExtendedProduct = Product & CategorySpecificAttributes;

export const extractProductValueByKeyFromAccordion = (
  product: ExtendedProduct,
  key: KeyType,
): string | null | undefined => {
  if (!product) {
    return null;
  }

  switch (key) {
    case SPECIFIC_PRODUCT_ACCORDION_KEYS.ADULT_SUPERVISION:
    case SPECIFIC_PRODUCT_ACCORDION_KEYS.BATTERIES_INCLUDED: {
      return null;
    }
    case SPECIFIC_PRODUCT_ACCORDION_KEYS.MATERIAL: {
      const { materialsComposition } = product;

      return materialsComposition
        ? normalizeValue(extractProductMaterials(materialsComposition))
        : null;
    }

    case SPECIFIC_PRODUCT_ACCORDION_KEYS.COUNTRY_AVAILABILITY: {
      return normalizeValue(product?.countryAvailability);
    }

    case SPECIFIC_PRODUCT_ACCORDION_KEYS.MODEL_HEIGHT: {
      return normalizeValue(product?.modelHeightPerLocale);
    }

    case SPECIFIC_PRODUCT_ACCORDION_KEYS.MODEL_SIZE: {
      return normalizeValue(product?.modelSize);
    }

    default: {
      const result = product[key];

      if (typeof result === "string") {
        return normalizeValue(result);
      }

      return null;
    }
  }
};

const normalizeValue = (value?: string | Nullable<Nullable<string>[]>) => {
  if (!value) {
    return null;
  }

  const stringifiedValue = Array.isArray(value) ? value.join(", ") : value;

  return stringifiedValue.endsWith(";")
    ? stringifiedValue.slice(0, -1).replaceAll(";", "; ")
    : stringifiedValue.replaceAll(";", "; ");
};
