import { CartValidationError } from "~/bff/types/CartValidationError";
import { Store } from "~/bff/types/Store";
import { ShoppingBagContextData } from "~/context/shopping-bag/context";

import { ErrorReasons } from "../components/shopping-bag-order-summary/constants";

type ValueOf<T> = T[keyof T];

type ShoppingBag = ValueOf<Pick<ShoppingBagContextData, "userShoppingBag">>;

const hasQuantityError = (bag: ShoppingBag) => {
  return bag?.validateCart?.errors?.find(
    (error) => error?.reason === ErrorReasons.QUANTITY_LIMIT_ERROR,
  );
};

const hasStockError = (bag: ShoppingBag) => {
  return bag?.validateCart?.errors?.filter(
    (error) =>
      error?.reason === ErrorReasons.STOCK_ERROR ||
      error?.reason === ErrorReasons.NOT_FOUND_IN_LINE_ITEM,
  );
};

export const checkProductsWithErrors = (
  store: Store | undefined | null,
  shoppingBag: ShoppingBag,
): boolean | null | undefined => {
  if (!store) {
    return null;
  }

  const stockErrors: (CartValidationError | null)[] =
    hasStockError(shoppingBag) || [];
  const quantityError = hasQuantityError(shoppingBag) || {};

  const hasUnpublishedProducts = shoppingBag?.products?.some((product) => {
    const endDate = product?.endDate;
    return endDate && new Date(endDate).getTime() <= new Date().getTime();
  });

  const hasProductsWithStockError = shoppingBag?.products?.some((product) =>
    stockErrors?.some((error) => error?.lineItemId === product?.id),
  );

  const hasProductsWithQuantityError = shoppingBag?.products?.some((product) => {
    const actualQuantities = quantityError?.actual?.split(",");
    const productSku = product?.sku;
    return actualQuantities && productSku
      ? actualQuantities.includes(productSku)
      : false;
  });

  return (
    hasUnpublishedProducts ||
    hasProductsWithStockError ||
    hasProductsWithQuantityError
  );
};
