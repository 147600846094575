export const DESKTOP_GLOBAL_NAVIGATION_PREFIX = "DesktopGlobalNavigation";

export const DESKTOP_GLOBAL_NAVIGATION_CLASSES = {
  floor: `${DESKTOP_GLOBAL_NAVIGATION_PREFIX}-floor`,
  row: `${DESKTOP_GLOBAL_NAVIGATION_PREFIX}-row`,
  item: `${DESKTOP_GLOBAL_NAVIGATION_PREFIX}-item`,
  categoryItem: `${DESKTOP_GLOBAL_NAVIGATION_PREFIX}-categoryItem`,
  optionContent: `${DESKTOP_GLOBAL_NAVIGATION_PREFIX}-optionContent`,
  drawerModal: `${DESKTOP_GLOBAL_NAVIGATION_PREFIX}-drawerModal`,
  drawerContent: `${DESKTOP_GLOBAL_NAVIGATION_PREFIX}-drawerContent`,
  hidden: `${DESKTOP_GLOBAL_NAVIGATION_PREFIX}-hidden`,
};
