import React from "react";

import { IconProps } from "./../types";

import { BaseSVGIcon } from "./base-svg-icon";

export const OutOfTime24: React.FC<IconProps> = (props) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M18 6C18 5.46957 17.7893 4.96086 17.4142 4.58579C17.0391 4.21071 16.5304 4 16 4H8C7.46957 4 6.96086 4.21071 6.58579 4.58579C6.21071 4.96086 6 5.46957 6 6C6 8.62 8.56 10.8 10.34 12C8.56 13.2 6 15.38 6 18C6 18.5304 6.21071 19.0391 6.58579 19.4142C6.96086 19.7893 7.46957 20 8 20H16C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18C18 15.38 15.44 13.2 13.66 12C15.44 10.8 18 8.62 18 6ZM16 18C16 18 12.67 16.5 12 15C11.33 16.5 8 18 8 18C8 16.27 10.31 13.05 12 12C13.69 13.05 16 16.25 16 18ZM12 12C10.1582 10.6546 8.7839 8.76626 8.07 6.6C8.05498 6.52651 8.05669 6.45059 8.075 6.37785C8.09331 6.30511 8.12775 6.23743 8.17577 6.17981C8.22378 6.12218 8.28415 6.0761 8.35239 6.04498C8.42064 6.01385 8.49501 5.99848 8.57 6H15.43C15.505 5.99848 15.5794 6.01385 15.6476 6.04498C15.7158 6.0761 15.7762 6.12218 15.8242 6.17981C15.8723 6.23743 15.9067 6.30511 15.925 6.37785C15.9433 6.45059 15.945 6.52651 15.93 6.6C15.2113 8.7638 13.838 10.6508 12 12Z"
        fill="currentColor"
      />
    </BaseSVGIcon>
  );
};
