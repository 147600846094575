import React, { useMemo } from "react";

import { Alert, AlertTitle } from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { FallbackProps } from "react-error-boundary";

import { BffComponentType } from "~/bff/ComponentsConfig";
import { Nullable } from "~/types/general.types";
import { isDebug } from "~/utils/is-debug";
import { Logger } from "~/utils/logger";

interface ErrorFallbackProps extends FallbackProps {
  component?: Nullable<BffComponentType>;
}

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  error,
  resetErrorBoundary,
  component,
}) => {
  const stack = useMemo(
    () =>
      `${error?.stack ?? ""}`
        .split(/[\n\r]+/)
        .slice(1)
        .join("\n"),
    [error],
  );

  if (error) {
    Logger.getLogger("ErrorFallback").error(
      `Error happened during ${component} render. ${error}`,
    );
  }

  if (isDebug()) {
    return null;
  }

  return (
    <Alert
      severity="error"
      sx={{ paddingTop: 3, paddingBottom: 0, "& .MuiAlert-message": { padding: 0 } }}
    >
      <AlertTitle>{error?.message}</AlertTitle>
      <Typography component="pre" sx={{ paddingBottom: 3 }}>
        {stack}
      </Typography>
      <Divider />
      <Typography sx={{ py: 3, pr: 0, pl: 2 }}>
        <Button
          variant="text"
          className="body1 underlined"
          onClick={resetErrorBoundary}
        >
          Try again
        </Button>
      </Typography>
    </Alert>
  );
};
