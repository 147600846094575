import { ImageTemplates } from "~/components/image/types";
import { WindowFormat } from "~/context/breakpoints/types";

export const imageTemplates: ImageTemplates = {
  [WindowFormat.mobile]: "categoriesimages-mobile",
  [WindowFormat.tablet]: "categoriesimages-tablet",
  [WindowFormat.desktop]: "categoriesimages-regulardesktop",
  [WindowFormat.wideDesktop]: "categoriesimages-largedesktop",
};

export const HERO_BANNER_CAROUSEL_ITEM_PREFIX = "HeroBannerCarouselItem";

export const HERO_BANNER_CAROUSEL_ITEM_CLASSES = {
  imageFrame: `${HERO_BANNER_CAROUSEL_ITEM_PREFIX}-imageFrame`,
  image: `${HERO_BANNER_CAROUSEL_ITEM_PREFIX}-image`,
  title: `${HERO_BANNER_CAROUSEL_ITEM_PREFIX}-title`,
};
