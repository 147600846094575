export const formatCurrency = (
  locale: string,
  currency: string,
  amount: number,
  minimumFractionDigits = 2,
): string => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    // replacing "RON" with "LEI" line fixes wrong behavior for "RON" currency code
    // for example, for "EUR" - we display "€", for "GBP" - "£"
    // for "RON" should be displayed "LEI"
    // but "Intl.NumberFormat" returns "RON" (and it's not the desired behaviour)
    currency: currency === "RON" ? "LEI" : currency,
    minimumFractionDigits,
  }).format(amount);
};
