import React, { FC } from "react";

import type { Components } from "react-markdown";
import ReactMarkdown from "react-markdown";

import { Nullable } from "~/types/general.types";

import { config } from "./constants";

export interface MarkdownProps {
  config?: Components;
  text?: Nullable<string>;
  className?: string;
}

export const Markdown: FC<MarkdownProps> = ({
  text,
  config: components = config,
  className,
}) => {
  return (
    <ReactMarkdown
      skipHtml
      allowedElements={Object.keys(components)}
      components={components}
      className={className}
    >
      {text ?? ""}
    </ReactMarkdown>
  );
};
