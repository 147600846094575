import { GeosearchResponse } from "~/bff/transport/Geosearch";
import {
  GeosearchWithInventoryResponse,
  GeosearchWithInventoryVariables,
} from "~/bff/transport/GeosearchWithInventory";
import { Coordinate } from "~/bff/types/Coordinate";
import { Store } from "~/bff/types/Store";
import { StoresResponse } from "~/bff/types/StoresResponse";
import { DEFAULT_STORES_RADIUS } from "~/components/store-locator-page/constants";
import { PlaceApiProvider } from "~/constants/data-layer";
import { LOCALES } from "~/constants/i18n";
import { getApolloClient } from "~/graphql/client";
import { GET_STORES_QUERY } from "~/graphql/queries/getStores";
import { GET_STORES_WITH_INVENTORY_FOR_SEARCH } from "~/graphql/queries/getStoresAvailability";
import { GET_STORES_FOR_SEARCH_FILTERS } from "~/graphql/queries/getStoresForSearch";
import { executeGrapQlQuery } from "~/helpers/execute-graphql-query";
import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";
import { Nullable } from "~/types/general.types";

export interface Result {
  place?: Coordinate;
  stores: Nullable<Store[]>;
}

export interface FindByAddressResponse extends StoresResponse {
  formatted_address?: string;
}

const getGeocodeByPlaceId = async (
  placeId: string,
  region: string,
): Promise<google.maps.GeocoderResult[]> => {
  return new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
    new google.maps.Geocoder().geocode(
      {
        placeId: placeId,
        region: region,
      },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results) {
          resolve(results);
        } else {
          reject(new Error(status));
        }
      },
    );
  });
};

const getLoqateGeocodeByPlaceId = async (placeId: string, pageLocale: LOCALES) => {
  const loqateApiUrl =
    AzureConfigurator?.getConfig(pageLocale)?.storeSearch?.loqateSearchUrl;

  try {
    const response = await fetch(
      `${loqateApiUrl}?${new URLSearchParams({
        addressId: placeId,
      })}`,
    );
    if (response.ok) {
      const data = await response.json();
      return data?.Items;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error fetching loqate data", error);
    return null;
  }
};

const getStoresWithInventoryByCoords = async (
  locale: LOCALES,
  sku: string | undefined,
  latitude: number,
  longitude: number,
) => {
  const { data } = await getApolloClient({ locale }).query<
    GeosearchResponse & GeosearchWithInventoryResponse,
    GeosearchWithInventoryVariables
  >({
    query: sku
      ? GET_STORES_WITH_INVENTORY_FOR_SEARCH
      : GET_STORES_FOR_SEARCH_FILTERS,
    variables: {
      sku: sku as string,
      locale,
      latitude: latitude,
      longitude: longitude,
      radius: DEFAULT_STORES_RADIUS,
    },
  });
  return data;
};

export const getStoresByCoords = async (
  locale: LOCALES,
  latitude: number,
  longitude: number,
  limit = 10,
  offset = 0,
  radius: number = DEFAULT_STORES_RADIUS,
): Promise<Store[]> => {
  try {
    const response = await executeGrapQlQuery<GeosearchResponse>(locale, {
      query: GET_STORES_QUERY,
      variables: {
        locale,
        latitude: latitude,
        longitude: longitude,
        limit: limit,
        offset: offset,
        radius: radius,
      },
    });

    const stores = response?.data?.geosearch?.stores?.filter(
      (store): store is Store => store !== null,
    );

    if (stores) {
      return stores;
    }
    return [];
  } catch (error) {
    return [];
  }
};

const getLatLongByPlaceId = async (
  placeId: string,
  region: string,
  pageLocale: LOCALES,
  placeApiProvider: PlaceApiProvider,
): Promise<null | { lat: number; long: number }> => {
  if (placeApiProvider === PlaceApiProvider.google) {
    const results = await getGeocodeByPlaceId(placeId, region);
    if (!results || results.length === 0) {
      return null;
    }

    const result = results[0];
    const {
      geometry: {
        location: { lat, lng },
      },
    } = result;
    const latitude = lat();
    const longitude = lng();

    return {
      lat: latitude,
      long: longitude,
    };
  } else if (placeApiProvider === PlaceApiProvider.loqate) {
    const loqateSearchUrl =
      AzureConfigurator?.getConfig(pageLocale)?.storeSearch?.loqateSearchUrl;

    const response = await fetch(
      `${loqateSearchUrl}?${new URLSearchParams({
        addressId: placeId,
      })}`,
    );

    if (!response.ok) {
      return null;
    }

    const responseData = await response.json();

    const lat: number = responseData?.Items[0]?.Location?.Latitude;
    const long: number = responseData?.Items[0]?.Location?.Longitude;

    if (typeof lat !== "number" || typeof long !== "number") {
      return null;
    }

    return {
      lat,
      long,
    };
  }

  return null;
};

export const getStoresByPlaceId = async (
  locale: LOCALES,
  region: string,
  placeId: string,
  placeApiProvider: PlaceApiProvider,
): Promise<Nullable<Result>> => {
  if (!placeId) {
    return {
      stores: [],
    };
  }

  const latLong = await getLatLongByPlaceId(
    placeId,
    region,
    locale,
    placeApiProvider,
  );

  if (!latLong) {
    return {
      stores: [],
    };
  }

  const { lat, long } = latLong;

  const stores = await getStoresByCoords(locale, lat, long);

  const result = {
    place: {
      latitude: lat,
      longitude: long,
    },
    stores,
  };

  return result;
};

const getStoresWithInventoryByLoqateAddressId = async (
  locale: LOCALES,
  addressId: string,
  sku?: string,
) => {
  const results = await getLoqateGeocodeByPlaceId(addressId, locale);
  if (!results || results.length === 0) {
    return { stores: [] };
  }
  const result = results[0];
  const {
    Location: { Latitude: latitude, Longitude: longitude },
  } = result;
  const data = await getStoresWithInventoryByCoords(
    locale,
    sku,
    latitude,
    longitude,
  );
  if (!data || (!data.geosearchWithInventory && !data.geosearch)) {
    return { stores: [] };
  }
  const response = sku ? data.geosearchWithInventory : data.geosearch;
  if (!response) {
    return { stores: [] };
  }
  return { ...response, stores: response.stores };
};

export const getStoresWithInventoryByPlaceId = async (
  locale: LOCALES,
  region: string,
  placeId: string,
  sku?: string,
  isLoqateSearchEnabled?: boolean,
): Promise<Nullable<FindByAddressResponse>> => {
  if (!placeId) {
    return { stores: [] };
  }
  if (isLoqateSearchEnabled) {
    return getStoresWithInventoryByLoqateAddressId(locale, placeId, sku);
  }
  const results = await getGeocodeByPlaceId(placeId, region);
  if (!results || results.length === 0) {
    return { stores: [] };
  }
  const result = results[0];
  const {
    geometry: {
      location: { lat, lng },
    },
    formatted_address,
  } = result;
  const latitude = lat();
  const longitude = lng();
  const data = await getStoresWithInventoryByCoords(
    locale,
    sku,
    latitude,
    longitude,
  );
  const response = sku ? data?.geosearchWithInventory : data?.geosearch;
  if (!response) {
    return { stores: [] };
  }
  return { ...response, stores: response.stores || [], formatted_address };
};
