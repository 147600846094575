import { gql } from "@apollo/client";

export const GET_STORE_DETAILS_BY_ID_QUERY = gql`
  query StoreDetailsPageById($locale: String!, $storeId: String!) {
    storeDetailsPage(locale: $locale) {
      props {
        storeDetailsById(storeId: $storeId) {
          trial
          id
          googlePlaceId
          name
          geomodifier
          countryCode
          address {
            line1
            line2
            city
            postalCode
            countryCode
            region
          }
          description
          paymentOptions
          distance {
            distanceMiles
            distanceKilometers
          }
          clickAndCollectStore
          storeLimits {
            minOrderPrice
            maxOrderPrice
            maxLineItemQuantity
          }
          collectionDates {
            country
            minCollectionTime
            storeId
            dates {
              collectionDate
              shippingDate
            }
          }
          additionalHoursText
          timeZoneUtcOffset
          timezone
          facilities
          departments {
            departmentName
            departmentFloor
            departmentURL
            fontColour
            departmentImage {
              url
              sourceUrl
              alternateText
            }
          }
          hours {
            monday {
              openIntervals {
                start
                end
              }
              isClosed
              date
            }
            tuesday {
              openIntervals {
                end
                start
              }
              isClosed
              date
            }
            wednesday {
              openIntervals {
                end
                start
              }
              isClosed
              date
            }
            thursday {
              openIntervals {
                end
                start
              }
              isClosed
              date
            }
            friday {
              openIntervals {
                end
                start
              }
              isClosed
              date
            }
            saturday {
              openIntervals {
                end
                start
              }
              isClosed
              date
            }
            sunday {
              openIntervals {
                end
                start
              }
              isClosed
              date
            }
          }
          holidayHours {
            openIntervals {
              end
              start
            }
            isClosed
            date
          }
        }
      }
    }
  }
`;
