import React from "react";

import { IconProps } from "./../types";

import { BaseSVGIcon } from "./base-svg-icon";

export const Up32: React.FC<IconProps> = (props) => {
  return (
    <BaseSVGIcon width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
      <path
        d="M2 22L16 8L30 22"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </BaseSVGIcon>
  );
};

export const Up24: React.FC<IconProps> = (props) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M19 15L12 8L5 15"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </BaseSVGIcon>
  );
};

export const UpTiny24: React.FC<IconProps> = (props) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M16 14L12 10L8 14"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </BaseSVGIcon>
  );
};

export const Up16: React.FC<IconProps> = (props) => {
  return (
    <BaseSVGIcon width="16" height="10" viewBox="0 0 16 10" fill="none" {...props}>
      <path d="M15 9L8 2L1 9" stroke="black" strokeWidth="2" strokeLinecap="round" />
    </BaseSVGIcon>
  );
};
