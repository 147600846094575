import { gql } from "@apollo/client";

export const GET_STORE_COLLECTION_DATES = gql`
  query StoreCollectionDates($locale: String!, $storeId: String!) {
    storeDetailsPage(locale: $locale) {
      props {
        storeDetailsById(locale: $locale, storeId: $storeId) {
          collectionDates {
            country
            minCollectionTime
            storeId
            dates {
              collectionDate
              shippingDate
            }
          }
        }
      }
    }
  }
`;
