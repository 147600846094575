import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";

import { COLOR_WHITE } from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

import { SEARCH_PANEL_DESKTOP_CLASSES } from "./constants";

export interface UseStylesProps {
  offset?: number;
  isInfoBanner?: boolean;
  isSearchOpen?: boolean;
  iconsCount?: number;
  panelPosition?: number;
}

const ICON_WIDTH = 48;

const fadeInEffect = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slideInEffect = keyframes`
  0% {
    transform: scale(1, 0);
  }
  100% {
    transform: scale(1, 1);
  }
`;

export const SearchPanelDesktopContainer = styled("div")<UseStylesProps>(({
  theme,
  isSearchOpen,
  iconsCount,
  panelPosition,
}) => {
  const barHeight = 52;
  const fieldWideningTimeMs = 200;
  const panelSlidingTimeMs = 250;

  const { BREAKPOINT_DESKTOP } = getFormatMedia(theme);
  return {
    [`& .${SEARCH_PANEL_DESKTOP_CLASSES.toolbarWrapper}`]: {
      marginTop: theme.spacing(1.75),
      marginBottom: theme.spacing(1.75),
      [BREAKPOINT_DESKTOP]: {
        display: "flex",
        flexFlow: "row-reverse",
      },
    },
    [`& .${SEARCH_PANEL_DESKTOP_CLASSES.toolbar}`]: {
      width: "100%",
      height: barHeight,
      position: "relative",
      zIndex: 100,
      [BREAKPOINT_DESKTOP]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: isSearchOpen ? "100%" : "50%",
      },
    },
    [`& .${SEARCH_PANEL_DESKTOP_CLASSES.logoCentered}`]: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [BREAKPOINT_DESKTOP]: {
        position: "relative",
        display: "inline-block",
        left: 0,
        top: 0,
        transform: "translate(-50%, 0)",
        flexShrink: 0,
      },
    },
    [`& .${SEARCH_PANEL_DESKTOP_CLASSES.logoLeft}`]: {
      position: "absolute",
      top: "50%",
      left: 0,
      transform: "translate(0, -50%)",
      animation: `${fadeInEffect} ${fieldWideningTimeMs}`,
    },
    [`& .${SEARCH_PANEL_DESKTOP_CLASSES.iconsWrapper}`]: {
      position: "absolute",
      right: "0",
      zIndex: 999,
      marginTop: theme.spacing(1),
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(1),
      },
      [BREAKPOINT_DESKTOP]: {
        position: "relative",
        flexShrink: 0,
      },
    },
    [`& .${SEARCH_PANEL_DESKTOP_CLASSES.shoppingListButton}`]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [`& .${SEARCH_PANEL_DESKTOP_CLASSES.searchFieldRight}`]: {
      zIndex: 1,
      maxWidth: "50%",
      position: "absolute",
      top: "50%",
      right: iconsCount ? ICON_WIDTH * iconsCount : 0,
      transform: "translate(0, -50%)",
      [BREAKPOINT_DESKTOP]: {
        position: "relative",
        top: "0",
        right: "0 !important",
        display: "flex",
        justifyContent: "flex-end",
        maxWidth: "none",
        marginLeft: "auto",
        marginRight: theme.spacing(1),
        transform: "none",
      },
    },
    [`& .${SEARCH_PANEL_DESKTOP_CLASSES.searchFieldCentered}`]: {
      maxWidth: "50%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      transition: `all ${fieldWideningTimeMs}ms`,
    },
    [`& .${SEARCH_PANEL_DESKTOP_CLASSES.closeButton}`]: {
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
      animation: `${fadeInEffect} ${fieldWideningTimeMs}ms`,
    },
    [`& .${SEARCH_PANEL_DESKTOP_CLASSES.drawerContent}`]: {
      backgroundColor: COLOR_WHITE,
      width: "100%",
      position: "absolute",
      minHeight: "calc(min(100%, max(80%, 580px)))",
      boxShadow: `0px 12px 12px rgb(0 0 0 / 15%)`,
      animation: `${panelSlidingTimeMs}ms ${fieldWideningTimeMs}ms both ${slideInEffect}`,
      transformOrigin: "top",
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
      transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
      overflowY: "auto",
      WebkitOverflowScrolling: "touch",
    },
    [`& .${SEARCH_PANEL_DESKTOP_CLASSES.drawer}`]: {
      position: "fixed",
      zIndex: theme.zIndex.modal,
      inset: 0,
      top: panelPosition,
      display: "none",
      [BREAKPOINT_DESKTOP]: {
        display: "block",
      },
    },
    [`& .${SEARCH_PANEL_DESKTOP_CLASSES.modalBackdrop}`]: {
      position: "absolute",
      animation: `${panelSlidingTimeMs}ms ${fieldWideningTimeMs}ms backwards ${fadeInEffect}`,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      zIndex: -1,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.25)",
      WebkitTapHighlightColor: "transparent",
    },
    [`& .${SEARCH_PANEL_DESKTOP_CLASSES.accountButton}`]: {
      padding: theme.spacing(1),
    },
  };
});
