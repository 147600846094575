"use client";

import React from "react";

import Slide from "@mui/material/Slide";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import dynamic from "next/dynamic";

import { GeoLocationMobile } from "~/components/geo-location-mobile/component";
import { getReplacedLabel } from "~/components/global-navigation/helpers/get-replaced-label";
import { InformationBanner } from "~/components/information-banner/component";
import { Variables } from "~/constants/request";
import { HeaderContext } from "~/context/header/context";
import { ShoppingBagContext } from "~/context/shopping-bag/context";
import { useVariable } from "~/context/variables/hooks/use-variable";
import { importComponentByName } from "~/helpers/import-component-by-name";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { useIsGeoLocationEnabled } from "~/hooks/use-geo-location/use-is-geo-location-enabled";

import { PageType } from "__generated__/globalTypes";

import { ConvertedHeaderProps } from "../../types";

import { HeaderToolbar } from "./header-toolbar/component";
import { AppBarStyled, InformationBannerContainer } from "./styled";

const SideMenu = dynamic(async () =>
  importComponentByName(import("./side-menu/component"), "SideMenu"),
);
const SearchPanelMobile = dynamic(
  async () =>
    importComponentByName(
      import("~/components/search-panel/components/search-panel-mobile/component"),
      "SearchPanelMobile",
    ),
);
const MobileGlobalNavigation = dynamic(async () =>
  importComponentByName(
    import(
      "~/components/global-navigation/components/mobile-global-navigation/component"
    ),
    "MobileGlobalNavigation",
  ),
);

export const HeaderMobile: React.FC<ConvertedHeaderProps> = ({
  logo,
  navigation = [],
  additionalItems = [],
  informationBanner,
  onInfoBannerClose,
  isInfoBannerClose,
  searchPanel,
  storeLocatorLinkLabel,
  translations,
}) => {
  const trigger = useScrollTrigger();
  const isScrollOnTheTop = !useScrollTrigger({
    disableHysteresis: true,
    threshold: 10,
  });

  const isGeolocationEnabled = useIsGeoLocationEnabled();

  const {
    isMenuOpen,
    handleMenuOpen,
    handleMenuClose,
    isSearchOpen,
    handleSearchOpen,
  } = React.useContext(HeaderContext) ?? {};

  const viewAllLabel = getReplacedLabel(translations?.viewAll ?? "");

  const { shouldShowAddToCartPopUp } = React.useContext(ShoppingBagContext) ?? {};

  const pageType = useVariable(Variables.PAGE_TYPE);
  const isHomePage = pageType === PageType.HomePage;

  return (
    <>
      <Slide
        appear={false}
        direction="down"
        in={shouldShowAddToCartPopUp || !trigger}
      >
        <AppBarStyled
          position="sticky"
          role="banner"
          {...getTestAutomationProps("header")}
          elevation={isScrollOnTheTop ? 0 : 4}
          isScrollOnTheTop={isScrollOnTheTop}
        >
          <InformationBannerContainer>
            {informationBanner ? (
              <InformationBanner
                props={informationBanner.props}
                onInfoBannerClose={onInfoBannerClose}
                isInfoBannerClose={isInfoBannerClose}
              />
            ) : null}
          </InformationBannerContainer>
          <HeaderToolbar
            logo={logo}
            isMenuOpen={isMenuOpen}
            onMenuOpen={handleMenuOpen}
            isSearchOpen={isSearchOpen}
            onSearchOpen={handleSearchOpen}
          />
          <SideMenu
            logo={logo}
            isOpen={isMenuOpen}
            onClose={handleMenuClose}
            isSearchOpen={isSearchOpen}
            onSearchOpen={handleSearchOpen}
          >
            <MobileGlobalNavigation
              navigation={navigation}
              additionalItems={additionalItems}
              storeLocatorLinkLabel={storeLocatorLinkLabel}
              myAccountName={translations?.accountName}
              viewAllLabel={viewAllLabel}
            />
          </SideMenu>
          <SearchPanelMobile searchPanel={searchPanel} />
        </AppBarStyled>
      </Slide>

      {isGeolocationEnabled && isHomePage ? <GeoLocationMobile /> : null}
    </>
  );
};
