import { gql } from "@apollo/client";

export const GET_STORES_QUERY = gql`
  query GetStores(
    $locale: String!
    $latitude: Float!
    $longitude: Float!
    $limit: Int!
    $offset: Int!
    $radius: Int!
  ) {
    geosearch(
      locale: $locale
      latitude: $latitude
      longitude: $longitude
      limit: $limit
      offset: $offset
      radius: $radius
    ) {
      count
      stores {
        id
        trial
        name
        geomodifier
        clickAndCollectStore
        address {
          line1
          line2
          city
          postalCode
          countryCode
          region
        }
        faqTitle
        faqUrl
        helpLink
        helpTitle
        hours {
          monday {
            isClosed
            openIntervals {
              end
              start
            }
          }
          tuesday {
            isClosed
            openIntervals {
              end
              start
            }
          }
          wednesday {
            isClosed
            openIntervals {
              end
              start
            }
          }
          thursday {
            isClosed
            openIntervals {
              end
              start
            }
          }
          friday {
            isClosed
            openIntervals {
              end
              start
            }
          }
          saturday {
            isClosed
            openIntervals {
              start
              end
            }
          }
          sunday {
            isClosed
            openIntervals {
              end
              start
            }
          }
        }
        services {
          id
          name
          address {
            line1
            line2
            city
            postalCode
            countryCode
            region
          }
          bookingCTA {
            label
            link
          }
          hours {
            monday {
              isClosed
              openIntervals {
                start
                end
              }
            }
            tuesday {
              isClosed
              openIntervals {
                start
                end
              }
            }
            wednesday {
              isClosed
              openIntervals {
                start
                end
              }
            }
            thursday {
              isClosed
              openIntervals {
                start
                end
              }
            }
            friday {
              isClosed
              openIntervals {
                start
                end
              }
            }
            saturday {
              isClosed
              openIntervals {
                start
                end
              }
            }
            sunday {
              isClosed
              openIntervals {
                start
                end
              }
            }
          }
          url
        }
        additionalHoursText
        timeZoneUtcOffset
        timezone
        description
        metaDescription
        metaTitle
        phoneNumber
        phoneNumberFormattedNational
        phoneNumberFormattedInternational
        facilities
        displayCoordinate {
          latitude
          longitude
        }
        walkableCoordinate {
          latitude
          longitude
        }
        routableCoordinate {
          latitude
          longitude
        }
        distance {
          from {
            latitude
            longitude
          }
          distanceMiles
          distanceKilometers
        }
      }
    }
  }
`;
