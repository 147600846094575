import { useContext, useMemo } from "react";

import { useParams } from "next/navigation";

import { getTestCampaignChoicesFromResponse } from "~/utils/dynamic-yield/get-campaign-choices-from-response";
import { reportUserEngagement } from "~/utils/dynamic-yield/report-user-engagement";
import { DyCampaignName, EngagementType } from "~/utils/dynamic-yield/types";

import { GlobalPathParams } from "../../app/[locale]/types";
import { DynamicYieldContext } from "../../context/dynamic-yield/context";

export const useMinibagDYCampaignData = () => {
  const { locale } = useParams<GlobalPathParams>();

  const dyContext = useContext(DynamicYieldContext);

  const dyChooseResponseData = dyContext ? dyContext.chooseResponseData : null;

  const dyMinibagChoiceData = useMemo(
    () =>
      getTestCampaignChoicesFromResponse(
        dyChooseResponseData,
        DyCampaignName.MiniAddToBagCheckoutAndViewbagCTA,
        locale,
      ),
    [dyChooseResponseData, locale],
  );

  const reportMinibagCampaignEngagement = () => {
    const decisionId = dyMinibagChoiceData?.decisionId;

    if (decisionId) {
      reportUserEngagement(locale, EngagementType.CLICK, decisionId);
    }
  };

  const dyData = dyMinibagChoiceData?.variations[0]?.payload?.data;

  return { isVisible: dyData?.isVisible, reportMinibagCampaignEngagement };
};
