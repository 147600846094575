import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";

import { dimensions as logoDimensions } from "~/components/logo/constants";
import { getFormatMedia } from "~/theme/utils";

import { HEADER_TOOLBAR_CLASSES } from "./constants";

export const HeaderToolbarContainer = styled(Toolbar)(({ theme }) => ({
  [`& .${HEADER_TOOLBAR_CLASSES.menuButton}`]: {
    marginRight: theme.spacing(1.5),

    [getFormatMedia(theme).BREAKPOINT_TABLET]: {
      marginRight: theme.spacing(2.5),
    },
  },
  [`& .${HEADER_TOOLBAR_CLASSES.logo}`]: {
    height: logoDimensions.height.small,
    width: logoDimensions.width.small,

    [theme.breakpoints.up(360)]: {
      height: logoDimensions.height.medium,
      width: logoDimensions.width.medium,
    },
    [getFormatMedia(theme).BREAKPOINT_DESKTOP]: {
      height: logoDimensions.height.large,
      width: logoDimensions.width.large,
    },
  },
  [`& .${HEADER_TOOLBAR_CLASSES.buttonGroup}`]: {
    marginLeft: "auto",
    flexShrink: 0,
    "& button": {
      width: 40,
    },
    "& a": {
      width: 40,
    },
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  [`& .${HEADER_TOOLBAR_CLASSES.shoppingListButton}`]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));
