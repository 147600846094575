"use client";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { COLOR_WHITE } from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

import { HEADER_DESKTOP_CLASSES } from "./constants";

export const HeaderDesktopWrapper = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  const headerHeight = 88;
  return {
    backgroundColor: COLOR_WHITE,
    paddingTop: theme.spacing(1),
    [`& .${HEADER_DESKTOP_CLASSES.searchAnimationBox}`]: {
      height: headerHeight,
      maxWidth: 1920,
      marginInline: "auto",
    },
    [`& .${HEADER_DESKTOP_CLASSES.floor}`]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      [formatMedia.BREAKPOINT_LARGE_DESKTOP]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
      },
    },
    [`& .${HEADER_DESKTOP_CLASSES.secondaryLevel}`]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };
});

export const InformationBannerContainer = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    display: "none",
    [formatMedia.BREAKPOINT_DESKTOP]: {
      display: "block",
    },
  };
});
