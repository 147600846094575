"use client";

import React, { VFC } from "react";

import Typography from "@mui/material/Typography";
import clsx from "clsx";

import { ImageCommon } from "~/bff/types/ImageCommon";
import { Link } from "~/bff/types/Link";
import { CombinedLink } from "~/components/combined-link/component";
import { Image } from "~/components/image/component";
import { prepareTextAttribute } from "~/helpers/prepare-text-attribute";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { Nullable } from "~/types/general.types";
import { getImageSrc } from "~/utils/image/get-image-src";

import { BANNER_CLASSES, imageTemplates } from "./constants";
import { BannerContainer } from "./styled";

export interface BannerProps {
  className?: string;
  image?: Nullable<ImageCommon>;
  name?: string;
  link?: Nullable<Link>;
  textColor?: string;
}

export const Banner: VFC<BannerProps> = ({
  className,
  image,
  name,
  link,
  textColor = "dark",
}) => {
  const idName = (name || "").replaceAll(" ", "-").toLowerCase();
  return (
    <BannerContainer textColor={textColor}>
      <CombinedLink
        id={`navigation-content-banner-${idName}`}
        href={link?.url ?? ""}
        className={clsx(BANNER_CLASSES.root, className)}
        underline="none"
        display="block"
        {...getTestAutomationProps(`banner`)}
      >
        <Image
          className={BANNER_CLASSES.image}
          imageTemplates={imageTemplates}
          src={getImageSrc(image?.source)}
          alt={prepareTextAttribute(image?.alt, true)}
          title={prepareTextAttribute(image?.title)}
        />
        <Typography
          variant="h5"
          className={BANNER_CLASSES.label}
          {...getTestAutomationProps("title")}
        >
          {name ?? link?.label}
        </Typography>
      </CombinedLink>
    </BannerContainer>
  );
};
