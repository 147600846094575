import { StoreDetailsPageResponse } from "~/bff/transport/StoreDetailsPage";
import { LOCALES } from "~/constants/i18n";
import { GET_STORE_DETAILS_BY_ID_QUERY } from "~/graphql/queries/getStoreDetailsById";
import { Logger } from "~/utils/logger";

import { executeGrapQlQuery } from "./execute-graphql-query";

export const getStoreDetailsById = async (
  locale: LOCALES,
  storeId: string,
): Promise<StoreDetailsPageResponse | undefined> => {
  try {
    const { data } = await executeGrapQlQuery<StoreDetailsPageResponse>(locale, {
      query: GET_STORE_DETAILS_BY_ID_QUERY,
      variables: {
        locale,
        storeId,
      },
      fetchPolicy: "no-cache",
    });

    return data;
  } catch (error) {
    Logger.getLogger().error(String(error));
  }
};
