export const findJsCssFile = (filename: string, filetype: string): boolean => {
  const targetElement =
    filetype === "js" ? "script" : filetype === "css" ? "link" : "none";
  const targetAttribute =
    filetype === "js" ? "src" : filetype === "css" ? "href" : "none";
  const allDomElements = document.getElementsByTagName(targetElement);

  for (let i = allDomElements.length; i >= 0; i--) {
    if (allDomElements[i]?.getAttribute(targetAttribute)?.includes(filename)) {
      return true;
    }
  }

  return false;
};
