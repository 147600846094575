import { useState } from "react";

import { Logger } from "~/utils/logger";

type ReturnType<T> = [T, (value: T) => void];

export const useLocalStorage = <T>(
  key: string,
  initialValue: T,
  formatValueFromStorage = (value: string) => JSON.parse(value) as T,
): ReturnType<T> => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      if (!IS_SERVER) {
        const item = localStorage.getItem(key);
        return item ? formatValueFromStorage(item) : initialValue;
      }
      return initialValue;
    } catch (error) {
      const errorMessage = error instanceof Error ? error : new Error(String(error));

      Logger.getLogger().error(errorMessage);
      return initialValue;
    }
  });
  const setValue = (value: T) => {
    try {
      if (!IS_SERVER) {
        setStoredValue(value);
        localStorage.setItem(key, JSON.stringify(value));
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error : new Error(String(error));

      Logger.getLogger().error(errorMessage);
    }
  };
  return [storedValue, setValue];
};
