import { ImageTemplates } from "~/components/image/types";
import { WindowFormat } from "~/context/breakpoints/types";

export const imageTemplates: ImageTemplates = {
  [WindowFormat.mobile]: "navimages-mobile",
  [WindowFormat.tablet]: "navimages-tablet",
  [WindowFormat.desktop]: "navimages-regulardesktop",
  [WindowFormat.wideDesktop]: "navimages-largedesktop",
};

export const MENU_ITEM_PREFIX = "MenuItem";

export const MENU_ITEM_CLASSES = {
  buttonRoot: `${MENU_ITEM_PREFIX}-buttonRoot`,
  imageBox: `${MENU_ITEM_PREFIX}-imageBox`,
  label: `${MENU_ITEM_PREFIX}-label`,
  arrow: `${MENU_ITEM_PREFIX}-arrow`,
};
