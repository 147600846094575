"use client";

import React, { useCallback, useEffect, useMemo, useState, FC } from "react";

import Box from "@mui/material/Box";
import clsx from "clsx";

import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { useWindowScroll } from "~/hooks/use-window-scroll/index";
import { ScrollDirection } from "~/hooks/use-window-scroll/types";
import { Up16 } from "~/theme/icons/components/up";

import { BACK_TO_TOP_BUTTON_CLASSES } from "./constants";
import { BackToTopButtonContainer } from "./styled";

const SCROLL_OFFSET_THRESHOLD = 1024;
export interface BackToTopButtonProps {
  name?: string;
}

export const BackToTopButton: FC<BackToTopButtonProps> = ({ name = "to top" }) => {
  const [isInitiated, setIsInitiated] = useState(false);

  const { scrollTop, scrollDirection } = useWindowScroll();

  const desktopHeaderHeight =
    typeof document !== "undefined"
      ? document.getElementById("desktopHeader")?.offsetHeight
      : 0;

  const isScrollingUp = useMemo(() => {
    return scrollDirection === ScrollDirection.UP;
  }, [scrollDirection]);

  const scrollToTop = useCallback(() => {
    scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (isInitiated) {
      return;
    }
    if (scrollTop > SCROLL_OFFSET_THRESHOLD && isScrollingUp) {
      setIsInitiated(true);
    }
  }, [scrollTop, isScrollingUp, isInitiated]);

  const shouldBeShown = useMemo(() => {
    return isInitiated;
  }, [isInitiated]);

  useEffect(() => {
    if (scrollTop < (desktopHeaderHeight ?? 0) || !isScrollingUp) {
      setIsInitiated(false);
    }
  }, [scrollTop, isScrollingUp, desktopHeaderHeight]);

  return (
    <BackToTopButtonContainer
      aria-label={name}
      className={clsx(
        { [BACK_TO_TOP_BUTTON_CLASSES.isVisible]: shouldBeShown },
        { [BACK_TO_TOP_BUTTON_CLASSES.isHidden]: !shouldBeShown },
      )}
      onClick={scrollToTop}
      {...getTestAutomationProps("back-to-top-button")}
    >
      <Box className={BACK_TO_TOP_BUTTON_CLASSES.up}>
        <Up16 />
      </Box>
    </BackToTopButtonContainer>
  );
};
