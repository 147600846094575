import { BffComponentType as Component } from "~/bff/ComponentsConfig";
import { HeaderLogoImage } from "~/bff/types/HeaderLogoImage";
import { HeaderSecondaryNavLinks } from "~/bff/types/HeaderSecondaryNavLinks";
import { HeaderSecondaryNavLinksItem } from "~/bff/types/HeaderSecondaryNavLinksItem";
import { Nullable } from "~/types/general.types";

import {
  ConvertedHeaderProps,
  ExtendedHeaderProps,
  HeaderChild,
  HeaderChildRen,
} from "../types";
import { HeaderSecondaryNavLinksItemProps } from "~/bff/components/HeaderSecondaryNavLinksItem";

const findComponentByName = <T extends HeaderChild>(
  children: HeaderChildRen,
  name: string,
): Nullable<T> => {
  const foundChild = children.find((child): child is T => child?.component === name);

  return foundChild || null;
};

export const convertHeaderToProps = (
  data: ExtendedHeaderProps,
): ConvertedHeaderProps => {
  let headerChildren = data.children || [];

  const logo = findComponentByName<HeaderLogoImage>(
    headerChildren,
    Component.HEADER_LOGO_IMAGE,
  );
  const secondaryNavLinks = findComponentByName<HeaderSecondaryNavLinks>(
    headerChildren,
    Component.HEADER_SECONDARY_NAV_LINKS,
  );

  return {
    ...data,
    logo: logo?.props?.image,
    additionalItems: secondaryNavLinks?.children?.map(
      (child) => child?.props as HeaderSecondaryNavLinksItemProps,
    ),
  };
};
