import { useState } from "react";

import { useDebounce } from "use-debounce";

import { DEBOUNCE_DELAY_150 } from "~/constants/delay-constants";
import { useWindowEvent } from "~/hooks/use-window-event";

import { WindowScrollState, ScrollDirection } from "./types";

export const useWindowScroll = (shouldDebounce = false): WindowScrollState => {
  const [windowScrollState, setWindowScrollState] = useState<WindowScrollState>({
    scrollTop: 0,
    scrollDirection: ScrollDirection.NO,
  });

  const [prevScrollTop, setPrevScrollTop] = useState(0);

  const globalScrollHandler = () => {
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    const scrollOffset = scrollTop - prevScrollTop;

    const scrollDirection =
      scrollOffset > 0 ? ScrollDirection.DOWN : ScrollDirection.UP;

    setPrevScrollTop(scrollTop);
    setWindowScrollState({
      scrollTop,
      scrollDirection,
    });
  };

  useWindowEvent("scroll", globalScrollHandler);

  const [windowScrollStateCached] = useDebounce(
    windowScrollState,
    DEBOUNCE_DELAY_150,
  );

  return shouldDebounce ? windowScrollStateCached : windowScrollState;
};
