import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { SUB_CATEGORY_GROUP_CLASSES } from "./constants";

export const SubCategoryGroupContainer = styled(Box)(() => {
  return {
    [`& .${SUB_CATEGORY_GROUP_CLASSES.item}`]: {
      whiteSpace: "normal",
    },
  };
});
