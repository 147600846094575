import { Accessibility } from "~/bff/types/Accessibility";
import { BtnsDictionary } from "~/bff/types/BtnsDictionary";
import { AccessibilityLabels } from "~/constants/accessibility";
import { Dictionary } from "~/context/dictionary-label/types";

export const convertLabelsToProps = (
  rawLabels: Accessibility | BtnsDictionary,
): AccessibilityLabels | Dictionary | undefined => {
  return rawLabels?.items?.reduce<AccessibilityLabels | Dictionary>((acc, item) => {
    if (item?.key !== undefined && item?.key !== null) {
      return { ...acc, [item.key]: item?.value };
    } else {
      return acc;
    }
  }, {});
};
