// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-restricted-globals */
import isEqual from "lodash/isEqual";

import { LOCALES } from "~/constants/i18n";
import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";
import { Logger } from "~/utils/logger";

import { findJsCssFile } from "../dom-utils/find-js-css-file";

import { BloomreachPixelData } from "./types";

const bloomReachScriptCdnLink = "//cdn.brcdn.com/v1/br-trk-6657.js";

const updateBrDataEvent = (data: { [key: string]: unknown }): void => {
  const br_track = BrTrk?.getTracker();
  br_track?.updateBrData(data);
  br_track?.logPageView();
};

export function initBloomreachPixel(
  data: BloomreachPixelData,
  locale?: LOCALES,
  prevPath?: string,
): void {
  try {
    const pixelConfig = AzureConfigurator.getConfig(locale)?.bloomReachPixel;

    const br_data: { [key: string]: unknown } = {};

    br_data.acct_id = pixelConfig?.id;
    br_data.domain_key = pixelConfig?.domainKey;
    br_data.ptype = data.pType;
    br_data.title = data.title;
    br_data.user_id = data.user_id ?? "";
    br_data.ref = prevPath;
    br_data.view_id = locale;

    // Conversion (Thank you page) specific Tracking Parameters
    if (data.is_conversion) {
      br_data.is_conversion = data.is_conversion;
      br_data.basket_value = data.basket_value;
      br_data.order_id = data.order_id;
      br_data.basket = data.basket;
    }

    // Product specific Tracking Parameters
    if (data.prodId) {
      br_data.prod_id = data.prodId;
    }
    if (data.prodName) {
      br_data.prod_name = data.prodName;
    }

    if (data.sku) {
      br_data.sku = data.sku;
    }
    // End - Product specific Page Tracking Parameters

    // Category specific Tracking Parameters
    if (data.catId) {
      br_data.cat_id = data.catId;
    }
    if (data.cat) {
      br_data.cat = data.cat;
    }
    // End - Category page specific tracking parameters

    // Search specific Tracking Parameters
    if (data.searchTerm) {
      br_data.search_term = data.searchTerm;
    }
    // End - Search Results page specific tracking parameters

    if (data.catalogs) {
      br_data.catalogs = data.catalogs;
    }
    if (data.itemId) {
      br_data.item_id = data.itemId;
    }
    if (data.itemName) {
      br_data.item_name = data.itemName;
    }

    // Test Data (for test environments only, do not declare on live site)
    br_data.test_data = pixelConfig?.testData;

    if (isEqual(window.br_data, br_data)) {
      return;
    }

    // Set the global br_data so the Bloomreach script can pick it up.
    window.br_data = br_data;

    const isBrScriptInserted = findJsCssFile(bloomReachScriptCdnLink, "js");

    if (!isBrScriptInserted) {
      OneTrust &&
        OneTrust.InsertScript &&
        OneTrust.InsertScript(bloomReachScriptCdnLink, "head", () => {}, null, "3");
    }

    if (typeof BrTrk !== "undefined") {
      updateBrDataEvent(br_data);
    }
  } catch (error: unknown) {
    if (typeof error === "string" || error instanceof Error) {
      Logger.getLogger().error(error);
    }
  }
}
