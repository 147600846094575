"use client";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  FunctionComponent,
  PropsWithChildren,
} from "react";

import { OperationVariables, useLazyQuery } from "@apollo/client";
import { useParams, usePathname, useRouter, useSearchParams } from "next/navigation";
import { useDebounce } from "use-debounce";

import { GlobalPathParams } from "~/app/[locale]/types";
import {
  AutosuggestionsResponse,
  AutosuggestionsVariables,
} from "~/bff/transport/Autosuggestions";
import { SearchTabs } from "~/components/search-results-page/types";
import { BloomreachCatalogsAzureKeys } from "~/constants/bloomreach";
import { FUNCTIONAL_COOKIES } from "~/constants/cookie-categories";
import { DEBOUNCE_DELAY_300 } from "~/constants/delay-constants";
import { Params, Routes } from "~/constants/request";
import { LAST_SEARCHES } from "~/constants/user-cookies";
import { waitForRouterHistory } from "~/context/router-history/helper";
import { getApolloClient } from "~/graphql/client";
import { GET_AUTO_SUGGESTIONS } from "~/graphql/queries/getAutoSuggestions";
import { getBloomreachCatalogsAzureKeys } from "~/helpers/get-bloomreach-catalogs-azure-keys";
import { logBloomreachEvent } from "~/helpers/log-bloomreach-event";
import {
  BloomreachEventType,
  BloomreachGroupType,
} from "~/helpers/log-bloomreach-event/types";
import { useCookies } from "~/hooks/use-cookies";
import { Nullable } from "~/types/general.types";
import { getBrUid2Client } from "~/utils/data/bloomreach/get-br-uid2-client";
import { getRefUrlClient } from "~/utils/data/bloomreach/get-ref-url-client";

import { HeaderContext } from "./context";
import { HeaderHelpers } from "./types";

export type HeaderProviderProps = PropsWithChildren;

export const HeaderProvider: FunctionComponent<HeaderProviderProps> = ({
  children,
}) => {
  const { locale: pageLocale } = useParams<GlobalPathParams>();
  const router = useRouter();

  const searchParams = useSearchParams();
  const pathName = usePathname();

  const client = useMemo(
    () => getApolloClient({ locale: pageLocale }),
    [pageLocale],
  );
  const [query, setQuery] = useState<string>(
    () => (searchParams.get(Params.QUERY) as string) ?? "",
  );

  const queryParam = useMemo(
    () => (searchParams.get(Params.QUERY) as string) ?? "",
    [searchParams],
  );
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [queryCached] = useDebounce(query, DEBOUNCE_DELAY_300);

  const [lastSearches, setLastSearches] = useCookies<string[]>(LAST_SEARCHES, [], {
    oneTrustGroup: FUNCTIONAL_COOKIES,
  });

  const [suggestions, setSuggestions] = useState<(Nullable<string> | undefined)[]>();

  const brUid2 = getBrUid2Client(pageLocale);

  const [loadSuggestions] = useLazyQuery<
    AutosuggestionsResponse,
    AutosuggestionsVariables & OperationVariables
  >(GET_AUTO_SUGGESTIONS, {
    notifyOnNetworkStatusChange: true,
    client,
    onCompleted: (data) => {
      if (!data?.autosuggestions) {
        return;
      }
      const { autosuggestions: { queryContext, suggestionGroups = [] } = {} } = data;

      const { originalQuery } = queryContext ?? {};
      const { querySuggestions } = suggestionGroups?.[0] ?? {};
      if (queryCached.trim() === originalQuery && querySuggestions) {
        const queryList = querySuggestions?.map((suggestion) =>
          suggestion ? suggestion.query : "",
        );
        setSuggestions(queryList);
      }
    },
  });

  const handleQueryChange = useCallback((query: string) => {
    setQuery(query);
  }, []);
  const handleQueryClear = useCallback(() => {
    setQuery("");
  }, []);
  const handleSearchClick = useCallback(() => {
    logBloomreachEvent({
      group: BloomreachGroupType.suggest,
      etype: BloomreachEventType.submit,
      q: query.trim(),
      catalogs: [
        {
          name: getBloomreachCatalogsAzureKeys(
            pageLocale,
            BloomreachCatalogsAzureKeys.products,
          ),
        },
      ],
      viewId: pageLocale,
    });
    setLastSearches(
      [query.trim(), ...lastSearches]
        .filter((value, index, self) => self.indexOf(value) === index)
        .slice(0, 10),
    );

    const routerQuery = new URLSearchParams();
    routerQuery.set(Params.QUERY, query.trim());
    routerQuery.set(Params.TAB, SearchTabs.PRODUCTS);
    const preparedUrl = `/${pageLocale}${Routes.SEARCH}?${routerQuery.toString()}`;
    router.push(preparedUrl);
  }, [query, pageLocale, setLastSearches, lastSearches, router]);

  const handleSuggestionClick = useCallback(
    (query: string) => {
      const prepared = `${query ?? ""}`.trim();
      logBloomreachEvent({
        group: BloomreachGroupType.suggest,
        etype: BloomreachEventType.click,
        aq: queryCached,
        q: prepared,
        catalogs: [
          {
            name: getBloomreachCatalogsAzureKeys(
              pageLocale,
              BloomreachCatalogsAzureKeys.products,
            ),
          },
        ],
        viewId: pageLocale,
      });
      setLastSearches(
        [prepared, ...lastSearches]
          .filter((value, index, self) => self.indexOf(value) === index)
          .slice(0, 10),
      );
      setQuery(prepared);
      setIsSearchOpen(false);
      const routerQuery = new URLSearchParams();
      routerQuery.set(Params.QUERY, prepared);
      routerQuery.set(Params.TAB, SearchTabs.PRODUCTS);
      const preparedUrl = `/${pageLocale}${Routes.SEARCH}?${routerQuery.toString()}`;
      router.push(preparedUrl);
    },
    [queryCached, pageLocale, setLastSearches, lastSearches, router],
  );
  const handleMenuClose = useCallback(() => setIsMenuOpen(false), []);
  const handleSearchClose = useCallback(() => {
    setIsSearchOpen(false);
  }, []);
  const handleSearchOpen = useCallback(() => {
    setIsSearchOpen(true);
    setIsMenuOpen(false);
  }, []);
  const handleMenuOpen = useCallback(() => {
    setIsMenuOpen(true);
    setIsSearchOpen(false);
  }, []);

  const helpers = useMemo(() => {
    return {
      query: query,
      queryCached: queryCached,
      lastSearches: lastSearches,
      suggestions: suggestions,
      isMenuOpen: isMenuOpen,
      isSearchOpen: isSearchOpen,
      handleQueryChange: handleQueryChange,
      handleQueryClear: handleQueryClear,
      handleSearchClick: handleSearchClick,
      handleMenuClose: handleMenuClose,
      handleMenuOpen: handleMenuOpen,
      handleSearchClose: handleSearchClose,
      handleSearchOpen: handleSearchOpen,
      handleSuggestionClick: handleSuggestionClick,
    } as HeaderHelpers;
  }, [
    query,
    queryCached,
    lastSearches,
    suggestions,
    isMenuOpen,
    isSearchOpen,
    handleQueryChange,
    handleQueryClear,
    handleSearchClick,
    handleMenuClose,
    handleMenuOpen,
    handleSearchClose,
    handleSearchOpen,
    handleSuggestionClick,
  ]);

  // this effect will handle the query param changes from the browser. Ex: back, forward, etc.
  useEffect(() => {
    setQuery(queryParam);
  }, [queryParam]);

  useEffect(() => {
    const prepared = queryCached.trim();
    if (prepared.length >= 3) {
      waitForRouterHistory(async () => {
        await loadSuggestions({
          variables: {
            q: prepared,
            locale: pageLocale,
            brUid2,
            brRefUrl: getRefUrlClient(pageLocale),
          },
        });
      });
    } else {
      setSuggestions([]);
    }
  }, [loadSuggestions, queryCached, pageLocale, brUid2]);

  useEffect(() => {
    handleMenuClose();
    handleSearchClose();
  }, [handleMenuClose, handleSearchClose, pathName]);

  return <HeaderContext.Provider value={helpers}>{children}</HeaderContext.Provider>;
};
