import Cookies from "js-cookie";

import { BR_DEFAULT_UID, BR_USERID_KEY } from "~/constants/bloomreach";

import { AzureConfigurator } from "../../../services/azure-configurator/azure-configurator";

export const getBrUid2Client = (pageLocale: string) => {
  const isBloomreachBruid =
    AzureConfigurator.getConfig(pageLocale)?.featureFlagList?.bloomreachBruid
      ?.enabled;
  const brUid2Cookie = isBloomreachBruid ? Cookies.get(BR_USERID_KEY) : "";
  let brUid2 = BR_DEFAULT_UID;
  if (brUid2Cookie) {
    brUid2 = brUid2Cookie;
  }
  return brUid2;
};
