import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { COLOR_GREYSCALE_BLACK_8 } from "~/theme/colors";

import { OPTION_CONTENT_CLASSES } from "./constants";

export const OptionContentContainer = styled(Box)(({ theme }) => {
  return {
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [`& .${OPTION_CONTENT_CLASSES.column}`]: {
      width: "20%",
      "&:not(:first-of-type)": {
        flexGrow: 1,
      },
    },
    [`& .${OPTION_CONTENT_CLASSES.commonColumn}`]: {
      alignSelf: "start",
      borderRight: `1px solid ${COLOR_GREYSCALE_BLACK_8}`,
      "& > *:not(:last-child)": {
        marginBottom: theme.spacing(2),
      },
    },
    [`& .${OPTION_CONTENT_CLASSES.specialItem}`]: {
      marginRight: theme.spacing(3),
    },
    [`& .${OPTION_CONTENT_CLASSES.specialLinksDivider}`]: {
      marginBottom: theme.spacing(2),
    },
    [`& .${OPTION_CONTENT_CLASSES.rootLink}`]: {
      display: "flex",
      alignItems: "center",
      height: theme.spacing(4),
      margin: theme.spacing(0, 3, 2, 0),
      textTransform: "none",
    },
    [`& .${OPTION_CONTENT_CLASSES.subcategoryItem}`]: {
      display: "flex",
      alignItems: "center",
      minHeight: theme.spacing(4),
      marginLeft: theme.spacing(3),
      textTransform: "none",
      fontSize: theme.typography.body2.fontSize,
    },
    [`& .${OPTION_CONTENT_CLASSES.banner}`]: {
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(3),
      flexGrow: 1,
      height: "240px",

      "&::after": {
        width: "100%",
        paddingBottom: "0",
      },
    },
  };
});
