import { useContext, useMemo } from "react";

import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { DynamicYieldContext } from "~/context/dynamic-yield/context";
import { useAzureConfigurator } from "~/services/azure-configurator/use-azure-configurator";
import { getTestCampaignChoicesFromResponse } from "~/utils/dynamic-yield/get-campaign-choices-from-response";
import { isDyCampaignEnabled } from "~/utils/dynamic-yield/is-dy-campaign-enabled";
import { DyCampaignName } from "~/utils/dynamic-yield/types";

export const useIsGeoLocationEnabled = () => {
  const { locale: pageLocale } = useParams<GlobalPathParams>();
  const config = useAzureConfigurator(pageLocale);

  const isGeoLocationEnabledInAzure = config?.featureFlags?.geolocation?.enabled;

  const dyContext = useContext(DynamicYieldContext);

  const dyChooseResponseData = dyContext ? dyContext.chooseResponseData : null;

  const isGeoLocationTestEnabled = useMemo(
    () => isDyCampaignEnabled(DyCampaignName.Geolocation, pageLocale),
    [pageLocale],
  );

  // If 'Geolocation' AB test is disabled (at all, not per current page)
  // we should fall back to the value of 'geolocation' feature flag.
  // Otherwise - we should use the value from the AB test
  if (!isGeoLocationTestEnabled) {
    return isGeoLocationEnabledInAzure;
  }

  const dyChoiceData = getTestCampaignChoicesFromResponse(
    dyChooseResponseData,
    DyCampaignName.Geolocation,
    pageLocale,
  );

  // If 'Geolocation' AB test is disabled per current page
  // we should fall back to the 'false' value
  const geolocationData = dyChoiceData?.variations[0]?.payload?.data;
  return geolocationData?.isGeoDisplayed || false;
};
