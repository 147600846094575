import { omit } from "lodash";

import { Account } from "~/bff/types/Account";
import { BillingAddress } from "~/bff/types/BillingAddress";
import { PaymentMethod } from "~/bff/types/PaymentMethod";

export const normalizeBillingAddress = (
  billingAddresses: (BillingAddress | null)[],
): BillingAddress[] =>
  billingAddresses
    .filter<BillingAddress>(
      (el): el is BillingAddress => el !== null && el !== undefined,
    )
    .map((el) => omit(el, ["__typename"]));

export const normalizePaymentMethod = (
  paymentMethod: (PaymentMethod | null)[],
): PaymentMethod[] =>
  paymentMethod
    .filter<PaymentMethod>(
      (el): el is PaymentMethod => el !== null && el !== undefined,
    )
    .map((el) => ({
      ...omit(el, ["__typename"]),
      billingAddress: omit(el?.billingAddress, ["__typename"]),
    }));

export const normalizeAccountData = (account: Account): Account => {
  return {
    ...omit(account, [
      "uid",
      "__typename",
      "billingAddresses",
      "verifiedOptIn",
      "verifiedOptInDate",
      "verifiedOptInTrigger",
      "errors",
    ]),
    dateOfBirth: account?.dateOfBirth
      ? omit(account?.dateOfBirth, "__typename")
      : null,
    billingAddresses: normalizeBillingAddress(account?.billingAddresses ?? []),
    ...((account?.paymentMethods?.length ?? 0) > 0 && {
      paymentMethods: normalizePaymentMethod(account?.paymentMethods ?? []),
    }),
  };
};
