export const CUSTOM_LOCATION_SEARCH_PREFIX = "CustomLocationSearch";

export const CUSTOM_LOCATION_SEARCH_CLASSES = {
  header: `${CUSTOM_LOCATION_SEARCH_PREFIX}-header`,
  label: `${CUSTOM_LOCATION_SEARCH_PREFIX}-label`,
  control: `${CUSTOM_LOCATION_SEARCH_PREFIX}-control`,
  backdrop: `${CUSTOM_LOCATION_SEARCH_PREFIX}-backdrop`,
  searchAdornment: `${CUSTOM_LOCATION_SEARCH_PREFIX}-searchAdornment`,
  searchButton: `${CUSTOM_LOCATION_SEARCH_PREFIX}-searchButton`,
  searchBox: `${CUSTOM_LOCATION_SEARCH_PREFIX}-searchBox`,
  googleLogo: `${CUSTOM_LOCATION_SEARCH_PREFIX}-googleLogo`,
  loqateLogo: `${CUSTOM_LOCATION_SEARCH_PREFIX}-loqateLogo`,
};

export const SS_LISTBOX_ID = "store-selector-search-box";
