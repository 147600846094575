export const dynamicYieldMetaLinks = [
  "//cdn-eu.dynamicyield.com",
  "//st-eu.dynamicyield.com",
  "//rcom-eu.dynamicyield.com",
];

export const preconnectClassName = "dy-switchable-preconnect";
export const prefetchClassName = "dy-switchable-prefetch";
export const preconnectLinkType = "preconnect";
export const prefetchLinkType = "dns-prefetch";
