import { ApolloQueryResult, QueryOptions } from "@apollo/client";

import { LOCALES } from "~/constants/i18n";
import { getApolloClient } from "~/graphql/client";

export const executeGrapQlQuery = async <Result>(
  locale: LOCALES,
  options: QueryOptions,
): Promise<ApolloQueryResult<Result>> => {
  const result = await getApolloClient({ locale }).query<Result>(options);
  if (result.errors?.length) {
    throw result.errors[0];
  }

  if (result.error) {
    throw result.error;
  }

  return result as ApolloQueryResult<Result>;
};
