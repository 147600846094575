import { StoreDetailsPageResponse } from "~/bff/transport/StoreDetailsPage";
import { LOCALES } from "~/constants/i18n";
import { getApolloClient } from "~/graphql/client";
import { GET_STORE_COLLECTION_DATES } from "~/graphql/queries/getStoreCollectionDates";
import { Logger } from "~/utils/logger";

export const getStoreCollectionDates = async (
  locale: LOCALES,
  country: string,
  storeId: string,
): Promise<StoreDetailsPageResponse> => {
  try {
    const { data } = await getApolloClient({
      locale,
    }).query<StoreDetailsPageResponse>({
      query: GET_STORE_COLLECTION_DATES,
      variables: {
        locale,
        storeId,
        country,
      },
      fetchPolicy: "no-cache",
    });
    return data;
  } catch (error) {
    Logger.getLogger().error(String(error));
    throw error;
  }
};
