import React from "react";

import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

export const SuggestionContainer = styled(
  React.forwardRef<HTMLLIElement, MenuItemProps>(function SuggestionContainer(
    { ...restProps },
    ref,
  ) {
    return (
      <MenuItem ref={ref} {...restProps} role="option" /> //NOSONAR ARIA is acceptable to be used here
    );
  }),
)(({ theme }) => {
  return {
    ...theme.typography.body1,
    marginTop: theme.spacing(1.1),
    paddingTop: 0,
    paddingBottom: 0,
    lineHeight: "24px",
    cursor: "pointer",
    whiteSpace: "pre",
    "&:first-of-type": {
      marginTop: theme.spacing(0.6),
    },
  };
});
