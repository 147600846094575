"use client";

import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useState,
} from "react";

import { ClickAndCollectInterrupterPanelContext } from "./context";

export type ClickAndCollectInterrupterPanelProviderProps = PropsWithChildren<{
  isDefaultOpen?: boolean;
}>;

export const ClickAndCollectInterrupterPanelProvider: FunctionComponent<
  ClickAndCollectInterrupterPanelProviderProps
> = ({ children, isDefaultOpen }) => {
  const [isOpen, setIsOpen] = useState<boolean>(Boolean(isDefaultOpen));
  const [targetLink, setTargetLink] = useState<string>();

  const toggleClickAndCollectInterrupter = useCallback(
    (open: boolean, url?: string) => {
      setIsOpen(open);
      if (url) {
        setTargetLink(url);
      }
    },
    [],
  );

  return (
    <ClickAndCollectInterrupterPanelContext.Provider
      value={{ isOpen, targetLink, toggleClickAndCollectInterrupter }}
    >
      {children}
    </ClickAndCollectInterrupterPanelContext.Provider>
  );
};
