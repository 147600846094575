"use client";

import * as React from "react";

import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { GEO_SEARCH_ERROR_POPUP_CLASSES } from "~/components/pop-up/components/geo-search-error-pop-up/constants";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { Close24 } from "~/theme/icons/components/close";

import { GeoSearchErrorPopUpDialog } from "./styled";
import type { GeoSearchErrorPopUpProps } from "./types";

export const GeoSearchErrorPopUp = (
  props: GeoSearchErrorPopUpProps,
): React.ReactElement => {
  const { open, title, description, handleClose, openStoreSelector } = props;

  const handleClick = () => {
    handleClose && handleClose();
    openStoreSelector && openStoreSelector();
  };

  return (
    <GeoSearchErrorPopUpDialog
      open={open}
      PaperProps={{
        classes: { root: GEO_SEARCH_ERROR_POPUP_CLASSES.root },
      }}
      onClose={handleClick}
      {...getTestAutomationProps("pop-up")}
      fullWidth
    >
      <DialogTitle className={GEO_SEARCH_ERROR_POPUP_CLASSES.titleBlock}>
        <Box>
          <Typography
            variant="h5"
            className={GEO_SEARCH_ERROR_POPUP_CLASSES.titleText}
          >
            {title}
          </Typography>
          <IconButton
            onClick={handleClick}
            className={GEO_SEARCH_ERROR_POPUP_CLASSES.closeButton}
            {...getTestAutomationProps("close-button")}
          >
            <Close24 />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={GEO_SEARCH_ERROR_POPUP_CLASSES.descriptionBlock}>
        <DialogContentText
          className={GEO_SEARCH_ERROR_POPUP_CLASSES.descriptionText}
          {...getTestAutomationProps("description")}
        >
          {description}
        </DialogContentText>
      </DialogContent>
    </GeoSearchErrorPopUpDialog>
  );
};
