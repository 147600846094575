import React from "react";

import {
  COLOR_DISABLED_BACKGROUND,
  COLOR_ERROR,
  COLOR_SUCCESS,
  COLOR_WARNING,
  COLOR_NOT_RANGED,
  COLOR_DISABLED_ICON,
} from "~/theme/colors";
import { Available24 } from "~/theme/icons/components/available";
import { NotAvailable24 } from "~/theme/icons/components/not-available";
import { NotRanged24 } from "~/theme/icons/components/not-ranged";
import { OutOfTime24 } from "~/theme/icons/components/out-of-time";
import { PriorityHigh24 } from "~/theme/icons/components/priority-high";

export enum SalesChannelKey {
  notSoldInStore = "Click and Collect Only",
  storeOnly = "Store Only",
  availableEverywhere = "Available for Click and Collect and Store",
}

export enum AvailabilityStatusKey {
  availableInStore = "IN_STOCK",
  lowInStore = "LOW_STOCK",
  soldOutInStore = "OUT_OF_STOCK",
  soldOutCompletely = "soldOutCompletely",
  notAvailableInStore = "NOT_RANGED",
  noLongerAvailable = "noLongerAvailable",
  sizeNotSelected = "",
}

export enum ClickAndCollectStatusKey {
  availableInStore = "AVAILABLE",
  notAvailableInStore = "NOT_AVAILABLE",
  soldOut = "SOLD_OUT",
  sizeNotSelected = "SIZE_NOT_SELECTED",
  empty = "",
}

interface AvailabilityStatusData {
  [key: string]: {
    icon: JSX.Element;
    iconColor: string;
    textColor: string;
    background: string;
  };
}

export const AvailabilityStatusData: AvailabilityStatusData = {
  [AvailabilityStatusKey.availableInStore]: {
    icon: <Available24 />,
    iconColor: COLOR_SUCCESS,
    textColor: COLOR_SUCCESS,
    background: COLOR_DISABLED_BACKGROUND,
  },
  [AvailabilityStatusKey.lowInStore]: {
    icon: <OutOfTime24 />,
    iconColor: COLOR_WARNING,
    textColor: COLOR_WARNING,
    background: COLOR_DISABLED_BACKGROUND,
  },
  [AvailabilityStatusKey.soldOutInStore]: {
    icon: <NotAvailable24 />,
    iconColor: COLOR_ERROR,
    textColor: COLOR_ERROR,
    background: COLOR_DISABLED_BACKGROUND,
  },
  [AvailabilityStatusKey.soldOutCompletely]: {
    icon: <NotRanged24 />,
    iconColor: COLOR_DISABLED_ICON,
    textColor: COLOR_NOT_RANGED,
    background: COLOR_DISABLED_BACKGROUND,
  },
  [AvailabilityStatusKey.notAvailableInStore]: {
    icon: <NotRanged24 />,
    iconColor: COLOR_DISABLED_ICON,
    textColor: COLOR_NOT_RANGED,
    background: COLOR_DISABLED_BACKGROUND,
  },
  [AvailabilityStatusKey.sizeNotSelected]: {
    icon: <PriorityHigh24 />,
    iconColor: COLOR_DISABLED_ICON,
    textColor: COLOR_NOT_RANGED,
    background: COLOR_DISABLED_BACKGROUND,
  },
  [SalesChannelKey.notSoldInStore]: {
    icon: <NotRanged24 />,
    iconColor: COLOR_DISABLED_ICON,
    textColor: COLOR_NOT_RANGED,
    background: COLOR_DISABLED_BACKGROUND,
  },
};

export const clickAndCollectStatusData = {
  [ClickAndCollectStatusKey.availableInStore]: {
    icon: <Available24 />,
    iconColor: COLOR_SUCCESS,
  },
  [ClickAndCollectStatusKey.soldOut]: {
    icon: <NotAvailable24 />,
    iconColor: COLOR_ERROR,
  },
  [ClickAndCollectStatusKey.notAvailableInStore]: {
    icon: <NotRanged24 />,
    iconColor: COLOR_DISABLED_ICON,
  },
  [ClickAndCollectStatusKey.sizeNotSelected]: {
    icon: <PriorityHigh24 />,
    iconColor: COLOR_DISABLED_ICON,
    textColor: COLOR_NOT_RANGED,
    background: COLOR_DISABLED_BACKGROUND,
  },
  [ClickAndCollectStatusKey.empty]: {
    icon: "",
    iconColor: "",
  },
  [SalesChannelKey.storeOnly]: {
    icon: <NotRanged24 />,
    iconColor: COLOR_DISABLED_ICON,
    textColor: COLOR_NOT_RANGED,
    background: COLOR_DISABLED_BACKGROUND,
  },
};
