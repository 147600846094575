"use client";

import React from "react";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { Variant } from "@mui/material/styles/createTypography";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { kebabCase } from "lodash";

import { ImageCommon } from "~/bff/types/ImageCommon";
import { prepareTextAttribute } from "~/helpers/prepare-text-attribute";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { Nullable } from "~/types/general.types";
import { getImageSrc } from "~/utils/image/get-image-src";
import { getImageSrcSetWithAmplienceTemplates } from "~/utils/image/get-image-src-set-with-amplience-templates";

import { ArrowIcon } from "./components/arrow-icon/component";
import { imageTemplates, MENU_ITEM_CLASSES } from "./constants";
import { MenuItemContainer } from "./styled";

export interface MenuItemProps {
  id?: string;
  className?: string;
  image?: Nullable<ImageCommon>;
  label: Nullable<string>;
  onOpen?: () => void;
  variant?: Variant | "inherit";
  showArrow?: boolean;
  hideEmptyIcon?: boolean;
  as?: "div" | "button";
  "aria-label"?: string;
}

export const MenuItem: React.FC<MenuItemProps> = (props) => {
  const {
    id,
    className,
    image,
    label,
    onOpen,
    variant = "body1",
    showArrow = false,
    hideEmptyIcon = false,
    as: asBaseComponent = "div",
    "aria-label": ariaLabel,
  } = props;

  const imageSrc = getImageSrc(image?.source);

  const labelId = `label-${kebabCase(label?.toLowerCase())}`;
  const ariaLabelId = asBaseComponent === "button" ? labelId : undefined;

  return (
    <MenuItemContainer
      id={id}
      className={clsx(
        { [MENU_ITEM_CLASSES.buttonRoot]: asBaseComponent === "button" },
        className,
      )}
      display="block"
      onClick={onOpen}
      variant={variant}
      as={asBaseComponent}
      {...getTestAutomationProps("menu-item")}
      aria-label={ariaLabel}
    >
      <Box className={MENU_ITEM_CLASSES.imageBox}>
        {image?.source ? (
          <Avatar
            alt={prepareTextAttribute(image?.alt, true)}
            title={prepareTextAttribute(image?.title ?? image?.alt)}
            src={imageSrc}
            srcSet={
              getImageSrcSetWithAmplienceTemplates(imageSrc, imageTemplates) || ""
            }
          />
        ) : (
          !hideEmptyIcon && <Avatar>?</Avatar>
        )}
      </Box>
      <Typography id={ariaLabelId} className={MENU_ITEM_CLASSES.label}>
        {label}
      </Typography>
      {showArrow && (
        <ArrowIcon
          className={MENU_ITEM_CLASSES.arrow}
          asButton={asBaseComponent === "div"}
          label={ariaLabelId}
        />
      )}
    </MenuItemContainer>
  );
};
