"use client";

import React, { useCallback } from "react";

import { usePathname, useRouter } from "next/navigation";

import { CombinedButton } from "~/components/combined-button/component";
import { Routes } from "~/constants/request";
import { removeLocaleFromPath } from "~/helpers/get-page-categories-titles";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { Favorite24 } from "~/theme/icons/components/favorite";

interface ShoppingListButtonProps {
  className?: string;
}

export const ShoppingListButton: React.FC<ShoppingListButtonProps> = ({
  className,
}) => {
  const router = useRouter();
  const pathName = usePathname();
  const isShoppingListPage = removeLocaleFromPath(pathName) === Routes.SHOPPING_LIST;

  const handleClick = useCallback(() => {
    if (isShoppingListPage) {
      router.refresh();
    }
  }, [isShoppingListPage, router]);

  return (
    <CombinedButton
      href={Routes.SHOPPING_LIST}
      aria-label="shopping list"
      className={className}
      onClick={handleClick}
      {...getTestAutomationProps("shopping-list-link")}
      id="shopping-list-link"
    >
      <Favorite24 />
    </CombinedButton>
  );
};
