import { gql } from "@apollo/client";

export const GET_STORES_WITH_INVENTORY = gql`
  query StoresListWithInventory(
    $locale: String!
    $sku: String!
    $limit: Int
    $offset: Int
  ) {
    storesListWithInventory(
      locale: $locale
      sku: $sku
      limit: $limit
      offset: $offset
    ) {
      count
      stores {
        id
        trial
        name
        geomodifier
        address {
          line1
          line2
          city
          postalCode
          countryCode
          region
        }
        distance {
          distanceMiles
          distanceKilometers
        }
        clickAndCollectStore
        inventoryBySku {
          available
          clickAndCollectStore
          clickAndCollectStock
          clickAndCollectAvailableQuantity
          salesChannel
        }
        storeLimits {
          minOrderPrice
          maxOrderPrice
          maxLineItemQuantity
        }
        additionalHoursText
        timeZoneUtcOffset
        timezone
        facilities
        hours {
          monday {
            openIntervals {
              start
              end
            }
            isClosed
            date
          }
          tuesday {
            openIntervals {
              end
              start
            }
            isClosed
            date
          }
          wednesday {
            openIntervals {
              end
              start
            }
            isClosed
            date
          }
          thursday {
            openIntervals {
              end
              start
            }
            isClosed
            date
          }
          friday {
            openIntervals {
              end
              start
            }
            isClosed
            date
          }
          saturday {
            openIntervals {
              end
              start
            }
            isClosed
            date
          }
          sunday {
            openIntervals {
              end
              start
            }
            isClosed
            date
          }
        }
        holidayHours {
          openIntervals {
            end
            start
          }
          isClosed
          date
        }
      }
    }
  }
`;

export const GET_STORES_WITH_INVENTORY_FOR_SEARCH = gql`
  query StoresAvailabilityForSearch(
    $sku: String!
    $locale: String!
    $latitude: Float
    $longitude: Float
    $radius: Int
  ) {
    geosearchWithInventory(
      sku: $sku
      locale: $locale
      latitude: $latitude
      longitude: $longitude
      radius: $radius
    ) {
      stores {
        id
        trial
        name
        geomodifier
        address {
          line1
          line2
          city
          postalCode
          countryCode
          region
        }
        distance {
          distanceMiles
          distanceKilometers
        }
        clickAndCollectStore
        inventoryBySku {
          available
          clickAndCollectStore
          clickAndCollectStock
          clickAndCollectAvailableQuantity
          salesChannel
        }
        timeZoneUtcOffset
        timezone
        facilities
        hours {
          monday {
            openIntervals {
              start
              end
            }
            isClosed
            date
          }
          tuesday {
            openIntervals {
              end
              start
            }
            isClosed
            date
          }
          wednesday {
            openIntervals {
              end
              start
            }
            isClosed
            date
          }
          thursday {
            openIntervals {
              end
              start
            }
            isClosed
            date
          }
          friday {
            openIntervals {
              end
              start
            }
            isClosed
            date
          }
          saturday {
            openIntervals {
              end
              start
            }
            isClosed
            date
          }
          sunday {
            openIntervals {
              end
              start
            }
            isClosed
            date
          }
        }
        holidayHours {
          openIntervals {
            end
            start
          }
          isClosed
          date
        }
      }
    }
  }
`;
