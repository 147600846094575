const AGEC_BANNER_CLASSES_PREFIX = "AgecBanner";

export const AGEC_BANNER_CLASSES = {
  list: `${AGEC_BANNER_CLASSES_PREFIX}-list`,
  textLink: `${AGEC_BANNER_CLASSES_PREFIX}-textLink`,
  containerLink: `${AGEC_BANNER_CLASSES_PREFIX}-containerLink`,
  title: `${AGEC_BANNER_CLASSES_PREFIX}-title`,
  expanded: `${AGEC_BANNER_CLASSES_PREFIX}-expanded`,
  showMoreButtonContainer: `${AGEC_BANNER_CLASSES_PREFIX}-show-more-button-container`,
  showMoreButton: `${AGEC_BANNER_CLASSES_PREFIX}-show-more`,
  truncatedContent: `${AGEC_BANNER_CLASSES_PREFIX}-truncated-content`,
} as const;

export const AGEC_BANNER_LINK =
  "https://www.economie.gouv.fr/dgccrf/laction-de-la-dgccrf/injonctions-et-sanctions";
export const AGEC_BANNER_STORAGE_KEY = "wasAgecBannerShownToUser";
