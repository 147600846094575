"use client";

import * as React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useParams, usePathname } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { Image } from "~/components/image/component";
import { getCurrencyCodeByLocale } from "~/constants/i18n";
import { RouteBase } from "~/constants/request";
import { prepareTextAttribute } from "~/helpers/prepare-text-attribute";
import { useStoreSelectorData } from "~/hooks/store-selector/use-store-selector-data";
import { formatCurrency } from "~/utils/currency/format-currency";

import { NavigationButtonsGroup } from "./components/navigation-buttons-group/component";
import { ADDED_TO_COLLECT_BAG_POPUP_CLASSES, imageTemplates } from "./constants";
import { AddedToCollectBagPopUpContainer } from "./styled";
import type { AddedToCollectBagPopUpProps } from "./types";

export const AddedToCollectBagPopUp = (
  props: AddedToCollectBagPopUpProps,
): React.ReactElement | null => {
  const {
    className,
    translations: {
      title,
      colorLabel,
      sizeLabel,
      totalOrder,
      orderAmountMet,
      orderAmountNotMet,
      checkoutCTA,
      viewBagCTA,
    },
    product,
    totalOrderPrice,
  } = props;

  const storeSelectorData = useStoreSelectorData(null);

  const minOrderPrice = storeSelectorData?.currentStore?.storeLimits?.minOrderPrice;

  const isOrderAmountMet = React.useMemo(
    () => Number(totalOrderPrice?.replace(/[^\d.-]/g, "")) >= Number(minOrderPrice),
    [minOrderPrice, totalOrderPrice],
  );

  const { locale } = useParams<GlobalPathParams>();
  const pathname = usePathname();

  const checkIfPdp = pathname?.includes(RouteBase.PDP);

  const minPriceWithCurrency = formatCurrency(
    locale,
    getCurrencyCodeByLocale(locale),
    Number(minOrderPrice),
    0,
  );

  return checkIfPdp && minOrderPrice ? (
    <AddedToCollectBagPopUpContainer
      isOrderAmountMet={isOrderAmountMet}
      className={className}
    >
      <Typography variant="h5">{title}</Typography>
      <Box className={ADDED_TO_COLLECT_BAG_POPUP_CLASSES.infoContainer}>
        <Image
          alt={prepareTextAttribute(title, true)}
          title={prepareTextAttribute(title)}
          width="100%"
          height="100%"
          src={product.images?.[0]?.url ?? "/assets/images/no-image.png"}
          imageTemplates={imageTemplates}
          loading="lazy"
          className={ADDED_TO_COLLECT_BAG_POPUP_CLASSES.productImage}
        />
        <Box className={ADDED_TO_COLLECT_BAG_POPUP_CLASSES.infoTextContainer}>
          <Typography>{product.name}</Typography>
          <Typography className={ADDED_TO_COLLECT_BAG_POPUP_CLASSES.price}>
            {/* Noncompliant: Use rawPrice instead */}
            {product?.price}
          </Typography>
          <Typography variant="body2">{`${colorLabel}: ${product?.displayColor}`}</Typography>
          <Typography variant="body2">{`${sizeLabel}: ${product?.displaySize}`}</Typography>
        </Box>
      </Box>
      <Box className={ADDED_TO_COLLECT_BAG_POPUP_CLASSES.totalOrderContainer}>
        <Typography className={ADDED_TO_COLLECT_BAG_POPUP_CLASSES.totalPrice}>
          {totalOrder}
        </Typography>
        <Typography className={ADDED_TO_COLLECT_BAG_POPUP_CLASSES.totalPrice}>
          {totalOrderPrice}
        </Typography>
      </Box>
      <NavigationButtonsGroup
        className={ADDED_TO_COLLECT_BAG_POPUP_CLASSES.buttonGroup}
        checkoutCTA={checkoutCTA}
        viewBagCTA={viewBagCTA}
      />
      <Box className={ADDED_TO_COLLECT_BAG_POPUP_CLASSES.orderAmountMetContainer}>
        <Typography variant="body2">
          {`${minPriceWithCurrency} ${
            isOrderAmountMet ? orderAmountMet : orderAmountNotMet
          }`}
        </Typography>
      </Box>
    </AddedToCollectBagPopUpContainer>
  ) : null;
};
