export const GEO_SEARCH_ERROR_POPUP_PREFIX = "GeoSearchErrorPopUp";

export const GEO_SEARCH_ERROR_POPUP_CLASSES = {
  root: `${GEO_SEARCH_ERROR_POPUP_PREFIX}-root`,
  titleBlock: `${GEO_SEARCH_ERROR_POPUP_PREFIX}-titleBlock`,
  titleText: `${GEO_SEARCH_ERROR_POPUP_PREFIX}-titleText`,
  descriptionBlock: `${GEO_SEARCH_ERROR_POPUP_PREFIX}-descriptionBlock`,
  descriptionText: `${GEO_SEARCH_ERROR_POPUP_PREFIX}-descriptionText`,
  closeButton: `${GEO_SEARCH_ERROR_POPUP_PREFIX}-closeButton`,
};
