import React, {
  PropsWithChildren,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from "react";

import { QueryResult, useLazyQuery, useMutation } from "@apollo/client";
import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import Cookies from "js-cookie";
import { useParams, usePathname, useRouter, useSearchParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { AccountResponse, AccountVariables } from "~/bff/transport/Account";
import {
  ShoppingBagResponse,
  ShoppingBagVariables,
} from "~/bff/transport/ShoppingBag";
import { ShoppingListProduct } from "~/bff/types/ShoppingListProduct";
import { getCurrencyCodeByLocale } from "~/constants/i18n";
import { SHOPPING_BAG_MERGING_POPUP, SHOPPING_BAG_MERGING_POPUP_TYPE } from "~/constants/local-storage";
import { Routes, Variables } from "~/constants/request";
import { SELECTED_STORE } from "~/constants/user-cookies";
import { getApolloClient } from "~/graphql/client";
import {
  ADD_ABANDONED_PRODUCTS_TO_SHOPPING_BAG,
  ADD_PRODUCT_TO_SHOPPING_BAG,
  GET_USER_SHOPPING_BAG,
  GET_USER_SHOPPING_BAG_WITHOUT_VALIDATION,
  MOVE_ITEM_FROM_SHOPPING_BAG_TO_SHOPPING_LIST,
  REMOVE_SHOPPING_BAG_ITEM,
  REPLACE_SHOPPING_BAG_ITEM,
  UPDATE_SHOPPING_BAG_LOCATION_ID,
} from "~/graphql/queries/shoppingBagQueries";
import { getSelectedOrPreferredStoreId } from "~/helpers/selected-store/get-selected-or-preferred-store-id";
import { updateSelectedStoreToAccount } from "~/helpers/selected-store/update-selected-store-to-account";
import { useLoadAccountLazyQuery } from "~/hooks/use-load-account-lazy-query";
import { useLocalStorage } from "~/hooks/use-local-storage/hook";
import { useToken } from "~/hooks/use-token";
import { useUserAccount } from "~/hooks/use-user-account";
import { Evergage } from "~/lib/salesforce/evergage";
import { getChangeCartUrlEventData } from "~/lib/salesforce/evergage/helpers";
import { useAzureConfigurator } from "~/services/azure-configurator/use-azure-configurator";
import { Logger } from "~/utils/logger";

import { PageType } from "__generated__/globalTypes";

import { useVariable } from "../variables/hooks/use-variable";

import { ShoppingBagContext, ShoppingBagContextData } from "./context";
import { UserShoppingBag } from "./types";

const POP_UP_SHOW_TIME = 5500;

export const ShoppingBagProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { locale } = useParams<GlobalPathParams>();
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathName = usePathname();
  const isAuthenticated = useIsAuthenticated();
  const { inProgress: authProgressStatus } = useMsal();
  const { extractToken } = useToken(locale);
  const [userShoppingBag, setUserShoppingBag] = useState<UserShoppingBag>({});
  const { updateUserAccount } = useUserAccount(locale);
  const config = useAzureConfigurator(locale);
  const pageType = useVariable(Variables.PAGE_TYPE);
  const isPdpPage = pageType === PageType.Pdp;

  const [initialUserShoppingBag, setInitialUserShoppingBag] =
    useState<UserShoppingBag>({});

  const [, setShoppingBagMergingPopupType] = useLocalStorage<SHOPPING_BAG_MERGING_POPUP_TYPE | null>(
    SHOPPING_BAG_MERGING_POPUP,
    null,
  );

  const [shouldShowAddToCartPopUp, setShowAddToCartPopUp] = useState(false);
  const [shouldBlockAddToBagPopUp, setShowBlockAddToBagPopUp] = useState(false);
  const [latestAddedSku, setLatestAddedSku] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const shoppingBagSkuMap = useMemo(() => {
    const map = new Map();
    initialUserShoppingBag?.products?.forEach(
      (product: ShoppingListProduct | null) => map.set(product?.sku, product),
    );
    return map;
  }, [initialUserShoppingBag?.products]);

  const handleSetUserShoppingBag = useCallback(
    (shoppingBag: UserShoppingBag) => {
      if (shoppingBag) {
        setUserShoppingBag(shoppingBag);
        setInitialUserShoppingBag(shoppingBag);
      }
    },
    [setUserShoppingBag],
  );

  const client = useMemo(() => getApolloClient({ locale }), [locale]);

  const fetchAuthToken = useCallback(async () => {
    if (isAuthenticated) {
      try {
        return await extractToken();
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error : new Error(String(error));

        Logger.getLogger().error(errorMessage);
        return null;
      }
    } else {
      return null;
    }
  }, [isAuthenticated, extractToken]);

  const [getAccount] = useLoadAccountLazyQuery(locale);

  const [getShoppingBag] = useLazyQuery<ShoppingBagResponse, ShoppingBagVariables>(
    GET_USER_SHOPPING_BAG,
    {
      notifyOnNetworkStatusChange: true,
      client,
      onCompleted: ({ shoppingBag }) => {
        setIsLoading(false);
        if (shoppingBag) {
          setUserShoppingBag(shoppingBag);
          setInitialUserShoppingBag(shoppingBag);
        }
      },
      onError: (e) => {
        setIsLoading(false);
        Logger.getLogger().error(e);
      },
    },
  );

  const [getInitialShoppingBag] = useLazyQuery<
    ShoppingBagResponse,
    ShoppingBagVariables
  >(GET_USER_SHOPPING_BAG_WITHOUT_VALIDATION, {
    notifyOnNetworkStatusChange: true,
    client,
    onCompleted: ({ shoppingBag }) => {
      setIsLoading(false);
      if (shoppingBag) {
        setInitialUserShoppingBag(shoppingBag);
      }
    },
    onError: (e) => {
      setIsLoading(false);
      Logger.getLogger().error(e);
    },
  });

  const [addProductToShoppingBag] = useMutation(ADD_PRODUCT_TO_SHOPPING_BAG, {
    notifyOnNetworkStatusChange: true,
    client,
    onCompleted: ({ addShoppingBagItem: shoppingBag }) => {
      if (shoppingBag) {
        setIsLoading(false);
        setUserShoppingBag(shoppingBag);
        setInitialUserShoppingBag(shoppingBag);
        if (!shouldBlockAddToBagPopUp) {
          setShowAddToCartPopUp(true);
          setTimeout(() => setShowAddToCartPopUp(false), POP_UP_SHOW_TIME);
        }
      }
      Evergage.sendEvent(locale, getChangeCartUrlEventData(shoppingBag, locale));
    },
    onError: (e) => {
      setIsLoading(false);
      Logger.getLogger().error(e);
    },
  });

  const [addAbandonedProductsToShoppingBag] = useMutation(
    ADD_ABANDONED_PRODUCTS_TO_SHOPPING_BAG,
    {
      notifyOnNetworkStatusChange: true,
      client,
      onCompleted: ({ addShoppingBagAbandonedItems: shoppingBag }) => {
        Evergage.sendEvent(locale, getChangeCartUrlEventData(shoppingBag, locale));

        if (shoppingBag.isMergedAbandonedList) {
          setShoppingBagMergingPopupType(SHOPPING_BAG_MERGING_POPUP_TYPE.ABANDONED);
        }

        router.replace(pathName);
      },
      onError: (e) => {
        Logger.getLogger().error(e);
      },
    },
  );

  const [replaceProductInShoppingBag] = useMutation(REPLACE_SHOPPING_BAG_ITEM, {
    notifyOnNetworkStatusChange: true,
    client,
    onCompleted: ({ replaceShoppingBagItem: shoppingBag }) => {
      if (shoppingBag) {
        setIsLoading(false);
        setUserShoppingBag(shoppingBag);
        setInitialUserShoppingBag(shoppingBag);
      }
      Evergage.sendEvent(locale, getChangeCartUrlEventData(shoppingBag, locale));
    },
    onError: (e) => {
      setIsLoading(false);
      Logger.getLogger().error(e);
    },
  });

  const [moveItemFromShoppingBagToShoppingList] = useMutation(
    MOVE_ITEM_FROM_SHOPPING_BAG_TO_SHOPPING_LIST,
    {
      notifyOnNetworkStatusChange: true,
      client,
      onCompleted: ({ moveItemFromShoppingBagToShoppingList: shoppingBag }) => {
        setIsLoading(false);
        setUserShoppingBag(shoppingBag || {});
        setInitialUserShoppingBag(shoppingBag || {});
        Evergage.sendEvent(locale, getChangeCartUrlEventData(shoppingBag, locale));
      },
      onError: (e) => {
        setIsLoading(false);
        Logger.getLogger().error(e);
      },
    },
  );

  const [removeProductFromShoppingBag] = useMutation(REMOVE_SHOPPING_BAG_ITEM, {
    notifyOnNetworkStatusChange: true,
    client,
    onCompleted: ({ removeShoppingBagItem: shoppingBag }) => {
      setIsLoading(false);
      setUserShoppingBag(shoppingBag || {});
      setInitialUserShoppingBag(shoppingBag || {});
      Evergage.sendEvent(locale, getChangeCartUrlEventData(shoppingBag, locale));
    },
    onError: (e) => {
      setIsLoading(false);
      Logger.getLogger().error(e);
    },
  });

  const [updateShoppingBagLocationId, { loading: updateLocationIsLoading }] =
    useMutation(UPDATE_SHOPPING_BAG_LOCATION_ID, {
      notifyOnNetworkStatusChange: true,
      client,
      onCompleted: ({ updateShoppingBagLocationId: shoppingBag }) => {
        if (shoppingBag) {
          setIsLoading(false);
          setUserShoppingBag(shoppingBag);
          setInitialUserShoppingBag(shoppingBag);
        }
        Evergage.sendEvent(locale, getChangeCartUrlEventData(shoppingBag, locale));
      },
      onError: (e) => {
        setIsLoading(false);
        Logger.getLogger().error(e);
      },
    });

  const handleAddProductToShoppingBag = useCallback<
    NonNullable<ShoppingBagContextData["handleAddProductToShoppingBag"]>
  >(
    async (sku, storeId, blockPopUp = false) => {
      const token = await fetchAuthToken();
      setLatestAddedSku(sku ?? null);
      setIsLoading(true);

      if (blockPopUp) {
        setShowBlockAddToBagPopUp(true);
      }

      await addProductToShoppingBag({
        variables: {
          locale,
          currencyCode: getCurrencyCodeByLocale(locale),
          sku,
          shoppingBagId: userShoppingBag.id,
          version: userShoppingBag?.version,
          quantity: 1,
          collectionStoreId: storeId,
        },
        ...(token && {
          context: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        }),
      });
    },
    [addProductToShoppingBag, fetchAuthToken, locale, userShoppingBag],
  );

  const handleAddAbandonedProductsToShoppingBag = useCallback(async () => {
    const abandonedCart = searchParams.get("abandonedCart");
    const collectionStoreId = searchParams.get("collectionStoreId");
    const token = await fetchAuthToken();

    const items = abandonedCart
      ?.split(",")
      .reduce<{ sku: string; quantity: number }[]>((acc, value) => {
        const [sku, quantity] = value.split(":");

        acc.push({ sku, quantity: Number(quantity) });

        return acc;
      }, []);

    let account: QueryResult<AccountResponse, AccountVariables> | null = null;

    if (token) {
      account = await getAccount({
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        variables: {
          locale,
        },
      });
    }

    const currentCollectionStoreId =
      getSelectedOrPreferredStoreId(account?.data?.account) ||
      (collectionStoreId as string);

    updateSelectedStoreToAccount(
      account?.data?.account,
      currentCollectionStoreId,
      token,
      updateUserAccount,
    );

    addAbandonedProductsToShoppingBag({
      variables: {
        locale,
        items,
        version: userShoppingBag?.version,
        shoppingBagId: userShoppingBag.id,
        currencyCode: getCurrencyCodeByLocale(locale),
        collectionStoreId: currentCollectionStoreId,
      },
      ...(token && {
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      }),
    });
  }, [
    locale,
    searchParams,
    userShoppingBag,
    getAccount,
    fetchAuthToken,
    updateUserAccount,
    addAbandonedProductsToShoppingBag,
  ]);

  const handleReplaceProductInShoppingBag = useCallback(
    async (sku: string, productId: string, addedAt: string, quantity: number) => {
      const token = await fetchAuthToken();
      setIsLoading(true);
      replaceProductInShoppingBag({
        variables: {
          locale,
          currencyCode: getCurrencyCodeByLocale(locale),
          shoppingBagId: userShoppingBag?.id,
          removableItemId: productId,
          sku,
          addedAt,
          quantity,
        },
        ...(token && {
          context: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        }),
      });
    },
    [fetchAuthToken, locale, replaceProductInShoppingBag, userShoppingBag?.id],
  );

  const handleRemoveProductFromShoppingBag = useCallback(
    async (productId: string) => {
      const token = await fetchAuthToken();
      await removeProductFromShoppingBag({
        variables: {
          locale,
          currencyCode: getCurrencyCodeByLocale(locale),
          shoppingBagId: userShoppingBag?.id,
          removableItemId: productId,
        },
        ...(token && {
          context: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        }),
      });
    },
    [fetchAuthToken, locale, removeProductFromShoppingBag, userShoppingBag?.id],
  );

  const handleMoveProductToShoppingList = useCallback(
    async (
      sku: string,
      productId: string,
      addedAt: string,
      shoppingListId: string,
    ) => {
      const token = await fetchAuthToken();
      return moveItemFromShoppingBagToShoppingList({
        variables: {
          locale,
          currencyCode: getCurrencyCodeByLocale(locale),
          shoppingBagId: userShoppingBag?.id,
          removableItemId: productId,
          shoppingListId,
          sku,
          addedAt,
        },
        ...(token && {
          context: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        }),
      });
    },
    [
      fetchAuthToken,
      locale,
      moveItemFromShoppingBagToShoppingList,
      userShoppingBag?.id,
    ],
  );

  const handleGetShoppingBag = useCallback(
    async (shouldDisplayLoading = true) => {
      const token = await fetchAuthToken();
      shouldDisplayLoading && setIsLoading(true);
      return getShoppingBag({
        variables: {
          locale,
          currencyCode: getCurrencyCodeByLocale(locale),
          shoppingBagId: userShoppingBag?.id,
        },
        ...(token && {
          context: {
            headers: {
              Authorization: `Bearer ${token}`,
              "selected-store": Cookies.get(SELECTED_STORE) ?? undefined,
            },
          },
        }),
      });
    },
    [fetchAuthToken, getShoppingBag, locale, userShoppingBag?.id],
  );

  const handleGetInitialShoppingBag = useCallback(
    async (shouldDisplayLoading = true) => {
      const token = await fetchAuthToken();
      shouldDisplayLoading && setIsLoading(true);
      return getInitialShoppingBag({
        variables: {
          locale,
          currencyCode: getCurrencyCodeByLocale(locale),
          shoppingBagId: userShoppingBag?.id,
        },
        ...(token && {
          context: {
            headers: {
              Authorization: `Bearer ${token}`,
              "selected-store": Cookies.get(SELECTED_STORE) ?? undefined,
            },
          },
        }),
      });
    },
    [fetchAuthToken, getInitialShoppingBag, locale, userShoppingBag?.id],
  );

  const handleUpdateShoppingBagLocation = useCallback(
    async (locationId: string) => {
      const token = await fetchAuthToken();
      try {
        updateShoppingBagLocationId({
          variables: {
            locale,
            locationId,
            currencyCode: getCurrencyCodeByLocale(locale),
          },
          ...(token && {
            context: {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          }),
        });
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error : new Error(String(error));
        Logger.getLogger().error(errorMessage);
      }
    },
    [fetchAuthToken, locale, updateShoppingBagLocationId],
  );

  useEffect(() => {
    if (isPdpPage) {
      setIsLoading(false);
    }
  }, [isPdpPage]);

  useEffect(() => {
    const abandonedCart = searchParams.get("abandonedCart");

    if (
      config?.featureFlags?.abandonedCart?.enabled &&
      abandonedCart &&
      authProgressStatus === InteractionStatus.None
    ) {
      handleAddAbandonedProductsToShoppingBag();
    }
  }, [authProgressStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const abandonedCart = searchParams.get("abandonedCart");

    if (
      pathName?.includes(Routes.SHOPPING_BAG) &&
      (!config?.featureFlags?.abandonedCart?.enabled || !abandonedCart)
    ) {
      handleGetShoppingBag();
    }
  }, [isAuthenticated, handleGetShoppingBag, pathName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const shouldRequestShoppingBag =
      !pathName?.includes(Routes.LOGIN) &&
      !pathName?.includes(Routes.LOGOUT) &&
      !pathName?.includes(Routes.SHOPPING_BAG);

    if (shouldRequestShoppingBag) {
      handleGetInitialShoppingBag();
    }
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  const shoppingBag = useMemo(
    () =>
      ({
        userShoppingBag,
        initialUserShoppingBag,
        shoppingBagSkuMap,
        handleGetShoppingBag,
        handleSetUserShoppingBag,
        handleAddProductToShoppingBag,
        handleReplaceProductInShoppingBag,
        handleMoveProductToShoppingList,
        handleRemoveProductFromShoppingBag,
        handleUpdateShoppingBagLocation,
        isLoading: isLoading || updateLocationIsLoading,
        shouldShowAddToCartPopUp,
        latestAddedSku,
      }) as unknown as ShoppingBagContextData,
    [
      userShoppingBag,
      shoppingBagSkuMap,
      initialUserShoppingBag,
      handleGetShoppingBag,
      handleSetUserShoppingBag,
      handleAddProductToShoppingBag,
      handleReplaceProductInShoppingBag,
      handleMoveProductToShoppingList,
      handleRemoveProductFromShoppingBag,
      handleUpdateShoppingBagLocation,
      isLoading,
      shouldShowAddToCartPopUp,
      latestAddedSku,
      updateLocationIsLoading,
    ],
  );

  return (
    <ShoppingBagContext.Provider value={shoppingBag}>
      {children}
    </ShoppingBagContext.Provider>
  );
};
