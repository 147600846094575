"use client";

import React, { useContext } from "react";

import { Box, Button } from "@mui/material";
import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { getPreparedData } from "~/components/shopping-bag-page/components/filled-bag/components/shopping-bag-order-summary/helpers";
import { checkProductsWithErrors } from "~/components/shopping-bag-page/components/filled-bag/helpers/check-products-with-errors";
import { Routes } from "~/constants/request";
import { ShoppingBagContext } from "~/context/shopping-bag/context";
import { useStoreSelectorData } from "~/hooks/store-selector/use-store-selector-data";
import { useMinibagDYCampaignData } from "~/hooks/use-mini-bag-dy-campaign-data/hook";
import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";
import { getBaseUrl } from "~/utils/url/get-base-url";

import type { NavigationButtonsGroupProps } from "./types";

export const NavigationButtonsGroup = (props: NavigationButtonsGroupProps) => {
  const { locale } = useParams<GlobalPathParams>();

  const isEnabled =
    AzureConfigurator.getConfig(locale)?.featureFlagList
      ?.miniAddToBagCheckoutAndViewBagCTA?.enabled;

  const { isVisible, reportMinibagCampaignEngagement } = useMinibagDYCampaignData();

  const shoppingBagContext = useContext(ShoppingBagContext);

  const storeSelectorData = useStoreSelectorData(null);

  if (!isEnabled || isVisible === false) {
    return null;
  }

  const {
    hasCollectionPoint,
    hasRequiredMinPrice,
    hasExceededMaxPrice,
    hasMaxLineItems,
  } = getPreparedData({
    store: storeSelectorData?.currentStore ?? undefined,
    bag: shoppingBagContext?.userShoppingBag,
  });

  const goToCheckout = () =>
    (document.location.href = `${getBaseUrl()}${Routes.CHECKOUT}/${locale}`);

  const goToShoppingBag = () =>
    (document.location.href = `${getBaseUrl()}/${locale}${Routes.SHOPPING_BAG}`);

  const handleShoppingBagClick = () => {
    reportMinibagCampaignEngagement();

    goToShoppingBag();
  };

  const handleCheckoutClick = () => {
    reportMinibagCampaignEngagement();

    shoppingBagContext
      ?.handleGetShoppingBag?.(false)
      .then(({ data: { shoppingBag } }) => {
        const hasProductsWithErrors = checkProductsWithErrors(
          storeSelectorData.currentStore,
          shoppingBag ?? undefined,
        );

        const hasErrors = Boolean(
          hasProductsWithErrors ||
            !hasCollectionPoint ||
            hasExceededMaxPrice ||
            hasMaxLineItems ||
            !hasRequiredMinPrice,
        );

        hasErrors ? goToShoppingBag() : goToCheckout();
      });
  };

  return (
    <Box className={props?.className}>
      <Button variant="outlined" onClick={handleShoppingBagClick}>
        {props?.viewBagCTA}
      </Button>
      <Button variant="contained" onClick={handleCheckoutClick}>
        {props?.checkoutCTA}
      </Button>
    </Box>
  );
};
