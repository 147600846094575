import { useCallback, useMemo } from "react";

import { useIsAuthenticated } from "@azure/msal-react";
import { useRouter, useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { Routes } from "~/constants/request";
import { getLinkUrl } from "~/helpers/locales";

import { useLoginLogout } from "../use-login-logout/index";

interface ReturnType {
  goToProfile: (event: React.MouseEvent | React.KeyboardEvent) => void;
  isRouteProtected: (route: string) => boolean;
}

export const useProtectedRoutes = (): ReturnType => {
  const router = useRouter();
  const { locale } = useParams<GlobalPathParams>();

  const isUserAuthenticated = useIsAuthenticated();

  const { startLogin } = useLoginLogout(locale);

  const pathToProfile = useMemo(() => getLinkUrl(locale, Routes.PROFILE), [locale]);

  const goToProfile = useCallback(
    (e: React.MouseEvent | React.KeyboardEvent) => {
      if (!e.ctrlKey) {
        if (isUserAuthenticated) {
          router.push(pathToProfile);
        } else {
          e.preventDefault();

          startLogin(pathToProfile);
        }
      }
    },
    [isUserAuthenticated, pathToProfile, router, startLogin],
  );

  const isRouteProtected = useCallback((route: string) => {
    if (route?.includes(Routes.PROFILE)) {
      return true;
    }
    return false;
  }, []);

  return {
    goToProfile,
    isRouteProtected,
  };
};
