import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import type { Components } from "react-markdown";

import { CombinedLink } from "~/components/combined-link/component";
import { prepareTextAttribute } from "~/helpers/prepare-text-attribute";

export const config: Components = {
  p: ({ children }) => (
    <Typography>{children[0] === "[br]" ? <br /> : children}</Typography>
  ),
  em: ({ children }) => <Box component="em">{children}</Box>,
  strong: ({ children }) => <Box component="strong">{children}</Box>,
  h1: ({ children }) => <Typography variant="h1Regular">{children}</Typography>,
  h2: ({ children }) => <Typography variant="h2">{children}</Typography>,
  h3: ({ children }) => <Typography variant="h3">{children}</Typography>,
  h4: ({ children }) => <Typography variant="h4">{children}</Typography>,
  h5: ({ children }) => <Typography variant="h5">{children}</Typography>,
  h6: ({ children }) => <Typography variant="h6">{children}</Typography>,
  a: ({ children, href, title }) => (
    <CombinedLink href={href} title={prepareTextAttribute(title)}>
      {children as React.ReactNode}
    </CombinedLink>
  ), //as React.FC<AnchorHTMLAttributes<unknown>>,
  ul: "ul",
  ol: "ol",
  // remove empty lines before and after content in list items, but keep any empty lines inside
  li: ({ children }) => (
    <li>
      {children.filter(
        (child, i) =>
          (i !== 0 && i !== children.length - 1) ||
          typeof child !== "string" ||
          !!child.trim(),
      )}
    </li>
  ),
};

export const markdownConfigNewWindow = {
  ...config,
  a: ({ children, href }: { children: React.ReactNode; href: string }) => (
    <CombinedLink target="_blank" href={href}>
      {children}
    </CombinedLink>
  ),
};
