export const GEO_LOCATION_MOBILE_PREFIX = "GeoLocationMobile";

export const GEO_LOCATION_MOBILE_CLASS_NAMES = {
  desktopNotDisplay: `${GEO_LOCATION_MOBILE_PREFIX}-desktopNotDisplay`,
  tabletPaddings: `${GEO_LOCATION_MOBILE_PREFIX}-tabletPaddings`,
  block: `${GEO_LOCATION_MOBILE_PREFIX}-block`,
  divider: `${GEO_LOCATION_MOBILE_PREFIX}-divider`,
  text: `${GEO_LOCATION_MOBILE_PREFIX}-text`,
  helperText: `${GEO_LOCATION_MOBILE_PREFIX}-helperText`,
  tooltip: `${GEO_LOCATION_MOBILE_PREFIX}-tooltip`,
  tooltipPopper: `${GEO_LOCATION_MOBILE_PREFIX}-tooltipPopper`,
  toolTipBorders: `${GEO_LOCATION_MOBILE_PREFIX}-toolTipBorders`,
  tooltipText: `${GEO_LOCATION_MOBILE_PREFIX}-tooltipText`,
  closeButton: `${GEO_LOCATION_MOBILE_PREFIX}-closeButton`,
  arrow: `${GEO_LOCATION_MOBILE_PREFIX}-arrow`,
  backdrop: `${GEO_LOCATION_MOBILE_PREFIX}-backdrop`,
  searchContainer: `${GEO_LOCATION_MOBILE_PREFIX}-searchContainer`,
  control: `${GEO_LOCATION_MOBILE_PREFIX}-control`,
};
