import React from "react";

import { IconProps } from "./../types";

import { BaseSVGIcon } from "./base-svg-icon";

export const Close12: React.FC<IconProps> = (props) => {
  return (
    <BaseSVGIcon width="12" height="12" fill="none" viewBox="0 0 12 12" {...props}>
      <path
        d="M2.97998 3.02002L8.99498 9.00502"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M2.98999 8.98988L9.00499 3.00488"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </BaseSVGIcon>
  );
};
