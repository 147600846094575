import { useContext, useMemo } from "react";

import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { DynamicYieldContext } from "~/context/dynamic-yield/context";
import { useAzureConfigurator } from "~/services/azure-configurator/use-azure-configurator";
import { getTestCampaignChoicesFromResponse } from "~/utils/dynamic-yield/get-campaign-choices-from-response";
import { isDyCampaignEnabled } from "~/utils/dynamic-yield/is-dy-campaign-enabled";
import { DyCampaignName } from "~/utils/dynamic-yield/types";

export const useIsEnabled = () => {
  const { locale } = useParams<GlobalPathParams>();
  const config = useAzureConfigurator(locale);

  const isLoqateSearchEnabledInAzure =
    config?.featureFlags?.loqateLocationSearch?.enabled;

  const dyContext = useContext(DynamicYieldContext);

  const dyChooseResponseData = dyContext ? dyContext.chooseResponseData : null;

  const isLoqateDyTestEnabled = useMemo(
    () => isDyCampaignEnabled(DyCampaignName.LoqateTesting, locale),
    [locale],
  );

  // If 'Locate Api Testing' AB test is disabled (at all, not per current page)
  // we should fall back to the value of 'loqateLocationSearch' feature flag.
  // Otherwise - we should use the value from the AB test
  if (!isLoqateDyTestEnabled) {
    return isLoqateSearchEnabledInAzure;
  }

  const dyChoiceData = getTestCampaignChoicesFromResponse(
    dyChooseResponseData,
    DyCampaignName.LoqateTesting,
    locale,
  );

  // If 'Locate Api Testing' AB test is disabled per current page
  // we should fall back to the 'false' value (google api usage)
  const loqateDyTestData = dyChoiceData?.variations[0]?.payload?.data;
  return loqateDyTestData?.isLoqateApiEnabled || false;
};
