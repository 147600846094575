"use client";

import React, { useMemo } from "react";

import { JsonLdProps } from "~/bff/components/JsonLd";
import { useSeoMetaTags } from "~/components/seo-meta-tags/helpers";
import { replacePlaceholders } from "~/utils/jsonLd";

export const JsonLd: React.FC<JsonLdProps> = ({ data }) => {
  const { jsonLDConfig: config } = useSeoMetaTags();

  const result = useMemo(() => {
    if (!data) {
      return null;
    }

    let object = JSON.parse(data);
    object = replacePlaceholders(object, config);

    return JSON.stringify(object, null, 2);
  }, [data, config]);

  return result ? (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: result }}
    />
  ) : null;
};
